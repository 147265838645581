<template>
  <div class="h-full w-full map-min-height relative">
    <div :id="id" class="h-full"></div>
    <div
      class="map-loading-overlay top-0 left-0 flex justify-center items-center"
      v-show="getCurrentLocationLoading"
    >
      <div class="h-20 w-20">
        <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
      </div>
    </div>
    <div
      class="absolute left-2.5 top-20 flex flex-col justify-center items-center"
      v-if="edit"
    >
      <button
        class="btn bg-white border-2 border-gray-400 px-2 map-input"
        @click="getCurrentLocation"
      >
        <MapPinIcon class="w-4 h-4" />
      </button>
    </div>
    <div
      class="absolute right-3 top-3 flex flex-col md:flex-row map-input justify-end items-end"
      v-if="edit"
      ref="latlngInput"
    >
      <input
        :value="lat"
        type="number"
        inputmode="decimal"
        class="form-control mr-1 my-0.5 w-3/5"
        placeholder="ละติจูด"
        @blur="handleChangeLat"
        @keyup.enter="handleChangeLat"
      />
      <input
        :value="lng"
        type="number"
        inputmode="decimal"
        class="form-control my-0.5 w-3/5"
        placeholder="ลองติจูด"
        @blur="handleChangeLng"
        @keyup.enter="handleChangeLng"
      />
    </div>
  </div>
</template>

<script setup>
import * as L from "leaflet";
import { onMounted, reactive, watch, computed, ref } from "vue";
// const OPEN_STREET_MAP = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const OSM_MAPNIK_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const GOOGLE_SATELLITE = "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
const MAPNIK_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const WORLD_URL =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: "mapid",
  },
  lat: {
    type: Number,
    default: 13.720564133220474,
  },
  lng: {
    type: Number,
    default: 100.49031257629396,
  },
});
const emits = defineEmits(["update:lat", "update:lng"]);

const getCurrentLocationLoading = ref(false);
const latlngInput = ref(null);
let map;
let marker;
onMounted(() => {
  createMap();
  createMarker();
  watch(
    () => props.edit,
    (newval) => {
      if(props.lat === 13.720564133220474 && props.lng === 100.49031257629396){
        getCurrentLocation()
      }
      if (newval) marker.dragging.enable();
      else marker.dragging.disable();
    }
  );
  watch(
    () => props.lat,
    () => {
      if(props.lat && props.lng){
        marker.setLatLng({ lat: props.lat, lng: props.lng });
        map.setView({ lat: props.lat, lng: props.lng });
      }
    }
  );
  watch(
    () => props.lng,
    () => {
      if(props.lat && props.lng){
        marker.setLatLng({ lat: props.lat, lng: props.lng });
        map.setView({ lat: props.lat, lng: props.lng });
      }
    }
  );
});
const createMap = () => {
  var OSM_Mapnik = L.tileLayer(OSM_MAPNIK_URL, {
    // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
    maxZoom: 19,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  });

  var google_map = L.tileLayer(GOOGLE_SATELLITE, {
    maxZoom: 19,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });

  var Mapnik = L.tileLayer(MAPNIK_URL, {
    maxZoom: 19,
  });

  var WorldImagery = L.tileLayer(WORLD_URL, {
    maxZoom: 19,
  });

  var baseMaps = {
    Carto: OSM_Mapnik,
    "Google Maps": google_map,
    Mapnik: Mapnik,
    "World Imagery": WorldImagery,
  };

  // create map
  map = L.map(props.id, {
    layers: [OSM_Mapnik],
  })
  map.setView([props.lat, props.lng], 13);

  L.control.layers(baseMaps, {}, { position: "bottomleft" }).addTo(map);
};

const createMarker = () => {
  marker = L.marker([props.lat, props.lng], {
    draggable: props.edit,
  }).addTo(map);
  marker.on("move", handleMarkerMove);
};

const handleMarkerMove = ({ latlng }) => {
  emits("update:lat", latlng.lat);
  emits("update:lng", latlng.lng);
};

const getCurrentLocation = () => {
  getCurrentLocationLoading.value = true;
  // on success
  const success = async (position) => {
    const latitude = await position.coords.latitude;
    const longitude = await position.coords.longitude;
    const result = { lat: latitude, lng: longitude };
    handleMarkerMove({ latlng: result });
    getCurrentLocationLoading.value = false;
  };
  // on error
  const error = () => {
    alert("กรุณาอนุญาติการเข้าถึงพื้นที่");
    getCurrentLocationLoading.value = false;
  };
  // This will open permission popup
  navigator.geolocation.getCurrentPosition(success, error);
};

const handleChangeLat = (evt) => {
  emits("update:lat", parseFloat(evt.target.value));
};
const handleChangeLng = (evt) => {
  emits("update:lng", parseFloat(evt.target.value));
};

</script>

<style lang="scss" scoped>
.map-input {
  z-index: 1001;
}
.map-loading-overlay {
  z-index: 1002;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  text-align: center;
}

// hide arrow

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.map-min-height {
  // min-height: 400px;
  min-height: 300px;
}
</style>
