<template>
  <section data-test="filter-person-section" class="grid grid-cols-12 gap-4">
    <div class="mt-2 col-span-12 sm:col-span-6">
      <!-- <h4 class="text-base font-normal leading-none text-theme-33">
        เลขบัตรประชาชน
      </h4> -->
      <label class="from-label text-medium text-gray-600">เลขบัตรประชาชน</label>
      <input
        class="form-control"
        type="text"
        placeholder="กรุณากรอกเลขบัตรประชาชน"
        :value="national_id"
        @input="handelNationalIdChange"
        oninput="if(this.value.length > 13){this.value = this.value.slice(0, 13);};this.value = this.value.replace(/[^\d]/g, '')"
      >
    </div>
    <div class="mt-2 col-span-12 sm:col-span-6">
      <!-- <h4 class="text-base font-normal leading-none text-theme-33 mt-3">
        ชื่อ-นามสกุล
      </h4> -->
      <label class="from-label text-medium text-gray-600">ชื่อ-นามสกุล</label>
      <input
        class="form-control"
        type="text"
        placeholder="กรุณากรอกชื่อ-นามสกุล"
        :value="full_name"
        @input="handelFullNameChange"
      >
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  national_id: String,
  full_name: String,
});

const emits = defineEmits(["update:national_id", "update:full_name"]);

const handelNationalIdChange = (evt) => {
  emits("update:national_id", evt.target.value);
};
const handelFullNameChange = (evt) => {
  emits("update:full_name", evt.target.value);
};
</script>
