<template>
    <div>
        <button class="btn btn-primary bg-primary-btn border-primary-btn whitespace-pre" @click="openModal = true">
            <PlusIcon class="h-3 w-3" /> เพิ่มหัวข้อย่อย
        </button>
        <ModalLayout
            id="modal-header-form"
            v-model="openModal"
            modal-size="modal-lg"
            closeButton
            :header="`${isEdit ? 'แก้ไข' : 'เพิ่ม'}หัวข้อย่อย`"
            @click:save="submitForm"
            @click:cancel="clearModal"
            :isDone="isDone"
        >
            <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="col-span-12">
                    <!-- <div class="mt-2"> -->
                        <div class="form-checkbox-custom pl-6">
                            <label
                                class="form-check-label align-middle flex items-baseline"
                                for="has-header"
                            >
                                <input
                                    id="has-header"
                                    class="form-check-input align-middle"
                                    type="checkbox"
                                    value="true"
                                    v-model="form.has_header"
                                    :disabled="false"
                                > ไม่มีหัวข้อย่อย
                            </label>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="col-span-12" v-if="!form.has_header">
                    <label class="form-label">
                        ชื่อหัวข้อย่อย
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="header"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อหัวข้อย่อย"
                        data-test="header"
                        :disabled="false"
                        :class="{ 'border-theme-24': validate.header.$error}"
                        v-model="validate.header.$model"
                    />
                    <template v-if="validate.header.$error">
                        <div
                            v-for="(error, index) in validate.header.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12" v-if="filteredHeaderList.length">
                    <CustomAccordion
                        accordion-id="order_header_list"
                        headerText="เลือกตำแหน่งหัวข้อย่อยเพื่อกำหนดลำดับการแสดงผลโดยหัวข้อย่อยปัจจุบันจะแทนที่ตำแหน่งที่เลือก"
                    >
                        <template v-slot:body-content>
                            <div class="col-span-12 ml-[-1rem] mr-[-1rem]">
                                <hr>
                                <!-- <div class="p-4">
                                    <label class="form-label">
                                        ค้นหา
                                    </label>
                                    <input
                                        id="search_question_list_order"
                                        type="text"
                                        class="form-control"
                                        placeholder="พิมพ์คำค้นหาที่นี่"
                                        data-test="search_question_list_order"
                                        :disabled="false"
                                        v-model="searchWordOrder"
                                    />
                                </div> -->
                                <div v-for="(data, index) in filteredHeaderList" :key="index">
                                    <label
                                        class="form-check-label align-middle flex items-center hover:bg-gray-100 ml-0" 
                                        :for="`position-${index}`"
                                        :class="{'bg-gray-100' : form.new_position == data.position}"
                                    >
                                        <div class="p-5">
                                            <div class="header flex items-center">
                                                <input
                                                    :id="`position-${index}`"
                                                    class="form-check-header-input align-middle mr-2"
                                                    type="checkbox"
                                                    :value="data.position"
                                                    :disabled="data.disabled"
                                                    v-model="form.new_position"
                                                >
                                                {{data.header ? data.header : 'ไม่มีหัวข้อย่อย'}}
                                            </div>
                                        </div>
                                    </label>
                                    <hr class="mr-4 ml-4">
                                </div>
                            </div>
                        </template>
                    </CustomAccordion>
                </div>
            </div>
        </ModalLayout>
    </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import ModalLayout from "@/components/base/custom-modal/CustomModalLayout.vue"
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import CustomAccordion from "@/components/base/custom-accordion/index.vue";
import axios from "axios";
import { useStore } from "vuex";
import Swal from "sweetalert2"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const props =  defineProps({
    currentStep: {
        type: Object
    },
    editData:{
        type: Object
    }
})

const emit = defineEmits(["saveSuccess"])

const store = useStore()
const openModal = ref(false)
const headerList = ref([])
const isEdit = ref(false)
const isDone = ref(false)
const form = reactive({
  header: null,
  step_id: null,
  position: null,
  new_position: [],
  has_header: false,
  current_position: null,
  temp_position: null,
  id: null
})
const formrules = computed(()=>{
  return {
    header: {
      required: helpers.withMessage(`กรุณาระบุ`, requiredIf(() => !form.has_header)),
    },
  }
})

const validate = useVuelidate(formrules, form)
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};

const clearModal = (isUpdate = null) => {
    openModal.value = false
    validate.value.$reset()
    isDone.value = false
    isEdit.value = false
    form.header = null
    form.step_id = null
    form.position = null
    form.new_position = []
    form.has_header = false
    form.current_position = null
    form.id = null
    setTimeout(()=>{
        emit("saveSuccess",isUpdate)
    },500)
}

const submitForm = () => {
    validate.value.$touch();
    if (validate.value.$invalid) {
        return;
    }
    swalCustom.fire({
        text: "ยืนยันบันทึกข้อมูล",
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
    }).then(async(result)=>{
        if (result.isConfirmed) {
            try{
                const config_axios = {
                    method: isEdit.value ? 'PUT' : 'POST',
                    url: `${process.env.VUE_APP_BASE_API}admin/quesion_header${isEdit.value ? `/${form.id}` : ``}`,
                    data: {
                        step_id: props.currentStep.id,
                        header: form.has_header ? null : form.header
                    },
                    headers: headers
                }
                if(form.position && !form.current_position && !isEdit.value){
                    config_axios.data["position"] = form.position
                }
                if(form.current_position){
                    config_axios.data["position"] = form.position
                    config_axios.data["current_position"] = form.current_position
                }
                const response = await axios(config_axios).catch(()=>{
                    swalCustom.fire({
                        text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                        icon: "warning",
                        // timer: 3000, //timeOut for auto-close
                        confirmButtonText: "ปิด",
                    });
                })
                if(response.status == 200){
                    // swalCustom.fire({
                    //     text:"สำเร็จ",
                    //     icon: 'success',
                    //     timer: 3000,
                    //     confirmButtonText: "ปิด",
                    // }).then(() => {
                    isDone.value = true
                    clearModal(true)
                    // });
                }
            }
            catch{
                swalCustom.fire({
                    text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                    icon: "warning",
                    // timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                });
            }
        }
    })
}

const getHeaderList = async () => {
    const url = `${process.env.VUE_APP_BASE_API}admin/quesion_header`;
    const res = await axios.get(url, { headers }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        headerList.value = res.data
        // loadingQuestion.value = !loadingQuestion.value
        // surveyStore.setItem('cwd', JSON.parse(JSON.stringify(res.data)))
    }
}

const filteredHeaderList = computed(()=>{
    if(openModal.value){
        let headerArr = headerList.value.filter((header)=> header.step_id == props.currentStep.id).sort((a,b)=> a.position - b.position)
        headerArr.map(item=>{
            item.disabled = false
            return item
        })
        return headerArr
    }
    return []
})

watch(filteredHeaderList, (value) => {
    if(openModal.value){
        if(!isEdit.value){
            form.temp_position = value.length ? value[value.length-1]?.position + 1 : 1
            form.position = form.temp_position
        }
    }
})


watch(()=>form.new_position, (value)=>{
    filteredHeaderList.value.map((item)=>{
        if(value?.[0] && (item.position != value?.[0]))
            item.disabled = true
        else
            item.disabled = false
    })
    if(value?.length){
        if(!isEdit.value){
            form.position = value[0]
        }
        else{
            form.current_position = form.temp_position
            form.position = value[0]
        }
    }
    else{
        form.position = form.temp_position
        form.current_position = null
    }
})

watch(()=>props.editData,(val)=>{
    if(val){
        isEdit.value = true
        openModal.value = true
        form.header = val.header
        form.id = val._id
        form.temp_position = val.position
        if(!form.header)
            form.has_header = true
    }
})
onMounted(()=>{
    getHeaderList()
})
</script>

<style>

</style>