<template>
  <BarChart
    type="bar"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
    customLegend
  />
</template>

<script setup>
import {  computed } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  chartLabel:{
    type: Array,
    default: ()=>[]
  },
  series:{
    type: Array,
    default: ()=> []
  },
})
const data = computed(() => {
  return {
    labels: props.chartLabel,
    datasets: [
      {
        label: 'เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)',
        barPercentage: 1,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 2,
        backgroundColor: '#1C4E80',
        data: props.series
      },
      {
        label: 'วัยเรียน (อายุ 5-14 ปี)',
        barPercentage: 1,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 2,
        backgroundColor: '#0091D5',
        data: [12,33,42,21,66]
      },
      {
        label: 'วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)',
        barPercentage: 1,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 2,
        backgroundColor: '#A5D8DD',
        data: [20,10,28,10, 24]
      },
      {
        label: 'วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)',
        barPercentage: 1,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 2,
        backgroundColor: '#EA6A47',
        data: [9,19,20,11, 43]
      },
      {
        label: 'วัยผู้สูงอายุ (60 ปีขึ้นไป)',
        barPercentage: 1,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 2,
        backgroundColor: '#DBAE58',
        data: [22,23,55,42,0]
      }
    ]
  }
})
const options = computed(() => {
  return {
    indexAxis: 'y',
    scales: {
      x: {
        display: false,
        stacked: true,
        // grid: {
        //   display: false,
        // },
      },
      y: {
        stacked: true,
        // display: false,
        // grid: {
        //   display: false,
        //   drawBorder: false,
        // },
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        callbacks: {
          title: function(tooltipItems, data) {
           let sum = 0;
            tooltipItems.forEach(function(tooltipItem) {
              sum += tooltipItem.raw;
            });
            return `${tooltipItems[0].label} (รวม: ${new Intl.NumberFormat().format(sum)})`;
          },
        }
      },
      legend: {
        labels: {
          fontSize: 14,
          boxWidth: 6
        },
        display: false,
        position: "bottom",
        align: "start",
        // onClick: function(e, legendItem, legend){
        //   const index = legendItem.datasetIndex;
        //   const type = legend.chart.config.type;
        //   console.log("ind", index, type);
          
        // }
      }
    },
    // legend: {
    //     labels: {
    //       fontSize: 14,
    //       boxWidth: 6
    //     },
    //     position: 'bottom',
    //     align: "start"
    // },
    responsive:true,
  }
})

</script>
