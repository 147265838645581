<template>
  <section data-test="filter-address-section" class="grid grid-cols-12 gap-4">
    <!-- <div class="mt-3">
      <h5 class="text-base font-normal leading-none text-theme-33">
        ที่อยู่
      </h5>
    </div> -->
    <div class="mt-2 col-span-12 sm:col-span-6">
      <label class="from-label text-medium text-gray-600" for="province-select"
        >จังหวัด</label
      >
      <v-select
        :disabled="disabledProvince"
        data-test="province-select"
        id="province-select"
        :loading="provinceOptionsLoading"
        v-model="provinceComputed"
        :options="provinceOptions"
        label="province_name"
        :clearable="true"
        placeholder="กรุณาเลือกจังหวัด"
        @update:modelValue="handelProvinceSelected"
        :appendToBody="true"
      >
        <template #no-options="">
          <p class="text-sm font-normal text-gray-500">
            ไม่พบข้อมูล
          </p>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <ChevronDownIcon class="w-5 h-5"
          /></span>
        </template>
      </v-select>
    </div>

    <div class="mt-2 col-span-12 sm:col-span-6">
      <label class="from-label text-medium text-gray-600" for="amphur-select"
        >เขต/อำเภอ</label
      >
      <v-select
        :disabled="disabledAmphur"
        data-test="amphur-select"
        id="amphur-select"
        :loading="amphurOptionsLoading"
        v-model="amphurComputed"
        :options="amphurOptions"
        label="amphur_name"
        :clearable="true"
        placeholder="กรุณาเลือกเขต/อำเภอ"
        @update:modelValue="handelAmphurSelected"
        :appendToBody="true"
      >
        <template #no-options="">
          <p class="text-sm font-normal text-gray-500">
            ไม่พบข้อมูล
          </p>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <ChevronDownIcon class="w-5 h-5"
          /></span>
        </template>
      </v-select>
    </div>

    <div class="mt-2 col-span-12 sm:col-span-6">
      <label class="from-label text-medium text-gray-600" for="tumbol-select"
        >แขวง/ตำบล</label
      >
      <v-select
        :disabled="disabledTumbol"
        data-test="tumbol-select"
        id="tumbol-select"
        :loading="tumbolOptionsLoading"
        v-model="tumbolComputed"
        :options="tumbolOptions"
        label="tumbol_name"
        :clearable="true"
        placeholder="กรุณาเลือกแขวง/ตำบล"
        @update:modelValue="handelTumbolSelected"
        :appendToBody="true"
      >
        <template #no-options="">
          <p class="text-sm font-normal text-gray-500">
            ไม่พบข้อมูล
          </p>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <ChevronDownIcon class="w-5 h-5"
          /></span>
        </template>
      </v-select>
    </div>

    <div class="mt-2 col-span-12 sm:col-span-6">
      <label class="from-label text-medium text-gray-600" for="village-select"
        >หมู่บ้าน</label
      >
      <v-select
        data-test="village-select"
        id="village-select"
        :loading="villageOptionsLoading"
        v-model="villageComputed"
        :options="villageOptions"
        label="village_name"
        :clearable="true"
        placeholder="กรุณาเลือกหมู่บ้าน"
        :appendToBody="true"
      >
        <template #no-options="">
          <p class="text-sm font-normal text-gray-500">
            ไม่พบข้อมูล
          </p>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <ChevronDownIcon class="w-5 h-5"
          /></span>
        </template>
      </v-select>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useStore } from "vuex";

const disabledAmphur = ref(false);
const disabledTumbol = ref(false);
const disabledProvince = ref(false);

// declear varible
const props = defineProps({
  province: Object,
  amphur: Object,
  tumbol: Object,
  village: Object,
  parentComponent: {
    type: String,
    require: true,
  },
});
const emits = defineEmits([
  "update:province",
  "update:amphur",
  "update:tumbol",
  "update:village",
]);

const provinceComputed = computed({
  get() {
    return props.province;
  },
  set(evt) {
    emits("update:province", evt);
  },
});
const amphurComputed = computed({
  get() {
    return props.amphur;
  },
  set(evt) {
    emits("update:amphur", evt);
  },
});
const tumbolComputed = computed({
  get() {
    return props.tumbol;
  },
  set(evt) {
    emits("update:tumbol", evt);
  },
});
const villageComputed = computed({
  get() {
    return props.village;
  },
  set(evt) {
    emits("update:village", evt);
  },
});


const store = useStore();
const provinceOptions = ref([]);
const provinceOptionsLoading = ref(true);
const amphurOptions = ref([]);
const amphurOptionsLoading = ref(false);
const tumbolOptions = ref([]);
const tumbolOptionsLoading = ref(false);
const villageOptions = ref([]);
const villageOptionsLoading = ref(false);
const keyCokeProv = computed(
  () => parseInt(store.getters["userAuth/getUserProvinceCode"]) // ["userAuth/getTokenParsed"]["provinceCode"] // ตัวเลือกจังหวัดในหน้าครัวเรือน เงื่อนไขการค้นหา
);
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
};

onMounted(async () => {
  if(onlineComputed.value)
    await getProvinceOptions();
  // await getAmphurOptions();
  let startProv;
  let startAmp;
  if (props.parentComponent === "household")
    startProv = await store.getters["householdFilter/province"];
  if (props.parentComponent === "member")
    startProv = await store.getters["memberFilter/province"];
  if (props.parentComponent === "neets")
    startProv = await store.getters["neetsFilter/province"];
  if (props.parentComponent === "cwd")
    startProv = await store.getters["cwdFilter/province"];
  if (props.parentComponent === "dashboard")
    startProv = await store.getters["dashboardFilter/province"];
  if (props.parentComponent === "dashboard-cwd-neets")
    startProv = await store.getters["dashboardCWDNEETsFilter/province"];

  //set current province from keycloak if no province data from store
  if (!startProv && keyCokeProv.value) {
    const temp = await provinceOptions.value.filter(
      (prov) => prov._id.province_ID === String(keyCokeProv.value)
    )[0];
    provinceComputed.value = JSON.parse(JSON.stringify(temp));
    disabledProvince.value = true



    // if (!startAmp) {
    //   await getAmphurOptions();
    //     const tempAmp = await amphurOptions.value.filter(
    //       (amp) =>
    //         amp._id.amphur_ID ==
    //         parseInt(store.getters["userAuth/getUserAmphurCode"])
    //     );
    //     if (props.parentComponent === "household") {
    //       await store.dispatch("householdFilter/setAmphur", tempAmp);
    //     }
    //     amphurComputed.value = JSON.parse(JSON.stringify(tempAmp));
    //   }
  }
});

const getProvinceOptions = async () => {
  provinceOptionsLoading.value = true;
  const url = `${process.env.VUE_APP_BASE_API}refadms/`;
  const res = await axios.get(url, {headers});
  if (res.status === 200) {
    provinceOptions.value = res.data.sort((a, b) =>
      a.province_name.localeCompare(b.province_name,'th')
    );
    if(keyCokeProv.value){
      let prov_data = res.data.find(x=> x._id.province_ID == keyCokeProv.value)
      if(prov_data){
        provinceComputed.value = prov_data
        disabledProvince.value = true;
      }
    }
  } else {
    Swal.fire({
      icon: "warning",
      // title: "",
      text: "ระบบผิดพลาด",
    });
  }
  provinceOptionsLoading.value = false;
};
// getProvinceOptions();

const getAmphurOptions = async () => {
  amphurOptionsLoading.value = true;
  const url = `${process.env.VUE_APP_BASE_API}refadms/?prov=${provinceComputed.value._id.province_ID}`;
  const res = await axios.get(url, {headers});
  if (res.status === 200) {
    amphurOptions.value = res.data.sort((a, b) =>
      a.amphur_name.localeCompare(b.amphur_name,'th')
    );
    if(store.getters["userAuth/getUserAmphurCode"].length == 4){
      let amp_data = res.data.find(x=> x._id.amphur_ID == store.getters["userAuth/getUserAmphurCode"])
      if(amp_data){
        amphurComputed.value = amp_data
        disabledAmphur.value = true;
      }
    }
  } else {
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    });
  }
  amphurOptionsLoading.value = false;
};

const getTumbolOptions = async () => {
  tumbolOptionsLoading.value = true;
  const url = `${process.env.VUE_APP_BASE_API}refadms/?prov=${provinceComputed.value._id.province_ID}&amp=${amphurComputed.value._id.amphur_ID}`;
  const res = await axios.get(url, {headers});
  if (res.status === 200) {
    tumbolOptions.value = res.data.sort((a, b) =>
      a.tumbol_name.localeCompare(b.tumbol_name,'th')
    );
    //check len cuz case user province level have area_code 2 digit and tumbolCode getter fn set from area_code then tumbol code is 2 so tumbol value not correct format
    if(store.getters["userAuth/getUserTumbolCode"].length == 6){
      let tam_data = res.data.find(x=> x._id.tumbol_ID == store.getters["userAuth/getUserTumbolCode"])
      if(tam_data){
        tumbolComputed.value = tam_data
        disabledTumbol.value = true;
      }
    }
  } else {
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    });
  }
  tumbolOptionsLoading.value = false;
};

const getVillageOptions = async () => {
  villageOptionsLoading.value = true;
  const url = `${process.env.VUE_APP_BASE_API}refadms/?prov=${provinceComputed.value._id.province_ID}&amp=${amphurComputed.value._id.amphur_ID}&tam=${tumbolComputed.value._id.tumbol_ID}`;
  const res = await axios.get(url, {headers});
  if (res.status === 200) {
    villageOptions.value = res.data.sort((a, b) =>
      a.village_name.localeCompare(b.village_name, undefined, { numeric: true })
    );
  } else {
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    });
  }
  villageOptionsLoading.value = false;
};

const handelProvinceSelected = (evt) => {
  amphurComputed.value = null;
  tumbolComputed.value = null;
  villageComputed.value = null;
};

watch(provinceComputed, () => {
  if (provinceComputed.value) {
    getAmphurOptions();
  } else {
    amphurOptions.value = [];
    tumbolOptions.value = [];
    villageOptions.value = [];
  }
});
watch(amphurComputed, () => {
  if (amphurComputed.value) getTumbolOptions();
  else {
    tumbolOptions.value = [];
    villageOptions.value = [];
  }
});
watch(tumbolComputed, () => {
  if (tumbolComputed.value) getVillageOptions();
  else villageOptions.value = [];
});

const handelAmphurSelected = () => {
  tumbolComputed.value = null;
  villageComputed.value = null;
};

const handelTumbolSelected = () => {
  villageComputed.value = null;
};
</script>

<style lang="scss" scoped>
.t-tooltip-text {
  left: -40px;
}
</style>
