<template>
  <div>
    <div class="mt-0 p-0">
      <div
        class="grid grid-cols-12 gap-4 gap-y-5 items-baseline"
        data-test="form-step4"
      >
        <div class="col-span-12" v-if="isNotSaved">
          <SurveyAlert />
        </div>
        <div class="col-span-12">
            <div
              :id="data.question_code"
              tabindex="-1"
              class="accordion accordion-boxed"
              :class="[{ 'mt-5': key > 0 }, {'relative' : isAdminManagement}]"
              v-for="(data, key) in question_group4"
              :key="key"
            >
              <div class="absolute right-1 flex gap-1 z-10" :class="data.question_type == 'header' ? 'top-3' : 'top-1'" v-if="isAdminManagement">
                <input
                  class="form-check-switch"
                  type="checkbox"
                  :checked="data.status == 'active'"
                  @change="updateStatusChange($event, data)"
                  v-if="data.question_type != 'header'"
                  :id="`input-check-switch-${data.question_code}`"
                >
                <div class="dropdown">
                  <button class="dropdown-toggle btn bg-transparent p-[2px] rounded border-0 shadow-none rotate-90" aria-expanded="false">
                    <MoreHorizontalIcon class="w-5 h-4"/>
                  </button>
                  <div class="dropdown-menu w-48">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left text-primary hover:bg-gray-200" @click="addEvent(data)" v-if="data.question_type == 'header'">
                            <PlusIcon class="w-4 h-4 mr-2" /> เพิ่มคำถาม
                          </button>
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="emit('editQuestion', data)" v-if="data.question_type != 'header'">
                            <EditIcon class="w-4 h-4 mr-2" /> แก้ไข
                          </button>
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left text-theme-24/[0.8] hover:bg-gray-200" @click="emit('deleteQuestion',data._id)" v-if="!data.edit_only && data.question_type != 'header'">
                            <Trash2Icon class="w-4 h-4 mr-2 " /> ลบ
                          </button>
                      </div>
                  </div>
                </div>
              </div>
              <!-- <div class="my-2 text-base font-bold" v-if="data.question_type == 'header'">{{data.question_desc}}</div> -->
              <div class="accordion-item" v-if="data.question_type == 'header' ? checkQuestionAnswer(data) : (getRelateResult(data) && checkRelateAge(data.relate_age_month))" :class="{'!py-2 bg-[#eaf0ff]' : data.question_type == 'header', '!border-theme-24' : validate.formObj?.[data.question_code]?.$error, '!pt-6' : data.question_type != 'header'}">
                <div class="grid grid-cols-12 gap-3">
                  <div class="col-span-12 lg:col-span-6">
                    <div
                      :id="`question-accordion-content-${key}`"
                      class="accordion-header"
                    >
                      <!-- <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#problem-accordion-collapse-${key}`"
                        aria-expanded="true"
                        :aria-controls="`problem-accordion-collapse-${key}`"
                      > -->
                        <span class="text-[#142E71] whitespace-pre-line" :class="{'text-base' : data.question_type == 'header'}">{{ !data.is_checkbox_group && !data.is_multiple_answers_type_except ? data.question_code : null }} {{ data.question_desc ? data.question_desc : data.question_type == 'header' ? 'ไม่มีหัวข้อย่อย': '' }} {{data.question_type == "radio" ? ' (เลือกตอบเพียง 1 ข้อ)' : data.question_type == "checkbox" ? ' (เลือกตอบได้มากกว่า 1 ข้อ)' : ''}}<span class="text-theme-24 text-md" v-if="data.required"> *</span></span>
                      <!-- </button> -->
                    </div>
                    <!-- <div
                      :id="`problem-accordion-collapse-${key}`"
                      class="accordion-collapse collapse show"
                      :aria-labelledby="`problem-accordion-content-${key}`"
                      :data-bs-parent="`#problem-accordion-${key}`"
                    > -->
                    <div
                      class="
                        accordion-body
                        text-gray-700
                        dark:text-gray-600
                        leading-relaxed
                        px-2
                        md:px-4
                        items-baseline
                        mt-3
                      "
                      :class="{'gap-3 grid grid-cols-12' : data.question_type != 'radio' && data.question_type != 'checkbox'}"
                      v-if="data.question_type != 'header'"
                    >
                      <div class="gap-3 grid grid-cols-12" v-if="data.question_type == 'radio' || data.question_type == 'checkbox'">
                        <div
                          class="col-span-12"
                          :class="{'sm:col-span-6': data.is_column}"
                          v-for="(choice, key2) in data.choices"
                          :key="key2"
                        >
                          <!-- checkbox -->
                          <div class="form-checkbox-custom pl-6" v-if="data.question_type == 'checkbox'">
                            <div class="mr-3">
                              <label
                                class="form-check-label align-middle flex items-baseline"
                                :for="`form-input-problem-${choice.option.code}`"
                              >
                                <div class="order-wrapper" v-if="choice.option.order">
                                  <div class="order-number text-white">{{choice.option.order}}</div>
                                </div>
                                <input
                                  :id="`form-input-problem-${choice.option.code}`"
                                  class="form-check-input align-middle"
                                  type="checkbox"
                                  :value="choice.option"
                                  v-model="form.formObj[data.question_code]"
                                  :disabled="isView || choice.disable || choice.required"
                                  @change="data.answer_limit ? handleOrder($event, data.question_code, data.answer_limit) : null; handleMultiple(key, key2)"
                                  :has-order="data.answer_limit ? true : false"
                                >{{ data.is_checkbox_group ? choice.option.code +' '+ choice.option.desc : choice.option.desc}}
                              </label>
                            </div>
                            <div v-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code) && choice.is_multiple_answer">
                              <div class="flex gap-2 items-start" :class="{'mt-2': ans_idx != 0}" v-for="(ans, ans_idx) in choice.option.answers" :key="ans_idx">
                                <div class="grid grid-cols-12 gap-2 flex-grow" v-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code)">
                                  <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                                    <label v-if="spf.label">{{spf.label}}</label>
                                    <div class="flex items-center">
                                      <div class="relative flex-grow">
                                        <input
                                          :type="spf.type"
                                          class="form-control"
                                          :placeholder="spf.message"
                                          v-model="ans[spf.key]"
                                          :disabled="isView || spf.disable"
                                          :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.answers?.[0]?.$response?.$errors?.[ans_idx]?.[spf.key].length && !isView, 'pr-[62px]' : spf.type == 'number' }"
                                          @input="handleInput($event, spf_index, data, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), key2, spf, ans_idx)"
                                          :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                          @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                        >
                                        <div
                                          v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                          class="absolute right-1 top-0 h-full mt-[6px]"
                                        >
                                          <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : ans[spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="ans[spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), ans_idx)">
                                            <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                          </button>
                                          <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), ans_idx)">
                                            <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                          </button>
                                        </div>
                                      </div>
                                      <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                    </div>

                                    <template v-if="validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.answers?.[0]?.$response?.$errors?.[ans_idx]?.[spf.key]?.length && !isView">
                                      <div
                                        v-for="(error, index) in validate.formObj[data.question_code].$each.$response.$errors[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)].answers[0].$response.$errors[ans_idx][spf.key]"
                                        :key="index"
                                        class="text-theme-24 mt-1 text-sm"
                                      >
                                        {{ error.$message }}
                                      </div>
                                    </template>
                                  </div>
                                </div>
                                <button class="btn btn-primary bg-primary-btn border-primary-btn" :class="{'mt-[26px]':choice.specify_value.some(({label}) => label)}" @click="addMoreItem(key, key2)" v-if="ans_idx == choice.option.answers.length-1 && !isView">
                                  <PlusIcon class="h-4 w-4" />
                                </button>
                                <button class="btn btn-danger bg-theme-24 border-theme-24" :class="{'mt-[26px]':choice.specify_value.some(({label}) => label)}"  @click="removeItem(key, key2, ans_idx)" v-else-if="!isView">
                                  <MinusIcon class="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            <div class="grid grid-cols-12 gap-2" v-else-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code)">
                              <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                                <label v-if="spf.label">{{spf.label}}</label>
                                <div class="flex items-center">
                                  <div class="relative flex-grow">
                                    <input
                                      :type="spf.type"
                                      class="form-control"
                                      :placeholder="spf.message"
                                      v-model="form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key]"
                                      :disabled="isView || spf.disable"
                                      :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.[spf.key]?.length && !isView, 'pr-[62px]' : spf.type == 'number' }"
                                      @input="handleInput($event, spf_index, data, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), key2, spf)"
                                      :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                      @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                    >
                                    <div
                                      v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                      class="absolute right-1 top-0 h-full mt-[6px]"
                                    >
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code))">
                                        <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                      </button>
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code))">
                                        <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                      </button>
                                    </div>
                                  </div>
                                  <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                </div>

                                <template v-if="validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.[spf.key]?.length && !isView">
                                  <div
                                    v-for="(error, index) in validate.formObj[data.question_code].$each.$response.$errors[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key]"
                                    :key="index"
                                    class="text-theme-24 mt-1 text-sm"
                                  >
                                    {{ error.$message }}
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                          <!-- radio -->
                          <div v-if="data.question_type == 'radio'">
                            <div class="mr-3">
                              <input
                                :id="`form-input-${choice.option.code}`"
                                class="form-check-input align-middle"
                                type="radio"
                                v-model="form.formObj[data.question_code]"
                                :value="choice.option"
                                :disabled="isView"
                                @click="uncheckedChoice(data.question_code, $event); handleMultiple(key, key2)"
                              >
                              <label
                                class="form-check-label align-middle"
                                :for="`form-input-${choice.option.code}`"
                              >{{choice.option.desc}}</label>
                            </div>
                            <div v-if="choice.specify_value && (form.formObj[data.question_code]?.code == choice.option.code) && choice.is_multiple_answer">
                              <div class="flex gap-2 pl-6 items-start" :class="{'mt-2': ans_idx != 0}" v-for="(ans, ans_idx) in choice.option.answers" :key="ans_idx">
                                <div class="grid grid-cols-12 gap-2 flex-grow">
                                  <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                                    <label v-if="spf.label">{{spf.label}}</label>
                                    <div class="flex items-center">
                                      <div class="relative flex-grow">
                                        <input
                                          :type="spf.type"
                                          class="form-control"
                                          :placeholder="spf.message"
                                          v-model="ans[spf.key]"
                                          :disabled="isView || spf.disable"
                                          :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.answers?.$each?.$response?.$errors?.[ans_idx]?.[spf.key]?.length, 'pr-[62px]' : spf.type == 'number' }"
                                          :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                          @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                          @input="handleInput($event, spf_index, data, null, key2, spf, ans_idx)"
                                        >
                                        <div
                                          v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                          class="absolute right-1 top-0 h-full mt-[6px]"
                                        >
                                          <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : ans[spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="ans[spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, null, ans_idx)">
                                            <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                          </button>
                                          <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, null, ans_idx)">
                                            <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                          </button>
                                        </div>
                                      </div>
                                      <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                    </div>

                                    <template v-if="validate?.formObj?.[data.question_code]?.answers?.$each?.$response?.$errors?.[ans_idx]?.[spf.key]?.length">
                                      <div
                                        v-for="(error, index) in validate.formObj[data.question_code].answers.$each.$response.$errors[ans_idx][spf.key]"
                                        :key="index"
                                        class="text-theme-24 mt-1 text-sm"
                                      >
                                        {{ error.$message }}
                                      </div>
                                    </template>
                                  </div>
                                </div>
                                <button class="btn btn-primary bg-primary-btn border-primary-btn" :class="{'mt-[26px]':choice.specify_value.some(({label}) => label)}" @click="addMoreItem(key, key2)" v-if="ans_idx == choice.option.answers.length-1 && !isView">
                                  <PlusIcon class="h-4 w-4" />
                                </button>
                                <button class="btn btn-danger bg-theme-24 border-theme-24" :class="{'mt-[26px]':choice.specify_value.some(({label}) => label)}"  @click="removeItem(key, key2, ans_idx)" v-else-if="!isView">
                                  <MinusIcon class="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            <div class="grid grid-cols-12 gap-2 pl-6" v-else-if="choice.specify_value && (form.formObj[data.question_code]?.code == choice.option.code)">
                              <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf,spf_index) in choice.specify_value" :key="spf_index">
                                <label v-if="spf.label">{{spf.label}}</label>
                                <div class="flex items-center">
                                  <div class="relative flex-grow">
                                    <input
                                      :type="spf.type"
                                      class="form-control"
                                      :placeholder="spf.message"
                                      v-model="form.formObj[data.question_code][spf.key]"
                                      :disabled="isView || spf.disable"
                                      :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.[spf.key]?.$error || validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid, 'pr-[62px]' : spf.type == 'number' }"
                                      :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                      @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                      @input="handleInput($event, spf_index, data, null, key2, spf)"
                                    >
                                    <div
                                      v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                      class="absolute right-1 top-0 h-full mt-[6px]"
                                    >
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="form.formObj[data.question_code][spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2)">
                                        <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                      </button>
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2)">
                                        <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                      </button>
                                    </div>
                                  </div>
                                  <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                </div>

                                <template v-if="validate?.formObj?.[data.question_code]?.[spf.key]?.$error || validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid">
                                  <div
                                    class="text-theme-24 mt-1 text-sm"
                                    v-if="validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid && !validate.formObj[data.question_code][spf.key]?.isLessThan?.$invalid"
                                  >
                                    {{ validate.formObj[data.question_code][spf.key].required.$message }}
                                  </div>
                                  <div
                                  v-if="validate.formObj[data.question_code][spf.key]?.isLessThan?.$invalid"
                                    class="text-theme-24 mt-1 text-sm"
                                  >
                                    {{ validate.formObj[data.question_code][spf.key].isLessThan.$message }}
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 lg:col-span-12 2xl:col-span-6" v-if="data.question_type == 'select'">
                        <v-select
                          :data-test="data.question_code"
                          :options="data.choices"
                          :clearable="!data.required"
                          :searchable="true"
                          :placeholder="data.placeholder"
                          v-model="form.formObj[data.question_code]"
                          :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                          :disabled="isView || data.disable"
                          label="label"
                          :reduce="label=>label.label"
                        >
                          <template #no-options="{}">
                            ไม่พบข้อมูล
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"
                              ><ChevronDownIcon class="w-5 h-5"
                            /></span>
                          </template>
                        </v-select>
                      </div>
                      <div class="col-span-12" v-if="data.question_type == 'multiple-inputs-answer'">
                        <div class="grid grid-cols-12 gap-3">
                          <div class="col-span-12 sm:col-span-6 lg:col-span-12 2xl:col-span-6" v-for="inp in data.choices" :key="inp">
                            <label v-if="inp.label">{{inp.label}}</label>
                            <div class="flex items-center">
                              <div class="relative flex-grow">
                                <input
                                  :type="inp.type == 'number' ? 'number' : 'text'"
                                  class="form-control"
                                  v-model="form.formObj[data.question_code][inp.key]"
                                  :disabled="isView"
                                  :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error, 'pr-[62px]' : inp.type == 'number' }"
                                  :min="inp.type == 'number' && !inp.is_negative ? '0' : null"
                                  @keypress="handleTypeNumber(inp.type, $event, null, inp.is_decimal, inp.is_negative)"
                                />
                                <div
                                  v-if="inp.type == 'number' && !isView && !inp.is_decimal"
                                  class="absolute right-1 top-0 h-full mt-[6px]"
                                >
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][inp.key] <= 0 && !inp.is_negative}" :disabled="form.formObj[data.question_code][inp.key] <= 0 && !inp.is_negative" @click="handleButtonIncreaseDecrease('decrease', data, inp)">
                                    <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                  </button>
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" @click="handleButtonIncreaseDecrease('increase', data, inp)">
                                    <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                  </button>
                                </div>
                              </div>
                              <div class="ml-2" v-if="inp.unit">{{inp.unit}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 lg:col-span-12 2xl:col-span-6" v-if="data.question_type == 'text' || data.question_type == 'number'">
                        <label v-if="data.question_label">{{data.question_label}}</label>
                        <div class="relative flex-grow">
                          <input
                            :type="data.question_type == 'number' ? 'number' : 'text'"
                            class="form-control"
                            :placeholder="data.placeholder ? data.placeholder : data.required_message"
                            v-model="form.formObj[data.question_code]"
                            @input="setValidate(data.question_code,(data.required || data.max_length_message)); handleAcceptInputType(data)"
                            :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error, 'pr-[62px]' : data.question_type == 'number' }"
                            :oninput="data.max_length && data.question_type == 'text' ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : null"
                            :maxlength="data.max_length"
                            :disabled="isView || data.disable"
                            :min="data.question_type == 'number' && !data.is_negative ? '0' : null"
                            @keypress="handleTypeNumber(data.question_type, $event, data.max_length, data.is_decimal, data.is_negative)"
                          />
                          <div
                            v-if="data.question_type == 'number' && !isView && !data.is_decimal"
                            class="absolute right-1 top-0 h-full mt-[6px]"
                          >
                            <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code] <= 0 && !data.is_negative || data.disable}" :disabled="form.formObj[data.question_code] <= 0 && !data.is_negative || data.disable" @click="handleButtonIncreaseDecrease('decrease', data)">
                              <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                            </button>
                            <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : data.disable}" :disabled="data.disable" @click="handleButtonIncreaseDecrease('increase', data)">
                              <PlusIcon class="h-4 w-4 !stroke-2.5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="(validate?.formObj?.[data.question_code]?.$error && data.question_type == 'radio' && validate?.formObj?.[data.question_code]?.$model?.code == undefined) || (validate?.formObj?.[data.question_code]?.$error && (data.question_type == 'text' || data.question_type == 'select' || data.question_type == 'number')) || (validate?.formObj?.[data.question_code]?.$error && validate?.formObj?.[data.question_code]?.$model?.length == 0)">
                      <div
                        v-for="(error, index) in validate.formObj[data.question_code].$errors"
                        :key="index"
                        class="text-theme-24 mt-1 text-sm px-2 md:px-4"
                      >
                        {{ error.$message }}
                      </div>
                    </template>
                    <template v-else-if="validate?.formObj?.[data.question_code]?.$error && data.question_type == 'multiple-inputs-answer'">
                      <div class="text-theme-24 mt-1 text-sm">
                        {{ validate.formObj[data.question_code].required.$message}}
                      </div>
                    </template>
                  </div>
                  <div :id="`accordion-desc4-${key}`" class="accordion col-span-12 lg:col-span-6" data-test="question-desc" v-if="data.additional_detail">
                    <div>
                      <div class="accordion-header" :id="`accordion-header-desc4-${key}`">
                        <div
                            class="accordion-button box shadow-none cursor-pointer flex justify-between items-center"
                            :class="{'collapsed': isDefaultHide}"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#accordion-desc4-collapse-${key}`"
                            :aria-expanded="!isDefaultHide ? true : false"
                            :aria-controls="`#accordion-desc4-collapse-${key}`"
                        >
                          <span>คำอธิบาย<PlayIcon class="h-3 w-4 rotate-90" /></span>
                        </div>
                      </div>
                      <div
                        :id="`accordion-desc4-collapse-${key}`"
                        class="accordion-collapse collapse !mt-0 information-detail"
                        :aria-labelledby="`accordion-header-desc4-${key}`"
                        :data-bs-parent="`accordion-desc4-${key}`"
                        :class="{'show' : !isDefaultHide}"
                        :style="isDefaultHide ? { display: 'none'} : {display: 'block'}"
                      >
                        <div class="whitespace-pre-line p-3 bg-gray-200 rounded-md" v-html="data.additional_detail"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
        </div>
        
      </div>
    </div>
    <div
      class="col-span-12 flex items-center mt-10 justify-between"
    >
      <div>
        <button
          type="button"
          class="btn btn-outline-secondary mr-1"
          data-test="cancel-btn"
          @click="closeModal"
        >
          ออก
        </button>
        <button
          class="btn btn-secondary ml-2"
          @click="skipStep"
        >
          ข้าม
        </button>
      </div>
      <div>
        <button class="btn btn-secondary ml-2" @click="prevStep">
          ย้อนกลับ
        </button>
        <button class="btn btn-primary ml-2" @click="isView || isAdminManagement ? emit('nextStepView', 4) : nextStep()" :disabled="isDisabledSubmitBtn">
          {{isView || isAdminManagement ? 'ถัดไป' : 'บันทึกและถัดไป'}}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, toRefs, computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf, minLength, maxLength, requiredUnless } from "@vuelidate/validators";
import Swal from "sweetalert2";
import SurveyAlert from "@/components/survey-alert"

const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const emit = defineEmits(["closeModal", "nextStep", "prevStep", "skipStep", "nextStepView", "editQuestion", "deleteQuestion", "updateStatus", "addQuestion"]);
const props = defineProps({
  memberData: {type: Object},
  isView: {type: Boolean, default: false},
  currentAgeMonth: {type: Number},
  isDefaultHide:{type: Boolean, default: false},
  questionList: {type: Array},
  isAdminManagement:{
    type: Boolean,
    default: false
  },
  isDisabledSubmitBtn: {type: Boolean, default: false},
  updateResult: {type: Object},
  checkValidate: {type: Object}
});
const isNotSaved = ref(false)
const question_group4 = reactive(
  props.questionList.find(x=> x.step.step_number == 4).questions.reduce((acc,v)=>{
    if(v.header_info){
      acc = [...acc, {
        question_type: "header",
        question_desc: v.header,
        question_list: v.question_lists.map(({question_code})=> question_code),
        choices: [],
        header_info: v.header_info
      }]
    }
    return [...acc, ...v.question_lists]
  },[])
)
const form = reactive({
  formObj:{
  }
});

function checkQuestionType(question){
  if(question.question_type === "checkbox"){
    //set default to checkbox in case choice has required property
    if(question.choices.some(({required})=>required)){
      let temp =[]
      for(let item of question.choices){
        if(item.required){
          temp.push(item.option)
        }
      }
      return temp
    }
    return []
  }else if(question.question_type === "radio" || question.question_type === "multiple-inputs-answer"){
    return {}
  }else{
    return null
  }
}

const getRelateResult = ({question_code, question_type, relate_question, choices}) => {
  if(props.isAdminManagement)
    return true
  if(relate_question.length == 0)
    return true
  //check current question which appear or not with a relate question have answer
  let tempQuestionAppear = []
  //current relate_question type have only 3 types below
  for(const {code, answer, type} of relate_question){
    let has_answer
    if(type == "radio"){
      has_answer = answer.includes(form.formObj[code]?.code)
    }
    else if(type == "checkbox"){
      has_answer = form.formObj[code].findIndex(x => answer.includes(x.code)) != -1 ? true : false
    }
    else if(type == "number"){//for type number answer more than 0
      has_answer = form.formObj[code] > 0
    }
    else if(type == "select"){
      has_answer = answer.includes(form.formObj[code])
    }
    else if(type == "multiple-inputs-answer"){
      has_answer = !Object.values(form.formObj[code]).every(x=>x == "" || x == null)
    }
    else{
      has_answer = form.formObj[code] ? true : false
    }
    //check current question when relate question's answer not include then clear current question's answer to default
    //checkbox required in choice, not clear this question code to []
    if(!has_answer && (
      ((question_type == "radio" || question_type == "multiple-inputs-answer") && Object.keys(form.formObj[question_code]).length > 0) || (question_type == "checkbox" && form.formObj[question_code].length > 0 && !choices.some(({required})=>required)) || (question_type == "text" || question_type == "number" || question_type == "date" || question_type == "select" || question_type == "province" || question_type == "district" || question_type == "education") && form.formObj[question_code])
    ){
      const type = checkQuestionType({question_code, question_type, relate_question, choices})
      form.formObj[question_code] = type
    }
    tempQuestionAppear = [...tempQuestionAppear, has_answer]
  }
  return tempQuestionAppear.every(elm => elm)
}
const checkRelateAge = ({start, end}) =>{
  if(props.isAdminManagement)
    return true
  if(typeof start == "number" && typeof end == "number")
    return (props.currentAgeMonth >= start) && (props.currentAgeMonth <= end)
  else if(typeof start == "number" && !end)
    return props.currentAgeMonth >= start
  return true
}

//for survey also have question with age range and required
//params choice for required choice check required only and will not set question answer to default
const checkRequired = (v, choice) =>{
  let check_question = true, check_age = true;
  if(v.relate_question)
    check_question = getRelateResult(v);
  if(v.relate_age_month)
    check_age = checkRelateAge(v.relate_age_month)

  //if check both get false this question should clear answer as default to prevent save this question answer behind. e.g. IN049 when age not match
  if(!(check_question && check_age) && !choice){
    const type = checkQuestionType(v)
    form.formObj[v.question_code] = type
  }

  return check_question && check_age
}

let initDataForm = question_group4.filter(({question_type}) => question_type != "header").reduce((a,v)=>
  {
    //init form obj from question list and map new input in side question type = multiple-inputs-group
    let newObj = {}
    if(v.question_type == "multiple-inputs-group"){
      let temp = v.choices.reduce((acc,val)=>({...acc, [val.question_code]: checkQuestionType(val)}),{})
      a = {...a, ...temp}
    }
    else{
      newObj = {
        [v.question_code]: checkQuestionType(v)
      }
    }
    return {...a, ...newObj}
  }
,{})

form.formObj = Object.assign(form.formObj, initDataForm)

const rules = computed(()=>{
  let hasRequired = question_group4.filter(e=>e.required || e.choices.some(({specify_value, required}) => specify_value || required) || e.max_length_message || e.required_selected_other)
  let requiredFieldObj = hasRequired.reduce((a,v)=>{
    let requiredField = {}
    if(v.question_type == "radio"){
      let set_required = {}
      let specify_value = {}
      let multiple_answers = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredIf(()=> checkRequired(v)) : required),
        }
      }

      if(v.choices.some(({specify_value})=>specify_value)){
        let temp_is_multiple = []
        let temp_is_not_multiple = []
        let temp_specify_value = v.choices.reduce((acc, val)=>{
          if(val.specify_value){
            for(let i of val.specify_value){
              if(val.is_multiple_answer){
                let idx = temp_is_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_multiple = [...temp_is_multiple, i]
              }
              else{
                let idx = temp_is_not_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_not_multiple = [...temp_is_not_multiple, i]
              }
            }
          }
          return {...acc, is_multiple_answer: temp_is_multiple, is_not_multiple_answer: temp_is_not_multiple}
        },{})

        let validation = {}
        let array_validation = {}
        if(temp_specify_value?.is_multiple_answer?.length && form.formObj[v.question_code].hasOwnProperty("answers")){
          for(let i of temp_specify_value.is_multiple_answer){
            let max_value_temp ={}
            if(i.is_max_value && temp_specify_value.is_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            array_validation = {
              ...array_validation,
              [i.key]: {
                required: helpers.withMessage(i.message, requiredIf(()=>form.formObj[v.question_code]?.answers?.length)),
                ...max_value_temp
              }
            }
          }
          multiple_answers = {
            answers: {
              $each: helpers.forEach({
                ...array_validation
              })
            }
          }
        }
        if(temp_specify_value?.is_not_multiple_answer?.length){
          for(let i of temp_specify_value.is_not_multiple_answer){
            let max_value_temp = {}
            if(i.is_max_value && temp_specify_value.is_not_multiple_answer.length > 1 && form.formObj[v.question_code].hasOwnProperty(i.key)){
              const value_input = temp_specify_value.is_not_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            validation = {...validation,
              [i.key]: {
                required: helpers.withMessage(i.message, requiredIf(()=>form.formObj[v.question_code].hasOwnProperty(i.key))),
                ...max_value_temp
              }
            }
          }
        }
        specify_value = {
          ...validation
        }
      }

      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...specify_value,
          ...multiple_answers
        }
      }
    }
    else if(v.question_type == "checkbox"){
      let set_required = {}
      let specify_value = {}
      let multiple_answers = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredIf(()=> checkRequired(v)) : required),
        }
      }

      if(v.choices.some(({specify_value})=>specify_value)){
        let temp_is_multiple = []
        let temp_is_not_multiple = []
        let temp_specify_value = v.choices.reduce((acc, val)=>{
          if(val.specify_value){
            for(let i of val.specify_value){
              if(val.is_multiple_answer){
                let idx = temp_is_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_multiple = [...temp_is_multiple, i]
              }
              else{
                let idx = temp_is_not_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_not_multiple = [...temp_is_not_multiple, i]
              }
            }
          }
          return {...acc, is_multiple_answer: temp_is_multiple, is_not_multiple_answer: temp_is_not_multiple}
        },{})

        let validation = {}
        let array_validation = {}
        if(temp_specify_value?.is_multiple_answer?.length){
          for(let i of temp_specify_value.is_multiple_answer){
            let max_value_temp ={}
            if(i.is_max_value && temp_specify_value.is_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            array_validation = {
              ...array_validation,
              [i.key]: {
                required: helpers.withMessage(i.message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredUnless(()=> !checkRequired(v, "choice")) : required),
                ...max_value_temp
              }
            }
          }
          multiple_answers = {
            answers: {
              $each: helpers.forEach({
                ...array_validation
              })
            }
          }
        }
        if(temp_specify_value?.is_not_multiple_answer?.length){
          for(let i of temp_specify_value.is_not_multiple_answer){
            let max_value_temp = {}
            if(i.is_max_value && temp_specify_value.is_not_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_not_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            validation = {...validation,
              [i.key] :{
                required: helpers.withMessage(i.message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredUnless(()=> !checkRequired(v, "choice")) : required),
                ...max_value_temp
              }
            }
          }
        }

        specify_value = {
          $each: helpers.forEach({
            ...validation,
            ...multiple_answers
          })
        }
      }
      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...specify_value
        }
      }
    }
    else if(v.question_type == "text" || v.question_type == "date" || v.question_type == "select" || v.question_type == "number"){
      let set_required = {}
      let required_max_length = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredIf(()=> checkRequired(v)) : required),
        }
      }
      if(v.max_length_message){
        required_max_length = {
          minLength: helpers.withMessage(
            v.max_length_message,
            minLength(v.max_length)
          ),
          maxLength: helpers.withMessage(
            v.max_length_message,
            maxLength(v.max_length)
          ),
        }
      }
      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...required_max_length
        }
      }
    }
    else if(v.question_type == "multiple-inputs-answer"){
      let validation = {
        required: helpers.withMessage(v.required_message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredIf(()=> checkRequired(v)) : required),
      }
      for(let i of v.choices){
        validation = {...validation,
          [i.key] :{
            required: helpers.withMessage(v.required_message, v.relate_question.length || Object.keys(v.relate_age_month).length ? requiredIf(()=> checkRequired(v)) : required)
          }
        }
      }
      requiredField = {
        [v.question_code]: {
          ...validation
        }
      }
    }
    return (
      {
        ...a,
        ...requiredField
      }
    )
  }
  ,{})

  return {
    formObj:{
      ...requiredFieldObj
    }
  }
})

const validate = useVuelidate(rules, toRefs(form));


const skipStep= () => {
  emit('skipStep', 4)
}

const setValidate = (field, isRequired) =>{
  if(isRequired){
    validate.value.formObj[field].$touch()
  }
  return false
}
const handleAcceptInputType = (data) => {
  if(data.question_type == 'text' && data.is_only_number){
    form.formObj[data.question_code] = form.formObj[data.question_code].replace(/[^\d]/g, "");
  }
  else if(data.question_type == 'text' && data.is_only_alphabet){
    form.formObj[data.question_code] = form.formObj[data.question_code].replace(/[^A-Za-z\u0E00-\u0E4F\s]|\u0E3F/g, "");
  }
  return;
};
const nextStep = (isCheckValidate = null) => {
  isNotSaved.value = false
  validate.value.$touch()
  if (validate.value.$invalid) {
    //check from validate object
    for (let key of Object.keys(validate.value.formObj)) {
      // Remove special properties
      if (key.includes("$")) continue
      if (validate.value.formObj[key].$error) {
        document.getElementById(key).focus()
        break;
      }
    }
    return;
  }
  if(!isCheckValidate){
    emit("nextStep", form.formObj, 4);
  }
  else{
    isNotSaved.value = true
  }
};

const closeModal = () => {
  emit("closeModal");
};

function prevStep() {
  // emit("prev-step", currStep.value);
  emit("prevStep", 4);
}


const checkQuestionAnswer = (data) =>{
  if(props.isAdminManagement)
    return true
  if(!data.question_desc)//no header text in case publish survey
    return false
  //each question code in data check below if some true then show header
  return data.question_list.some((item)=>{
    //get idx of this question to get relate_question or relate_age_month to check condition to show question
    //check condition with the same condition to show question in dom
    const idx = question_group4.findIndex(x => x.question_code == item)
    return getRelateResult(question_group4[idx]) && checkRelateAge(question_group4[idx].relate_age_month)
  })
}


function handleOrder(event, question, limit){
  const q_idx = question_group4.findIndex(x=>x.question_code == question)

  if(event.target.checked == false){
    const ch_idx = question_group4[q_idx].choices.findIndex(x=>x.option.code == event.target._value.code)
    question_group4[q_idx].choices[ch_idx].option.order = null
  }
  if(form.formObj[question].length <= limit){
    for(let i = 0 ; i < form.formObj[question].length; i++){
      form.formObj[question][i].order = i+1
    }
  }
  if(form.formObj[question].length >= limit){
    question_group4[q_idx].choices.map((item)=>{
      if(!form.formObj[question].some(({code})=>item.option.code == code))
        item.disable = true
    })
  }
  else{
    question_group4[q_idx].choices.map((item)=>item.disable = false)
  }
}
watch(()=> props.memberData,(val)=>{
  if(val.member_surveys){
    const dataEdit = JSON.parse(JSON.stringify(props.memberData.member_surveys))
    for(const item of Object.keys(form.formObj)){
      for (const [key, value] of Object.entries(dataEdit)) {
        if((item == key) && (value || typeof value == "number")){
          form.formObj[key] = value
          const q_idx = question_group4.findIndex(x=>x.question_code == key)
          if(question_group4[q_idx]?.question_type == "checkbox"){
            form.formObj[key].forEach((elm, index, arr) => {
              const idx = question_group4[q_idx].choices.findIndex(x => x.option.code == elm.code);
              if(idx != -1){
                for(let i = 0 ; i < question_group4[q_idx].choices[idx]?.specify_value?.length; i++){
                  if(question_group4[q_idx].choices[idx].is_multiple_answer){
                    question_group4[q_idx].choices[idx].option.answers = elm.answers
                  }
                  else{
                    question_group4[q_idx].choices[idx].option[question_group4[q_idx].choices[idx].specify_value[i].key] = elm[question_group4[q_idx].choices[idx].specify_value[i].key]
                  }
                  //check relate key to disabled next input if answer 0
                  if(question_group4[q_idx].choices[idx].specify_value[i].key != "other" && question_group4[q_idx].choices[idx]?.specify_value?.length > 1){
                    if(elm[question_group4[q_idx].choices[idx].specify_value[i].key] == 0 && question_group4[q_idx].choices[idx].specify_value[i+1]){
                      question_group4[q_idx].choices[idx].specify_value[i+1]["disable"] = true
                    }
                    else if(question_group4[q_idx].choices[idx].specify_value[i+1]){
                      question_group4[q_idx].choices[idx].specify_value[i+1]["disable"] = false
                    }
                  }
                }
                //check for question with limit answer
                if(question_group4[q_idx].answer_limit){
                  question_group4[q_idx].choices[idx].option.order = elm.order
                  if(form.formObj[key].length >= question_group4[q_idx].answer_limit){
                    question_group4[q_idx].choices.map((item)=>{
                      if(!form.formObj[key].some(({code})=>item.option.code == code))
                        item.disable = true
                    })
                  }
                  else{
                    question_group4[q_idx].choices.map((item)=>item.disable = false)
                  }
                }
                arr[index] = question_group4[q_idx].choices[idx].option
              }
            });
          }
          else if(question_group4[q_idx]?.question_type == "radio"){
            if(form.formObj[key] ? Object.keys(form.formObj[key]).length > 0 : false){
              const idx = question_group4[q_idx].choices.findIndex(x => x.option.code == form.formObj[key].code);
              if(idx != -1){
                for(let i = 0 ; i < question_group4[q_idx].choices[idx]?.specify_value?.length; i++){
                  if(question_group4[q_idx].choices[idx].is_multiple_answer){
                    question_group4[q_idx].choices[idx].option.answers = form.formObj[key].answers
                  }
                  else{
                    question_group4[q_idx].choices[idx].option[question_group4[q_idx].choices[idx].specify_value[i].key] = form.formObj[key][question_group4[q_idx].choices[idx].specify_value[i].key]
                  }
                }
                form.formObj[key] = question_group4[q_idx].choices[idx].option
              }
            }
          }
        }
      }
    }
  }
})
const handleInput = (evt, curr_input_index, question, ans_idx, choice_idx, current_input, mul_ans_idx) =>{
  const q_idx = question_group4.findIndex(x => x.question_code == question.question_code)
  if(question_group4[q_idx].choices[choice_idx].specify_value.length <= 1)
    return ;
  for(let i = 0 ; i < question_group4[q_idx].choices[choice_idx].specify_value.length; i++){
    if(i > 0 && question_group4[q_idx].choices[choice_idx].specify_value[i].type == "number" && current_input.type == "number" && current_input.key != "other" && i > curr_input_index){
      if(!question_group4[q_idx].choices[choice_idx].is_multiple_answer){
        if(evt.target.value && evt.target.value == 0){
          question_group4[q_idx].choices[choice_idx].specify_value[i].disable = true
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
          else{
            form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
        }else{
          question_group4[q_idx].choices[choice_idx].specify_value[i].disable = false
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
          else{
            form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
        }
      }
      else{
        if(evt.target.value && evt.target.value == 0){
          question_group4[q_idx].choices[choice_idx].specify_value[i].disable = true
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
          else{
            form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
        }else{
          question_group4[q_idx].choices[choice_idx].specify_value[i].disable = false
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
          else{
            form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
        }
      }
    }
  }
}
const uncheckedChoice = (name,evt) =>{
  if(form.formObj[name]?.code == evt.target._value?.code){
    form.formObj[name] = {}
  }
}
const updateStatusChange = (evt, data) => {
  swalCustom
  .fire({
      text: `ยืนยัน ${evt.target.checked ? `เปิดการใช้งาน` : `ปิดการใช้งาน`}`,
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,
  })
  .then((result) => {
    if (result.isConfirmed) {
      emit("updateStatus", data)
    }
    if(result.dismiss === Swal.DismissReason.cancel){
      evt.target.checked = !evt.target.checked
    }
  });
}

const handleTypeNumber = (type, event, have_max_length = null, is_decimal = null, is_negative = null) => {
  if(type != "number")
    return;
  let max_length_value = false,
  negative_value = true,
  decimal_value = true
  if(have_max_length){
    max_length_value = event.target.value.length == have_max_length
  }
  if(is_negative){
    negative_value = event.charCode != 45
  }
  if(is_decimal){
    event.target.setAttribute("step", "any")
    decimal_value = event.charCode != 46
  }
  return ((event.charCode != 8 && event.charCode == 0) || (negative_value && decimal_value && !(event.charCode >= 48 && event.charCode <= 57)) || max_length_value) && event.preventDefault()
}
const handleMultiple = (question_idx, choice_idx) => {
  if(Array.isArray(question_group4[question_idx].choices[choice_idx].option?.answers)){
    if(question_group4[question_idx].choices[choice_idx].option.answers.length == 0){
      let obj = {}
      for(let k of question_group4[question_idx].choices[choice_idx].specify_value){
        obj = {
          ...obj,
          [k.key]: null
        }
      }
      question_group4[question_idx].choices[choice_idx].option.answers.push(obj)
    }
  }
  return;
}

const addMoreItem = (question_idx, choice_idx) =>{
  let obj = {}
  for(let k of question_group4[question_idx].choices[choice_idx].specify_value){
    obj = {
      ...obj,
      [k.key]: null
    }
  }
  question_group4[question_idx].choices[choice_idx].option.answers.push(obj)
}

const removeItem = (question_idx, choice_idx, item_idx) =>{
  question_group4[question_idx].choices[choice_idx].option.answers.splice(item_idx, 1)
}

const handleButtonIncreaseDecrease = (action, question, current_input, curr_input_index, choice_idx, ans_idx, mul_ans_idx) =>{
  const q_idx = question_group4.findIndex(x => x.question_code == question.question_code)
  if(curr_input_index >= 0){
    if(question.question_type == "checkbox"){
      if(action == "increase"){
        if(mul_ans_idx >= 0){
          if(typeof form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key]++
        }else{
          if(typeof form.formObj[question.question_code][ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code][ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx][current_input.key]++
        }
      }else{
        if(mul_ans_idx >= 0){
          if(form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key]--
        }
        else{
          if(form.formObj[question.question_code][ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx][current_input.key]--
        }
      }
    }
    else{//radio
      if(action == "increase"){
        if(mul_ans_idx >= 0){
          if(typeof form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key]++
        }
        else{
          if(typeof form.formObj[question.question_code][current_input.key] != 'number')
            form.formObj[question.question_code][current_input.key] = 0
          else
            form.formObj[question.question_code][current_input.key]++
        }
      }else{
        if(mul_ans_idx >= 0){
          if(form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key]--
        }
        else{
          if(form.formObj[question.question_code][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][current_input.key] = 0
          else
            form.formObj[question.question_code][current_input.key]--
        }
      }
    }

    if(question_group4[q_idx].choices[choice_idx].specify_value.length <= 1)
      return ;
    for(let i = 0 ; i < question_group4[q_idx].choices[choice_idx].specify_value.length; i++){
      if(i > 0 && question_group4[q_idx].choices[choice_idx].specify_value[i].type == "number" && current_input.type == "number" && current_input.key != "other" && i > curr_input_index){
        if(!question_group4[q_idx].choices[choice_idx].is_multiple_answer){
          if(form.formObj[question.question_code][ans_idx][current_input.key] == 0){
            question_group4[q_idx].choices[choice_idx].specify_value[i].disable = true
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
            else{
              form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
          }else{
            question_group4[q_idx].choices[choice_idx].specify_value[i].disable = false
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
            else{
              form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
          }
        }
        else{
          if(form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] == 0){
            question_group4[q_idx].choices[choice_idx].specify_value[i].disable = true
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
            else{
              form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
          }else{
            question_group4[q_idx].choices[choice_idx].specify_value[i].disable = false
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
            else{
              form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code]["answers"][mul_ans_idx][question_group4[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
          }
        }
      }
    }
  }
  else{
    if(current_input){//multiple-answer-inputs question
      if(action == "increase"){
        if(typeof form.formObj[question.question_code][current_input.key] != 'number')
          form.formObj[question.question_code][current_input.key] = 0
        else
          form.formObj[question.question_code][current_input.key]++
      }
      else{
        if(form.formObj[question.question_code][current_input.key] < 1 && !current_input.is_negative)
          form.formObj[question.question_code][current_input.key] = 0
        else
          form.formObj[question.question_code][current_input.key]--
      }
    }else{//input number question
      if(action == "increase"){
        if(typeof form.formObj[question.question_code] != 'number')
          form.formObj[question.question_code] = 0
        else
          form.formObj[question.question_code]++
      }else{
        if(form.formObj[question.question_code] < 1 && !question.is_negative)
          form.formObj[question.question_code] = 0
        else
          form.formObj[question.question_code]--
      }

    }
  }
}
const addEvent = (curr_header) => {
  emit("addQuestion", curr_header)
}

watch(()=> props.updateResult, (val)=>{
  if(val){
    let input = document.getElementById(`input-check-switch-${val.question_code}`)
    if(val.status == 'active'){
      input.checked = true
    }
    else{
      input.checked = false
    }
  }
})

watch(()=> props.checkValidate, (val) => {
  if(val?.step?.step_number == 4){
    setTimeout(()=> nextStep(true), 500)
  }
})
</script>
<style lang="scss">
.accordion-item {
  border-color: #ced2d7 !important;
}
</style>
