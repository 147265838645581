import {
  createRouter,
  createWebHistory
} from "vue-router";
import store from "@/store";
import ErrorPage from "../views/error-page/Main.vue";
import HouseHold from "../views/house-hold/index.vue";
import HouseholdSurveyForm from "../views/house-hold/HouseholdSurveyForm.vue";
import AddHouseMember from "../views/member/AddHouseMember.vue"
import LandingPage from "../views/landing-page/Main.vue";
import MemberPage from "../views/member/index.vue";
import HouseHoldProfile from "../views/house-hold/Profile.vue";
import Dashboard1 from "../views/dashboard/dashboard-1/index.vue";
import Neets from "../views/neets/index";
import AddNeetForm from "../views/neets/AddNeetForm.vue"
import AddCWDForm from "../views/disability/AddCWDForm.vue"
import Disability from "../views/disability/index";
import SyncPage from "../views/sync-page/Main.vue";
import DashboardNeetsCWD from "../views/dashboard/dashboard-neets-cwd/index.vue";
import HouseSurveyManagement from "../views/admin/survey-management/household/index.vue";
import MemberSurveyManagement from "../views/admin/survey-management/member/index.vue";
import NeetsSurveyManagement from "../views/admin/survey-management/neets/index.vue";
import CWDSurveyManagement from "../views/admin/survey-management/cwd/index.vue";
import PermissionManagement from "../views/admin/permission-management/index.vue";
import AddSurveyManagement from "../views/admin/survey-management/add-survey/index.vue";
import SurveyManagement from "../views/admin/survey-management/survey/index.vue";
import SurveyDatatableManagement from "../views/admin/survey-management/surveys-datatable/index.vue";
import Swal from "sweetalert2";
import { getPermissionByLevel } from "@/utils/getPermission"
const routes = [
  // {
  // path: "/",
  // component: SideMenu,
  // children: [
  {
    path: "/", // /household
    name: "main-house-hold",
    component: HouseHold,
    meta: {
      requiresAuth: true,
      // crumb: [{
      //   name: "ครัวเรือน",
      //   active: true,
      // }, ],
    },
  },
  {
    path: "/landingpage", // "/"
    name: "landing-page", // landing-page
    component: LandingPage, // LandingPage
  },
  {
    path: "/dashboard-overview",
    name: "dashboard-overview",
    component: Dashboard1,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard-summary",
    name: "dashboard-summary",
    component: DashboardNeetsCWD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member",
    name: "member-page",
    component: MemberPage,
    meta: {
      requiresAuth: true,
      // crumb: [{
      //   name: "สมาชิก",
      //   active: true,
      // }, ],
    },
  },
  {
    path: "/neets",
    name: "neets",
    component: Neets,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cwd",
    name: "cwd",
    component: Disability,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/household/:hhid/:surveyid",
    name: "household-profile",
    component: HouseHoldProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-household",
    name: "add-household",
    component: HouseholdSurveyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-household-survey/:hhid",
    name: "add-household-survey",
    component: HouseholdSurveyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/household/edit/:hhid/:surveyid",
    name: "edit-household",
    component: HouseholdSurveyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/household/view/:hhid/:surveyid",
    name: "view-household",
    component: HouseholdSurveyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/add/:hhid/:surveyid",
    name: "add-member",
    component: AddHouseMember,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/neets/add/:surveyid/:memberid",
    name: "add-neet",
    component: AddNeetForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/neets/view/:surveyid/:memberid/:neetid",
    name: "view-neet",
    component: AddNeetForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/neets/edit/:surveyid/:memberid/:neetid",
    name: "edit-neet",
    component: AddNeetForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cwd/add/:surveyid/:memberid",
    name: "add-cwd",
    component: AddCWDForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cwd/view/:surveyid/:memberid/:cwdid",
    name: "view-cwd",
    component: AddCWDForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cwd/edit/:surveyid/:memberid/:cwdid",
    name: "edit-cwd",
    component: AddCWDForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/view/:hhid/:surveyid/:memberid",
    name: "view-member",
    component: AddHouseMember,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/member/edit/:hhid/:surveyid/:memberid",
    name: "edit-member",
    component: AddHouseMember,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sync",
    name: "sync-page",
    meta: {
      requiresAuth: true,
    },
    component: SyncPage,
  },
  {
    path: "/admin/house-survey",
    name: "house-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: HouseSurveyManagement,
  },
  {
    path: "/admin/member-survey",
    name: "member-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: MemberSurveyManagement,
  },
  {
    path: "/admin/neets-survey",
    name: "neets-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: NeetsSurveyManagement,
  },
  {
    path: "/admin/cwd-survey",
    name: "cwd-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: CWDSurveyManagement,
  },
  {
    path: "/admin/permission-management",
    name: "permission-management",
    meta: {
      requiresAuth: true,
    },
    component: PermissionManagement,
  },
  {
    path: "/admin/add-survey",
    name: "add-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: AddSurveyManagement,
  },
  {
    path: "/admin/edit-survey/:surveyid",
    name: "edit-survey-management",
    meta: {
      requiresAuth: true,
    },
    component: AddSurveyManagement,
  },
  {
    path: "/admin/:survey",
    name: "survey-management",
    meta: {
      requiresAuth: true,
    },
    component: SurveyManagement,
  },
  {
    path: "/admin/surveys-management",
    name: "survey-datatable",
    meta: {
      requiresAuth: true,
    },
    component: SurveyDatatableManagement,
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
  },
  /*
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
  */
  /*
   {
     path: "/xxx",
     name: "family-page",
     meta: {
       requiresAuth: true,
       //allowRoles: [""],
     },
     component: FamilyPage,
   },
   */
];

const router = createRouter({
  routes,
  //mode: "history",
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition || {
        left: 0,
        top: 0,
      }
    );
  },
});

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from, next, globalVariable) => {
  let auth = null
  if (!store.getters["network/isOnline"]) {
    const json = window.localStorage.getItem("mpi-logbook-auth");
    auth = JSON.parse(json);
    if(auth){
      store.dispatch("userAuth/setStore", auth);
      next();
    }else{
      Swal.fire({
        html: "<p>ไม่สามารถใช้งานโหมดออฟไลน์ได้</p><p>เนื่องจากไม่มีข้อมูลผู้ใช้งาน</p>",
        icon: "info",
        // timer: 3000,
        confirmButtonText: "ตกลง",
      })
    }
    return;
  }

  if(!auth && store.getters["network/isOnline"]){
    while (
      typeof store.getters["userAuth/getAllKeycloak"].createLoginUrl ==
      "undefined"
    ) {
      await sleep(100);
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (("authenticated", store.getters["userAuth/getAuthenticated"])) {
      if(store.getters["network/isOnline"])
        await getPermissionByLevel()

      if (!store.getters["permission/allowRouteName"].includes(to.name) && store.getters["network/isOnline"]) {
        next({name: store.getters["permission/allowRouteName"][0]});
      } else {
        //first time enter url have hash
        if(to.name != `${store.getters["permission/allowRouteName"][0]}` && to.hash && store.getters["network/isOnline"])
          next({name: store.getters["permission/allowRouteName"][0]});
        else
          next()
      }
    } else {
      /*
      if (localStorage.getItem("is_kc_logout_redirect") == "true") {
        localStorage.clear();
        localStorage.setItem("is_kc_logout_redirect", "false");
        window.location.replace(window.location.origin);
      } else {
        const loginUrl = store.getters[
          "userAuth/getAllKeycloak"
        ].createLoginUrl();

        window.location.replace(loginUrl);
      }
      */
      if((/line/.test(window.navigator.userAgent.toLowerCase()) || /instagram/.test(window.navigator.userAgent.toLowerCase()))&& /Apple Computer, Inc./.test(window.navigator.vendor)){
        Swal.fire({
          text: "กรุณาเปิดใช้งานระบบด้วยเว็บเบราเซอร์ Safari หรือ Chrome บนมือถือของท่าน",
          icon: "info",
          // timer: 3000,
          confirmButtonText: "ตกลง",
        })
        /*.then(()=>{
          const loginUrl = store.getters[
            "userAuth/getAllKeycloak"
          ].createLoginUrl();
          window.location.replace(loginUrl);
        })*/
      }else{
        const loginUrl = store.getters[
          "userAuth/getAllKeycloak"
        ].createLoginUrl();

        window.location.replace(loginUrl);
      }
    }
  } else {
    next();
  }
});
export default router;