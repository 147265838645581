import { createStore } from "vuex";
import main from "./main";
import sideMenu from "./side-menu";
import userAuth from "./user-auth";
import householdFilter from "./module/household-filter";
import memberFilter from "./module/member-filter";
import neetsFilter from "./module/neets-filter";
import cwdFilter from "./module/cwd-filter";
import dashboardFilter from "./module/dashboard-filter";
import dashboardCWDNEETsFilter from "./module/dashboard-cwd-neets-filter";
import network from './network'
import permission from './permission'

const store = createStore({
  modules: {
    main,
    sideMenu,
    userAuth,
    householdFilter,
    memberFilter,
    dashboardFilter,
    neetsFilter,
    cwdFilter,
    network,
    dashboardCWDNEETsFilter,
    permission
  },
});

export function useStore() {
  return store;
}

export default store;
