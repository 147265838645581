import localforage from "localforage";
import example from "./example.json"

var houseStore = localforage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

function insertHouse() {
    for(let i = 0; i < example.house.length; i++){
        houseStore.setItem(String(i+1), example.house[i])
    }
}

export {
    insertHouse
}