<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu :customMenu="customMenu" />
    <TopBar :is-hide-menu="isHideMenu" :bread-crumb-list="breadCrumbList" :class="(!isOnlineMode) || (isOnlineMode && currentNetwork && localDataFound && getPermission) || currentEnvMode == 'development' ? 'mb-2' : 'mb-12'"/>
    <AlertCustom :class="(!isOnlineMode) || (isOnlineMode && currentNetwork && localDataFound && getPermission) ? '!mb-2' : '!mb-6'" class="!py-3" theme-color='warning' title="ขณะนี้กำลังใช้งานอยู่ในระบบทดสอบ" v-if="currentEnvMode == 'development'">
    </AlertCustom>
    <AlertCustom v-if="!isOnlineMode" theme-color='dark' title="ขณะนี้กำลังใช้งานอยู่ในโหมดออฟไลน์" class="!py-3">
      <template #content-end v-if="currentNetwork">
        <button type="button" class="btn btn-outline-secondary p-1 px-4 mr-1" @click="switchToOnline">ออนไลน์</button>
      </template>
    </AlertCustom>
    <AlertCustom v-if="isOnlineMode && currentNetwork && localDataFound && getPermission" theme-color='primary' title="ตรวจพบข้อมูลที่ยังไม่บันทึก" class="gap-1 !py-3">
      <template #content-end>
        <div>
          <button type="button" class="btn btn-outline-secondary border-red text-red p-1 px-4 mr-1 hover:bg-transparent" @click="clearOfflineData">ลบทิ้ง</button>
          <button type="button" class="btn btn-outline-primary p-1 px-4 mr-1 hover:bg-transparent" @click="syncOfflineData">Sync</button>
        </div>
      </template>
    </AlertCustom>
    <div class="wrapper">
      <div class="wrapper-box">
        <!-- BEGIN: Side Menu -->
        <nav class="side-nav" v-if="!isHideMenu">
          <ul>
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu" :key="menu + menuKey">
              <li
                v-if="menu.key == 'devider'"
                class="side-nav__devider my-6"
              />
              <li v-else>
                <SideMenuTooltip
                  tag="a"
                  :content="menu.title"
                  :key="menu.title"
                  :href="
                    menu.subMenu
                      ? 'javascript:;'
                      : menu.params ? router.resolve({ name: menu.pageName, params: {...menu.params} }).path : router.resolve({ name: menu.pageName }).path
                  "
                  class="side-menu"
                  :class="{
                    'side-menu--active': menu.active,
                    'side-menu--open': menu.activeDropdown,
                  }"
                  @click="linkTo(menu, router, $event)"
                >
                  <div class="side-menu__icon">
                    <component :is="menu.icon" />
                  </div>
                  <div class="side-menu__title">
                    {{ menu.title }}
                    <div
                      v-if="menu.subMenu"
                      class="side-menu__sub-icon"
                      :class="{ 'transform rotate-180': menu.activeDropdown }"
                    >
                      <ChevronDownIcon />
                    </div>
                  </div>
                </SideMenuTooltip>
                <!-- BEGIN: Second Child -->
                <transition @enter="enter" @leave="leave">
                  <ul v-if="menu.subMenu && menu.activeDropdown">
                    <li
                      v-for="(subMenu, subMenuKey) in menu.subMenu"
                      :key="subMenuKey"
                    >
                      <SideMenuTooltip
                        tag="a"
                        :content="subMenu.title"
                        :href="
                          subMenu.subMenu
                            ? 'javascript:;'
                            : subMenu.params ? router.resolve({ name: subMenu.pageName, params: {...subMenu.params}}).path : router.resolve({ name: subMenu.pageName }).path
                        "
                        class="side-menu"
                        :class="{ 'side-menu--active': subMenu.active, 'sub-menu-open': subMenu.isSubmenu }"
                        @click="linkTo(subMenu, router, $event)"
                      >
                        <div class="side-menu__icon">
                          <div class="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-white flex items-center justify-center" v-if="subMenu.image">
                            <img src="@/assets/images/user.png" class="object-cover object-center h-full w-full" v-if="subMenu.image == 'default'">
                            <img :src="subMenu.image" class="object-cover object-center h-full w-full" v-else>
                          </div>
                          <component :is="subMenu.icon" v-else-if="subMenu.icon"/>
                          <ActivityIcon v-else/>
                        </div>
                        <div class="side-menu__title">
                          {{ subMenu.title }}
                          <div
                            v-if="subMenu.subMenu"
                            class="side-menu__sub-icon"
                            :class="{
                              'transform rotate-180': subMenu.activeDropdown,
                            }"
                          >
                            <ChevronDownIcon />
                          </div>
                        </div>
                      </SideMenuTooltip>
                      <!-- BEGIN: Third Child -->
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                          <template
                            v-for="(lastSubMenu,lastSubMenuKey) in subMenu.subMenu"
                            :key="lastSubMenuKey"
                          >
                            <li
                              v-if="lastSubMenu == 'devider'"
                              :key="lastSubMenu + lastSubMenuKey"
                              class="side-nav__devider my-2 !bg-gray-400"
                            />
                            <li
                              v-else
                            >
                              <SideMenuTooltip
                                tag="a"
                                :content="lastSubMenu.title"
                                :href="
                                  lastSubMenu.subMenu
                                    ? 'javascript:;'
                                    : lastSubMenu.params ? router.resolve({ name: lastSubMenu.pageName, params: {...lastSubMenu.params}}).path : router.resolve({ name: lastSubMenu.pageName }).path
                                "
                                class="side-menu"
                                :class="{
                                  'side-menu--active': lastSubMenu.active,
                                }"
                                @click="linkTo(lastSubMenu, router, $event)"
                              >
                                <div class="side-menu__icon">
                                  <component :is="lastSubMenu.icon" v-if="lastSubMenu.icon"/>
                                  <ActivityIcon v-else/>
                                  <!--<ZapIcon />-->
                                </div>
                                <div class="side-menu__title">
                                  {{ lastSubMenu.title }}
                                </div>
                              </SideMenuTooltip>
                            </li>
                          </template>
                        </ul>
                      </transition>
                      <!-- END: Third Child -->
                    </li>
                  </ul>
                </transition>
                <!-- END: Second Child -->
              </li>
            </template>
            <!-- END: First Child -->
          </ul>
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content">
          <slot />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-nav-bar/index.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import localForage from "localforage";
import Swal from "sweetalert2"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip,
  },
  props: {
    isHideMenu: {
      type: Boolean,
      default: false,
    },
    breadCrumbList:{
      type: Array
    },
    customMenu: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const currentNetwork = ref(false)
    const localDataFound = ref(0)

    const isOnlineMode = computed(() => store.getters['network/isOnline'])
    const getPermission = computed(() => store.getters["userAuth/getUserTumbolCode"].length == 6 && route.name != "sync-page")
    const statusSync = computed(()=> store.getters['network/statusSync'])
    currentNetwork.value = navigator.onLine
    
    const sideMenu = computed(() =>{
      // const menuList = props.customMenu.length > 0 ? [...store.getters["sideMenu/menu"], ...props.customMenu] : store.getters["sideMenu/menu"]
      const menuList = store.getters["sideMenu/menu"].reduce((acc, menu, index) => {
          let temp = []
          if(menu.isAdmin && props.customMenu.length){
            temp = [...temp, ...props.customMenu, menu]
          }else{
            temp = [...temp, menu]
          }
          if(store.getters["sideMenu/menu"].length - 1 == index && props.customMenu.length && !store.getters["sideMenu/menu"].some(s=> s.isAdmin)){
            temp = [...temp, ...props.customMenu]
          }

          return [...acc, ...temp];
      }, []);

      return nestedMenu(menuList, route)
    });

    watch(
      computed(() => props.customMenu),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );
    //update check local data when sync done
    watch(statusSync, async ()=>{
      if(!statusSync.value){
        localDataFound.value = await houseStore.length()
      }
    })

    onMounted(async() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main");
      formattedMenu.value = $h.toRaw(sideMenu.value);
      window.addEventListener("online", ()=>{
        currentNetwork.value = navigator.onLine
      });
      window.addEventListener("offline", ()=>{
        currentNetwork.value = navigator.onLine
      });
      localDataFound.value = await houseStore.length()
    });

    const switchToOnline = () =>{
      navigator.serviceWorker.controller.postMessage({value: navigator.onLine})
      //handle alert in toggle to hide if click online from here
    }
    const syncOfflineData = () =>{
      store.dispatch('network/setStatusSync', true)
      //redirect to sync page
      router.push({
        name: "sync-page"
      })
    }
    const clearOfflineData = async () => {
      swalCustom.fire({
          text:"ยืนยันลบข้อมูล",
          icon:"warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
          if (result.isConfirmed) {
            await houseStore.clear()
            localDataFound.value = await houseStore.length()
            if(localDataFound.value == 0){
              router.push({
                name: 'main-house-hold'
              })
            }
          }
      })
    }
    const currentEnvMode = computed(()=> process.env.VUE_APP_ENV)

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      isOnlineMode,
      currentNetwork,
      localDataFound,
      switchToOnline,
      syncOfflineData,
      clearOfflineData,
      getPermission,
      currentEnvMode
    };
  },
});
</script>
