<template>
  <!-- hh_code -->
  <div>
    <div class="box rounded-md shadow-md">
      <div class="p-5 flex flex-wrap justify-center">
        <div class="flex-shrink-0 mx-8 flex flex-col items-center mb-5">
          <div
            class="relative h-28 w-28 rounded-full mb-2 flex-shrink-0"
            :class="{
              'bg-theme-41': information ? information.gender === 'ชาย' : '',
              'bg-theme-36': information ? information.gender === 'หญิง' : '',
              'bg-theme-13': information ? information.gender === 'อื่น ๆ' : '',
            }"
          >
            <div class="h-full w-full rounded-full overflow-hidden">
              <img
                v-if="information?.image?.img_url"
                :src="`${imageUrl}${information.image.img_url}`"
                class="object-cover object-center h-full w-full"
              />
              <img
                v-else
                src="@/assets/images/user.png"
                class="object-cover object-center h-full w-full"
              />
            </div>
          </div>
          <!-- <button class="btn bg-theme-42 text-white rounded-3xl">
            {{ information?.member_surveys?.relation.desc }}
          </button> -->
        </div>
        <div class="flex flex-col min-w-full md:min-w-0 md:flex-grow">
          <div class="flex-grow">
          <h3 class="text-xl text-theme-37 font-semibold mb-1">
            {{information?.full_name}}
          </h3>
          <span class="text-base font-normal text-theme-37 mb-1">อายุ: {{information?.member_surveys?.age.year+' ปี '+ information?.member_surveys?.age?.month+' เดือน'}}</span>

          <p class="text-base text-theme-37 font-normal">
            {{information?.full_address}}
          </p>
          </div>
          <hr class="mt-3" />
          <div class="flex flex-wrap items-center py-2 gap-x-8 sm:gap-x-16 flex-grow">
            <div class="flex flex-col my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-6">เพศ</h5>
              <div class="flex flex-wrap items-center pb-1 gap-3">
                <div class="flex justify-center items-center" v-if="information?.gender == 'ชาย'">
                  <div
                    class="t-tooltip rounded-full bg-theme-41 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศชาย</span>
                    <img class="h-5 w-5 " src="@/assets/images/icon/male.png" />
                  </div>
                </div>
                <div class="flex justify-center items-center" v-if="information?.gender == 'หญิง'">
                  <div
                    class="t-tooltip rounded-full bg-theme-36 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศหญิง</span>
                    <img class="h-5 w-5" src="@/assets/images/icon/female.png" />
                  </div>
                </div>
                <div class="flex justify-center items-center" v-if="information?.gender == 'อื่น ๆ'">
                  <div
                    class="t-tooltip rounded-full bg-theme-13 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศอื่นๆ</span>
                    <img class="h-5 w-5" src="@/assets/images/icon/unisex.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-6">
                ช่วงวัย
              </h5>
              <div class="flex flex-wrap md:flex-row items-center pb-1 gap-2">
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full flex w-8 h-8 justify-center items-center"
                    :class="information?.member_surveys?.age_range.includes('เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)') ? 'bg-theme-1' : 'bg-theme-37'"
                  >
                    <span class="t-tooltip-text">เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)</span>
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/baby-boy.png"
                    />
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full flex w-8 h-8 justify-center items-center"
                    :class="information?.member_surveys?.age_range.includes('วัยเรียน (อายุ 5-14 ปี)') ? 'bg-theme-1' : 'bg-theme-37'"
                  >
                    <span class="t-tooltip-text">วัยเรียน (อายุ 5-14 ปี)</span>
                    <img class="h-5 w-5 " src="@/assets/images/icon/kid.png" />
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full flex w-8 h-8 justify-center items-center"
                    :class="information?.member_surveys?.age_range.includes('วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)') ? 'bg-theme-1' : 'bg-theme-37'"
                  >
                    <span class="t-tooltip-text">วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)</span
                    >
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/teenager.png"
                    />
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full flex w-8 h-8 justify-center items-center"
                    :class="information?.member_surveys?.age_range.includes('วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)') ? 'bg-theme-1' : 'bg-theme-37'"
                  >
                    <span class="t-tooltip-text">วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)</span
                    >
                    <img class="h-5 w-5 " src="@/assets/images/icon/man.png" />
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full flex w-8 h-8 justify-center items-center"
                    :class="information?.member_surveys?.age_range.includes('วัยผู้สูงอายุ (60 ปีขึ้นไป)') ? 'bg-theme-1' : 'bg-theme-37'"
                  >
                    <span class="t-tooltip-text">วัยผู้สูงอายุ (60 ปีขึ้นไป)</span>
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/old-man.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  information: Object,
});
const imageUrl = ref(process.env.VUE_APP_IMG_API)


</script>
