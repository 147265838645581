<template>
    <template-layout :breadCrumbList="breadcrumbComputed">
        <div class="py-12 px-2 sm:px-4">
            <nav class="flex justify-between items-center mb-6">
                <h1 class="text-2xl text-primary-2">
                ภาพรวม
                </h1>
            </nav>
            <DashboardFilter :filter="filterSummaryStore"/>
            <!-- <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="p-5 box bg-primary-3 text-white col-span-12 md:col-span-4">
                    <div class="px-4 py-4 flex flex-col justify-center flex-1 bg-white opacity-30 items-center h-full w-full absolute left-0 top-0" v-if="loadingHouse">
                        <div class="w-10 h-10">
                        <LoadingIcon
                            icon="three-dots"
                            class="w-10 h-10 loading-icon"
                        />
                        </div>
                    </div>
                    <div :class="{'opacity-0': loadingHouse }">
                        <div class="mt-3 text-5xl font-medium leading-8">{{new Intl.NumberFormat().format(all_house)}}</div>
                        <div class="flex mt-3 items-center">
                            <div class="text-base text-slate-500">จำนวนครัวเรือน</div>
                            <div class="ml-auto">
                                <div class="w-8 h-8 bg-primary-2 flex items-center justify-center rounded-full">
                                    <HomeIcon class="stroke-[#fff] w-5 h-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-5 box bg-primary-1 text-white col-span-12 md:col-span-4 h-full">
                    <div class="px-4 py-4 flex flex-col justify-center flex-1 bg-white opacity-30 items-center h-full w-full absolute left-0 top-0" v-if="loadingMember">
                        <div class="w-10 h-10">
                        <LoadingIcon
                            icon="three-dots"
                            class="w-10 h-10 loading-icon"
                        />
                        </div>
                    </div>
                    <div :class="{'opacity-0': loadingMember }">
                        <div class="mt-3 text-5xl font-medium leading-8">{{new Intl.NumberFormat().format(all_member)}}</div>
                        <div class="flex mt-3 items-center">
                            <div class="text-base text-slate-500">จำนวนสมาชิก</div>
                            <div class="ml-auto">
                                <div class="w-8 h-8 bg-primary-2 flex items-center justify-center rounded-full">
                                    <UserIcon class="stroke-[#fff] w-5 h-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="mt-5">
                <div class="flex items-center">
                    <h2 class="text-xl font-medium mr-5">ครัวเรือน</h2>
                </div>
                <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-12">
                        <div class="grid grid-cols-12 gap-6 relative">
                            <div class="col-span-12 lg:col-span-8">
                                <div class="mt-2 h-full">
                                    <div class="box grid grid-cols-12 gap-3 h-full">
                                        <div class="absolute w-full h-full" v-if="loadingHouse">
                                            <div class="mt-2 absolute flex justify-center items-center z-[60] w-full h-full ">
                                                <div class="w-20 h-20">
                                                    <LoadingIcon
                                                        icon="three-dots"
                                                        class="loading-icon"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mt-2 absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                            </div>
                                        </div>
                                        <div class="px-4 py-4 col-span-12 sm:col-span-7 relative">
                                            <div class="p-0 medium:p-6 large:p-3 extra:p-8 flex flex-col justify-center">
                                                <HomeIcon class="w-10 h-10 text-custom-member !stroke-2" />
                                                <div class="relative text-5xl font-bold mt-6 pl-2">
                                                    <h1 class="text-custom-member opacity-90" >
                                                        {{new Intl.NumberFormat().format(all_house)}}
                                                        <span class="text-base">ครัวเรือน</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div class="p-0 medium:p-6 large:p-3 extra:p-8 mt-6 flex flex-col justify-center">
                                                <SingleStackedBarChart
                                                    chart-id="townshipChart"
                                                    :height="100"
                                                    :key="key"
                                                    :chart-label="['']"
                                                    :series="township"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="px-4 py-8 col-span-12 sm:col-span-5 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed mt-auto mb-auto"
                                        >
                                            <CardItem title="สุขภาพ" :count="2" color="hover:bg-primary-2 bg-primary-2">
                                                <template v-slot:icons>
                                                    <HeartIcon class="!stroke-2"/>
                                                </template>
                                            </CardItem>
                                            <CardItem title="ความเป็นอยู่" :count="2" color="hover:bg-primary-2 bg-primary-2">
                                                <template v-slot:icons>
                                                    <HomeIcon class="!stroke-2"/>
                                                </template>
                                            </CardItem>
                                            <CardItem title="การศึกษา" :count="2" color="hover:bg-primary-2 bg-primary-2">
                                                <template v-slot:icons>
                                                    <BookOpenIcon class="!stroke-2"/>
                                                </template>
                                            </CardItem>
                                            <CardItem title="รายได้" :count="2" color="hover:bg-primary-2 bg-primary-2">
                                                <template v-slot:icons>
                                                    <DollarSignIcon class="!stroke-2"/>
                                                </template>
                                            </CardItem>
                                            <CardItem title="การเข้าถึงบริการภาครัฐ" :count="2" color="hover:bg-primary-2 bg-primary-2" class="mb-0">
                                                <template v-slot:icons>
                                                    <GiftIcon class="!stroke-2"/>
                                                </template>
                                            </CardItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div class="mt-2 h-full">
                                    <div class="box p-5 h-full">
                                        <div class="absolute left-0 top-0 w-full h-full" v-if="loadingHouse">
                                            <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                                <div class="w-20 h-20">
                                                    <LoadingIcon
                                                        icon="three-dots"
                                                        class="loading-icon"
                                                    />
                                                </div>
                                            </div>
                                            <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                            </div>
                                        </div>
                                        <div class="flex items-center text-custom-member font-medium text-base">
                                            สภาพปัญหา
                                        </div>
                                        <div class="mt-2 h-full">
                                            <StackedBarChart
                                                :height="250"
                                                chart-id="houseGroupBar"
                                                :key="key"
                                                :chart-label="[
                                                    'สุขภาพ',
                                                    'ความเป็นอยู่',
                                                    'การศึกษา',
                                                    'รายได้',
                                                    'การเข้าถึงบริการรัฐ',
                                                ]"
                                                :series="houseDimension"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-5">
                        <div class="box h-full p-5 relative flex flex-col">
                            <h2 class="font-medium text-base mr-auto mb-6 text-custom-member">จำนวนครัวเรือนแยกตาม สถานภาพการครอบครองที่อยู่อาศัย</h2>
                            <div class="absolute left-0 top-0 w-full h-full" v-if="loadingHouse">
                                <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                    <div class="w-20 h-20">
                                        <LoadingIcon
                                            icon="three-dots"
                                            class="loading-icon"
                                        />
                                    </div>
                                </div>
                                <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                </div>
                            </div>
                            <div class="">
                                <DoughnutChart
                                    :series="house_status"
                                    :chart-label="house_status_label"
                                    :height="180"
                                    chart-id="houseStatusChart"
                                    unit="ครัวเรือน"
                                    legendOnRight
                                    :key="key"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-5">
                        <div class="box h-full p-5 relative flex flex-col">
                             <div class="absolute left-0 top-0 w-full h-full" v-if="loadingHouse">
                                    <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                        <div class="w-20 h-20">
                                            <LoadingIcon
                                                icon="three-dots"
                                                class="loading-icon"
                                            />
                                        </div>
                                    </div>
                                    <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                    </div>
                                </div>
                            <h2 class="font-medium text-base mr-auto mb-6 text-custom-member">จำนวนครัวเรือนแยกตาม ประเภทที่อยู่อาศัย</h2>
                            <div
                                class=""
                            >
                                <PieChart
                                    :series="house_type"
                                    :chart-label="house_type_label"
                                    :height="180"
                                    chart-id="houseTypeChart"
                                    unit="ครัวเรือน"
                                    :key="key"
                                    legendOnRight
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="mt-5">
                <div class="flex items-center">
                    <h2 class="text-xl font-medium mr-5">สมาชิก</h2>
                </div>
                <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-12">
                        <div class="grid grid-cols-12 gap-6 relative">
                            <div class="absolute w-full h-full hidden">
                                <div class="mt-2 absolute flex justify-center items-center z-[60] w-full h-full ">
                                    <div class="w-20 h-20">
                                        <LoadingIcon
                                            icon="three-dots"
                                            class="loading-icon"
                                        />
                                    </div>
                                </div>
                                <div class="mt-2 absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="mt-2 h-full">
                                    <div class="box grid grid-cols-12 gap-3 h-full">
                                        <div class="absolute w-full h-full" v-if="loadingMember">
                                            <div class="mt-2 absolute flex justify-center items-center z-[60] w-full h-full ">
                                                <div class="w-20 h-20">
                                                    <LoadingIcon
                                                        icon="three-dots"
                                                        class="loading-icon"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mt-2 absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                            </div>
                                        </div>
                                        <div class="px-4 py-4 col-span-12 sm:col-span-7 relative">
                                            <div class="medium:p-6 large:p-3 extra:p-8 p-0 flex flex-col justify-center">
                                                <UsersIcon class="w-10 h-10 text-custom-member !stroke-2" />
                                                <div class="relative text-5xl font-bold mt-6 pl-2">
                                                    <h1 class="text-custom-member opacity-90" >
                                                        {{new Intl.NumberFormat().format(all_member)}}
                                                        <span class="text-base">คน</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div class="medium:px-6 large:px-3 extra:px-8 p-0 mt-6 medium:mt-0 flex flex-col justify-center">
                                                    <div>
                                                        <SingleStackedBarChart
                                                            chart-id="genderChart"
                                                            :height="100"
                                                            :key="key"
                                                            :chart-label="['']"
                                                            :series="gender"
                                                        />
                                                    </div>
                                                    <div class="mt-5">
                                                        <SingleStackedBarChart
                                                            chart-id="personalIdChart"
                                                            :height="100"
                                                            :key="key"
                                                            :chart-label="['']"
                                                            :series="personal_code"
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                        <div
                                            class="px-4 py-8 col-span-12 sm:col-span-5 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed mt-auto mb-auto"
                                        >
                                            <CardItem :title="age.title" :sub_title="age.sub_title" :count="age.count" color="hover:bg-theme-1 bg-theme-1" v-for="(age, index) in age_range" :key="age" :class="{'mb-0': index == age_range.length-1}">
                                                <template v-slot:icons>
                                                    <img
                                                        class="w-6"
                                                        :src="require(`@/assets/images/icon/${age.icon}`)"
                                                    />
                                                </template>
                                            </CardItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div class="mt-2 h-full">
                                    <div class="absolute left-0 top-0 w-full h-full" v-if="loadingMember">
                                        <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                            <div class="w-20 h-20">
                                                <LoadingIcon
                                                    icon="three-dots"
                                                    class="loading-icon"
                                                />
                                            </div>
                                        </div>
                                        <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                        </div>
                                    </div>
                                    <div class="box p-5 h-full">
                                        <div class="flex items-center font-medium text-base text-custom-member">
                                            5 ช่วงวัย
                                        </div>
                                        <div class="mt-2 h-full">
                                            <StackedBarChartMember
                                                :height="250"
                                                chart-id="memberGroupBar"
                                                :key="key"
                                                :chart-label="[
                                                    'สุขภาพ',
                                                    'ความเป็นอยู่',
                                                    'การศึกษา',
                                                    'รายได้',
                                                    'การเข้าถึงบริการรัฐ',
                                                ]"
                                                :series="memberDimension"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template-layout>
</template>

<script setup>
import TemplateLayout from "@/layouts/side-menu/Main.vue";
import PieChart from "@/components/pie-chart/Main.vue"
import DoughnutChart from "@/components/doughnut-chart/Main.vue"
import DashboardFilter from "@/components/filter-search/DashboardFilter.vue";
import StackedBarChart from "@/components/stacked-bar-chart/Main.vue"
import StackedBarChartMember from "@/components/stacked-bar-chart-member/Main.vue"
import SingleStackedBarChart from "@/components/single-stacked-bar-chart/Main.vue"
import CardItem from "./container/CardItem.vue"
import axios from "axios";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const store = useStore()
const token = store.getters["userAuth/getToken"];
const all_house = ref(0)
const all_member = ref(0)
const township = ref()
const township_label = ref()
const house_type = ref()
const house_type_label = ref()
const house_status = ref()
const house_status_label = ref()
const gender = ref()
const gender_label = ref()
const personal_code = ref()
const personal_code_label = ref()
const age_range = ref([
        {
            title: "เด็กแรกเกิด/ปฐมวัย",
            sub_title: "(อายุ 0-5 ปี)",
            count: 0,
            icon: "baby-boy.png"
        },
        {
            title: "วัยเรียน",
            sub_title: "(อายุ 5-14 ปี)",
            count: 0,
            icon: "kid.png"
        },
        {
            title: "วัยรุ่น/นักศึกษา",
            sub_title: "(อายุ 15-21 ปี)",
            count: 0,
            icon: "teenager.png"
        },
        {
            title: "วัยผู้ใหญ่/วัยแรงงาน",
            sub_title: "(อายุ 18-59 ปี)",
            count: 0,
            icon: "man.png"
        },
        {
            title: "วัยผู้สูงอายุ",
            sub_title: "(60 ปีขึ้นไป)",
            count: 0,
            icon: "old-man.png"
        },
    ])
const age_range_label = ref()
const key = ref(0)
const loadingHouse = ref(false)
const loadingMember = ref(false)
const filterValue = computed(
  () => store.getters["dashboardFilter/getFilterForApi"]
);
const filterSummaryStore = computed(
  () => store.getters["dashboardFilter/getFilterSummary"]
);
const houseDimension = ref()
const memberDimension = ref()
const bgColor = ['#1C4E80', '#0091D5', '#A5D8DD']
const onlineComputed = computed(()=> store.getters["network/isOnline"])

watch(filterValue, () => {
    if(onlineComputed.value){
        key.value++
        getHouseData()
        getMemberData()
    }
})
const getHouseData = async () => {
    loadingHouse.value = !loadingHouse.value
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}house/count`,
        {
            params: filterValue.value,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).catch(err => err);
        if(res.status === 200){
            setTimeout(()=>{loadingHouse.value = !loadingHouse.value}, 500)
            all_house.value = res.data.all_house
            const townshipPattern = ["ในเขตเทศบาล", "นอกเขตเทศบาล"]
            township.value = []
            townshipPattern.forEach((elm, index)=>{
                const idx = res.data.township.findIndex(x => x.name === elm && x.count > 0)
                if(idx != -1){
                    township.value.push({
                        label: res.data.township[idx].name,
                        barPercentage: 0.65,
                        categoryPercentage: 1,
                        barThickness: 40,
                        maxBarThickness: 40,
                        minBarLength: 2,
                        backgroundColor: bgColor[index],
                        data: [res.data.township[idx].count]
                    })
                }

            })
            /* township.value = res.data.township.map((item, index)=>{
                return {
                    label: item.name,
                    barPercentage: 1,
                    categoryPercentage: 1,
                    barThickness: 40,
                    maxBarThickness: 40,
                    minBarLength: 2,
                    backgroundColor: bgColor[index],
                    data: [item.count]
                }
            }) */
            township_label.value = res.data.township.map(x => x.name)
            house_type.value = res.data.housing_type.map(x => x.count)
            house_type_label.value = res.data.housing_type.map(x=>x.name)
            house_status.value = res.data.house_status.map(x => x.count)
            house_status_label.value = res.data.house_status.map(x => x.name)
        }
        else{
            setTimeout(()=>{loadingHouse.value = !loadingHouse.value}, 500)
            Swal.fire({
                icon: "warning",
                text: "ระบบผิดพลาด",
            })
        }
}
const getMemberData = async () => {
    loadingMember.value = !loadingMember.value
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}member/count`,
        {
            params: filterValue.value,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).catch(err => err);
        if(res.status === 200){
            setTimeout(()=>{loadingMember.value = !loadingMember.value}, 500)
            all_member.value = res.data.all_member
            const genderPattern = ["ชาย", "หญิง", "อื่น ๆ"]
            gender.value = []
            personal_code.value = []
            genderPattern.forEach((elm, index)=>{
                const idx = res.data.gender.findIndex(x => x.gender === elm && x.count > 0)
                if(idx != -1){
                    gender.value.push({
                        label: res.data.gender[idx].gender,
                        barPercentage: 0.65,
                        categoryPercentage: 1,
                        barThickness: 40,
                        maxBarThickness: 40,
                        minBarLength: 2,
                        backgroundColor: bgColor[index],
                        data: [res.data.gender[idx].count]
                    })
                }

            })
            const personalCodePattern = [true, false]
            personalCodePattern.forEach((elm, index)=>{
                const idx = res.data.personal_code.findIndex(x => x.NID === elm && x.count > 0)
                if(idx != -1){
                    personal_code.value.push({
                        label: res.data.personal_code[idx].NID ? "มีบัตร" : "ไม่มีบัตร" ,
                        barPercentage: 0.65,
                        categoryPercentage: 1,
                        barThickness: 40,
                        maxBarThickness: 40,
                        minBarLength: 2,
                        backgroundColor: bgColor[index],
                        data: [res.data.personal_code[idx].count]
                    })
                }

            })
            /* gender.value = res.data.gender.map((item, index)=>{
                return {
                    label: item.gender,
                    barPercentage: 0.65,
                    categoryPercentage: 1,
                    barThickness: 40,
                    maxBarThickness: 40,
                    minBarLength: 2,
                    backgroundColor: bgColor[index],
                    data: [item.count]
                }
            }) */
            /* personal_code.value = res.data.personal_code.map((item, index)=>{
                return {
                    label: item.NID ? "มีบัตร" : "ไม่มีบัตร",
                    barPercentage: 0.65,
                    categoryPercentage: 1,
                    barThickness: 40,
                    maxBarThickness: 40,
                    minBarLength: 2,
                    backgroundColor: bgColor[index],
                    data: [item.count]
                }
            }) */
            age_range.value.forEach(element => {
                const obj = res.data.age_range.find(item => item.age_range === `${element.title} ${element.sub_title}`)
                element.count = obj.count
                return element
            });
            houseDimension.value = [2,34,32,22,1]
            memberDimension.value = [12,24,52,41,32]
        }
        else{
            setTimeout(()=>{loadingMember.value = !loadingMember.value}, 500)
            Swal.fire({
                icon: "warning",
                text: "ระบบผิดพลาด",
            })
        }
}

const breadcrumbComputed = reactive([{
    name: "ภาพรวม",
    active: true
}])

onMounted(()=>{
    if(navigator.onLine && onlineComputed.value){
        getHouseData()
        getMemberData()
    }
})
</script>

<style>

</style>