<template>
  <div class="overflow-x-auto scrollbar-hidden neets-datatable">
    <DatatableProfile
      :data-array="dataOfflineComputed"
      :data-array-table="dataArrayTable"
      :key="key"
      @row-clicked="handleRowClick"
    />
  </div>
</template>

<script setup>
import DatatableProfile from "@/components/datatable/datatableProfile.vue"
import { reactive,ref,onMounted, watch, computed } from "vue"
import { useRouter } from "vue-router";
import localForage from "localforage"
import Swal from 'sweetalert2'

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const props = defineProps({
  dataArray:{
    type: Array
  }
})
const emit = defineEmits(["update-data"])
const router = useRouter()
const key = ref(0)
const listData = ref([])
watch(()=>props.dataArray, ()=>{
  key.value++
})
const dataOfflineComputed = computed(()=>{
  return props.dataArray
})

var customAgeRange = (value, data, type, params, component) => {
    return {
        "baby": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)") : false,
        "kid": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยเรียน (อายุ 5-14 ปี)") : false,
        "teenager": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)") : false,
        "adult": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)") : false,
        "elder": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้สูงอายุ (60 ปีขึ้นไป)") : false
    }
}
const dataArrayTable = reactive([
    {title:"ชื่อ-นามสกุล", field:"full_name", minWidth: 200, resizable:true, formatter:"textarea"},
    {title:"เลขบัตรประจำตัวประชาชน", field:"NID", hozAlign:"left",minWidth: 200, width: 230, resizable:true, formatter:function(cell, formatterParams){
        return cell.getValue() ?? 'ไม่มีบัตรประจำตัวประชาชน'
    }},
    {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300},
    {title:"ช่วงวัย", field:"age_range", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, mutator: customAgeRange,
        formatter:function(cell, formatterParams){
            var value = cell.getValue();
            if(value){
                let temp = ""
                for(const [key, val] of Object.entries(value)){
                    let icon = "", text = ""
                    if(key === "baby"){
                        icon = "baby-icon"
                        text = "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)"
                    }
                    if(key === "kid"){
                        icon = "kid-icon"
                        text = "วัยเรียน (อายุ 5-14 ปี)"
                    }
                    if(key === "teenager"){
                        icon = "teenager-icon"
                        text = "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)"
                    }
                    if(key === "adult"){
                        icon = "adult-icon"
                        text = "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)"
                    }
                    if(key === "elder"){
                        icon = "elder-icon"
                        text = "วัยผู้สูงอายุ (60 ปีขึ้นไป)"
                    }
                    temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                                <span class="t-tooltip-text">${text}</span>\
                                <span class="img-icon ${icon}"></span>\
                            </button>`
                }
                let res = `<div class="flex flex-wrap justify-start lg:justify-center">${temp}</div>`
                return res
            }
            return null
        }
    },
    {title:"การดำเนินการ", field:"action", hozAlign:"center", minWidth: 150, width: 200,
        formatter:function(cell, formatterParams){
            const editCallback = (e) => {
                //prevent default row click
                e.stopPropagation();
                if(cell.getData().neet_survey_id){
                    router.push({
                        name: 'edit-neet',
                        params:{
                            surveyid: cell.getData().surveyid,
                            memberid: cell.getData().member_id,
                            neetid: cell.getData().neet_survey_id
                        }
                    })
                }
            };
            const deleteCallback = (e) =>{
                //prevent default row click
                e.stopPropagation();
                swalCustom.fire({
                    text:"ยืนยันลบข้อมูล",
                    icon:"warning",
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ยกเลิก',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        //get key find member index check have neet_survey delete neet_survey_data setItem again to update value
                        const houseValue = await houseStore.getItem(String(cell.getData().surveyid))
                        const memberIdx = houseValue.house_surveys?.members.findIndex(x=> x.member_id == cell.getData().member_id)
                        if(memberIdx != -1){
                            delete houseValue.house_surveys.members[memberIdx].neet_survey_data
                            houseStore.setItem(String(cell.getData().surveyid), houseValue)
                            swalCustom.fire({
                                text: "สำเร็จ",
                                icon: "success",
                                timer: 3000, //timeOut for auto-close
                                confirmButtonText: "ปิด",
                            }).then(() => {
                                emit("update-data")
                            });
                        }
                    }
                })
            }

            const addEvent = (e) =>{
                //prevent default row click
                e.stopPropagation();
                router.push({
                    name: 'add-neet',
                    params:{
                        surveyid: cell.getData().surveyid,
                        memberid: cell.getData().member_id
                    }
                })
            }

            const viewEvent = (e) =>{
                //prevent default row click
                e.stopPropagation();
                if(cell.getData().neet_survey_id){
                    router.push({
                        name: 'view-neet',
                        params:{
                            surveyid: cell.getData().surveyid,
                            memberid: cell.getData().member_id,
                            neetid: cell.getData().neet_survey_id
                        }
                    })
                }
            }

            let divElm = document.createElement("div")
            divElm.className = "flex lg:justify-center items-center gap-2"
            
            let addDiv = document.createElement("div");
            addDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let addBtn = document.createElement("button");
            addBtn.className = "btn btn-secondary border-primary-1 bg-primary-1/[0.8] hover:bg-primary-1/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1 mb-1"
            let addText = document.createElement("span");
            addText.className = "t-tooltip-text"
            addText.append("เพิ่ม")
            let iconAdd = document.createElement("i");
            iconAdd.setAttribute("data-feather","plus")
            iconAdd.className = "w-7 h-7"
            if(cell.getData().neet_survey_id)
                addBtn.setAttribute("disabled","disabled")

            addBtn.addEventListener("click", addEvent);
            addBtn.append(iconAdd);
            addDiv.append(addText);
            addDiv.append(addBtn)
            divElm.append(addDiv)

            let viewDiv = document.createElement("div");
            viewDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let viewBtn = document.createElement("button");
            viewBtn.className = "btn btn-secondary bg-primary-2/[0.8] hover:bg-primary-2/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1 mb-1"
            let viewText = document.createElement("span");
            viewText.className = "t-tooltip-text"
            viewText.append("แสดง")
            let iconView = document.createElement("i");
            iconView.setAttribute("data-feather","eye")
            iconView.className = "w-7 h-7"
            if(!cell.getData().neet_survey_id)
                viewBtn.setAttribute("disabled","disabled")

            viewBtn.addEventListener("click", viewEvent);
            viewBtn.append(iconView);
            viewDiv.append(viewText)
            viewDiv.append(viewBtn)
            divElm.append(viewDiv)

            let editDiv = document.createElement("div");
            editDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let editBtn = document.createElement("button")
            editBtn.className = "btn btn-secondary bg-primary-3/[0.7] hover:bg-primary-3/[0.6] text-white mr-1 border-0 rounded w-7 h-7 p-1 mb-1"
            let editText = document.createElement("span");
            editText.className = "t-tooltip-text"
            editText.append("แก้ไข")
            let icon = document.createElement("i");
            icon.setAttribute("data-feather","edit")
            icon.className = "w-7 h-7"
            if(!cell.getData().neet_survey_id)
                editBtn.setAttribute("disabled","disabled")
            editBtn.addEventListener("click", editCallback);
            editBtn.append(icon);
            editDiv.append(editText)
            editDiv.append(editBtn)
            divElm.append(editDiv)

            let delDiv = document.createElement("div");
            delDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let delBtn = document.createElement("button")
            delBtn.className = "btn btn-secondary bg-theme-24/[0.8] hover:bg-theme-24/[0.5] text-white mr-1 border-0 rounded w-7 h-7 p-1 mb-1"
            let delText = document.createElement("span");
            delText.className = "t-tooltip-text"
            delText.append("ลบ")
            let delIcon = document.createElement("i");
            delIcon.setAttribute("data-feather","trash-2")
            delIcon.className = "w-7 h-7"

            if(!cell.getData().neet_survey_id)
                delBtn.setAttribute("disabled","disabled")
            delBtn.addEventListener("click", deleteCallback);
            delBtn.append(delIcon)
            delDiv.append(delText)
            delDiv.append(delBtn)
            divElm.append(delDiv)

            const b = cash(divElm)
            return b[0]
        }
    },
])
const handleRowClick = (row) =>{
    if(row.neet_survey_id){
        router.push({
            name: 'view-neet',
            params: {
                surveyid: row.surveyid,
                memberid: row.member_id,
                neetid: row.neet_survey_id
            },
        })
    }
}
</script>

<style lang="scss">

// .tabulator .tabulator-row .tabulator-cell[tabulator-field="action"]:hover{
//     cursor:crosshair !important;
// }
</style>