const state = () => ({
  allowRouteName: [],
  allowActions: []
})
// getters
const getters = {
  allowRouteName: state => state.allowRouteName,
  allowActions: state => state.allowActions
}

// actions
const actions = {
  setAllowRouteName({ commit }, data) {
    commit('setAllowRouteName', data)
  },
  setAllowActions({ commit }, data) {
    commit('setAllowActions', data)
  }
}

// mutations
const mutations = {
  setAllowRouteName(state, data){
    state.allowRouteName = data
  },
  setAllowActions(state, data){
    state.allowActions = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}