import localForage from "localforage";
import { checkDuplicateHouse, checkDuplicateMember } from "./utils/checkExist";
import axios from "axios";
import store from "./store";

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

const saveNeetSurvey = (data) => {
    return new Promise((resolve, reject)=>{
      const config = {
        method: data.neet_survey.id ? 'PUT' : 'POST',
        url: `${process.env.VUE_APP_BASE_API}neetSurveys`,
        data: data,
        headers: {
          Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
          "Content-Type": `application/json; charset=utf-8`,
        },
      }
      axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
}
const saveCWDSurvey = (data) => {
    return new Promise((resolve, reject)=>{
      const config = {
        method: data.cwd_survey.id ? 'PUT' : 'POST',
        url: `${process.env.VUE_APP_BASE_API}cwdSurveys`,
        data: data,
        headers: {
          Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
          "Content-Type": `application/json; charset=utf-8`,
        },
      }
      axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
}
const saveHousehold = (data) => {
    return new Promise((resolve, reject)=>{
      const config = {
        method: data.id || data.house_surveys.id ? 'PUT' : 'POST',
        url: `${process.env.VUE_APP_BASE_API}house`,
        data: data,
        headers: {
            Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
            "Content-Type": `application/json; charset=utf-8`,
        },
      }
      axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
}
const saveMember = (data) => {
    return new Promise((resolve, reject)=>{
      const config = {
        method: data.id ? 'PUT' : 'POST',
        url: `${process.env.VUE_APP_BASE_API}member`,
        data: data,
        headers: {
            Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
            "Content-Type": `application/json; charset=utf-8`,
        }
      }
      axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
}
async function getHouseStore(key) {
    let returnValue = null;
    await houseStore.getItem(key, function(err, value) {
        // Run this code once the value has been
        // loaded from the offline store.
        returnValue = value
    });
    return returnValue
}

async function syncDataToApi () {
    let keys = await houseStore.keys()
    let duplicateHouseList = []
    let duplicateMemberList = []
    let failHouseList = []
    let failMemberList = []
    let failNeetList = []
    let failCWDList = []
    let values = []
    for(let key of keys){
        let value = await getHouseStore(key)
        values.push(value)
    }
    for(let element of values){
        try{
            //check both house dup and dup number 11 and if both false then get sequence and save house
            let isDuplicateHouse = null
            if(element.address_num){
                isDuplicateHouse = await checkDuplicateHouse(element.address_num, element.village_ID)
            }
            let house_id = element.house_id ?? null
            let house_surveys_id = element.house_surveys_id ?? null
            if((isDuplicateHouse?.data?.length && !isDuplicateHouse?.data?.some(x => x._id == house_id))){
                //duplicate house but id match then assign house id to this element for user choose to insert as new survey in this house later
                element.house_id = isDuplicateHouse.data[0]._id
                duplicateHouseList.push(element)
            }
            else{
                const res = await axios.get(`${process.env.VUE_APP_BASE_API}autoincreament`,{params: {sequenceName: element.village_ID}}).catch(err => err);
                if(res.status === 200){
                    element.house_number_ID = `${res.data.sequenceName}${String(res.data.sequenceValue).padStart(6, '0')}`

                    const { id, members, ...rest } = element.house_surveys
                    const houseData = {
                        house_number_ID: element.house_number_ID,
                        township: element.township,
                        postcode: element.postcode,
                        house_number_11: element.house_number_11,
                        address_num: element.address_num,
                        soi: element.soi,
                        road: element.road,
                        sector: element.sector,
                        village_ID: element.village_ID,
                        village_name: element.village_name,
                        tambol_ID: element.tambol_ID,
                        tambol_name: element.tambol_name,
                        amphur_ID: element.amphur_ID,
                        amphur_name: element.amphur_name,
                        province_ID: element.province_ID,
                        province_name: element.province_name,
                        create_by: element.create_by,
                        house_surveys: rest
                    }
                    if(element.update_by){
                        houseData["update_by"] = element.update_by
                    }
                    if(house_id || house_surveys_id){
                        houseData["id"] = house_id
                        if(house_surveys_id){//case insert fail when insert again should update the same house
                            houseData["house_surveys"]["id"] = house_surveys_id
                            houseData["update_by"] = element.update_by ?? element.create_by
                        }
                    }
                    try{
                        const houseResponse = await saveHousehold(houseData)
                        if(houseResponse.status == 200){
                            let tempMemberList = []
                            if(houseResponse.data.lastErrorObject?.upserted)
                                element.house_id = houseResponse.data.lastErrorObject.upserted
                            if(houseResponse.data?.house_surveys_id)
                                element.house_surveys_id = houseResponse.data.house_surveys_id
                            //save member
                            if(element.house_surveys?.members?.length){
                                tempMemberList = [...element.house_surveys.members]
                                for(let mObj of element.house_surveys.members){
                                    try{
                                        const memberIDCheck = mObj.NID ? await checkDuplicateMember(null, null, null, null, mObj.NID) : null
                                        const memberNameCheck = await checkDuplicateMember(null, null, mObj.name, mObj.surname)
                                        const isDuplicateID = mObj.NID ? await checkDuplicateMember(element.house_id, element.house_surveys_id, null, null, mObj.NID) : null
                                        const isDuplicateMember = await checkDuplicateMember(element.house_id, element.house_surveys_id, mObj.name, mObj.surname)
                                        const mIdx = tempMemberList.findIndex(x=>x.member_id == mObj.member_id)
                                        if((isDuplicateID && (mObj.id ? isDuplicateID.member_id != mObj.id : true)) || (isDuplicateMember && (mObj.id ? isDuplicateMember.member_id != mObj.id : true)) || (memberIDCheck && (mObj.id ? memberIDCheck._id != mObj.id : true))){
                                            duplicateMemberList.push({...element, members_duplicate: {...mObj}})
                                            //remove from tempMemberList
                                            if(mIdx != -1)
                                                tempMemberList.splice(mIdx, 1)
                                        }
                                        else{
                                            try{
                                                if(memberIDCheck || memberNameCheck){
                                                    mObj.id = memberIDCheck._id
                                                }
                                                const { house_id, house_surveys_id, member_id, _id, neet_survey_data, cwd_survey_data, neet_survey_id, cwd_survey_id, ...rest } = mObj
                                                const memberData = {...rest, house_surveys_id: element.house_surveys_id}
                                                if(memberData.id)
                                                    memberData.update_by = memberData.update_by ?? memberData.create_by

                                                const memberResponse = await saveMember(memberData)
                                                if(memberResponse.status == 200){
                                                    let neetSaveSuccess = true, cwdSaveSuccess = true
                                                    //update to id in member obj from response id use to update member, house_surveys_id in house obj
                                                    tempMemberList[mIdx].id = memberResponse.data.member_id
                                                    if(mObj?.neet_survey_data && mObj.member_surveys?.NEET){
                                                        try{
                                                            mObj.neet_survey_data.house_id = element.house_id
                                                            mObj.neet_survey_data.survey_id = element.house_surveys_id
                                                            mObj.neet_survey_data.member_id = memberResponse.data.member_id
                                                            if(mObj.neet_survey_id){
                                                                mObj.neet_survey_data.neet_survey.id = mObj.neet_survey_id
                                                                mObj.neet_survey_data.update_by = mObj.neet_survey_data.update_by ?? mObj.neet_survey_data.create_by
                                                            }
                                                            const neetResponse = await saveNeetSurvey(mObj.neet_survey_data)
                                                            if(neetResponse.status == 200){
                                                                tempMemberList[mIdx]["neet_survey_id"] = neetResponse.data.neet_survey_id
                                                            }
                                                            else{
                                                                neetSaveSuccess = false
                                                                failNeetList.push({...element, fails: {...mObj, ...mObj.neet_survey_data}})
                                                            }
                                                        }
                                                        catch{
                                                            neetSaveSuccess = false
                                                            failNeetList.push({...element, fails: {...mObj, ...mObj.neet_survey_data}})
                                                        }
                                                    }
                                                    if(mObj?.cwd_survey_data && mObj.member_surveys?.CWD){
                                                        try{
                                                            mObj.cwd_survey_data.house_id = element.house_id
                                                            mObj.cwd_survey_data.survey_id = element.house_surveys_id
                                                            mObj.cwd_survey_data.member_id = memberResponse.data.member_id
                                                            if(mObj.cwd_survey_id){
                                                                mObj.cwd_survey_data.cwd_survey.id = mObj.cwd_survey_id
                                                                mObj.cwd_survey_data.update_by = mObj.cwd_survey_data.update_by ?? mObj.cwd_survey_data.create_by
                                                            }
                                                            const cwdResponse = await saveCWDSurvey(mObj.cwd_survey_data)
                                                            if(cwdResponse.status == 200){
                                                                tempMemberList[mIdx]["cwd_survey_id"] = cwdResponse.data.cwd_survey_id
                                                            }
                                                            else{
                                                                cwdSaveSuccess = false
                                                                failCWDList.push({...element, fails: { ...mObj, ...mObj.cwd_survey_data}})
                                                            }
                                                        }
                                                        catch{
                                                            cwdSaveSuccess = false
                                                            failCWDList.push({...element, fails: { ...mObj, ...mObj.cwd_survey_data}})
                                                        }
                                                    }

                                                    //remove from tempMemberList
                                                    if(mIdx != -1 && neetSaveSuccess && cwdSaveSuccess){
                                                        tempMemberList.splice(mIdx, 1)
                                                    }
                                                }
                                                else{
                                                    //member fail and get other status code(not 200) after call api
                                                    //push to memberFailList
                                                    failMemberList.push({...element, fails: {...mObj}})
                                                }
                                            }
                                            catch{
                                                //member fail when catch an error when try to save member
                                                failMemberList.push({...element, fails: {...mObj}})
                                            }
                                        }
                                    }
                                    catch{
                                        //member fail when catch an error when try to check duplicate member
                                        failMemberList.push({...element, fails: {...mObj}})
                                    }
                                }
                            }
                            //after save all member list if temp have len then update to members of house obj
                            if(tempMemberList.length){
                                element.house_surveys["members"] = tempMemberList
                                await houseStore.setItem(String(element.id), element)

                            }
                            else{
                                //remove this house
                                await houseStore.removeItem(String(element.id))
                            }

                        }
                        else{
                            //fail status code not 200
                            failHouseList.push(element)
                        }
                    }
                    catch{
                        //catch an error when try to save house
                        failHouseList.push(element)
                    }
                }
                else{//get auto increment fail house not save
                    failHouseList.push(element)
                }
            }
        }
        catch{
            //fail catch an error when try to check duplicate house
            failHouseList.push(element)
        }
    }
    return {
        duplicate_house: duplicateHouseList,
        duplicate_member: duplicateMemberList,
        fail_house: failHouseList,
        fail_member: failMemberList,
        fail_neet: failNeetList,
        fail_cwd: failCWDList
    };


}


export { syncDataToApi, saveHousehold, saveMember, saveNeetSurvey, saveCWDSurvey }
