import store from "@/store/index";
import axios from "axios";

const fetchMenu = () => {
    return new Promise(async(resolve) => {
        const config = {
            url: `${process.env.VUE_APP_BASE_API}admin/question_naire`,
            method: "GET",
            params:{
                status: "active"
            },
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
        };
        axios(config)
        .then((res)=>{
            resolve(res.data)
        })
        .catch(()=>{
            resolve([])
        })
    })
}
function getPermissionByLevel(){
    return new Promise(async(resolve, reject) => {
        let url = `${process.env.VUE_APP_BASE_API}managePermission`
        let roles = store.getters["userAuth/getRole"]
        let allow_access_route = []
        let allow_action = []
        if(roles?.includes("mpi_admin")){
            // allow_access_route = ["main-house-hold", "household-profile", "view-household", "member-page", "view-member", "neets", "view-neet", "cwd", "view-cwd", "dashboard-overview", "dashboard-summary", "house-survey-management", "member-survey-management", "neets-survey-management", "cwd-survey-management", "permission-management", "add-survey-management", "survey-management", "survey-datatable", "edit-survey-management"]
            allow_access_route = ["survey-datatable", "house-survey-management", "member-survey-management", "neets-survey-management", "cwd-survey-management", "permission-management", "add-survey-management", "survey-management", "edit-survey-management"]
            allow_action = ["read"]
        }
        else{
            const level = store.getters["userAuth/getAreaCode"]
            let level_name = null
            if(level.length == 2 && level != "00"){
                level_name = "ระดับจังหวัด"
            }
            else if(level.length == 4){
                level_name = "ระดับอำเภอ"
            }
            else if(level.length == 6){
                level_name = "ระดับตำบล"
            }
            else if(level == "00"){
                level_name = "ระดับประเทศ"
            }
            const config = {
            url: url,
            method: "GET",
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
            };
            if(level_name){
                config["params"] = {
                    level_name: level_name
                }
            }
            const response = await axios(config)
            .catch((error) => {
                reject(error);
            });
            if(response.status == 200){
                if(response.data.accessible_menus){
                    if(response.data.accessible_menus?.household && response.data.permissions?.read){
                        allow_access_route.push("main-house-hold")
                        //allow route from action
                        if(response.data.permissions?.read){
                            allow_access_route.push("household-profile", "view-household")
                        }
                        if(response.data.permissions?.create){
                            allow_access_route.push("add-household", "add-household-survey", "sync-page")
                        }
                        if(response.data.permissions?.update){
                            allow_access_route.push("edit-household")
                        }
                    }
                    if(response.data.accessible_menus?.member && response.data.permissions?.read){
                        allow_access_route.push("member-page")
                        //allow route from action
                        if(response.data.permissions?.read){
                            allow_access_route.push("view-member")
                        }
                        if(response.data.permissions?.create){
                            allow_access_route.push("add-member")
                        }
                        if(response.data.permissions?.update){
                            allow_access_route.push("edit-member")
                        }
                    }
                    if(response.data.accessible_menus?.neets && response.data.permissions?.read){
                        allow_access_route.push("neets")
                        //allow route from action
                        if(response.data.permissions?.read){
                            allow_access_route.push("view-neet")
                        }
                        if(response.data.permissions?.create){
                            allow_access_route.push("add-neet")
                        }
                        if(response.data.permissions?.update){
                            allow_access_route.push("edit-neet")
                        }
                    }
                    if(response.data.accessible_menus?.cwd && response.data.permissions?.read){
                        allow_access_route.push("cwd")
                        //allow route from action
                        if(response.data.permissions?.read){
                            allow_access_route.push("view-cwd")
                        }
                        if(response.data.permissions?.create){
                            allow_access_route.push("add-cwd")
                        }
                        if(response.data.permissions?.update){
                            allow_access_route.push("edit-cwd")
                        }
                    }
                    if(response.data.accessible_menus?.dashboard && response.data.permissions?.read){
                        allow_access_route.push("dashboard-overview", "dashboard-summary")
                    }
                    //allow action
                    if(response.data.permissions?.read){
                        allow_action.push("read")
                    }
                    if(response.data.permissions?.create){
                        allow_action.push("create")
                    }
                    if(response.data.permissions?.update){
                        allow_action.push("update")
                    }
                    if(response.data.permissions?.delete){
                        allow_action.push("delete")
                    }
                    if(response.data.permissions?.export){
                        allow_action.push("export")
                    }
                    if(!response.data.permissions?.read){
                        allow_access_route = ["error-page"]
                    }
                }
            }
        }
        const menu_result = await fetchMenu()
        store.dispatch("sideMenu/setPermissionToMenu", {menu: menu_result, route: allow_access_route})
        store.dispatch("permission/setAllowRouteName", allow_access_route)
        store.dispatch("permission/setAllowActions", allow_action)
        resolve(allow_access_route);
    });
}

function getPermissionAccessMenu(action){
    return store.getters["permission/allowActions"].includes(action)
}
export { getPermissionByLevel, getPermissionAccessMenu };