<template>
  <div>
    <label class="from-label text-medium text-gray-600">บ้านเลขที่</label>
    <input
      class="form-control"
      type="text"
      placeholder="กรุณากรอกบ้านเลขที่"
      :value="address_number"
      @input="handleInputChange"
    >
  </div>
</template>

<script setup>
const props = defineProps({
  address_number: String,
});

const emits = defineEmits(["update:address_number"]);

const handleInputChange = (evt) => {
  emits("update:address_number", evt.target.value);
};

</script>
