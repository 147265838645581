import {
  createApp
} from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import {
  initAuthOnLoad,
  initAuthConfig
} from './utils/auth'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import './utils/auth'

import VueMatomo from 'vue-matomo'

// vue-select css
import 'vue-select/dist/vue-select.css';

// SASS Theme
import './assets/sass/app.scss'
import { jwtInterceptor } from './jwt-interceptor'

import './registerServiceWorker'

jwtInterceptor();
const app = createApp(App)
  .use(store)
  .use(router)

app.use(VueMatomo, {
  host: 'https://montana.emenscr.in.th/analaytic/matomo',
  siteId: 2,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
  trackSiteSearch: false
})


if(store.getters['network/isOnline']){
  app.use(VueKeycloakJs, {
    init: initAuthOnLoad,
    config: initAuthConfig,
    logout: {
      redirectUri: "window.location.origin"
    },
    onReady(keycloak) {
      // console.log(keycloak)
      store.dispatch("userAuth/setStore", keycloak);
      

      if (keycloak.authenticated) {
        window._paq.push(['setUserId', keycloak.tokenParsed.preferred_username]);
        const json = JSON.stringify(keycloak);
        window.localStorage.setItem("mpi-logbook-auth", json);
      }

      setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            store.dispatch("userAuth/setToken", keycloak.token)
          }
        }).catch(() => {
          console.log('Failed to refresh token');
        });
      }, 10000)
      // 60000
    },
    // onInitError(error, err) {
    //   location.reload();
    //   console.log(localStorage.getItem('mso_kc_fauth'))
    //   if (localStorage.getItem('mso_kc_fauth') == true) {
    //     localStorage.setItem('mso_kc_fauth', false)
    //     location.reload();
    //   }
    //   console.log(error)
    // }
  })
}

globalComponents(app)
utils(app)

app.mount('#app')