<template>
  <template-layout>
    <!-- <h3 class="text-2xl text-center" v-if="!result"><RefreshCwIcon class="w-16 h-16 text-primary-1" :class="{'spinner': !result}"/> <div class="mt-2">กำลังนำเข้าข้อมูล </div></h3> -->
    <ModalLayout
      id="modal-sync-info"
      v-model="syncStatus"
      modal-size="modal-lg"
    >
      <h3 class="text-2xl text-center" v-if="statusSyncComputed"><RefreshCwIcon class="w-16 h-16 text-primary-1" :class="{'spinner': statusSyncComputed}"/> <div class="mt-2">กำลังนำเข้าข้อมูล </div></h3>
      <h3 class="text-2xl text-center" v-if="(result?.duplicate_house.length == 0 && result?.duplicate_member.length == 0 && result?.fail_house.length == 0 && result?.fail_member.length == 0 && result?.fail_neet.length == 0 && result?.fail_cwd.length == 0) || (resultAddNew?.fail_member.length == 0 && resultAddNew?.fail_house.length == 0 && resultAddNew?.fail_neets.length == 0 && resultAddNew?.fail_cwd.length == 0)">
      <div><CheckCircleIcon class="w-16 h-16 text-theme-10" /></div><div class="mt-2">สำเร็จ</div> </h3>
      <div v-if="resultAddNew?.fail_house?.length || resultAddNew?.fail_member?.length || resultAddNew?.fail_neets?.length || resultAddNew?.fail_cwd?.length">
        <div class="text-center"><InfoIcon class="w-16 h-16 text-primary-2" /></div>
        <h3 class="text-xl"><AlertCircleIcon class="w-6 h-6 text-theme-22 mt-[-5px]"/> นำเข้าไม่สำเร็จ </h3>
        <div class="overflow-x-auto mt-5" v-if="resultAddNew?.fail_house?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">รายการครัวเรือนที่นำเข้าไม่สำเร็จ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(house, index) in resultAddNew.fail_house" :key="house">
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${house.address_num ? `บ้านเลขที่ ${house.address_num} ` : null}${house.village_name} ${house.tambol_name} ${house.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="resultAddNew?.fail_member?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการสมาชิกที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in resultAddNew.fail_member.filter(m => !m.fails.id)" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="resultAddNew?.fail_neet?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการเด็ก NEETS ที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in resultAddNew.fail_neet" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="resultAddNew?.fail_cwd?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการเด็กพิการที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in result.fail_cwd" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #btn>
        <div class="flex flex-row justify-center items-center p-3" >
          <button class="btn btn-secondary mr-2" @click="hideModal" v-if="!statusSyncComputed">
            ปิด
          </button>
        </div>
      </template>
    </ModalLayout>
    <div class="mt-8">
      <div v-if="result?.duplicate_house?.length || result?.duplicate_member?.length" class="mb-5">
        <h3 class="text-xl"><AlertCircleIcon class="w-6 h-6 text-theme-22 mt-[-5px]"/> ไม่สามารถนำเข้าได้ </h3>
        <div class="overflow-x-auto mt-5">
          <table class="w-full text-left" v-if="result?.duplicate_house?.length">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">รายการครัวเรือนที่มีอยู่แล้วในระบบ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">การดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(house, index) in result.duplicate_house" :key="house">
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${house.address_num ? `บ้านเลขที่ ${house.address_num} ` : null}${house.village_name} ${house.tambol_name} ${house.amphur_name}`}}</td>
                <td class="px-5 py-3 border-1">
                  <div class="flex justify-center">
                    <button class="btn bg-theme-24 text-white p-1 whitespace-pre mr-1" @click="deleteFormLocal(house)" :disabled="deleting">
                      <Trash2Icon class="h-3 w-3 mr-2" /> ลบ
                    </button>
                    <!-- <button class="btn btn-primary bg-primary-btn border-primary-btn p-1 whitespace-pre" @click="addSurvey(house)" :disabled="updating">
                      <PlusIcon class="h-3 w-3 mr-2" /> เพิ่มการสำรวจใหม่
                    </button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5">
          <table class="w-full text-left" v-if="result?.duplicate_member?.length">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการสมาชิกที่มีอยู่แล้วในระบบ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in result.duplicate_member" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.members_duplicate.prefix_name} ${member.members_duplicate.name} ${member.members_duplicate.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="result?.fail_house?.length || result?.fail_member?.length || result?.fail_neet?.length || result?.fail_cwd?.length">
        <h3 class="text-xl"><AlertCircleIcon class="w-6 h-6 text-theme-22 mt-[-5px]"/> นำเข้าไม่สำเร็จ </h3>
        <div class="overflow-x-auto mt-5" v-if="result?.fail_house?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center">รายการครัวเรือนที่นำเข้าไม่สำเร็จ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(house, index) in result.fail_house" :key="house">
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${house.address_num ? `บ้านเลขที่ ${house.address_num} ` : null}${house.village_name} ${house.tambol_name} ${house.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="result?.fail_member?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการสมาชิกที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in result.fail_member.filter(m => !m.fails.id)" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="result?.fail_neet?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการเด็ก NEETS ที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in result.fail_neet" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto mt-5" v-if="result?.fail_cwd?.length">
          <table class="w-full text-left">
            <thead class="bg-gray-300">
              <tr>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap text-center" colspan="3">รายการเด็กพิการที่นำเข้าไม่สำเร็จ</th>
              </tr>
              <tr class="bg-gray-200">
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">#</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ชื่อ</th>
                <th class="font-medium px-5 py-3 dark:border-darkmode-300 border-1 border-b-0 text-slate-700 whitespace-nowrap">ครัวเรือน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in result.fail_cwd" :key="member">
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{index+1}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.fails.prefix_name} ${member.fails.name} ${member.fails.surname}`}}</td>
                  <td class="px-5 py-3 border-1 dark:border-darkmode-300">{{`${member.address_num ? `บ้านเลขที่ ${member.address_num} ` : null}${member.village_name} ${member.tambol_name} ${member.amphur_name}`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template-layout>
</template>

<script setup>
import { ref, reactive, onBeforeMount, watch, computed, onMounted} from "vue";
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import localForage from "localforage";
import ModalLayout from "@/components/base/modal-layout/ModalLayout.vue"
import {syncDataToApi, saveHousehold, saveMember, saveNeetSurvey, saveCWDSurvey} from "../../sync"
import { checkDuplicateMember } from "@/utils/checkExist";
import axios from "axios";

const villageStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'village'
});
const provinceStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'province'
});

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const syncStatus = ref(false)
const store = useStore()
const router = useRouter()
const route = useRoute()
const result = ref(null)
const resultAddNew = ref(null)
const deleting = ref(false)
const updating = ref(false)
const userProvCode = computed(
  () => parseInt(store.getters["userAuth/getUserProvinceCode"])
);


navigator.serviceWorker.onmessage = (event) => {
  if (event.data) {
    store.dispatch('network/setIsOnline', event.data.value)
    // store.dispatch('network/setIsOfflineWhileOnline', event.data.offlineWhileOnline)
  }
};

const onlineComputed = computed(()=>store.getters['network/isOnline'])
const statusSyncComputed = computed(()=> store.getters['network/statusSync'])
const hideModal = async () =>{
  syncStatus.value = false
  cash(`#modal-sync-info`).modal("hide");
  let houselen = await houseStore.length()
  if(!houselen){
    result.value = null
    setTimeout(()=>{
      router.push({
        name: "main-house-hold"
      })
    }, 500)
  }
}

const deleteFormLocal = async (data) =>{
  deleting.value = true
  const idx = result.value.duplicate_house.findIndex(x=>x.id == data.id)
  const res = await houseStore.removeItem(String(data.id))
  result.value.duplicate_house.splice(idx, 1)
  deleting.value = false
  let houselen = await houseStore.length()
  if(!houselen){
    router.push({
      name: 'main-house-hold'
    })
  }
}
const addSurvey = async (data) =>{
  let fail_house = []
  let fail_member = []
  let fail_neets = []
  let fail_cwd = []

  try{
    syncStatus.value = true
    store.dispatch('network/setStatusSync', true)
    let temp_house_surveys_id = null
    let house_id = data.house_id
    updating.value = true
    const {id, members, ...rest} = data.house_surveys
    const res = await saveHousehold({id: house_id, house_surveys: rest, update_by: data.create_by})
    if(res.status == 200){
      if(res.data?.house_surveys_id)
        temp_house_surveys_id = res.data.house_surveys_id
      //save each member
      if(data.house_surveys?.members?.length){
        for(let mObj of data.house_surveys.members){
          const memberIDCheck = mObj.NID ? await checkDuplicateMember(null, null, null, null, mObj.NID) : null
          const memberNameCheck = await checkDuplicateMember(null, null, mObj.name, mObj.surname)
          const isDuplicateID = mObj.NID ? await checkDuplicateMember(house_id, temp_house_surveys_id, null, null, mObj.NID) : null
          const isDuplicateMember = await checkDuplicateMember(house_id, temp_house_surveys_id, mObj.name, mObj.surname)
          //remove  || memberIDCheck if get member id and update member id instead
          if(isDuplicateID || isDuplicateMember || memberIDCheck){
            fail_member.push({...data, fails: {...mObj}})
          }
          else{
            try{
              if(memberNameCheck){
                mObj.id = memberNameCheck._id
              }
              const { house_id, house_surveys_id, member_id, _id, id, neet_survey_data, cwd_survey_data, ...rest } = mObj
              const memberData = {...rest, house_surveys_id: temp_house_surveys_id}
              const resMember = await saveMember(memberData)
              if(resMember.status == 200){
                // let neetSaveSuccess = true, cwdSaveSuccess = true
                //insert neet and cwd 
                if(mObj?.neet_survey_data && mObj.member_surveys?.NEET){
                    try{;
                        mObj.neet_survey_data.house_id = house_id
                        mObj.neet_survey_data.survey_id = temp_house_surveys_id
                        mObj.neet_survey_data.member_id = resMember.data.member_id
                        if(mObj.neet_survey_id){
                            mObj.neet_survey_data.neet_survey.id = mObj.neet_survey_id
                            mObj.neet_survey_data.update_by = mObj.neet_survey_data.update_by ?? mObj.neet_survey_data.create_by
                        }
                        const neetResponse = await saveNeetSurvey(mObj.neet_survey_data)
                        if(neetResponse.status != 200){
                          // neetSaveSuccess = false
                          fail_neets.push({...data, fails: {...mObj, ...mObj.neet_survey_data}})
                        }
                    }
                    catch{
                      // neetSaveSuccess = false
                      fail_neets.push({...data, fails: {...mObj, ...mObj.neet_survey_data}})
                    }
                }
                if(mObj?.cwd_survey_data && mObj.member_surveys?.CWD){
                    try{
                        mObj.cwd_survey_data.house_id = house_id
                        mObj.cwd_survey_data.survey_id = temp_house_surveys_id
                        mObj.cwd_survey_data.member_id = resMember.data.member_id
                        if(mObj.cwd_survey_id){
                            mObj.cwd_survey_data.cwd_survey.id = mObj.cwd_survey_id
                            mObj.cwd_survey_data.update_by = mObj.cwd_survey_data.update_by ?? mObj.cwd_survey_data.create_by
                        }
                        const cwdResponse = await saveCWDSurvey(mObj.cwd_survey_data)
                        if(cwdResponse.status != 200){
                          cwdSaveSuccess = false
                          fail_cwd.push({...data, fails: { ...mObj, ...mObj.cwd_survey_data}})
                        }
                    }
                    catch{
                      cwdSaveSuccess = false
                      fail_cwd.push({...data, fails: { ...mObj, ...mObj.cwd_survey_data}})
                    }
                }
              }
              else{
                //fail member
                fail_member.push({...data, fails: {...mObj}})
              }
            }
            catch{
              //member insert fail
              fail_member.push({...data, fails: {...mObj}})
            }
          }
        }
      }

    }
    else{
      //fail insert house
     fail_house.push(data)
    }
  }
  catch{
    //fail insert house
    fail_house.push(data)
  }
  setTimeout(async()=>{
    store.dispatch('network/setStatusSync', false)
    if(!fail_house.length){
      const idx = result.value.duplicate_house.findIndex(x=>x.id == data.id)
      await houseStore.removeItem(String(data.id))
      result.value.duplicate_house.splice(idx, 1)
      let houselen = await houseStore.length()
      if(!houselen){
        result.value = null
      }
    }
    updating.value = false
    resultAddNew.value = {
      fail_house: fail_house,
      fail_member: fail_member,
      fail_neets: fail_neets,
      fail_cwd: fail_cwd,
    }
  },500)
}
onMounted(()=>{
  if(statusSyncComputed.value){
    syncStatus.value = true
    setTimeout(async()=>{
      result.value = await syncDataToApi()
      if(result.value.duplicate_house.length || result.value.duplicate_member.length || result.value.fail_house.length || result.value.fail_member.length || result.value.fail_neet.length || result.value.fail_cwd.length){
          syncStatus.value = false
      }
    
      //update status
      store.dispatch('network/setStatusSync', false)
    }, 500)
  }
})



</script>
<style lang="scss">
.spinner {
    -webkit-animation: spinner 2s infinite linear;
    animation: spinner 2s infinite linear
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
</style>