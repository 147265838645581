<template>
  <div
    :id="accordionId"
    class="accordion accordion-boxed"
  >
    <div class="accordion-item shadow-lg px-4 py-1" :class="customClass">
      <div
        :id="`${accordionId}-content`"
        class="accordion-header"
      >
        <div class="flex items-center">
          <slot name="before-header"></slot>
          <button
            class="accordion-button flex justify-between items-center"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#${accordionId}-collapse`"
            :aria-expanded="isOpen"
            :aria-controls="`${accordionId}-collapse`"
            :class="{'collapsed':!isOpen}"
          >
            <slot name="header-content">
              <div class="inline-block py-2 rounded-md" v-if="headerText">
                {{ headerText }}
              </div>
            </slot>
            <slot name="custom-icon">
              <div class="flex flex-row items-center">
                <ChevronDownIcon class="h-4 w-4" v-if="showIcon"/>
              </div>
            </slot>
          </button>
        </div>
      </div>
      <div
        :id="`${accordionId}-collapse`"
        class="accordion-collapse collapse"
        :aria-labelledby="`${accordionId}-content`"
        :data-bs-parent="`#${accordionId}`"
        :class="{'show': isOpen}"
      >
        <div
          class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed gap-4 gap-y-5 grid grid-cols-12"
        >
          <slot name="body-content">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  accordionId: {
    type: String
  },
  headerText: {
    type: String
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  showIcon:{
    type: Boolean,
    default: true
  },
  customClass:{
    type: Array
  }
});

const open = ref(false);
</script>
<style lang="scss">
.accordion-button .feather-chevron-down{
  transition: transform .1s ease;
}
.accordion-button[aria-expanded="true"] .feather-chevron-down {
  transform: rotate(180deg);
  transition: transform .1s ease;
}
</style>
