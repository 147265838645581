<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div>
        <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-6">
            <h1 class="text-lg text-primary-2 mr-auto">
                {{`${isEdit ? 'แก้ไข' : 'สร้าง'}แบบสอบถาม`}}
            </h1>
        </div>
        <div class="intro-y box p-5">
            <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="col-span-12">
                    <label class="form-label">
                        ชื่อแบบสอบถาม
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="questionnaire_name"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อแบบสอบถาม"
                        data-test="questionnaire_name"
                        :disabled="false"
                        :class="{ 'border-theme-24': validate.questionnaire_name.$error}"
                        v-model="validate.questionnaire_name.$model"
                    />
                    <template v-if="validate.questionnaire_name.$error">
                        <div
                            v-for="(error, index) in validate.questionnaire_name.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        ชื่อแบบสอบถามแบบย่อ
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="questionnaire_name_short"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อแบบสอบถามแบบย่อ เพื่อใช้แสดงในแถบเมนู"
                        data-test="questionnaire_name_short"
                        :disabled="false"
                        :class="{ 'border-theme-24': validate.questionnaire_name_short.$error}"
                        v-model="validate.questionnaire_name_short.$model"
                    />
                    <template v-if="validate.questionnaire_name_short.$error">
                        <div
                            v-for="(error, index) in validate.questionnaire_name_short.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        ชื่อแบบสอบถามภาษาอังกฤษ
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="questionnaire_name_en"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อแบบสอบถามภาษาอังกฤษ เพื่อใช้สำหรับตารางฐานข้อมูล"
                        data-test="questionnaire_name_en"
                        :disabled="getDisabledComputed"
                        :class="{ 'border-theme-24': validate.questionnaire_name_en.$error || isNotUniqueName == false}"
                        v-model="validate.questionnaire_name_en.$model"
                        @input="debounce(() => checkExist()), form.questionnaire_name_en = form.questionnaire_name_en.replace(/[^A-Za-z0-9_]/g, '')"
                        :state="isNotUniqueName"
                        :style="nameChecking ? 'pointer-events:none;' : null"
                        @keypress="nameChecking ? $event.preventDefault() : false"
                    />
                    <template v-if="validate.questionnaire_name_en.$error">
                        <div
                            v-for="(error, index) in validate.questionnaire_name_en.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                    <template v-if="isNotUniqueName == false">
                        <div
                            class="text-theme-24 mt-1 text-sm"
                        >
                            ชื่อแบบสอบถามนี้ถูกใช้งานแล้ว
                        </div>
                    </template>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        ชื่อรหัสคำถาม
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="code_name"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อรหัสคำถาม เพื่อใช้เป็นรหัสตั้งต้นของคำถาม"
                        data-test="code_name"
                        :disabled="isEdit"
                        :class="{ 'border-theme-24': validate.code_name.$error}"
                        v-model="validate.code_name.$model"
                        maxlength="6"
                        oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);'"
                        @input="form.code_name = form.code_name.replace(/[^A-Za-z0-9_]/g, '')"
                    />
                    <template v-if="validate.code_name.$error">
                        <div
                            v-for="(error, index) in validate.code_name.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <!-- <div class="col-span-12" v-if="isEdit">
                    <div class="form-check flex-col items-start mt-3">
                        <label for="active-status" class="form-check-label ml-0 mb-2">เปิด/ปิดการใช้งาน</label>
                        <input id="active-status" class="form-check-switch" type="checkbox" v-model="form.status" true-value="active" false-value="inactive" :disabled="getDisabledComputed">
                    </div>
                </div> -->
            </div>
            <div class="flex justify-end mt-10">
                <button class="btn btn-secondary" @click="router.go(-1)">
                    ย้อนกลับ
                </button>
                <button class="btn btn-primary ml-2" @click="submit">
                    {{isEdit ? 'บันทึกข้อมูล' : 'สร้างแบบสอบถาม'}}
                </button>
            </div>
        </div>
    </div>
  </template-layout>
</template>

<script setup>
import { reactive, computed, ref, onMounted } from 'vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { useStore } from 'vuex';


const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const router = useRouter()
const route = useRoute()
const store = useStore()
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};
const questionnaire_data = ref()

const nameChecking = ref(false)
const isNotUniqueName = ref(null)
const isEdit = ref(false)

const form = reactive({
    questionnaire_name: null,
    questionnaire_name_short: null,
    questionnaire_name_en: null,
    code_name: null,
    status: "inactive"
})
const formrules = computed(()=>{
    return {
        questionnaire_name: {
            required: helpers.withMessage(`กรุณาระบุ`, required),
        },
        questionnaire_name_short: {
            required: helpers.withMessage(`กรุณาระบุ`, required),
        },
        questionnaire_name_en: {
            required: helpers.withMessage(`กรุณาระบุ`, required),
        },
        code_name: {
            required: helpers.withMessage(`กรุณาระบุ`, required)
        },
    }
})


const validate = useVuelidate(formrules, form)

const submit = () => {
    validate.value.$touch();
    if (validate.value.$invalid || nameChecking.value || isNotUniqueName.value == false) {
        return;
    }
    swalCustom.fire({
        text: `ยืนยัน${isEdit.value ? 'บันทึก' : 'สร้าง'}แบบสอบถาม`,
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
    }).then(async(result)=>{
        if (result.isConfirmed) {
            const config_axios = {
                method: questionnaire_data.value?._id ? 'PUT' : 'POST',
                url: `${process.env.VUE_APP_BASE_API}admin/question_naire${questionnaire_data.value?._id ? `/${questionnaire_data.value._id}` : ``}`,
                data: {
                    name: form.questionnaire_name,
                    short_name: form.questionnaire_name_short,
                    name_en: form.questionnaire_name_en,
                    code_name: form.code_name,
                    status: form.status
                },
                headers: headers
            }
            const response = await axios(config_axios).catch(()=>{
                swalCustom.fire({
                    text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                    icon: "warning",
                    // timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                });
            })
            if(response.status == 200){
                if(isEdit.value){
                    swalCustom.fire({
                        text:"สำเร็จ",
                        icon: 'success',
                        timer: 3000,
                        confirmButtonText: "ปิด",
                    }).then(() => {
                        router.replace({
                            name: "survey-datatable"
                        })
                    });
                }
                else{
                    router.replace({
                        name: "survey-management",
                        params:{
                            survey: response.data.name_en
                        }
                    })
                }
            }
        }
    })
}

const createDebounce = () => {
    let timeout = null;
    return function (fnc) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fnc();
      }, 1000);
    };
}

const debounce = createDebounce()

const checkExist = async() => {
    if(form.questionnaire_name_en){
        nameChecking.value = true
        isNotUniqueName.value = null
        const result = await axios.get(`${process.env.VUE_APP_BASE_API}admin/check/question_naire`,{ headers, params: { name_en: form.questionnaire_name_en }})
        if(result.data){
            setTimeout(()=>{
                nameChecking.value = false
            },500)
            if(result.data?._id == questionnaire_data.value?._id){
                    isNotUniqueName.value = true
            }
            else{
                isNotUniqueName.value = false
            }
        }
        else{
            setTimeout(()=>{
                nameChecking.value = false
            },500)
            isNotUniqueName.value = true
        }
    }
    else{
        isNotUniqueName.value = null
    }
}
const breadcrumbComputed = computed(()=>{
    return [
        {
            name: "เพิ่มแบบสอบถาม",
            active: true,
        }
    ]
})

const getQuestionnaireData = async () => {
    const url = `${process.env.VUE_APP_BASE_API}admin/question_naire`;
    const params = {
        name_en: route.params.surveyid
    }
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        questionnaire_data.value = res.data[0]
        Object.assign(form,{
            questionnaire_name: questionnaire_data.value.name,
            questionnaire_name_short: questionnaire_data.value.short_name,
            questionnaire_name_en: questionnaire_data.value.name_en,
            code_name: questionnaire_data.value.code_name,
            status: questionnaire_data.value.status
        })
    }
}

const getDisabledComputed = computed(()=>{
    if(form.questionnaire_name_en == "household"){
        return true
    }
    if(form.questionnaire_name_en == "member"){
        return true
    }
    if(form.questionnaire_name_en == "neets"){
        return true
    }
    if(form.questionnaire_name_en == "cwd"){
        return true
    }
    else{
        return false
    }
})

onMounted(()=>{
    if(route.params.surveyid){
        isEdit.value = true
        getQuestionnaireData()
    }
})
</script>

<style lang="scss">
</style>