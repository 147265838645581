<template>
  <div class="flex rounded-md border border-gray-400 overflow-hidden">
    <fieldset class="flex-grow text-center">
      <input
        class="peer"
        type="radio"
        :name="name"
        :id="firstOptionId"
        :value="firstOptionValue"
        v-model="selectState"
        @click="checkUnSelect"
        :disabled="isDisabled"
      >
      <label
        class="inline-block text-xs w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
        :for="firstOptionId"
      >{{ firstOptionComputed }}</label>
    </fieldset>
    <fieldset class="flex-grow text-center border-l border-gray-400">
      <input
        class="peer"
        type="radio"
        :name="name"
        :id="secondOptionId"
        :value="secondOptionValue"
        v-model="selectState"
        @click="checkUnSelect"
        :disabled="isDisabled"
      >
      <label
        class="inline-block text-xs w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
        :for="secondOptionId"
      >{{ secondOptionComputed }}</label>
    </fieldset>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  name: {
    type: String,
    require: true,
  },
  firstOptionId: {
    type: String,
    require: true,
  },
  secondOptionId: {
    type: String,
    require: true,
  },
  firstOptionValue: [String, Number],
  secondOptionValue: [String, Number],
  firstOptionText: [String, Number],
  secondOptionText: [String, Number],
  modelValue: [String, Number],
  unCheckAbility: {
    type: Boolean,
    default: true,
  },
  isDisabled:{
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(["update:modelValue"]);

const selectState = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits("update:modelValue", value);
  },
});
const firstOptionComputed = computed(() => {
  if (props.firstOptionText) return props.firstOptionText;
  return props.firstOptionValue;
});
const secondOptionComputed = computed(() => {
  if (props.secondOptionText) return props.secondOptionText;
  return props.secondOptionValue;
});

const checkUnSelect = (evt) => {
  if (props.modelValue === evt.target.value && props.unCheckAbility)
    emits("update:modelValue", null);
};
</script>

<style lang="scss" scoped>
input[type="radio"] {
  display: none;
}
</style>
