<template>
  <template-layout :is-hide-menu="true">
    <div>
      <div class="main-banner">
        <TinySlider
          :options="{
            mode: 'gallery',
            nav: true,
            speed: 500,
          }"
        >
          <!-- <div class="main-image-slide">-->
          <div>
            <img src="@/assets/images/main/banner-msologbook-V2.2.jpg" />
          </div>
          <!-- </div> -->
        </TinySlider>
      </div>
      <div />
      <div class="mt-12">
        <h3 class="text-xl font-bold leading-none mt-3 text-custom-member">
          ภาพรวมโครงการ
        </h3>
        <div class="grid grid-cols-12 gap-6 mt-2">
          <div
            class="report-box-2 col-span-12 sm:col-span-4 mt-2"
            :class="[i.shadow]"
            v-for="(i, k) in boxCard"
            :key="k"
          >
            <div class="box sm:flex" :class="[i.bg, i.color]">
              <div class="px-4 py-4 flex flex-col justify-center flex-1">
                <div class="flex items-center justify-between">
                  <span>{{ i.text }}</span>

                  <span
                    class="
                      w-8
                      h-8
                      px-2
                      py-2
                      flex
                      items-center
                      justify-center
                      rounded-full
                      bg-white
                      text-theme-17
                      ml-2
                      box-shadow
                    "
                  >
                    <HomeIcon
                      class="block mx-auto !stroke-2 stroke-[#90C4C4]"
                      v-if="i.icon == 'house'"
                    />
                    <UsersIcon
                      class="block mx-auto !stroke-2 stroke-[#7EA6D1]"
                      v-if="i.icon == 'family'"
                    />
                    <UserIcon
                      class="block mx-auto !stroke-2 stroke-[#15286C]"
                      v-if="i.icon == 'member'"
                    />
                  </span>
                </div>

                <div class="relative text-5xl font-medium ml-0.5">
                  {{ i.count }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Statistics />
        <NewsList :news-section="newsSection" />
        <AnnouncementsModal />
      </div>
    </div>
  </template-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import TemplateLayout from "@/layouts/side-menu/Main.vue";
import Statistics from "./container/Statistics.vue";
import NewsList from "./container/NewsList.vue";
import AnnouncementsModal from "@/components/modal-announcements/index.vue";

export default defineComponent({
  components: {
    TemplateLayout,
    Statistics,
    NewsList,
    AnnouncementsModal,
  },
  setup() {
    const boxCard = reactive([
      {
        text: "จำนวนครัวเรือน",
        bg: "bg-custom-household",
        count: 28,
        shadow: "before:bg-custom-hshadow",
        color: "text-custom-member",
        icon: "house",
      },
      {
        text: "จำนวนครอบครัว",
        bg: "bg-custom-family",
        count: 12,
        shadow: "before:bg-custom-fshadow",
        color: "text-custom-member",
        icon: "family",
      },
      {
        text: "จำนวนสมาชิก",
        bg: "bg-custom-member",
        count: 10,
        shadow: "before:bg-custom-mshadow",
        color: "text-white",
        icon: "member",
      },
    ]);
    const newsSection = reactive([
      { text: "เกี่ยวกับโครงการ" },
      { text: "เกี่ยวกับระบบ" },
      { text: "เกี่ยวกับข่าวสาร" },
    ]);
    return {
      boxCard,
      newsSection,
    };
  },
});
</script>
<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}
</style>
