<template>
  <div>
    <div class="container">
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-left"
      >
        <div class="text-theme-2 mt-10 lg:mt-0">
          <div class="intro-x text-4xl font-medium">
            ไม่มีสิทธิ์เข้าใช้งาน!
          </div>
          <div class="intro-x text-xl lg:text-2xl font-medium mt-5">
            บัญชีผู้ใช้งานนี้ไม่มีสิทธิ์เข้าใช้งานเมนูต่างๆ
          </div>
          <div class="intro-x text-xl mt-3">
            กรุณาเข้าใช้งานด้วยบัญชีอื่น
          </div>
          <button
            class="intro-x btn py-3 px-4 text-white btn btn-primary dark:border-dark-5 dark:text-gray-300 mt-10"
            @click="logoutUser"
          >
            เข้าใช้งานด้วยบัญชีอื่น
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex';

const store = useStore()
  const logoutUser = () => {
    store.getters["userAuth/getAllKeycloak"].logout({
      redirectUri: window.location.origin,
    });
    window.localStorage.removeItem("mpi-logbook-auth");
  };
  onMounted(() => {
    cash('body')
      .removeClass('main')
      .removeClass('login')
      .addClass('error-page pt-0')
  })

</script>
