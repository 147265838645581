const INIT_FILTER_VALUE = {
  address: {
    amphur: null,
    province: null,
    tumbol: null,
    village: null,
  },
  end_date: null,
  start_date: null,
  address_number: null,
  house_survey_status: null
};

const state = () => {
  return { ...INIT_FILTER_VALUE };
};

// actions
const actions = {
  setProvince({ commit }, province) {
    commit("setProvince", province);
  },
  clearStore({ commit }) {
    commit("clearStore");
  },
  setStore({ commit }, store) {
    commit("setStore", store);
  },
};

// mutations
const mutations = {
  setProvince(state, province) {
    state.address.province = province;
  },
  clearStore(state) {
    state = { ...INIT_FILTER_VALUE };
  },
  setStore(state, store) {
    Object.assign(state, store);
  },
};

// getters
const getters = {
  province: (state) => state.address.province,
  getAllFilter: (state) => {
    return state;
  },
  getFilterSummary: (state, getters, rootState, rootGetters) => {
    let result = [];

    if (state.address.province && rootGetters["userAuth/getAreaCode"] == "00"){
      result.push(state.address.province.province_name)
    }
    if (state.address.amphur && rootGetters["userAuth/getAreaCode"].length == 2){
      result.push(state.address.amphur.amphur_name)
    }
    if (state.address.tumbol && (rootGetters["userAuth/getAreaCode"].length == 2 || rootGetters["userAuth/getAreaCode"].length == 4)){
      result.push(state.address.tumbol.tumbol_name)
    }
    if (state.address.village){
      result.push(state.address.village.village_name)
    }
    if (state.address_number){
      result.push(`บ้านเลขที่ ${state.address_number}`)
    }
    if (state.start_date || state.end_date){
      if(state.start_date){
        let start_format = new Date(state.start_date)
        start_format = [String(start_format.getDate()).padStart(2, '0'),
                        String(start_format.getMonth() + 1).padStart(2, '0'),
                        start_format.getFullYear() + 543
                      ].join('/')
        result.push(`วันที่ ${start_format}`)
        if(state.end_date){
          let end_format = new Date(state.end_date)
          end_format = [String(end_format.getDate()).padStart(2, '0'),
                        String(end_format.getMonth() + 1).padStart(2, '0'),
                        end_format.getFullYear() + 543
                      ].join('/')
          result.push(`ถึง ${end_format}`)
        }
      }
    }
    if (state.house_survey_status){
      result.push(`สถานะ ${state.house_survey_status == "DRAFT" ? "ร่าง" : "สำเร็จ"}`)
    }

    return result;
  },
  getFilterForApi: (state, getters, rootState, rootGetters) => {
    const result = {};
    if ((rootGetters["userAuth/getUserProvinceCode"].length === 2 && rootGetters["userAuth/getUserProvinceCode"] !== "00") || state.address.province)
      result.province_ID = state.address.province ? state.address.province._id.province_ID: Number(rootGetters["userAuth/getUserProvinceCode"])
    if (rootGetters["userAuth/getUserAmphurCode"].length === 4 || state.address.amphur)
      result.amphur_ID = state.address.amphur ? state.address.amphur._id.amphur_ID : Number(rootGetters["userAuth/getUserAmphurCode"]);
    if (rootGetters["userAuth/getUserTumbolCode"].length === 6 || state.address.tumbol)
      result.tambol_ID = state.address.tumbol ? state.address.tumbol._id.tumbol_ID : Number(rootGetters["userAuth/getUserTumbolCode"]);
    if (state.address.village)
      result.village_ID = state.address.village._id.village_ID;
    if (state.address_number)
      result.address_num = state.address_number;
    if (state.start_date) result.survey_start_date = state.start_date;
    if (state.end_date) result.survey_end_date = state.end_date;
    if (state.house_survey_status) result.house_survey_status = state.house_survey_status;
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
