<template>
  <!-- <div :id="chartId">
    <canvas ref="chartRef" class="lg:p-[3rem] mb-3 lg:mb-0" />
    <div class="customLegend">

    </div>
  </div> -->
  <div class="w-full" :id="chartId" v-if="!legendOnRight">
    <div :class="{'chartWrap': mobileModeLayout}">
       <div class="h-full" :class="{'mobileContainer' : mobileModeLayout}">
          <div class="containerBody">
            <canvas ref="chartRef" class="mb-3 lg:mb-0 h-full"/>
          </div>
      </div>
    </div>
    <div class="customLegend mt-5" :class="legendBoxStyle">

    </div>
  </div>
  <div class="grid grid-cols-12 gap-2" v-else>
    <div class="pb-0" :class="[columnClass[0],legendPositionClass]">
      <canvas ref="chartRef" :height="height"/>
    </div>
    <div class="pt-0" :id="chartId" :class="[columnClass[1], legendPositionClass]">
      <div class="customLegend" :class="legendBoxStyle"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject, watch, onUnmounted } from 'vue'
import { helper as $h } from '@/utils/helper'
import Chart from 'chart.js/auto'
import {millionShortNum,millionShortString,globalNumDecFixed} from "@/utils/format-number"
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";


const props = defineProps(
  {
    type: {
      type: String,
      required: true,
      default: 'line',
      validator: value => {
        return ['bar'].indexOf(value) !== -1
      }
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    refKey: {
      type: String,
      default: null
    },
    chartId: {
      type: String,
      required: true,
      default: "chartBox"
    },
    legendOnRight:{
      type: Boolean,
      default: false
    },
    sumTotal:{
      type: Number,
      default: 0
    },
    plugins: {
      type: Object,
      default: () => ({})
    },
    customLegend: {
      type: Boolean,
      default: false
    },
    columnClass:{
      type: Array,
      default: ["col-span-5", "col-span-7"]
    },
    legendPosition:{
      type: String,
      default: "center"
    },
    legendBoxStyle:{
      type: String,
      default: ""
    },
    legendInRow:{
      type: Number,
      default: 0
    },
    mobileModeLayout:{
      type: Boolean,
      default: false
    }
  }
)
const isMobileScreen = ref(false)
const chartRef = ref()
const legendPositionClass = ref()
const init = () => {
  const canvas = chartRef.value?.getContext('2d')
  Chart.register(ChartjsPluginStacked100);
  Chart.defaults.font.family = "Kanit"
  Chart.defaults.plugins.legend.labels.usePointStyle = true
  const chart = new Chart(canvas, {
    type: props.type,
    data: $h.toRaw(props.data),
    options: props.options,
    plugins: props.plugins
  })

  watch(props, () => {
    chart.data = $h.toRaw(props.data)
    chart.options = props.options
    chart.plugins = props.plugins
    if(props.mobileModeLayout){
      const barWidth = 25
      const chartCurrId = document.getElementById(props.chartId)
      const chartWrap = chartCurrId.querySelector(".chartWrap")
      const totalBars = chart.data.labels.length
      const heightConst = totalBars == 1 ? 40 : 20
      if(isMobileScreen.value){
        let mobileHeight = 300
        chartWrap.style.height = `${mobileHeight}px`
        const containerBody = chartCurrId.querySelector(".containerBody")
        if(totalBars >= Math.ceil(chartWrap.clientWidth/barWidth))
          containerBody.style.width = `${barWidth*totalBars}px`
      }
      else{
          chartWrap.style.height = `${(barWidth * totalBars) + heightConst}px`
      }
    }
    chart.update()
    if(props.legendPosition){
      if(props.legendPosition == "center")
        legendPositionClass.value = "my-auto"
      else if(props.legendPosition == 'bottom')
        legendPositionClass.value = "mt-auto"

    }

    if(props.customLegend){
      //add custom legend
      const chartBox = document.getElementById(props.chartId)
      const div = chartBox.querySelector(".customLegend")
      const ul = document.createElement('UL')
      if(props.legendInRow)
        ul.classList.add("legend-row")
      chart.legend.legendItems.forEach((dataset, index)=>{
        const text = dataset.text
        const datasetIndex = dataset.datasetIndex
        const bgColor = dataset.fillStyle
        const bColor = dataset.strokeStyle
        const fontColor = dataset.fontColor
        const li = document.createElement('LI')
        li.setAttribute('title', text)
        if(props.legendInRow)
          li.style.gridColumn = `span ${props.legendInRow}/span ${props.legendInRow}`
        const spanBox = document.createElement('SPAN')
        spanBox.classList.add("legend-type")
        spanBox.style.borderColor = bColor
        spanBox.style.backgroundColor = bgColor

        const p = document.createElement('P')
        p.classList.add("truncate")
        // p.classList.add("mr-3")
        const textNode = document.createTextNode(text)
        li.onclick = (click) => {
          const isHidden = !chart.isDatasetVisible(datasetIndex)
          chart.setDatasetVisibility(datasetIndex,isHidden)
          // chart.toggleDataVisibility(datasetIndex)
          const element = click.target.parentNode
          element.classList.toggle('fade')
          chart.update()
        }
    
        ul.appendChild(li)
        li.appendChild(spanBox)
        li.appendChild(p)
        p.appendChild(textNode)
        // const valueSpan = document.createElement('span')
        // valueSpan.classList.add("ml-auto")
        // valueSpan.classList.add("legend-value")
        // const percent = globalNumDecFixed((chart.data.datasets[0].data[index] * 100) / props.sumTotal, 2)
        // valueSpan.appendChild(document.createTextNode(`${percent}%`))
        // li.appendChild(valueSpan)
      })
      // chartBox.appendChild(div)
      div.replaceChildren(ul)
    }
  })
  // Attach ChartJs instance
  chartRef.value.instance = chart
}

const setSize = () => {
  if (props.width) {
    cash(chartRef.value).attr({
      width: props.width
    })
  }

  if (props.height) {
    cash(chartRef.value).attr({
      height: props.height
    })
  }
  if (props.minHeight) {
    cash(chartRef.value).attr({
      height: props.minHeight
    })
  }
}
const getScreen = () =>{
  if(window.innerWidth < 1024){
    isMobileScreen.value = true
  }else{
    isMobileScreen.value = false
  }
}

const bindInstance = () => {
  if (props.refKey) {
    const bind = inject(`bind[${props.refKey}]`)
    if (bind) {
      bind(chartRef.value)
    }
  }
}

onMounted(() => {
  bindInstance()
  setSize()
  init()
  getScreen()
  window.addEventListener("resize", getScreen)
})
onUnmounted(()=>{
    window.removeEventListener("resize", getScreen);
})


</script>
<style lang="scss">
.chartWrap{
  width: 100%;
  height: 300px;
}
.mobileContainer{
  overflow-x: scroll;
  height: 100%;
}
.containerBody{
  height: 100%;
}
</style>
