import store from "@/store/index";
import axios from "axios";


function checkDuplicateHouse(addr_num, village_id, house_number_11){
  return new Promise((resolve, reject) => {
    let url = `${process.env.VUE_APP_BASE_API}house?start=1&length=10&draw=10`
    if(addr_num)
      url += `&address_num=${addr_num}`
    if(village_id)
      url += `&village_ID=${village_id}`
    if(house_number_11)
      url += `&house_number_11=${house_number_11}`
    const config = {
      url: url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      }
    };
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function checkDuplicateMember(houseId, surveyId, name, surname, NID){
  return new Promise((resolve, reject) => {
    let url = `${process.env.VUE_APP_BASE_API}member/check`
    const config = {
      url: url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      },
      params: {
        house_id: houseId,
        house_surveys_id: surveyId,
      }
    };
    if(NID)
      config.params["NID"] = NID
    if(name && surname){
      config.params["name"] = name
      config.params["surname"] = surname
    }
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export {checkDuplicateHouse, checkDuplicateMember };
