<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div class="py-12 px-2 sm:px-4">
      <nav class="flex justify-between items-baseline mb-6">
        <h1 class="text-2xl text-primary-2">จัดการสิทธิ์</h1>
      </nav>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 overflow-x-auto">
          <!-- <div class="table-permission">
            <div class="tr">
              <div class="th">ระดับ</div>
              <div class="th">สิทธิ์การเข้าถึงเมนู</div>
              <div class="th">สิทธิ์การดำเนินการ</div>
            </div>
            <div class="tr">
              <div class="td">TD</div>
              <div class="td">TD</div>
              <div class="td">TD</div>
              ic
            </div>
          </div> -->
          <!-- {{ dataPermission }} -->
          <table class="table permission-info border-2 border-primary-1">
              <thead>
                <tr class="bg-primary-1 text-white">
                  <th class="whitespace-nowrap">ระดับ</th>
                  <th class="whitespace-nowrap">สิทธิ์การเข้าถึงเมนู</th>
                  <th class="whitespace-nowrap">สิทธิ์การดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b-2 border-primary-1 hover:bg-gray-300" v-for="(data, index) in dataPermission" :key="index">
                  <td>{{ data.level_name }}</td>
                  <td>
                    <div>
                      <!-- <div class="form-checkbox-custom pl-6 py-1" v-for="(menu, mIdx) in Object.keys(data.accessible_menus)" :key="mIdx">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu${index}${mIdx}`"
                        >
                          <input
                            :id="`menu${index}${mIdx}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :disabled="false"
                          > {{menuList[menu]}}
                        </label>
                      </div> -->
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu-dashboard${index}`"
                        >
                          <input
                            :id="`menu-dashboard${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.accessible_menus.dashboard"
                            :disabled="false"
                          > Dashboard
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu-household${index}`"
                        >
                          <input
                            :id="`menu-household${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.accessible_menus.household"
                            :disabled="false"
                          > ครัวเรือน
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu-member${index}`"
                        >
                          <input
                            :id="`menu-member${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.accessible_menus.member"
                            :disabled="false"
                          > สมาชิก
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu-neets${index}`"
                        >
                          <input
                            :id="`menu-neets${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.accessible_menus.neets"
                            :disabled="false"
                          > เด็ก NEETs
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`menu-cwd${index}`"
                        >
                          <input
                            :id="`menu-cwd${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.accessible_menus.cwd"
                            :disabled="false"
                          > เด็กพิการ
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div >
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`action1${index}`"
                        >
                          <input
                            :id="`action1${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.permissions.read"
                            :disabled="false"
                          > แสดงผล
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`action2${index}`"
                          :class="{'text-gray-500' : !data.permissions.read}"
                        >
                          <input
                            :id="`action2${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.permissions.create"
                            :disabled="!data.permissions.read"
                          > เพิ่ม
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`action3${index}`"
                          :class="{'text-gray-500' : !data.permissions.read}"
                        >
                          <input
                            :id="`action3${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.permissions.update"
                            :disabled="!data.permissions.read"
                          > แก้ไข
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`action4${index}`"
                          :class="{'text-gray-500' : !data.permissions.read}"
                        >
                          <input
                            :id="`action4${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.permissions.delete"
                            :disabled="!data.permissions.read"
                          > ลบ
                        </label>
                      </div>
                      <div class="form-checkbox-custom pl-6 py-1">
                        <label
                          class="form-check-label align-middle"
                          :for="`action5${index}`"
                          :class="{'text-gray-500' : !data.permissions.read}"
                        >
                          <input
                            :id="`action5${index}`"
                            class="form-check-input align-middle"
                            type="checkbox"
                            :true-value="1"
                            :false-value="0"
                            v-model="data.permissions.export"
                            :disabled="!data.permissions.read"
                          > นำออก
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
          </table>
          <div class="mt-2 flex justify-end">
            <!-- <button class="btn btn-secondary ml-2">
              ออก
            </button> -->
            <button class="btn btn-primary ml-2" @click="submitData">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  </template-layout>
</template>
<script setup>
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from 'vuex'
import axios from "axios"
import Swal from "sweetalert2";

const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const store = useStore()
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};

const dataPermission = ref(null)
const getPermission = async () =>{
  const url = `${process.env.VUE_APP_BASE_API}managePermission`;
  const res = await axios.get(url, { headers }).catch(() =>
      Swal.fire({
          icon: "warning",
          text: "ระบบผิดพลาด",
      })
  );
  if (res.status === 200) {
    dataPermission.value = res.data.map(({ _id: id, ...rest}) => ({id, ...rest}))
  }
}

const submitData = () =>{
  swalCustom
  .fire({
    text: "ยืนยันบันทึกข้อมูล",
    icon: "info",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  })
  .then(async (result) => {
    if (result.isConfirmed) {
      const res = await axios.put(`${process.env.VUE_APP_BASE_API}managePermission/allLevel`,{...dataPermission.value}, {headers})
      .catch(() => {
        swalCustom.fire({
          text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
          icon: "warning",
          //timer: 3000, //timeOut for auto-close
          confirmButtonText: "ปิด",
        });
      })
      if(res.status == 200){
        swalCustom.fire({
          text: "สำเร็จ",
          icon: "success",
          timer: 3000,
          confirmButtonText: "ปิด",
        })
        .then(()=>getPermission());
      }
    }
  })
}

watch(()=> dataPermission.value, ()=>{
  dataPermission.value.map(item=>{
    if(!item.permissions.read){
      item.permissions.create = 0
      item.permissions.update = 0
      item.permissions.delete = 0
      item.permissions.export = 0
    }
    return item
  })
},
{deep: true})
onMounted(()=>{
  getPermission()
})
const breadcrumbComputed = computed(()=>{
  return [
    {
      name: "จัดการสิทธิ์",
      active: true,
    }
  ]
})

</script>

<style lang="scss" scoped>
.permission-info{
  tbody{
    tr{
      &:nth-child(even){
        @apply bg-gray-200;
      }
      &:nth-child(odd){
        @apply bg-white;
      }
    }
  }
}
</style>
