<template>
    <div class="grid grid-cols-12 gap-6 mt-6">
        <div class="col-span-12">
            <h3 class="text-xl font-bold leading-none mt-5 text-custom-member">
                ประกาศ
            </h3>
        </div>
        <div class="col-span-12">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-4 box" v-for="(item, key) in newsSection" :key="key">
                    <div class="flex border-b-1 items-center border-custom-member">
                        <div class="p-2 bg-primary-1 text-white">
                            <BookOpenIcon v-if="item.text == 'เกี่ยวกับโครงการ'" />
                            <SettingsIcon v-if="item.text == 'เกี่ยวกับระบบ'" />
                            <FileTextIcon v-if="item.text == 'เกี่ยวกับข่าวสาร'" />
                        </div>
                        <div class="p-2">
                            <h4 class="text-custom-member">{{item.text}}</h4>
                        </div>
                    </div>
                    <div class="px-5 pb-5 text-gray-600 h-64">
                        <news-item v-for="(i, key) in newsList" :key="key"></news-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import NewsItem from "./NewsItem.vue"
export default defineComponent({
    props:{
        newsSection: Object,
    },
    components:{
        NewsItem
    },
    setup(){
        const newsList = reactive([])
        return {
            newsList
        }
    }
})
</script>

<style>

</style>