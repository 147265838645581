<template>
  <Datepicker
    :model-value="date"
    @update:model-value="handleDate"
    :enableTimePicker="enable_time"
    :monthChangeOnScroll="false"
    selectText="ตกลง"
    cancelText="ยกเลิก"
    locale="th"
    :format="format"
    :previewFormat="format"
    :minDate="minDate"
    :maxDate="maxDate"
    preventMinMaxNavigation
    :yearRange="[1900, new Date().getFullYear()]"
    :clearable="false"
  >
    <template
    #month-year="{
      month,
      year,
      months,
      updateMonthYear,
      handleMonthYearChange
    }">
      <div class="month-year-wrapper">
        <div class="custom-month-year-component">
          <button type="button" class="custom-icon mr-1" @click="handleMonthYearChange(false)"> <ChevronLeftIcon/> </button>
          <select
              class="select-input"
              :value="month"
              @change="updateMonth($event, updateMonthYear, year)">
          >
              <option
                v-for="m in months"
                :key="m.value"
                :value="m.value"
              >{{ m.text }}</option>
          </select>
          <select
              class="select-input"
              :value="year"
              @change="updateYear($event, updateMonthYear, month)"
          >
            <option
              v-for="y in years"
              :key="y.value"
              :value="y.value"
            >{{ y.text }}</option>
          </select>
          <button type="button" class="custom-icon ml-1 disabled" @click="handleMonthYearChange(true)"> <ChevronRightIcon/> </button>
        </div>
      </div>
    </template>
  </Datepicker>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import Datepicker from "@vuepic/vue-datepicker";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  is_range: {
    type: Boolean,
    default: false
  },
  enable_time:{
    type: Boolean,
    default: false
  },
  minDate:{
    type: Date
  },
  maxDateProps:{
    type: Date
  },
  disableUpdateMaxDate:{
    type: Boolean,
    default: false
  }
})
const date = ref();
const maxDate = ref(new Date())
const format = (date) => {
  if(props.is_range){
    const day1 = String(date[0].getDate()).padStart(2, "0");
    const month1 = String(date[0].getMonth() + 1).padStart(2, "0");
    const year1 = date[0].getFullYear() + 543;
    const hour1 = String(date[0].getHours()).padStart(2, "0")
    const min1 = String(date[0].getMinutes()).padStart(2, "0")
    const day2 = date[1] ? String(date[1].getDate()).padStart(2, "0") : String(date[0].getDate()).padStart(2, "0");
    const month2 = date[1] ? String(date[1].getMonth() + 1).padStart(2, "0") : String(date[0].getMonth() + 1).padStart(2, "0");
    const year2 = date[1] ? date[1].getFullYear() + 543 : date[0].getFullYear() + 543;
    const hour2 = date[1] ? String(date[1].getHours()).padStart(2, "0") : String(date[0].getHours() + 1).padStart(2, "0")
    const min2 = date[1] ? String(date[1].getMinutes()).padStart(2, "0") : String(date[0].getMinutes()).padStart(2, "0")
    return `${day1}/${month1}/${year1} ${hour1}:${min1} - ${day2}/${month2}/${year2} ${hour2}:${min2}`
  }
  else{
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear() + 543;
    const hour = String(date.getHours()).padStart(2, "0")
    const min = String(date.getMinutes()).padStart(2, "0")

    return `${day}/${month}/${year}${props.enable_time ? ` ${hour}:${min}` : ''}`;
  }

};
const handleDate = (modelData) => {
  if(props.is_range){
    if(!modelData[1]){
      modelData[1] = new Date(modelData[0])
      modelData[1].setHours(modelData[1].getHours() + 1)
    }
  }
  date.value = modelData;
  emit("update:modelValue", modelData)
}
const clearDate = () => {
  date.value = null
}
const years = reactive([])
const updateMonth = (event, updateMonthYear, year) => {
  updateMonthYear(+(event.target.value), year);
};
const updateYear = (event, updateMonthYear, month) => {
  updateMonthYear(month, +(event.target.value));
};

function generateYear(){
  let start = new Date().getFullYear() - 120
  let current = new Date().getFullYear()
  for(let i = current; i>=start; i--){
    years.push(
      {value: i, text: String(i+543)}
    )
  }
}

const getNow = ()=>{
  maxDate.value = new Date()
}

watch(()=> props.maxDateProps,(val)=>{
  if(val)
    maxDate.value = new Date(val)
  else
    getNow()
})
onMounted(()=>{
  generateYear()
  if(!props.disableUpdateMaxDate)
    setInterval(getNow, 1000);
})

</script>

<style lang="scss">
.month-year-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-month-year-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.select-input {
  margin: 5px;
  padding: 5px;
  width: 85px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.icons {
  display: flex;
}

.custom-icon {
  padding: 5px;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  color: var(--dp-icon-color);
  text-align: center;
  border-radius: 50%;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: var(--dp-hover-color);
  }
}
</style>