<template>
  <div
    :id="id"
    class="modal"
    data-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog !mb-32" :class="modalSize">
      <div class="modal-content">
        <div v-if="loading" class="absolute w-full h-full bg-black opacity-70">
          <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
        </div>
        <div class="flex items-center pt-2" :class="header ? 'justify-between' : 'justify-end'" v-if="closeButton">
          <h3 class="pl-4 md:pl-10 text-md" v-if="header">{{header}}</h3>
          <button class="btn btn-light border-none shadow-none" @click="handleClickCancel">
            <XIcon class="h-5 w-5" />
          </button>
        </div>
        <div class="modal-body p-4 md:p-10">
          <slot></slot>
        </div>
        <div class="flex flex-row justify-end items-center p-3 px-5" v-if="isViewOnly">
          <button class="btn  btn-primary" @click="hideModal">
            ปิด
          </button>
        </div>
        <div class="flex flex-row justify-end items-center p-3 px-5" v-else>
          <button class="btn btn-secondary mr-2" @click="handleClickCancel" v-if="!isSaveOnly">
            ยกเลิก
          </button>
          <button class="btn btn-primary" :disabled="!isDone && isDisabledSubmit" @click="handleClickSave">
            บันทึก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
// export default {
//   inheritAttrs: false,
// };
//
</script>

<script setup>
import { onMounted, watch } from "vue";
const props = defineProps({
  modelValue: Boolean,
  id: {
    type: String,
    default: "custom-modal",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  headerTitle: String,
  isDone: {
    type: Boolean,
    default: false
  },
  isViewOnly:{
    type: Boolean,
    default: false
  },
  isSaveOnly:{
    type: Boolean,
    default: false
  },
  isDisabledSubmit:{
    type: Boolean,
    default: false
  },
  closeButton: {
    type: Boolean,
    default: false,
  },
  modalSize: {
    type: String,
    default: 'modal-xl'
  },
  header:{
    type: String,
    default: null
  }
});
const emits = defineEmits(["update:modelValue", "click:cancel", "click:save"]);

onMounted(() => {
  if (props.modelValue) {
    showModal();
  }
});

watch(
  () => props.modelValue,
  (newval) => {
    if (newval) {
      showModal();
    } else {
      hideModal();
    }
  }
);

const showModal = () => {
  cash(`#${props.id}`).modal("show");
};

const hideModal = () => {
  cash(`#${props.id}`).modal("hide");
  emits("update:modelValue", false);
};

const handleClickCancel = () => {
  hideModal();
  emits("click:cancel");
};

const handleClickSave = () => {
  watch(()=> props.isDone,(val)=>{
    if(val){
      hideModal();
    }
  })
  emits("click:save");
};
</script>
