import axios from "axios";
import imageCompression from "browser-image-compression";
import store from "@/store/index";
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
}

// การตั้ง path -> documents/household_id/
// YYYY-MM-DD-HH-MM-SS-consent-form.pdf
// อนาคต ถ้า Uoload หลายไฟล์พร้อมกัน ให้ -counter มาด้วย
const uploadfile = async (file, file_name) => {
  // data = file data
  if (!(file instanceof File)) {
    throw new Error("Accept only file!");
    return;
  }
  const typeOfFile = file.name.split(".").pop();
  const nameofFile = genNameOfFile(file_name, typeOfFile);
  const formData = new FormData();
  formData.append("image", file, nameofFile);
  const url = `${process.env.VUE_APP_IMG_API}`;
  const res = await axios.post(url, formData, headers).catch((res) => console.log(res));

  return {
    res,
    url: `${nameofFile}`,
    file_name: nameofFile
  };
};
const uploadCroppedfile = async (file, file_name) => {
  const typeOfFile = file.type.split("/").pop();
  const nameofFile = genNameOfFile(file_name, typeOfFile);
  const formData = new FormData();
  formData.append("image", file, nameofFile);
  const url = `${process.env.VUE_APP_IMG_API}`;
  const res = await axios.post(url, formData, headers).catch((res) => console.log(res));

  return {
    res,
    url: `${nameofFile}`,
    file_name: nameofFile
  };
};


const genNameOfFile = (name, typeOfFile) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date
      .getMonth() + 1)
    .toLocaleString(undefined, {
      minimumIntegerDigits: 2
    });
  const day = date
    .getDate()
    .toLocaleString(undefined, {
      minimumIntegerDigits: 2
    });
  const hour = date
    .getHours()
    .toLocaleString(undefined, {
      minimumIntegerDigits: 2
    });
  const minute = date
    .getMinutes()
    .toLocaleString(undefined, {
      minimumIntegerDigits: 2
    });
  const seconds = date
    .getSeconds()
    .toLocaleString(undefined, {
      minimumIntegerDigits: 2
    });
  const mill = date
    .getMilliseconds()
    .toLocaleString(undefined, {
      minimumIntegerDigits: 3
    });
  const result = `${year}${month}${day}${hour}${minute}${seconds}${mill}-${name}.${typeOfFile}`;
  return result;
};
const compressImage = async (imageFile) => {
  if (!(imageFile instanceof File)) {
    throw new Error("Accept only file!");
  }
  if (imageFile.type.includes("image/") && imageFile.size / 1024 / 1024 > 1) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let tempImageFile = await imageCompression(imageFile, options).catch(() => {
      throw new Error("Accept only image");
    });
    tempImageFile = new File([tempImageFile], tempImageFile.name);

    return tempImageFile;
  }

  return imageFile;
};

export {
  uploadfile,
  uploadCroppedfile,
  compressImage
};