<template>
  <!-- <section data-test="filter-age-section" class="grid grid-cols-12 gap-4">
    <div class="mt-2 col-span-12 sm:col-span-6"> -->
    <div>
      <label class="from-label text-medium text-gray-600">ช่วงอายุ</label>
      <div class="w-full flex items-baseline gap-3">
        <div class="flex-grow">
          <input
            class="form-control"
            type="number"
            placeholder="เริ่มต้น"
            v-model="startComputed"
            min="0"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
          >
        </div>
        <span>-</span>
        <div class="flex-grow">
          <input
            class="form-control"
            type="number"
            placeholder="สิ้นสุด"
            v-model="endComputed"
            min="0"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
          >
        </div>
        <span>ปี</span>
      </div>
    </div>
    <!-- </div>
  </section> -->
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  start_age: Number,
  end_age: Number,
});

const emits = defineEmits(["update:start_age", "update:end_age"]);

const startComputed = computed({
  get() {
    return props.start_age
  },
  set(evt) {
    emits("update:start_age", evt);
  },
});
const endComputed = computed({
  get() {
    return props.end_age
  },
  set(evt) {
    emits("update:end_age", evt);
  },
});
</script>
