<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div class="py-12 px-2 sm:px-4">
      <nav class="flex justify-between items-baseline mb-6">
        <h1 class="text-2xl text-primary-2">
          เด็ก NEETs
        </h1>
        <div>
          <div class="flex gap-x-2" v-if="onlineComputed">
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [CSV] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                CSV <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm hidden sm:inline-flex items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                ออกรายงาน [Excel] <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex sm:hidden items-center justify-center py-2 rounded font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                Excel <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='code'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบรหัส
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel='desc'">
                    <DownloadIcon class="h-3 w-3 mr-2" /> รูปแบบคำอธิบาย
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hidden sm:block" v-if="onlineComputed">
            <button class="btn btn btn-outline-secondary rounded bg-white mr-1 mb-2" @click="downloadCsv=true" :disabled="!getPermissionAccessMenu('export')"><DownloadIcon class="h-3 w-3 mr-2" /> CSV</button>
            <button class="btn btn btn-outline-secondary rounded bg-white mb-2" @click="downloadExcel=true" :disabled="!getPermissionAccessMenu('export')"><DownloadIcon class="h-3 w-3 mr-2" /> Excel</button>
          </div>
          <div class="relative ml-auto sm:ml-0 block sm:hidden bottom-[-5px] mb-2" v-if="onlineComputed">
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 rounded-md font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('export')">
                <MoreVerticalIcon class="w-5 h-5"/>
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadCsv=true">
                    <DownloadIcon class="h-3 w-3 mr-2" /> CSV
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="downloadExcel=true">
                    <DownloadIcon class="h-3 w-3 mr-2" /> Excel
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </nav>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12" v-if="onlineComputed">
          <NeetsFilter :filter="filterSummaryStore"/>
        </div>
        <div class="col-span-12">
          <Datatable :filter="filterValue" :downloadCsv="downloadCsv" @downloadCsvComplete="downloadCsv = null" :downloadExcel="downloadExcel" @downloadExcelComplete="downloadExcel = null" v-if="onlineComputed" />
          <OfflineDatatable :data-array="dataOfflineList" @update-data="getOfflineData" v-else />
        </div>
      </div>
    </div>
  </template-layout>
</template>

<script setup>
import NeetsFilter from "@/components/filter-search/NeetsFilter.vue";
import Datatable from "./Datatable.vue"
import { computed, reactive, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import OfflineDatatable from "./OfflineDatatable.vue"
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission";

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const dataOfflineList = ref([])

const downloadCsv = ref(null)
const downloadExcel = ref(null)

const store = useStore(); 
const filterSummaryStore = computed(
  () => store.getters["neetsFilter/getFilterSummary"]
);

const filterValue = computed(() => store.getters["neetsFilter/getFilterForApi"]);
const breadcrumbComputed = reactive([{
  name: "เด็ก NEETs",
  active: true
}])
const onlineComputed = computed(()=> store.getters["network/isOnline"])
watch(onlineComputed, () =>{
  if(!onlineComputed.value && store.getters["userAuth/getUserTumbolCode"].length == 6){
    getOfflineData()
  }
})
const getOfflineData = () => {
  houseStore.keys()
  .then(function(keys){
      var matches = []
      for(let i in keys) {
        matches.push(houseStore.getItem(keys[i]))
      }
      // Promise.all returns a promise that resolves to an array 
      // of the values they resolve to
      return Promise.all(matches)
  })
  .then(values => {
      // values is an array of your items
      dataOfflineList.value = []
      values.forEach(element=>{
        if(element.house_surveys?.members){
          let member_neet = element.house_surveys.members.filter(item => item?.member_surveys?.NEET)
          member_neet.forEach(elm=>{
            elm.full_address = `${element.address_num} ${element.village_name} ${element.soi ? "ซอย"+element.soi : ""} ${element.road ? "ถนน"+element.road : ""} ${element.tambol_name} ${element.amphur_name} ${element.province_name} ${element.postcode}`
            elm.full_name = `${elm.prefix_name} ${elm.name} ${elm.surname}`
            elm.neet_survey_id = elm?.neet_survey_data ? 1 : null //set neet_survey_id if have neet_survey_data set 1 cause can have only one survey and null for member have NEET and not done survey yet
            elm.surveyid = element.id// id = key in indexdb and use this to get object
          })
          dataOfflineList.value = [...dataOfflineList.value, ...member_neet]
        }
      })
  })
}
onMounted(()=>{
  if(!onlineComputed.value && store.getters["userAuth/getUserTumbolCode"].length == 6)
    getOfflineData()
})

</script>
