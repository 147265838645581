const initAuthOnLoad = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    //silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

const initAuthConfig = {
    url: process.env.VUE_APP_AUTH_KEYCLOAK_URL,
    realm: process.env.VUE_APP_AUTH_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_AUTH_KEYCLOAK_CLIENT_ID,
    offlineToken: true
};

export {
    initAuthOnLoad,
    initAuthConfig
};