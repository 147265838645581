const state = () => ({
  isOnline: navigator.onLine,
  statusSync: false
  // isOfflineWhileOnline: false
})
// getters
const getters = {
  isOnline: state => state.isOnline,
  statusSync: state => state.statusSync
  // isOfflineWhileOnline: state => state.isOfflineWhileOnline
}

// actions
const actions = {
  setIsOnline({ commit }, data) {
    commit('setIsOnline', data)
  },
  setStatusSync({ commit }, data) {
    commit('setStatusSync', data)
  },
  // setIsOfflineWhileOnline({commit}, data){
  //   commit('setIsOfflineWhileOnline', data)
  // }
}

// mutations
const mutations = {
  setIsOnline(state, data){
    state.isOnline = data
  },
  setStatusSync(state, data){
    state.statusSync = data
  },
  // setIsOfflineWhileOnline(state, data){
  //   state.isOfflineWhileOnline = data
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}