<template>
<!-- v-if="dataArray.length>0" -->
  <div class="overflow-x-auto scrollbar-hidden house-datatable" data-test="house-datatable">
    <Datatable :api-params="paramsComputed" :api-url="'house'" :method-request="'GET'" :init-sorter="[{field: 'UPDATE_DATETIME', dir: 'desc'}]" :data-array-table="dataArrayTable" :key="key" @rowClicked="handleRowClick" :downloadFile="downloadFile" @getDataComplete="exportFile" question-group="household"/>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from 'vue'
import Datatable from "@/components/datatable/index.vue"
import {useStore} from "vuex"
import { useRouter } from 'vue-router'
import { utils, writeFileXLSX, writeFile } from "xlsx"
import Swal from 'sweetalert2'
import exportFields from "@/data/exportFields.json"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const props = defineProps({
  filter:{
    type: Object
  },
  downloadCsv:{
    type: String
  },
  downloadExcel: {
    type: String
  }
})
const emits = defineEmits(["downloadCsvComplete", "downloadExcelComplete"])
let dataArray = ref([])
const store = useStore()
const filterValue = ref()
const params = ref({start: 1,draw: 10,length: 10})
const key = ref(1)
const router = useRouter()
const downloadFile = ref(false)
const currentFileType = ref("")
const downloadFormat = ref(null)
watch(()=>props.filter, ()=>{
  key.value++
})
// watch(()=>props.reloadTable,(val)=>{
//      key.value++
// })
const onlineComputed = computed(()=>store.getters["network/isOnline"])
const paramsComputed = computed(() => ({...params.value, ...props.filter}))
var datediffMutator = (value, data, type, params, component) => {
  return {"datediff": data.datediff, "head_img": "@/assets/images/main/logo_logbook.png"}
}
var customNameMutator = (value, data, type, params, component) => {
  return `${data.prefix_name ? data.prefix_name : "ไม่มีหัวหน้าครัวเรือน"}${data.name ? data.name : ""} ${data.surname ? data.surname : ""}`
}
var customAddressMutator = (value, data, type, params, component) => {
  return `${data.address_num ? data.address_num:""} ${data.moo?'หมู่ '+data.moo:""} ${data.village_name?data.village_name: ""} ${data.tambol_name?'ตำบล'+data.tambol_name: ""}  ${data.amphur_name?'อำเภอ'+data.amphur_name: ""}`
}
var customProblemMutator = (value, data, type, params, component) => {
  return {
    "health": false,
    "housing": false,
    "education": false,
    "occupation": false,
    "accessibility": false
  }
}
const dataArrayTable = reactive([
  // {title:"", field:"tb_pic", resizable:true, mutator: datediffMutator,
  // formatter:function(cell, formatterParams){
  //     var value = cell.getValue();
  //     let res = `<div class="flex flex-wrap">\
  //     <img src="${value.head_img}">\
  //     <input type="hidden" value="${value.datediff}">\
  //     </div>
  //     `
  //     return res
  //     }
  // },
  {title:"หัวหน้าครัวเรือน", field:"full_name", resizable:true, formatter:"textarea", minWidth: 250,formatter:function(cell, formatterParams){
    return cell.getValue() ? cell.getValue() : 'ไม่มีหัวหน้าครัวเรือน'
  }},
  {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300,},
  {title:"ปัญหา 5 มิติ", field:"problem", hozAlign:"center", minWidth: 250, variableHeight:true, resizable:true, mutator: customProblemMutator,
    formatter:function(cell, formatterParams){
      var value = cell.getValue();
      if(value){
          let temp = ""
          for(const [key, val] of Object.entries(value)){
              let icon = "", text = ""
              if(key === "housing"){
                  icon = "home-icon"
                  text = "ครอบครัว"
              }
              if(key === "health"){
                  icon = "heart-icon"
                  text = "สุขภาพอนามัย"
              }
              if(key === "occupation"){
                  icon = "dollar-sign-icon"
                  text = "การมีงานทำและมีรายได้"
              }
              if(key === "education"){
                  icon = "book-open-icon"
                  text = "การศึกษา"
              }
              if(key === "accessibility"){
                  icon = "card-icon"
                  text = "การเข้าไม่ถึงสิทธิและความเป็นธรรมในสังคม"
              }
              temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-primary-2 hover:bg-primary-2':'bg-theme-37 hover:bg-theme-37'}'>\
                          <span class="t-tooltip-text">${text}</span>\
                          <span class="img-icon ${icon}"></span>\
                      </button>`
          }
          let res = `<div class="flex flex-wrap justify-start lg:justify-center">${temp}</div>`
          return res
      }
      return null
    }
  },
  {title:"จำนวนสมาชิกในครัวเรือน", field:"member_cnt", hozAlign:"center", resizable:true, minWidth: 195, width: 220, formatter:"textarea", formatter:function(cell, formatterParams){
    return cell.getValue() ? cell.getValue() : 0
  }},
  {title:"จำนวนสมาชิกตามทะเบียนบ้าน", field:"member_reg_cnt", hozAlign:"center", resizable:true, minWidth: 220, width: 240, formatter:"textarea", formatter:function(cell, formatterParams){
    return cell.getValue() ? cell.getValue() : 0
  }},
  {title:"จำนวนครั้งที่สำรวจ", field:"house_surveys_count", hozAlign:"center", resizable:true, minWidth: 175, width: 200, formatter:"textarea",}, 
  {title:"วันที่ปรับปรุงข้อมูลล่าสุด", field:"UPDATE_DATETIME", hozAlign:"center", minWidth: 200, width: 220, variableHeight:true,
    formatter:function(cell, formatterParams){
      if(cell.getValue()){
        let date = cell.getValue().split`T`[0]
        date = new Date(date)
        return [String(date.getDate()).padStart(2, '0'),
                  String(date.getMonth() + 1).padStart(2, '0'),
                  date.getFullYear() + 543
                ].join('/');
      }
      return null
    }
  },
  {title:"สถานะ", field:"house_surveys_status", hozAlign:"center", resizable:true, minWidth: 130, width: 150, formatter:"textarea", formatter:function(cell, formatterParams){
    return cell.getValue() == "DRAFT" ? "ร่าง" : cell.getValue() == "COMPLETE" ? "สำเร็จ" : null
  }},
])
const handleRowClick = (row) =>{
  if(getPermissionAccessMenu("read") || !onlineComputed.value){
    router.push({
      name: "household-profile",
      params: {
        hhid: row._id,
        surveyid: row.house_surveys_id
      },
    })
  }
}


watch(()=> props.downloadCsv, (val)=>{
  if(val){
    downloadFile.value = true//props.downloadCsv
    currentFileType.value = "csv"
    downloadFormat.value = val
  }
})

watch(()=> props.downloadExcel, (val)=>{
  if(val){
    downloadFile.value = props.downloadExcel
    currentFileType.value = "xlsx"
    downloadFormat.value = val
  }
})
const exportFile = (evt, questions) => {
  if(evt.status === 200){
    let columns_header = ["HH001"]
    columns_header = [...columns_header, ...questions.map(q => {
        if(q.question_code == "address_num")
          return ["HH002"]
        if(q.question_code == "subDistrictSelected")
          return ["HH003"]
        if(q.question_code == "districtSelected")
          return ["HH004"]
        if(q.question_code == "provinceSelected")
          return ["HH005"]
        if(q.question_code == "postcode")
          return ["HH006"]
        if(q.question_code == "sector")
          return ["HH007"]
        if(q.question_code == "township")
          return ["HH008"]
        if(q.question_code == "informant_prefix_name")
          return ["HH009"]
        if(q.question_code == "position")
          return ["HH010", "HH010-1"]//["HH010", "HH010-1"]
        if(q.question_code == "member_cnt")
          return ["HH011"]
        if(q.question_code == "member_reg_cnt")
          return ["HH012"]
        if(q.question_code == "house_number_11")
          return ["HH013"]
        if(q.question_type == "checkbox" && q.is_checkbox_group){
          const res = q.choices.map(c => {
            if(c?.specify_value?.length > 1 && !c.is_multiple_answer){
              let tempCh = []
              for(let i = 1 ; i <= c.specify_value.length; i++){
                tempCh.push(`${c.option.code}-${i}`)
              }
              return tempCh
            }
            return c.option.code
          }).flat()
          return res
        }
        if(q.question_type == "checkbox" && q.choices.some(c => c.option.hasOwnProperty("order"))){
          let haveSpecify = q.choices.find(c => c.specify_value)
          let temp = []
          for(let i = 1; i<=q.answer_limit; i++){
            temp.push(`${q.question_code}-${i}`)
            if(haveSpecify){
              for(let j = 1 ; j <= haveSpecify.specify_value.length; j++){
                temp.push(`${q.question_code}-${i}-${j}`)
              }
            }
          }
          return temp
        }
        if(q.question_type == "multiple-inputs-answer"){
          if(q.choices.length > 1){
            let tempCh = []
            for(let i = 1 ; i <= q.choices.length; i++){
              tempCh.push(`${q.question_code}-${i}`)
            }
            return tempCh
          }
          return [q.question_code]
        }
        if((q.question_type == "radio" || q.question_type == "checkbox") && q.choices.some(c => c.specify_value) && q.question_code != "position"){
          let specify = q.choices.find(c=>c.specify_value)
          let tempCode = []
          if(specify){
            for(let i = 1 ; i <= specify.specify_value.length; i++){
              tempCode.push(`${q.question_code}-${i}`)
            }
          }
          return [q.question_code,...tempCode]
        }

        return [q.question_code]
      }).flat()
    ]
    columns_header = columns_header.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true })
    )
    columns_header.unshift("province_name", "amphur_name", "tambol_name", "village_name")
    columns_header.push("survey_status", "survey_start_date", "survey_end_date", "survey_start_time", "survey_end_time", "house_id")
    let newArr = evt.data.map((item)=>{
      let newItem = {}
        for(let col of columns_header){
          if(item[col])
            newItem[col] = item[col]
          else
            newItem[col] = null
        }
        //sort column
        if(item.house_number_ID){
          newItem["HH001"] = String(item.house_number_ID)
        }
        // if(item.address_num || item.soi || item.road || item.village_name){
        //   item["HH002"] = `${item.address_num ?? ""} ${item.village_name ?? ""} ${item.soi ? 'ซอย'+item.soi : ""} ${item.road ? 'ถนน'+item.road : ""}`
        // }
        if(item.tambol_name){
          newItem["HH003"] = item.tambol_name
        }
        if(item.amphur_name){
          newItem["HH004"] = item.amphur_name
        }
        if(item.province_name){
          newItem["HH005"] = item.province_name
        }
        if(item.postcode){
          newItem["HH006"] = item.postcode
        }
        if(item.sector){
          newItem["HH007"] = item.sector
        }
        if(item.township){
          newItem["HH008"] = item.township.desc
        }
        // if(item.HH009){
        //   newItem["HH009"] = item.HH009
        // }
        if(item.house_number_11){
          newItem["HH013"] = item.house_number_11
        }

        if(item.house_surveys){
          const keys = Object.keys(item.house_surveys)
          // if(item.house_surveys.informant_prefix_name || item.house_surveys.informant_name || item.house_surveys.informant_surname){
          //   newItem["HH009"] = `${item.house_surveys.informant_prefix_name ?? ""} ${item.house_surveys.informant_name ?? ""} ${item.house_surveys.informant_surname ?? ""}`
          // }
          if(item.house_surveys.position){
            if(downloadFormat.value == "desc"){
              newItem["HH010"] = item.house_surveys.position.desc
            }
            else{
              newItem["HH010"] = item.house_surveys.position.code.split("-")?.[1] ? item.house_surveys.position.code.split("-")[1] : item.house_surveys.position.code.split("-")[0]
            }
            if(item.house_surveys.position?.other)
              newItem["HH010-1"] = item.house_surveys.position.other
          }
          if(item.house_surveys.member_cnt || item.house_surveys.member_cnt == 0){
            newItem["HH011"] = item.house_surveys.member_cnt
          }
          if(item.house_surveys.member_reg_cnt || item.house_surveys.member_reg_cnt == 0){
            newItem["HH012"] = item.house_surveys.member_reg_cnt
          }
          if(item.house_surveys.status)
            newItem["survey_status"] = item.house_surveys.status
          if(item.house_surveys.survey_start_date)
            newItem["survey_start_date"] = item.house_surveys.survey_start_date
          if(item.house_surveys.survey_end_date)
            newItem["survey_end_date"] = item.house_surveys.survey_end_date


          keys.forEach((elm)=>{
            if(item.house_surveys[elm] || item.house_surveys[elm] == 0){
              if(typeof item.house_surveys[elm] === "object"){
                let tempObj = questions.find(({question_code}) => elm === question_code)
                if(Array.isArray(item.house_surveys[elm]) && tempObj){
                  if(questions.some(q=>q.question_code == elm && q.is_checkbox_group)){
                    let temp = []
                    item.house_surveys[elm].forEach((it)=>{
                      const choice = tempObj.choices.find(x => x.option.code === it.code)
                      let tempDesc = ""
                      if(choice?.is_multiple_answer){
                        if(choice?.specify_value && it?.answers){
                          let ans_idx = 0
                          for(let val of it.answers){
                              let i = 0
                              let ansDesc = ""
                              for(let ch of choice.specify_value){
                                  if(ch.key == "other"){
                                      ansDesc += `${val[ch.key]} `
                                  }
                                  else{
                                    if(val[ch.key] || val[ch.key] == 0){
                                        ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                    }
                                  }
                                  i++
                              }

                              tempDesc += `${ansDesc}${ans_idx == it.answers.length - 1 ? '' : ' , '}`
                              ans_idx++
                          }
                          temp.push({code: it.code, value: tempDesc})
                        }
                      }
                      else{
                        if(choice?.specify_value){
                          let i = 0
                          let codeNum = null
                          for(let ch of choice.specify_value){
                              if(choice.specify_value.length == 1){
                                  codeNum = it.code
                              }
                              else{
                                  codeNum = `${it.code}-${i+1}`
                              }
                              if(it[ch.key] || it[ch.key] == 0){
                                  temp.push({code: codeNum, value: it[ch.key]})
                              }
                              i++
                          }
                        }
                      }

                    })
                    const newData = temp.reduce((a,v)=> ({...a, [v.code]: v.value}),{})
                    return newItem = {...newItem, ...newData}
                  }
                  else{
                    if(item.house_surveys[elm].some(x => x.order)){
                      const res = item.house_surveys[elm].reduce((temp,v, idx)=> {
                        const choice = tempObj.choices.find(x => x.option.code === v.code)
                        let tempDesc = ""
                        let tempAnswerObj = {}
                        if(downloadFormat.value == "desc"){
                          tempDesc = choice ? choice.option.desc : v.desc
                        }
                        else{
                          tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                        }
                        tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                        if(choice?.is_multiple_answer){
                          if(choice?.specify_value && v?.answers){
                            let ans_idx = 0
                            for(let val of v.answers){
                              let i = 0
                              let ansDesc = ""
                              tempDesc += (ans_idx == 0) ? ` (` : ''
                              for(let ch of choice.specify_value){
                                if(ch.key == "other"){
                                  ansDesc += ` ${val[ch.key]} `
                                }
                                else if(val[ch.key] || val[ch.key] == 0){
                                  ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : '  '}`
                                }
                                i++
                              }

                              tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                              ans_idx++
                            }
                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                          }
                        }
                        else{
                          if(choice?.specify_value){
                            let i = 0
                            for(let ch of choice.specify_value){
                              i++
                              if(v[ch.key] || v[ch.key] == 0){
                                tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}-${i}`]: v[ch.key]}
                              }
                            }
                          }
                        }
                        return {...temp, ...tempAnswerObj}
                      },{})
                      return newItem = {...newItem,...res}
                    }
                    else{
                      let tempAnswerObj = {}
                      const res = item.house_surveys[elm].reduce((prevVal,v,idx)=>{
                        const choice = tempObj.choices.find(x => x.option.code === v.code)
                        let tempDesc = ""
                        if(downloadFormat.value == "desc"){
                          tempDesc = choice ? choice.option.desc : v.desc
                        }
                        else{
                          tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                        }
                        if(choice?.is_multiple_answer){
                          if(choice?.specify_value && v?.answers){
                            let ans_idx = 0
                            for(let val of v.answers){
                              let i = 0
                              let ansDesc = ""
                              tempDesc += (ans_idx == 0) ? ` (` : ''
                              for(let ch of choice.specify_value){
                                if(ch.key == "other"){
                                  ansDesc += ` ${val[ch.key]} `
                                }
                                else if(val[ch.key] || val[ch.key] == 0){
                                  ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                }
                                i++
                              }

                              tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                              ans_idx++
                            }
                          }
                        }
                        else{
                          if(choice?.specify_value){
                            let i = 0
                            for(let ch of choice.specify_value){
                              i++
                              if(choice.specify_value.some(sp => sp.key == "other")){
                                tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: v[ch.key]}
                              }
                              else if(v[ch.key] || v[ch.key] == 0){
                                tempDesc += (i == 1) ? ` (` : ''
                                tempDesc += `${v[ch.key]}${i == choice.specify_value.length ? '' : ', '}`
                                tempDesc += (i == choice.specify_value.length) ? `)` : ''
                              }
                            }
                          }
                        }
                        return idx == 0 ? tempDesc : prevVal + ', ' + tempDesc
                      }, '')
                      newItem = {...newItem, ...{[elm]: res, ...tempAnswerObj}}
                      return newItem
                    }
                  }
                }
                else{
                  if(item.house_surveys[elm]?.desc && tempObj.question_code != "position"){
                    const choice = tempObj.choices.find(x => x.option.code === item.house_surveys[elm].code)
                    let tempAnswerObj = {}
                    let tempDesc = ""
                    if(downloadFormat.value == "desc"){
                      tempDesc = choice ? choice.option.desc : item.house_surveys[elm].desc
                    }
                    else{
                      tempDesc = choice ? choice.option.code.split('-')?.[1] ? choice.option.code.split('-')[1] : choice.option.code.split('-')[0]  : item.house_surveys[elm].code.split('-')?.[1] ? item.house_surveys[elm].code.split('-')[1] : item.house_surveys[elm].code.split('-')[0]
                    }
                    tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}

                    if(choice?.is_multiple_answer){
                      if(choice?.specify_value && item.house_surveys[elm]?.answers){
                        let ans_idx = 0
                        for(let val of item.house_surveys[elm].answers){
                          let i = 0
                          let ansDesc = ""
                          tempDesc += (ans_idx == 0) ? ` (` : ''
                          for(let ch of choice.specify_value){
                            if(ch.key == "other"){
                              ansDesc += ` ${val[ch.key]} `
                            }
                            else{
                              if(val[ch.key] || val[ch.key] == 0){
                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                              }
                            }
                            i++
                          }

                          tempDesc += `${ansDesc}${ans_idx == item.house_surveys[elm].answers.length - 1 ? ')' : ' , '}`
                          ans_idx++
                        }
                        tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}
                      }
                    }
                    else{
                      if(choice?.specify_value){
                        let i = 0
                        for(let ch of choice.specify_value){
                          i++
                          if(item.house_surveys[elm][ch.key] || item.house_surveys[elm][ch.key] == 0){
                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: item.house_surveys[elm][ch.key]}
                          }
                        }
                      }
                    }
                    return newItem = {...newItem, ...tempAnswerObj}
                  }
                  else if(tempObj?.question_type == "multiple-inputs-answer"){//object answer has no desc only this type
                    const choice = tempObj.choices
                    let i = 0
                    let tempInp = {}
                    for(let ch of choice){
                        let codeNum = null
                        if(choice.length == 1)
                            codeNum = elm
                        else
                            codeNum = `${elm}-${i+1}`
                        if(item.house_surveys[elm][ch.key] || item.house_surveys[elm][ch.key] == 0){
                            tempInp = {...tempInp, [codeNum]: item.house_surveys[elm][ch.key]}
                        }
                        i++
                    }
                    return newItem = {...newItem, ...tempInp}
                  }
                }
              }
              return newItem[elm] = item.house_surveys[elm]
            }
          })
        }
      
      return newItem
    })

    newArr = newArr.map(({ amphur_ID, position, CREATE_DATETIME, UPDATE_DATETIME, image, moo,province_ID, road, sector, soi, status, tambol_ID, village_ID, house_surveys_count, member_cnt, member_reg_cnt, informant_name, informant_surname, full_address, full_name, headOfHouseholdPrefixName, headOfHouseholdName, headOfHouseholdSurname, survey_user, create_by, update_by, house_number_ID, member_surveys, headOfHousehole_name, headOfHousehole_prefix_name, headOfHousehole_surname, survey_date, villageSelected, step, _id, ...rest}) => ({...rest}));
    const ws = utils.json_to_sheet(newArr)
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date()
    let current_date = [date.getFullYear(),String(date.getMonth() + 1).padStart(2, '0'),String(date.getDate()).padStart(2, '0'),String(date.getHours()).padStart(2, '0'),String(date.getMinutes()).padStart(2, '0'),String(date.getSeconds()).padStart(2, '0')].join('')
    let file_name = `${props.filter?.village_ID ? props.filter.village_ID : props.filter?.tambol_ID ? props.filter.tambol_ID : props.filter?.amphur_ID ? props.filter.amphur_ID : props.filter?.province_ID ? props.filter.province_ID : "" }_ครัวเรือน_${downloadFormat.value == "code" ? 'รหัส' : 'คำอธิบาย'}_${current_date}`
    //for csv
    if(currentFileType.value == "csv"){
      writeFile(wb, `${file_name}.csv`);
    }
    //for excel
    if(currentFileType.value == "xlsx"){
      writeFile(wb, `${file_name}.xlsx`);
    }
  }else{
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
  }
  downloadFile.value = false
  emits("downloadCsvComplete")
  emits("downloadExcelComplete")
}
</script>
<style lang="scss">
</style>

