<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="
      top-bar-boxed
      border-b
      border-black/[0.25]
      -mt-7
      md:-mt-5
      -mx-3
      sm:-mx-8
      px-3
      sm:px-8
      md:pt-0
    "
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <router-link
        :to="{ name: 'main-house-hold' }"
        class="-intro-x hidden md:flex items-center"
      >
        <!-- <BookOpenIcon class="text-white" /> -->
        <!-- <img src="@/assets/images/main/logo-msologbook.png" style="height: 70px;"/> -->
        <div class="web-logo flex items-center">
          <img src="@/assets/images/main/Logo-Local-census.png" />
        </div>
        <!-- <span class="text-white text-lg ml-3"> สมุดพก </span> -->
      </router-link>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <!-- <div class="breadcrumb mr-auto">
        <div v-if="isHideMenu">
          <a href="">Application</a>
          <ChevronRightIcon class="breadcrumb__icon" />
          <a href="" class="breadcrumb--active">Dashboard</a>
        </div>
      </div> -->
      <Breadcrumb
        :is-hide-menu="isHideMenu"
        :bread-crumb-list="breadCrumbList"
      />
      <!-- <div v-if="isUserAuth()" class="relative mr-3 sm:mr-6">
        <router-link
          :to="{ name: 'main-house-hold' }"
          class="flex items-center"
        >
          <span class="text-white ml-3"> จัดการข้อมูลครัวเรือน </span>
        </router-link>
      </div> -->
      <!-- BEGIN: Account Menu -->
      <div v-if="isUserAuth()" class="intro-x dropdown">
        <div
          class="dropdown-toggle lg:flex items-center"
          role="button"
          aria-expanded="false"
        >
          <div
            class="
              w-8
              h-8
              rounded-full
              overflow-hidden
              shadow-lg
              image-fit
              zoom-in
              scale-110
              bg-white
              flex
              items-center
              justify-center
            "
            :class="{'bg-[#eaeaea]': !onlineComputed}"
          >
            <!-- <img> -->
            <UserIcon v-if="onlineComputed"/>
            <WifiOffIcon v-else/>
          </div>
          <div class="ml-3">
            <p class="text-primary-2 lg:block hidden">
              <a href="#">{{ navUsername() }}</a>
            </p>
          </div>
        </div>
        <div class="dropdown-menu w-56">
          <div
            class="
              dropdown-menu__content
              box
              bg-theme-11
              dark:bg-dark-6
              text-white
            "
          >
            <!-- <div v-if="isUserCM()" class="p-2">
              <router-link
                :to="{ name: 'user-profile' }"
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  hover:bg-theme-1
                  dark:hover:bg-dark-3
                  rounded-md
                "
                data-dismiss="dropdown"
              >
                <UserIcon v-if="isUserCM()" class="w-4 h-4 mr-2" />
                <span class="text-white ml-3">โปรไฟล์</span>
              </router-link>
            </div> -->
            <!-- <div v-if="isUserCM()" class="p-2">
              <router-link
                :to="{ name: 'volunteer-page' }"
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  hover:bg-theme-1
                  dark:hover:bg-dark-3
                  rounded-md
                "
                data-dismiss="dropdown"
              >
                <UserIcon v-if="isUserCM()" class="w-4 h-4 mr-2" />
                <span class="text-white ml-3">จัดการข้อมูล อพม.</span>
              </router-link>
            </div> -->
            <div class="p-2 border-t border-theme-12 dark:border-dark-3">
              <a
                href="#"
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  hover:bg-theme-1
                  dark:hover:bg-dark-3
                  rounded-md
                "
                @click="logoutUser"
              >
                <ToggleRightIcon class="w-4 h-4 mr-2" /> ออกจากระบบ
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="intro-x dropdown">
        <a href="#" @click="createLoginUrl" class="text-white ml-3">
          เข้าสู่ระบบ
        </a>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import store from "@/store";
import Breadcrumb from "@/components/breadcrumb/index.vue";

export default defineComponent({
  components: {
    Breadcrumb,
  },
  props: {
    isHideMenu: {
      type: Boolean,
      default: false,
    },
    breadCrumbList: {
      type: Array,
    },
  },
  setup() {
    const searchDropdown = ref(false);

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const createLoginUrl = () => {
      localStorage.setItem("mso_kc_fauth", true);
      window.location.href = store.getters[
        "userAuth/getAllKeycloak"
      ].createLoginUrl();
    };

    const logoutUser = () => {
      //localStorage.clear();
      //localStorage.setItem("is_kc_logout_redirect", "true")
      store.getters["userAuth/getAllKeycloak"].logout({
        redirectUri: window.location.origin,
      });
      window.localStorage.removeItem("mpi-logbook-auth");
    };

    const isUserAuth = () => {
      if (store.getters["userAuth/getUserAuth"]) {
        return true;
      } else {
        return false;
      }
    };

    const isUserCM = () => {
      if (store.getters["userAuth/getUserRoleCode"] == "cm") {
        return true;
      } else {
        return false;
      }
    };

    const navUsername = () => {
      return store.getters["userAuth/getUsername"];
    };

    /*
    const navUsername = () => {
      if (store.getters["userAuth/getUserRoleCode"] == "cm") {
        return store.getters["userAuth/getUsername"];
      } else {
        let userData = JSON.parse(localStorage.getItem("volunteerData"));

        if (userData != null) {
          if (typeof userData.name != "undefined") {
            console.log(userData.name);
            return store.getters["userAuth/getUsername"];
          }
        }

        return store.getters["userAuth/getUsername"];
      }
    };
    */

    const navPositionNameShort = () => {
      return store.getters["userAuth/getUserPositionNameShort"];
    };

    const navProvinceName = () => {
      return store.getters["userAuth/getUserProvinceName"];
    };
    const onlineComputed = computed(()=> store.getters["network/isOnline"])

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      createLoginUrl,
      logoutUser,
      isUserAuth,
      isUserCM,
      navUsername,
      navPositionNameShort,
      navProvinceName,
      onlineComputed
    };
  },
});
</script>
<style lang="scss" scoped>
.web-logo {
  height: 60px;
  img {
    height: 100%;
    // transform: scale(1.8);
  }
}
</style>
