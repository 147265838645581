<template>
  <!-- <div class="box p-4" data-test="filter"> -->
    <div id="accordion-filter-1" class="accordion" data-test="filter">
      <div class="accordion-item box shadow-lg px-4 py-1 !mb-0">
        <div class="accordion-header" id="accordion-filter">
          <div
            class="accordion-button collapsed box shadow-none cursor-pointer flex justify-between items-center gap-2"
            data-bs-toggle="collapse"
            data-bs-target="#accordion-filter-collapse"
            aria-expanded="false"
            aria-controls="#accordion-filter-collapse"
          >
            <div>
              <h2 class="text-lg text-theme-2 font-semibold">
                เงื่อนไขการค้นหา
              </h2>
              <span class="italic text-gray-700">{{filter.join(' ')}}</span>
            </div>
            <div class="flex flex-row items-center">
              <button class="btn btn-primary bg-primary-btn mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1">
                  <ChevronDownIcon class="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        <div
          id="accordion-filter-collapse"
          class="accordion-collapse collapse flex !mt-0"
          aria-labelledby="accordion-filter"
          data-bs-parent="accordion-filter-1"
        >
          <div
            class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex justify-between items-baseline mb-3">
      <h2 class="text-lg text-theme-2 font-semibold">
        เงื่อนไขการค้นหา
      </h2>
    </div>
    <slot /> -->
  <!-- </div> -->
</template>

<script setup>
import { computed} from "vue";
const props = defineProps({
  modelValue: { type: Boolean, default: false },
  advanceOption: { type: Boolean, default: true },
  filter: { type: Array, default: [] }
});

const emits = defineEmits(["update:modelValue"]);
const modelValueComputed = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits("update:modelValue", val);
  },
});

</script>
