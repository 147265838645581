<template>
  <template-layout :customMenu="customMenu" :breadCrumbList="breadcrumbComputed">
    <div>
      <div class="intro-y flex sm:flex-row items-center justify-end mt-8 flex-wrap">
        <h1 class="text-lg font-medium mr-auto">
          แบบสอบถามเพื่อการบูรณาการบริการสำหรับเด็กพิการ
        </h1>
        <button
          class="btn btn-primary bg-primary-btn border-primary-btn mr-1"
          @click="isEdit = true, isPromptRouteLeave = true, isView = false"
          v-if="isView && (getPermissionAccessMenu('update') || !onlineComputed)"
        >
          <Edit2Icon class="h-3 w-3 mr-2" />แก้ไข
        </button>
      </div>
      <div class="w-1/6 h-1/6 mx-auto" v-if="loadingQuestion && (isView ? loadingMember : loadingQuestion)">
        <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
      </div>
      <div v-else>
        <Information class="mt-5 intro-y" :information="memberData" />
        <div class="intro-y box pb-10 pt-10 sm:pb-20 sm:pt-8 mt-5">
          <form-wizard :memberData="memberData" :isView="isView" :isEdit="isEdit" @exit-form="exitForm" @complete-form="completeForm" :key="route.params.memberid" :questionList="question_data?.[0].data" />
        </div>
      </div>
    </div>
    <SurveyInformationModal
      id="modal-cwd-survey-info"
      v-model="showModal"
      modal-size="modal-lg"
      close-button
      @click:cancel="completeForm(true)"
      @click:ok="showModal = false"
      header="คำอธิบายสำหรับการสำรวจข้อมูลเด็กพิการ"
      modal-name="cwd-survey"
    >
      <div>
        <p class="mb-3">แบบสอบถามข้อมูลเด็กพิการ เป็นแบบสอบถามย่อยภายใต้แบบสอบถามเพื่อการพัฒนาคน โดยจะ<strong>สำรวจเฉพาะเด็กและเยาวชน (อายุระหว่าง 0-18 ปี) ที่มีความพิการเท่านั้น</strong> (คำถาม IN105 กรณีไม่ได้ตอบตัวเลือกที่ (1)) เพื่อสำรวจความลำบากหรือปัญหาด้านสุขภาพที่ทำให้เด็กมีข้อจำกัดในการดำเนินชีวิตประจำวันอันจะนำไปสู่การศึกษา วิเคราะห์ และออกแบบนโยบาย มาตรการ และบริการที่จำเป็นต่าง ๆ ได้อย่างเหมาะสม พร้อมทั้งบูรณาการและเชื่อมโยงการบริการสำหรับเด็กพิการที่เกี่ยวข้อง ให้สามารถส่งต่อการสนับสนุนช่วยเหลือได้อย่างมีประสิทธิภาพ ตลอดจนสามารถยกระดับคุณภาพชีวิตของเด็กและเยาวชนผู้พิการในอนาคต</p>
        <p ><u>หมายเหตุ</u> หากเด็กและเยาวชนผู้พิการไม่อยู่บ้าน หรือไม่สามารถให้ข้อมูลได้ ให้หัวหน้าครัวเรือน พ่อแม่ผู้ปกครอง บุคคลที่อาศัยอยู่ในครัวเรือน หรือผู้ที่ได้รับความยินยอมจากบุคคลในครัวเรือนเป็นผู้ให้ข้อมูลแทนได้</p>

        
      </div>
    </SurveyInformationModal>
  </template-layout>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import formWizard from '@/components/add-cwd-wizard/index.vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from "axios"
import Swal from "sweetalert2";
import Information from "@/components/info-section/Main.vue"
import SurveyInformationModal from "@/components/survey-information-modal/Main.vue";
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const surveyStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'survey'
});
const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const route = useRoute()
const router = useRouter()
const store = useStore()
const memberData = ref({})
const isView = ref(false)
const isEdit = ref(false)
const customMenu = ref([])
const householdData = ref(null)
const houseSurveysId = ref(route.params.surveyid ?? null)
const houseId = ref(null)
const loadingMember = ref(false)
const showModal = ref(false)
const isPromptRouteLeave = ref(true)
const loadingQuestion = ref(false)
const question_data = ref(null)
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const getSurvey = async() =>{
  loadingMember.value = true
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}member/${route.params.memberid}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const params = {
    house_survey_id: houseSurveysId.value
  }
  const res = await axios.get(url, { headers, params }).catch(() =>{
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
    loadingMember.value = false
  }
  );
  if (res.status === 200) {
      loadingMember.value = false
      memberData.value = {...memberData.value, ...res.data}
      //set house id when get member/:id (view/edit member form)
      houseId.value = memberData.value.house_id
  }
}
const getCWDSurvey = async() =>{
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}cwdSurveys/${route.params.cwdid}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const res = await axios.get(url, { headers }).catch(() =>{
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
  }
  );
  if (res.status === 200) {
    memberData.value = {...memberData.value, cwd_survey_data: res.data}
  }
}
/* const getHouseSurvey = async() =>{
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}house/${route.params.hhid}`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const res = await axios.get(url, { headers }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        householdData.value = res.data
        if(householdData.value.house_surveys?.length > 0){
            householdData.value["questionnaires"] = householdData.value.house_surveys.find(x=>x._id == houseSurveysId.value)
        }
    }
}
const getCustomMenu = () => {
  if(householdData.value?.house_surveys?.length > 0){
    let sub_menu = []
    for(let i = householdData.value.house_surveys.length; i > 0; i-- ){
      sub_menu.push({
        icon: '',
        pageName: 'household-profile',
        title: `ครั้งที่ ${i}` ,
        params: {
          hhid: householdData.value._id,
          surveyid: householdData.value.house_surveys[i-1]["_id"]
        },
        path: `/household/${householdData.value._id}/${householdData.value.house_surveys[i-1]["_id"]}`
      })
    }
    customMenu.value = [
      {key: "devider"},
      {
        icon: "FileTextIcon",
        title: "แบบสำรวจ",
        subMenu: sub_menu
      }
    ]
    const idx = householdData.value.house_surveys.findIndex(x=>x._id == houseSurveysId.value)
    const dataMember = householdData.value.house_surveys[idx]?.member_surveys ?? []
    if(dataMember.length > 0){
      let member_menu = []
      for(let i = 0; i < dataMember.length; i++ ){
        member_menu.push({
          pageName: 'view-member',
          title: `${dataMember[i].name} ${dataMember[i].surname}` ,
          params: {
            hhid: householdData.value._id,
            surveyid: householdData.value.house_surveys[idx]._id,
            memberid: dataMember[i].member_id
          },
          path: `/member/view/${householdData.value._id}/${householdData.value.house_surveys[idx]._id}/${dataMember[i].member_id}`,
          image: dataMember[i]?.image?.img_url ? process.env.VUE_APP_IMG_API+dataMember[i].image.img_url : "default"
        })
      }
      customMenu.value = [...customMenu.value, {key: "devider"}, {icon: "UsersIcon",title: "สมาชิกในครัวเรือน",subMenu: member_menu}]
    }
  }
} */
const getOfflineData = async () =>{
  const houseValue = await houseStore.getItem(route.params.surveyid)
  memberData.value = houseValue.house_surveys?.members.find(x=> x.member_id == route.params.memberid)
  memberData.value.full_address = `${houseValue.address_num} ${houseValue.village_name} ${houseValue.soi ?? ""} ${houseValue.road ?? ""} ${houseValue.tambol_name} ${houseValue.amphur_name} ${houseValue.province_name} ${houseValue.postcode}`
  memberData.value.full_name = `${memberData.value.prefix_name} ${memberData.value.name} ${memberData.value.surname}`
  houseSurveysId.value = memberData.value.house_surveys_id
  houseId.value = route.params.surveyid //key of indexeddb
  //check cwd_survey set cwd_survey_id for edit/view
  if(memberData.value?.cwd_survey_data){
    memberData.value.cwd_survey_id = 1 //fix as 1 cause for online when edit route required cwd_survey_id, but in offline we have only one survey as object type so always have 1
  }

}
const getQuestionList = async () => {
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const params = {
      question_nare_name_en: "cwd"
    }
    loadingQuestion.value = !loadingQuestion.value
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        question_data.value = res.data
        loadingQuestion.value = !loadingQuestion.value
        surveyStore.setItem('cwd', JSON.parse(JSON.stringify(res.data)))
    }
}
const getQuestionListOffline = async () =>{
    let res = null
    await surveyStore.getItem("cwd").then((value)=>{
      res = value
    })
    question_data.value = res
}
onMounted(async ()=>{
  if(route.name === "add-cwd")
    showModal.value = true

  if(onlineComputed.value){
    await getQuestionList()
    await getSurvey()
    if(route.name === "view-cwd" || route.name === "edit-cwd"){
      if(route.name === "view-cwd"){
        isView.value = true
        isPromptRouteLeave.value = false
      }
      else if(route.name === "edit-cwd"){
        isEdit.value = true
      }
      await getCWDSurvey()
    }
  }else{
    await getQuestionListOffline()
    await getOfflineData()
    if(route.name === "view-cwd"){
      isView.value = true
      isPromptRouteLeave.value = false
    }
  }
  
  // await getHouseSurvey()
  // getCustomMenu()
})
// watch(() =>route.params, async (val)=>{
//   if(val && route.name == "view-member"){
//     //call api to get member data load into form when route params change
//     await getSurvey()
//   }
// })
const breadcrumbComputed = computed(()=>{
  let array = [
    {
      name: "ครัวเรือน",
      path: "main-house-hold",
    }
  ];

  if (houseId.value) {
    array.push(
      {
        name: "ข้อมูลครัวเรือน",
        path: "household-profile",
        param:{
          hhid: houseId.value,
          surveyid: houseSurveysId.value
        }
      },
      {
        name: "ข้อมูลสมาชิก",
        path: "view-member",
        param:{
          hhid: houseId.value,
          surveyid: houseSurveysId.value,
          memberid: route.params.memberid
        }
      },
      {
        name: `${route.name == "view-cwd" ? 'ข้อมูลเด็กพิการ' : route.name == "edit-cwd" ? 'แก้ไขข้อมูลเด็กพิการ' : 'เพิ่มข้อมูลเด็กพิการ'}`,
        active: true,
      }
    );
  }
  return array;
})
const completeForm = (backStatus=null) => {
    showModal.value = false
    isPromptRouteLeave.value = false
    if(backStatus)
      router.go(-1)
}
const exitForm = () =>{
  router.go(-1)
}
onBeforeRouteLeave((to, from, next) => {
  if(isPromptRouteLeave.value){
    swalCustom.fire({
      html: isView.value ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
          <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        showModal.value = false
        next()
      } else {
        next(false)
      }
    })
  }
  else{
    next()
  }
})
</script>

<style lang="scss">
</style>