<template>
  <div class="overflow-x-auto scrollbar-hidden house-member-datatable" v-if="dataArray.length>0">
    <DatatableProfile
      :data-array="dataArray"
      :data-array-table="dataArrayTable"
      @row-clicked="handleRowClick"
    />
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue'
import DatatableProfile from "@/components/datatable/datatableProfile.vue"
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Swal from "sweetalert2"
import axios from 'axios'
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const props = defineProps({
  dataArray:{
    type: Array
  }
})
const router = useRouter()
const route = useRoute()
const store = useStore()
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
};
const emits = defineEmits(["update-house"])
const onlineComputed = computed(()=> store.getters["network/isOnline"])

var customNameMutator = (value, data, type, params, component) => {
  let fullname = "-"
    fullname = `${data.prefix_name ?? ""}${data.name ?? ""} ${data.surname ?? ""}`
  return fullname;
}
/* var customAddressMutator = (value, data, type, params, component) => {
    return `${data.hnum?data.hnum:""} ${data.moo?'หมู่ '+data.moo:''} ${data.village_name?data.village_name: ''} ${data.soi?'ซอย '+data.soi: ''} ${data.road?'ถนน '+data.road: ''} ${data.tumbol_name?'ตำบล'+data.tumbol_name: ''}  ${data.amphur_name?'อำเภอ'+data.amphur_name: ''} ${data.province_name?'จังหวัด'+data.province_name: ''} ${data.postcode?data.postcode:""}`
} */
var customAgeRange = (value, data, type, params, component) => {
    return {
        "baby": data?.age_range ? data.age_range.some(x=> x === "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)") : false,
        "kid": data?.age_range ? data.age_range.some(x=> x === "วัยเรียน (อายุ 5-14 ปี)") : false,
        "teenager": data?.age_range ? data.age_range.some(x=> x === "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)") : false,
        "adult": data?.age_range ? data.age_range.some(x=> x === "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)") : false,
        "elder": data?.age_range ? data.age_range.some(x=> x === "วัยผู้สูงอายุ (60 ปีขึ้นไป)") : false
    }
}
const getTarget = (value, data, type, params, component) => {
  return{
    neet: data?.NEET ?? false,
    cwd: data?.CWD ?? false
  }
}
const dataArrayTable = reactive([
  {title:"ชื่อ-นามสกุล", field:"full_name", minWidth: 200, mutator: customNameMutator, resizable:true, formatter:"textarea"},
  {title:"เลขบัตรประจำตัวประชาชน", field:"NID", hozAlign:"left", minWidth: 200, resizable:true, formatter:function(cell, formatterParams){
    return cell.getValue() ?? 'ไม่มีบัตรประจำตัวประชาชน'
  }},
  {title:"ความสัมพันธ์", field:"relation", hozAlign:"left", resizable:true, minWidth: 200,
    formatter:function(cell, formatterParams){
      return cell.getValue() ? cell.getValue().desc : null
    }
  },
  {title:"ช่วงวัย", field:"age_range", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, mutator: customAgeRange,
    formatter:function(cell, formatterParams){
      var value = cell.getValue();
      if(value){
          let temp = ""
          for(const [key, val] of Object.entries(value)){
              let icon = "", text = ""
              if(key === "baby"){
                  icon = "baby-icon"
                  text = "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)"
              }
              if(key === "kid"){
                  icon = "kid-icon"
                  text = "วัยเรียน (อายุ 5-14 ปี)"
              }
              if(key === "teenager"){
                  icon = "teenager-icon"
                  text = "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)"
              }
              if(key === "adult"){
                  icon = "adult-icon"
                  text = "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)"
              }
              if(key === "elder"){
                  icon = "elder-icon"
                  text = "วัยผู้สูงอายุ (60 ปีขึ้นไป)"
              }
              temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                          <span class="t-tooltip-text">${text}</span>\
                          <span class="img-icon ${icon}"></span>\
                      </button>`
          }
          let res = `<div class="flex flex-wrap lg:justify-center justify-start">${temp}</div>`
          return res
      }
      return null
    }
  },
  {title:"กลุ่มเป้าหมาย", field:"target", hozAlign:"center", minWidth: 120, width: 140, variableHeight:true, mutator: getTarget,
    formatter:function(cell, formatterParams){
      var value = cell.getValue();
      if(value){
          let temp = ""
          for(const [key, val] of Object.entries(value)){
              let icon = "", text = ""
              if(key === "neet"){
                  icon = "neets-icon"
                  text = "เด็ก NEETs"
              }
              if(key === "cwd"){
                  icon = "cwd-icon"
                  text = "เด็กพิการ"
              }
              temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                          <span class="t-tooltip-text">${text}</span>\
                          <span class="img-icon ${icon}"></span>\
                      </button>`
          }
          let res = `<div class="flex flex-wrap lg:justify-center justify-start">${temp}</div>`
          return res
      }
      return null
    }
  },
  {title:"สถานะ", field:"status", hozAlign:"center", resizable:true, minWidth: 120, width: 120,
    formatter:function(cell, formatterParams){
      return cell.getValue() == "COMPLETE" ? "สำเร็จ" : "ร่าง"
    }
  },
  {title:"วันที่ปรับปรุงข้อมูลล่าสุด", field:"UPDATE_DATETIME", hozAlign:"center", minWidth: 200, width: 220, variableHeight:true,
    formatter:function(cell){
      if(cell.getValue()){
        return new Date(cell.getValue()).toLocaleDateString('th-TH',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )
      }
      return null
    }
  },
  {title:"การดำเนินการ", field:"action", hozAlign:"center", minWidth: 150, width: 200,
    formatter:function(cell, formatterParams){
      const editCallback = (e) => {
        //prevent default row click
        e.stopPropagation();
        if(cell.getData().member_id){
          router.push({
            name: 'edit-member',
            params:{
              hhid: route.params.hhid,
              surveyid: route.params.surveyid,
              memberid: cell.getData().member_id
            }
          })
        }
      };
      const deleteCallback = (e) =>{
        //prevent default row click
        e.stopPropagation();
        swalCustom.fire({
          text:"ยืนยันลบข้อมูล",
          icon:"warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
        }).then(async (result) => {
          if (result.isConfirmed) {
            if(onlineComputed.value){
              const params = {}
              if(cell.getData().relation.code == 1){
                params.headOfHousehole = true
              }
              const res = await axios.delete(`${process.env.VUE_APP_BASE_API}memberSurveys/${route.params.surveyid}/${cell.getData().member_id}`, {params, headers}).catch(() =>{
                swalCustom.fire({
                  text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                  icon: "warning",
                  timer: 3000, //timeOut for auto-close
                  confirmButtonText: "ปิด",
                });
              });
              if(res.status == 200){
                swalCustom.fire({
                  text: "สำเร็จ",
                  icon: "success",
                  timer: 3000, //timeOut for auto-close
                  confirmButtonText: "ปิด",
                }).then(() => {
                  //emit to parent to get update house data
                  emits("update-house")
                });
              }
            }
            else{
              //get key find member index setItem again to update value
              const houseValue = await houseStore.getItem(String(cell.getData().house_id))
              const memberIdx = houseValue.house_surveys?.members.findIndex(x=> x.member_id == cell.getData().member_id)
              if(memberIdx != -1){
                  houseValue.house_surveys.members.splice(memberIdx, 1)
                  houseStore.setItem(String(cell.getData().house_id), houseValue)
                  swalCustom.fire({
                      text: "สำเร็จ",
                      icon: "success",
                      timer: 3000, //timeOut for auto-close
                      confirmButtonText: "ปิด",
                  }).then(() => {
                      emits("update-house")
                  });
              }
            }
          }
        })
      }
      const viewEvent = (e) =>{
        //prevent default row click
        e.stopPropagation();
        router.push({
          name: 'view-member',
          params: {
            hhid: route.params.hhid,
            surveyid: route.params.surveyid,
            memberid: cell.getData().member_id
          },
        })
      }

      let divElm = document.createElement("div")
      divElm.className = "flex lg:justify-center items-center gap-2"

      let editDiv = document.createElement("div");
      editDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let editBtn = document.createElement("button")
      editBtn.className = "btn btn-secondary bg-primary-3/[0.7] hover:bg-primary-3/[0.6] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let editText = document.createElement("span");
      editText.className = "t-tooltip-text"
      editText.append("แก้ไข")
      let icon = document.createElement("i");
      icon.setAttribute("data-feather","edit")
      icon.className = "w-7 h-7"
      if((cell.getData().status == "COMPLETE" || !getPermissionAccessMenu("update") && onlineComputed.value))
          editBtn.setAttribute("disabled","disabled")
      editBtn.addEventListener("click", editCallback);
      editBtn.append(icon);
      editDiv.append(editText)
      editDiv.append(editBtn)
      divElm.append(editDiv)

      let viewDiv = document.createElement("div");
      viewDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let viewBtn = document.createElement("button");
      viewBtn.className = "btn btn-secondary bg-primary-2/[0.8] hover:bg-primary-2/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let viewText = document.createElement("span");
      viewText.className = "t-tooltip-text"
      viewText.append("แสดง")
      let iconView = document.createElement("i");
      iconView.setAttribute("data-feather","eye")
      iconView.className = "w-7 h-7"
      if(!getPermissionAccessMenu("read") && onlineComputed.value)
        viewBtn.setAttribute("disabled","disabled")

      viewBtn.addEventListener("click", viewEvent);
      viewBtn.append(iconView);
      viewDiv.append(viewText)
      viewDiv.append(viewBtn)
      divElm.append(viewDiv)

      let delDiv = document.createElement("div");
      delDiv.className = "t-tooltip flex justify-center w-7 h-7"
      let delBtn = document.createElement("button")
      delBtn.className = "btn btn-secondary bg-theme-24/[0.8] hover:bg-theme-24/[0.5] text-white mr-1 border-0 rounded w-7 h-7 p-1"
      let delText = document.createElement("span");
      delText.className = "t-tooltip-text"
      delText.append("ลบ")
      let delIcon = document.createElement("i");
      delIcon.setAttribute("data-feather","trash-2")
      delIcon.className = "w-7 h-7"
      if((cell.getData().status == "COMPLETE" || !getPermissionAccessMenu("delete") && onlineComputed.value)){
        delBtn.setAttribute("disabled","disabled")
      }
      delBtn.addEventListener("click", deleteCallback);
      delBtn.append(delIcon)
      delDiv.append(delText)
      delDiv.append(delBtn)
      divElm.append(delDiv)

      const b = cash(divElm)
      return b[0]
    }
  },
])

const handleRowClick = (row) =>{
    if(getPermissionAccessMenu("read") || !onlineComputed.value){
      router.push({
        name: 'view-member',
        params: {
          hhid: route.params.hhid,
          surveyid: route.params.surveyid,
          memberid: row.member_id
        },
      })
    }
}

</script>

