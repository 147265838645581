<template>
    <div class="breadcrumb mr-auto">
        <div v-if="!isHideMenu">
            <span v-for="(d,k) in breadCrumbList" :key="k">
                <router-link :to="d.param?{name:d.path,params:d.param}:{name:d.path}" :class="{'breadcrumb--active': d.active}">{{d.name}}</router-link>
                <ChevronRightIcon class="breadcrumb__icon" v-if="k != breadCrumbList.length - 1"/>
          </span>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router';
export default defineComponent({
    props:{
        isHideMenu:{
            type: Boolean,
            default: false
        },
        breadCrumbList:{
            type: Array
        }
    },
    setup(){
        // const route = useRoute();
        // const crumb = ref([])
        // if(route.matched.some((m) => m.meta.crumb)){
        //     crumb.value = route.meta.crumb
        // }
        // return {
        //     crumb
        // }
    }
})
</script>

<style>

</style>