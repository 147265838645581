import Chart from './chart/Main.vue'
import BarChart from './barchart/Main.vue'
import GoogleMapLoader from './google-map-loader/Main.vue'
import Highlight from './highlight/Main.vue'
import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
// import ClassicEditor from './ckeditor/ClassicEditor.vue'
// import BalloonBlockEditor from './ckeditor/BalloonBlockEditor.vue'
// import BalloonEditor from './ckeditor/BalloonEditor.vue'
import DocumentEditor from './ckeditor/DocumentEditor.vue'
// import InlineEditor from './ckeditor/InlineEditor.vue'
import Dropzone from './dropzone/Main.vue'
import FullCalendar from './calendar/Main.vue'
import FullCalendarDraggable from './calendar/Draggable.vue'
import DisabilityIcon from "./disability-icon/Main.vue"
import NeetsIcon from "./neets-icon/Main.vue"
import FormIcon from "./form-icon/Main.vue"
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import vSelect from 'vue-select'
import AlertCustom from './alert/Main.vue'

export default app => {
  app.component('Chart', Chart)
  app.component('BarChart', BarChart)
  app.component('GoogleMapLoader', GoogleMapLoader)
  app.component('Highlight', Highlight)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  app.component('Dropzone', Dropzone)
  // app.component('ClassicEditor', ClassicEditor)
  // app.component('BalloonBlockEditor', BalloonBlockEditor)
  // app.component('BalloonEditor', BalloonEditor)
  app.component('DocumentEditor', DocumentEditor)
  // app.component('InlineEditor', InlineEditor)
  app.component('FullCalendar', FullCalendar)
  app.component('FullCalendarDraggable', FullCalendarDraggable)
  app.component('v-select', vSelect)
  app.component('DisabilityIcon', DisabilityIcon)
  app.component('NeetsIcon', NeetsIcon)
  app.component('AlertCustom', AlertCustom)
  app.component('FormIcon', FormIcon)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
