<template>
  <div class="w-full" :id="chartId" v-if="!legendOnRight">
    <canvas ref="chartDonutRef" class="lg:p-[3rem] mb-3 lg:mb-0 h-full" :height="height"/>
  </div>
  <div class="grid grid-cols-12 sm:gap-3 xl:gap-6" v-else>
    <div class="col-span-12 medium:col-span-6 large:col-span-12 extra:col-span-6 my-auto p-5 medium:p-3 extra:p-0">
      <canvas ref="chartDonutRef" :height="height"/>
    </div>
    <div class="col-span-12 medium:col-span-6 large:col-span-12 extra:col-span-6 pt-0 my-auto" :id="chartId">
    </div>
  </div>
</template>

<script setup>
import Chart from 'chart.js/auto'
import { onMounted, ref, watch, computed } from 'vue'
import {millionShortNum,millionShortString,globalNumDecFixed} from "@/utils/format-number"

const props = defineProps({
  chartLabel:{
    type: Array,
    default: ()=>[]
  },
  series:{
    type: Array,
    default: ()=> []
  },
  chartId: {
    type: String,
    required: true,
    default: "chartBox"
  },
  height: {
    type: Number,
    default: 0
  },
  unit: {
    type: String,
    default: "รายการ"
  },
  legendOnRight:{
    type: Boolean,
    default: false
  }

})
const data = computed(()=>{
  return{
    labels: props.chartLabel,
    datasets: [
      {
        data: props.series,
        backgroundColor: ['#1C4E80', '#0091D5', '#A5D8DD', '#EA6A47', '#DBAE58', '#1b6279', '#488A99', '#6AB187', '#7E909A'],
        hoverBackgroundColor: ['#1C4E80', '#0091D5', '#A5D8DD', '#EA6A47', '#DBAE58', '#1b6279', '#488A99', '#6AB187', '#7E909A'],
        borderWidth: 3,
        borderColor: "#fff",
      },
    ],
  }
})
const sumTotal = computed(()=>{
  if(props.series.length > 0)
    return props.series.reduce((a,v) => a+v, 0)
  return null
})

const stackedText = {
  id: "stackedtext",
  afterDatasetsDraw(chart, args, options){
    const {ctx, chartArea:{top, bottom, left, right}, width, height} = chart
    let fontWidth = width
    ctx.save()
    ctx.font = `bolder ${fontWidth*87/100}% Kanit`;
    // ctx.font = "bolder 2.8rem Kanit";
    ctx.fillStyle = "#224181";
    ctx.textAlign = "center";
    if(sumTotal.value)
      ctx.fillText(`${millionShortNum(sumTotal.value)}${millionShortString(props.totalData)}`, width/2, height/2)
    ctx.restore();
    ctx.font = `bolder ${fontWidth*40/100}% Kanit`;
    ctx.fillStyle = "#224181";
    ctx.textAlign = "center";
    if(sumTotal.value)
      ctx.fillText(`${props.unit}`, width/2, height/2 + (fontWidth*87/100)/10)
    // ctx.fillStyle = "#000";
    // // (xStart, yStart, width, height)
    // ctx.fillRect(width/2,top,1, bottom)
    // ctx.fillRect(left, height/2, right, 1)
  }
}
const chartDonutRef = ref()
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div.custom-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('custom-tooltip')

    const parentDiv = document.createElement('div');
    parentDiv.classList.add('tooltip-wrap')
    parentDiv.style.maxWidth = '160px'
    parentDiv.style.minWidth = '50px'

    tooltipEl.appendChild(parentDiv);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const divTitle = document.createElement('div');
    divTitle.classList.add('tooltip-title')

    titleLines.forEach(title => {
      const text = document.createTextNode(title);
      divTitle.appendChild(text);
    });

    const divBody = document.createElement('div');
    divBody.classList.add('tooltip-body')
    bodyLines.forEach((body, i) => {
      if(body.length){
        const colors = tooltip.labelColors[i];
        const divWrap = document.createElement('div')
        divWrap.classList.add('tooltip-item')
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = 'rgba(254, 254, 254, 0.88)'//colors.borderColor;
        const text = document.createTextNode(body);
        divWrap.appendChild(span)
        divWrap.appendChild(text)
        divBody.appendChild(divWrap);
      }


    });

    const divRoot = tooltipEl.querySelector('div.tooltip-wrap');

    // Remove old children
    while (divRoot.firstChild) {
      divRoot.firstChild.remove();
    }

    // Add new children
    divRoot.appendChild(divTitle);
    divRoot.appendChild(divBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
const init = () => {
    const canvas = chartDonutRef.value?.getContext('2d')
    Chart.defaults.font.family = "Kanit"
    const chart = new Chart(canvas, {
        type: 'doughnut',
        data: data.value,
        plugins:[stackedText],
        options: {
            // layout:{
            //     padding: 40
            // },
            cutoutPercentage: 70,
            plugins:{
              legend: {
                display: false,
                position: "bottom",
                align: "start",
                labels: {
                  pointStyle: "circle",
                  usePointStyle: true,
                  boxWidth: 7,
                  fontSize: 11,
                  padding: 10,
                },
              },
              tooltip: {
                // usePointStyle: true,
                enabled: false,
                external:externalTooltipHandler,
                position: 'nearest',
                callbacks: {
                  label: (tooltipItems) => {
                    const data = tooltipItems.chart.data;
                    const datasetIndex = tooltipItems.datasetIndex;
                    const index = tooltipItems.dataIndex;
                    const datasetLabel = data.labels[index] || "";
                    let sum = data.datasets[datasetIndex].data.reduce((sum, dataset) => {
                      return sum + dataset;
                    }, 0);
                    let percent = data.datasets[datasetIndex].data[index] / sum * 100;
                    if(Math.round(percent) !== percent) {
                      percent = percent.toFixed(2)
                    }
                    if(data.datasets[datasetIndex].data[index])
                      return `${datasetLabel}: ${data.datasets[datasetIndex].data[index]} (${percent}%)`;
                    return;
                  }
                }
              },
            },
            responsive:true,
        },
    })
    
    //update chart data when dataset value change
    watch(data, () => {
      chart.data = data.value
      chart.update()
      const chartBox = document.getElementById(props.chartId)
      const div = document.createElement('DIV')
      div.setAttribute('class', 'customLegend')
      div.classList.add("px-5","medium:px-3", "large:px-2", "large:ml-2", "mt-5", "large:mt-0")
      const ul = document.createElement('UL')
      chart.legend.legendItems.forEach((dataset, index)=>{
        const text = dataset.text
        const datasetIndex = dataset.index
        const bgColor = dataset.fillStyle
        const bColor = dataset.strokeStyle
        const fontColor = dataset.fontColor
        if(chart.data.datasets[0].data[index]){
          const li = document.createElement('LI')
          li.setAttribute('title', text)
          const spanBox = document.createElement('SPAN')
          spanBox.classList.add("legend-type")
          spanBox.style.borderColor = bColor
          spanBox.style.backgroundColor = bgColor

          const p = document.createElement('P')
          p.classList.add("truncate")
          p.classList.add("mr-3")
          const textNode = document.createTextNode(text)
          li.onclick = (click) => {
            chart.toggleDataVisibility(datasetIndex)
            const element = click.target.parentNode
            element.classList.toggle('fade')
            chart.update()
          }
          const valueSpan = document.createElement('span')
          valueSpan.classList.add("ml-auto")
          valueSpan.classList.add("legend-value")
          ul.appendChild(li)
          li.appendChild(spanBox)
          li.appendChild(p)
          li.appendChild(valueSpan)
          let percent = (chart.data.datasets[0].data[index] * 100) / sumTotal.value
          if(Math.round(percent) !== percent) {
            percent = percent.toFixed(2)
          }
          valueSpan.appendChild(document.createTextNode(`${percent}%`))
          p.appendChild(textNode)
        }
      })
      chartBox.appendChild(div)
      div.appendChild(ul)
    })
    chartDonutRef.value.instance = chart
}

onMounted(()=>{
    init()
})
</script>

