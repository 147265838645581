<template>
  <div>
    <!-- <div class="relative mx-6">
      <div class="wizard short-wizard flex flex-col pt-1 gap-2" id="survey_wizard">
        <div class="flex items-center z-10"
            :class="{ 'mt-5': key > 0 }" v-for="(header, key) in stepForm" :key="key"
        >
            <div class="relative">
              <button class="w-10 h-10 rounded-full btn" :class="[
                  header.step == currentStep.step ||
                  allSaveStep.includes(header.step)
                      ? 'btn-primary'
                      : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
                  ]"
                  @click="isView || isEdit || isAdminManagement ? jumpToStep(header.step) : null"
                  v-if="header.id"
              >
                  {{ header.step }}
              </button>
              <div class="
                w-4
                h-4
                flex
                items-center
                justify-center
                absolute
                rounded-full
                text-white
                bg-theme-10
                bottom-0
                "
                style="left: 53%"
                v-show="allSaveStep.includes(header.step)"
              >
                <CheckIcon class="w-3 h-3 text-white" />
              </div>
            </div>
            <div class="
                font-medium
                text-base
                ml-3
            ">
                {{ header.title }}
            </div>
            <div>
              <button class="btn btn-primary shadow-none border-0 bg-transparent text-gray-500 hover:text-gray-700 p-1" @click="editStepHeader(header)" v-if="isAdminManagement">
                <EditIcon class="h-4 w-4" />
              </button>
              <button class="btn btn-primary shadow-none border-0 bg-transparent text-theme-24/[0.6] hover:text-theme-24/[0.8] p-1" @click="editStepHeader(header)" v-if="isAdminManagement">
                <Trash2Icon class="h-4 w-4" />
              </button>
            </div>
            <div class="relative wizard-line-block">
              <button class="w-10 h-10 rounded-full btn btn-primary" @click="showAddStepModal = true">
                <PlusIcon class="h-4 w-4" />
              </button>
            </div>
            <div class="
                font-medium
                text-base
                ml-3
            ">
            {{ header.title }}
            </div>
        </div>
      </div>
    </div> -->
    <Accordion
      accordion-id="question_step"
      class="px-6"
      :customClass="['!border-0 !shadow-none', 'bg-[#f6f6f6]']"
      isOpen
    >
      <template v-slot:header-content>
          <h2 class="text-md text-theme-2 font-semibold">
              หัวข้อหลัก
          </h2>
      </template>
      <template v-slot:custom-icon>
        <div class="flex flex-row items-center">
          <button class="btn btn-primary bg-primary-btn mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1">
              <ChevronDownIcon class="h-6 w-6" />
          </button>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="col-span-12">
          <div class="wizard short-wizard flex flex-col pt-1 gap-2" id="survey_wizard">
            <div class="flex items-center z-10 steps"
                :class="{ 'mt-5': key > 0 }" v-for="(header, key) in stepForm" :key="key"
            >
              <div class="relative">
                <button class="w-10 h-10 rounded-full btn" :class="[
                    header.step == currentStep.step ||
                    allSaveStep.includes(header.step)
                        ? 'bg-primary-2 text-white'
                        : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
                    ]"
                    @click="isView || isEdit || isAdminManagement ? jumpToStep(header.step) : null"
                    v-if="header.id"
                >
                    {{ header.step }}
                </button>
                <button class="w-10 h-10 rounded-full btn btn-primary" @click="showAddStepModal = true" v-else>
                  <PlusIcon class="h-4 w-4" />
                </button>
                <div class="
                  w-4
                  h-4
                  flex
                  items-center
                  justify-center
                  absolute
                  rounded-full
                  text-white
                  bg-theme-10
                  bottom-0
                  "
                  style="left: 53%"
                  v-show="allSaveStep.includes(header.step) && !isAdminManagement"
                >
                  <CheckIcon class="w-3 h-3 text-white" />
                </div>
              </div>
              <div
                class="
                  font-medium
                  text-base
                  ml-3"
                  style="flex: 0 0 20em;"
                >
                  {{ header.title }}
              </div>
              <div>
                <button class="btn btn-primary shadow-none border-0 bg-transparent text-gray-700 hover:text-gray-800 p-1" @click="editStepHeader(header)" v-if="isAdminManagement && header.id">
                  <EditIcon class="h-4 w-4" />
                </button>
                <button class="btn btn-primary shadow-none border-0 bg-transparent text-theme-24/[0.7] hover:text-theme-24/[0.8] p-1" @click="deleteStepHeader(header.id)" v-if="isAdminManagement && header.id">
                  <Trash2Icon class="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Accordion>
    <hr class="mt-8" />
    <div class="p-6 pb-0 flex justify-end mb-[-0.75rem]">
      <AddHeaderForm class="mr-2" :currentStep="currentStep" @saveSuccess="emit('saveSuccess', $event, currentStep), editHeaderData = null" :editData="editHeaderData" v-if="isAdminManagement && currentStep?.id"/>
      <AddQuestionForm :editData="editData" :currentStep="currentStep" :questionList="questionList" @saveSuccess="emit('saveSuccess', $event, currentStep), editData = null, currentHeaderData = null"  :questionnaireData="questionnaireData" v-if="isAdminManagement" :currentHeaderData="currentHeaderData" v-model="openAddQuestionModal" />
    </div>
    <div class="p-6" v-if="questionList" ref="questionSection">
      <StepComponent
        :class="[currentStep.form == step.step.step_number ? 'block' : 'hidden']"
        v-for="(step, index) in questionList"
        :key="index"
        :isDefaultHide="defaultHide"
        :questionList="step.questions"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-event="emit('deleteEvent', $event, currentStep)"
        @edit-header="editHeaderData = $event"
        @next-step-view="nextstep(findStep($event))"
        @skip-step="skipStep"
        @prev-step="prevstep"
        :currentStep="currentStep"
        :countStep="stepForm.length"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
      />
    </div>
  </div>
  <ModalLayout
    id="modal-step-form"
    v-model="showAddStepModal"
    modal-size="modal-lg"
    closeButton
    :header="`${isEdit ? 'แก้ไข' : 'เพิ่ม'}หัวข้อหลัก`"
    @click:save="submitStepForm"
    @click:cancel="clearStepForm"
    :isDone="isDone"
  >
    <div class="grid grid-cols-12 gap-4 gap-y-5">
    <div class="col-span-12">
        <label class="form-label">
            หัวข้อหลัก
            <span class="text-theme-24 text-md"> *</span>
        </label>
        <input
            id="step_title"
            type="text"
            class="form-control"
            placeholder="หัวข้อหลัก"
            data-test="step_title"
            :disabled="false"
            :class="{ 'border-theme-24': validate_step.step_title.$error}"
            v-model="validate_step.step_title.$model"
        />
        <template v-if="validate_step.step_title.$error">
            <div
                v-for="(error, index) in validate_step.step_title.$errors"
                :key="index"
                class="text-theme-24 mt-1 text-sm"
            >
                {{ error.$message }}
            </div>
        </template>
      </div>
    </div>
  </ModalLayout>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import ModalLayout from "@/components/base/custom-modal/CustomModalLayout.vue"
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import StepComponent from "./container/step.vue"
import AddQuestionForm from "@/components/add-question-form/index.vue"
import axios from "axios";
import Swal from "sweetalert2";
import AddHeaderForm from "@/components/add-header-form/index.vue"
import Accordion from "@/components/base/custom-accordion/index.vue"


const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const store = useStore();
const onlineComputed = computed(()=> store.getters["network/isOnline"])

// variable
const props = defineProps({
  memberData : {type: Object},
  isEdit: {type: Boolean, default: false},
  isView: {type: Boolean, default: false},
  addMemberModal: {
    type: Boolean,
    default: false,
  },
  editModal: {
    type: Boolean,
    default: false,
  },
  questionList:{
    type: Array
  },
  isAdminManagement:{
    type: Boolean,
    default: false
  },
  currentStepObj: {
    type: Object
  },
  questionnaireData:{
    type: Object
  }
});

const emit = defineEmits(["modal-value", "exit-form", "complete-form", "saveSuccess", "deleteEvent"]);

const form_step = reactive({
  step_title: null,
  step_id: null
})
const form_rules = computed(()=>{
  return {
    step_title: {
      required: helpers.withMessage(`กรุณาระบุ`, required),
    },
  }
})


const validate_step = useVuelidate(form_rules, form_step,{ $scope: false })
const currentStep = ref({
  step: null,
  form: null,
});
const showAddStepModal = ref(false)
const isDone = ref(false)
const isEditStep = ref(false)
const allSaveStep = reactive([]);
const stepForm = ref({});
const editData = ref(null)
const editHeaderData = ref(null)
const questionSection = ref(null)
const openAddQuestionModal = ref(false)
const currentHeaderData = ref(false)
const dataUpdate = ref(null)


// function
const initialStepForm = (form) => {
  let step = [];
  if(props.questionList?.length){
    form.forEach((e, i) => {
      let stepObj = props.questionList.find(({step})=> step.step_number == e)?.step
      if(stepObj){
        step.push({
          title: stepObj.step_title,
          step: i + 1,
          form: e,
          id: stepObj.step_id
        });
      }
    });
  }
  if(props.isAdminManagement){
    step.push({
      title: "เพิ่มหัวข้อหลัก",
      step: 0,
      form: null,
      id: null
    })
  }
  return step;
};

const getSurveysStep = computed(()=>{
  let arr = []
  for(let i = 1; i <= props.questionList?.length; i++){
    arr.push(i)
  }
  return arr
})
const initValue = async () => {
  stepForm.value = initialStepForm(getSurveysStep.value);
  if(props.currentStepObj){
    currentStep.value = props.currentStepObj
  }
  else{
    currentStep.value = stepForm.value.find((e) => {
      return e.step == 1;
    });
    if(!currentStep.value){
      currentStep.value = {
        form: 0,
        step: 0
      }
    }
  }
  // render.value = true;
};

const findStep = (formnumber) => {
  return stepForm.value.find((e) => e.form == formnumber);
};

const nextstep = (current) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
  var step = current.step + 1;
  var next = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = next;
  if(!allSaveStep.includes(current.step)){
    // saveStep.value = current.step
    allSaveStep.push(current.step)
  }
};


const skipStep = (currentForm) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    let current = findStep(currentForm);
    //last step cannot skip
    if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
    let step = current.step + 1;
    currentStep.value = stepForm.value.find((e) => {
        return e.step == step;
    });
}

const prevstep = (currentForm) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  var current = findStep(currentForm);
  if (current.step == stepForm.value[0].step) return;
  var step = current.step - 1;
  var pre = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = pre;
};

const defaultHide = ref(false)
const getScreen = () =>{
  if(window.innerWidth < 1024){
    defaultHide.value = true
  }
  else{
    defaultHide.value = false
  }
}


onMounted(()=>{
  if(onlineComputed.value)
    initValue();
  getScreen()
  window.addEventListener("resize", getScreen)

})
onUnmounted(()=>{
    window.removeEventListener("resize", getScreen);
})

watch(()=> props.questionList, ()=>{
  if(!onlineComputed.value){
    initValue();
  }
})


const submitStepForm = () =>{
  validate_step.value.$touch();
  if (validate_step.value.$invalid) {
    return;
  }
  swalCustom.fire({
    text: "ยืนยันบันทึกข้อมูล",
    icon: "info",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async(result)=>{
    if (result.isConfirmed) {
      try{
        const config_axios = {
          method: isEditStep.value ? 'PUT' : 'POST',
          url: `${process.env.VUE_APP_BASE_API}admin/step${isEditStep.value ? `/${form_step.step_id}` : ''}`,
          data: {
            step_title: form_step.step_title,
            question_naire_id: props.questionnaireData.questionnaire_id
          },
          headers: {
            Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
            "Content-Type": `application/json; charset=utf-8`,
          }
        }
        if(!isEditStep.value){
          config_axios.data.step_number = stepForm.value.length == 1 ? 1 : stepForm.value[stepForm.value.length-2].form + 1
        }
        const response = await axios(config_axios).catch(()=>{
            swalCustom.fire({
                text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                icon: "warning",
                // timer: 3000, //timeOut for auto-close
                confirmButtonText: "ปิด",
            });
        })
        if(response.status == 200){
            // swalCustom.fire({
            //   text:"สำเร็จ",
            //   icon: 'success',
            //   timer: 3000,
            //   confirmButtonText: "ปิด",
            // }).then(() => {
              isDone.value = true
              clearStepForm(true)
            // });
        }
      }
      catch{
        swalCustom.fire({
          text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
          icon: "warning",
          // timer: 3000, //timeOut for auto-close
          confirmButtonText: "ปิด",
        });
      }
    }
  })
}

const clearStepForm = (isUpdate = null) => {
  validate_step.value.$reset()
  showAddStepModal.value = false
  form_step.step_title = null
  form_step.step_id = null
  isDone.value = false
  isEditStep.value = false
  setTimeout(()=>{
    emit("saveSuccess",isUpdate, null)
  },500)
}

const editStepHeader = (data) => {
  showAddStepModal.value = true
  form_step.step_title = data.title
  form_step.step_id = data.id
  isEditStep.value = true
}

const deleteStepHeader = (id) => {
  swalCustom.fire({
    text:"ยืนยันลบข้อมูล",
    icon:"warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  }).then(async (result) => {
    if (result.isConfirmed) {
      const res = await axios.delete(`${process.env.VUE_APP_BASE_API}admin/step/${id}`,
      {
        headers: {
          Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
          "Content-Type": `application/json; charset=utf-8`,
        }
      }).catch(() =>{
        swalCustom.fire({
          text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
          icon: "warning",
          timer: 3000, //timeOut for auto-close
          confirmButtonText: "ปิด",
        });
      });
      if(res.status == 200){
        // swalCustom.fire({
        //   text: "สำเร็จ",
        //   icon: "success",
        //   timer: 3000, //timeOut for auto-close
        //   confirmButtonText: "ปิด",
        // }).then(() => {
          emit('saveSuccess', true, null)
        // });
      }
    }
  })
}
const jumpToStep = (toStep) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  //if step 1 not complete cannot jump to another step
  if(!allSaveStep.includes(1) && !props.isView && !props.isAdminManagement) return;
  currentStep.value = stepForm.value.find((e) => {
      return e.step == toStep;
  });
}

const updateQuestionStatus = async (data) => {
  dataUpdate.value = null
  const res = await axios.put(
    `${process.env.VUE_APP_BASE_API}admin/question/status/${data._id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      }
    }
  ).catch(() => {
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      //timer: 3000, //timeOut for auto-close
      confirmButtonText: "ปิด",
    })
    .then(()=>{
      dataUpdate.value = data
    });
  })

  if(res.status == 200){
    if(data.status == "active"){
      data.status = "inactive"
    }
    else{
      data.status = "active"
    }
    dataUpdate.value = data
  //   swalCustom.fire({
  //     text: "สำเร็จ",
  //     icon: "success",
  //     timer: 3000,
  //     confirmButtonText: "ปิด",
  //   });
  }
}
</script>
<style lang="scss">
.form-check-input {
  border-color: black !important;
}
.accordion-button .feather-play{
  transition: transform .1s ease;
  fill: #2244b0;
}
.accordion-button[aria-expanded="true"] .feather-play {
  transform: rotate(-90deg);
  transition: transform .1s ease;
}
</style>
