<template>
    <div class="grid grid-cols-12 gap-3 mt-3" id="statistics-section">
        <div class="col-span-12 mt-5 flex justify-between items-center flex-wrap">
            <div class="flex items-center text-gray-600 data-group mt-3 md:mt-0" data-test="data-level">
                <p class="text-md font-medium truncate mr-2">
                ระดับของข้อมูล :
                </p>
                <div class="w-72">
                    <v-select
                        class=""
                        data-test="main-category"
                        id="main-category-select"
                        v-model="valueSelected"
                        :options="options"
                        :clearable="false"
                        :searchable="true"
                        placeholder="ระดับข้อมูล"
                        @option:selecting="handleSelectedChange"
                        @open="handleOpenDropdown"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
                        </template>
                    </v-select>
                    <search-dropdown v-model="showDropdownFilter" class="rounded-md" :valueSelected="valueSelected" @click:cancel="handleClickCancel" @click:save="handleSearch"></search-dropdown>
                </div>
            </div>
            <div class="flex items-center text-gray-600 mt-3 md:mt-0" data-test="data-option">
                <p class="text-md font-medium truncate mr-2 ">
                    แสดงข้อมูลราย :
                </p>
                <div class="box">
                    <div class="flex rounded-md border border-gray-300 overflow-hidden">
                        <fieldset class="flex-grow text-center">
                            <input
                            class="peer"
                            type="radio"
                            name="name"
                            id="firstOptionId"
                            value="firstOptionValue"
                            v-model="selectState"
                            >
                            <label
                            class="inline-block text-md w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
                            for="firstOptionId"
                            >ครัวเรือน</label>
                        </fieldset>
                        <fieldset class="flex-grow text-center border-l border-gray-300">
                            <input
                            class="peer"
                            type="radio"
                            name="name"
                            id="secondOptionId"
                            value="secondOptionValue"
                            v-model="selectState"
                            >
                            <label
                            class="inline-block text-md w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
                            for="secondOptionId"
                            >ครอบครัว</label>
                        </fieldset>
                        <fieldset class="flex-grow text-center border-l border-gray-300">
                            <input
                            class="peer"
                            type="radio"
                            name="name"
                            id="thirdOptionId"
                            value="thirdOptionValue"
                            v-model="selectState"
                            >
                            <label
                            class="inline-block text-md w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
                            for="thirdOptionId"
                            >สมาชิก</label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 mt-1" v-if="valueSelected != 'ทั้งประเทศ'">
            <div class="flex">
                <p class="text-md font-medium truncate mr-2 text-gray-600">
                    เงื่อนไขการค้นหา :
                </p>
                <i class="text-md">
                    {{searchResultText}}
                </i>
            </div>
        </div>
        <div class="col-span-12">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 lg:col-span-8">
                    <div class="report-box-2 intro-y mt-2 h-full">
                        <div class="box sm:flex h-full">
                            <div class="px-4 py-4 flex-1 flex flex-col justify-center relative">
                                <div class="absolute top-4">
                                    <button
                                    class="btn btn-primary mr-2 py-1"
                                    data-test="all-status"
                                    >
                                    ทั้งหมด
                                    </button>
                                    <button
                                    class="btn btn-secondary py-1"
                                    data-test="approved-status"
                                    >
                                    อนุมัติ
                                    </button>
                                </div>
                                <div class="px-8 py-8 mt-4 flex flex-col justify-center">
                                    <HomeIcon class="w-10 h-10 text-theme-36" />
                                    <div class="relative text-5xl font-bold mt-6 pl-2">
                                        <h1 class="text-custom-member opacity-90">4.1 M</h1>
                                    </div>
                                    <div class="mt-4 text-gray-600 dark:text-gray-600 pr-20">
                                        <div class="text-theme-36">จำนวนครัวเรือนที่มีปัญหา</div>
                                        <div>
                                            ครัวเรือนที่มีปัญหาอย่างน้อย 1 ด้าน โดยคำนวณจากคำตอบของสมาชิกภายในครัวเรือน
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="px-4 py-8 flex flex-col justify-center flex-1 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed"
                            >
                                <div class="box px-4 py-4 mb-3 flex items-center zoom-in bg-gray-100 box-shadow">
                                    <div class="w-11 h-11 flex-none image-fit rounded-md overflow-hidden">
                                        <button class="btn btn-secondary mr-1 border-0 rounded-full w-11 h-11 p-2 text-white hover:bg-theme-37 bg-theme-36">
                                        <HomeIcon/>
                                        </button>
                                    </div>
                                    <div class="ml-auto">
                                        <div class="font-medium text-gray-600">ครัวเรือน</div>
                                        <div class="text-custom-member text-md mt-0.5 text-right text-4xl">200</div>
                                    </div>
                                </div>
                                <div class="box px-4 py-4 mb-3 flex items-center zoom-in bg-gray-100 box-shadow">
                                    <div class="w-11 h-11 flex-none image-fit rounded-md overflow-hidden">
                                        <button class="btn btn-secondary mr-1 border-0 rounded-full w-11 h-11 p-2 text-white hover:bg-theme-37 bg-theme-36">
                                        <DollarSignIcon/>
                                        </button>
                                    </div>
                                    <div class="ml-auto">
                                        <div class="font-medium text-gray-600">การเงิน</div>
                                        <div class="text-custom-member text-md mt-0.5 text-right text-4xl">120</div>
                                    </div>
                                </div>
                                <div class="box px-4 py-4 mb-3 flex items-center zoom-in bg-gray-100 box-shadow">
                                    <div class="w-11 h-11 flex-none image-fit rounded-md overflow-hidden">
                                        <button class="btn btn-secondary mr-1 border-0 rounded-full w-11 h-11 p-2 text-white hover:bg-theme-37 bg-theme-36">
                                        <HeartIcon/>
                                        </button>
                                    </div>
                                    <div class="ml-auto">
                                        <div class="font-medium text-gray-600">สุขภาพ</div>
                                        <div class="text-custom-member text-md mt-0.5 text-right text-4xl">122</div>
                                    </div>
                                </div>
                                <div class="box px-4 py-4 mb-3 flex items-center zoom-in bg-gray-100 box-shadow">
                                    <div class="w-11 h-11 flex-none image-fit rounded-md overflow-hidden">
                                        <button class="btn btn-secondary mr-1 border-0 rounded-full w-11 h-11 p-2 text-white hover:bg-theme-37 bg-theme-36">
                                        <PackageIcon/>
                                        </button>
                                    </div>
                                    <div class="ml-auto">
                                        <div class="font-medium text-gray-600">การเข้าถึงสิทธิ</div>
                                        <div class="text-custom-member mt-0.5 text-right text-4xl">10</div>
                                    </div>
                                </div>
                                <div class="box px-4 py-4 flex items-center zoom-in bg-gray-100 box-shadow">
                                    <div class="w-11 h-11 flex-none image-fit rounded-md overflow-hidden">
                                        <button class="btn btn-secondary mr-1 border-0 rounded-full w-11 h-11 p-2 text-white hover:bg-theme-37 bg-theme-36">
                                        <BookOpenIcon/>
                                        </button>
                                    </div>
                                    <div class="ml-auto">
                                        <div class="font-medium text-gray-600">การศึกษา</div>
                                        <div class="text-custom-member mt-0.5 text-right text-4xl">202</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <div class="report-box-2 intro-y mt-2 h-full">
                        <div class="box p-5 h-full">
                            <div class="flex items-center text-custom-member">
                                สภาพปัญหา (คน)
                            </div>
                            <div class="mt-2 h-full">
                                <StackedBarChart :height="350" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StackedBarChart from '@/components/stacked-bar-chart/Main.vue'
import { computed, defineComponent, reactive, ref, watch } from 'vue'

import searchDropdown from "../container/searchDropdown.vue"
export default defineComponent({
    components:{
        StackedBarChart,
        searchDropdown
    },
    setup(){
        const selectState = ref('firstOptionValue')
        const valueSelected = ref('ทั้งประเทศ')
        const options = reactive(['ทั้งประเทศ', 'สำนักงานส่งเสริมและสนับสนุนวิชาการ','จังหวัด'])
        const showModal = ref(false)
        const searchResult = ref(null)
        const showDropdownFilter = ref(false)
        const searchResultText = ref(null)
        const handleModal = (val) => {//when select change show modal
            if(val == "สำนักงานส่งเสริมและสนับสนุนวิชาการ" || val == "จังหวัด"){
                showModal.value = !showModal.value
            }
        }
        const handleSelectedChange = (val) => {
            if(val == "สำนักงานส่งเสริมและสนับสนุนวิชาการ" || val == "จังหวัด"){
                showDropdownFilter.value = !showDropdownFilter.value
            }
        }
        const handleClickCancel = () => {
            valueSelected.value = "ทั้งประเทศ"
        }
        const handleOpenDropdown = () =>{
            showDropdownFilter.value = false
        }
        const handleSearch = (val) => {
            let text = ""
            if(valueSelected.value == "จังหวัด" && val){
                if(val.province){
                    text += "จังหวัด"+val.province.province_name
                    if(val.district){
                        text += " อำเภอ"+val.district.amphur_name
                    }
                    if(val.subdistrict){
                        text += " ตำบล"+val.subdistrict.tumbol_name
                    }
                    if(val.village){
                        text += " หมู่บ้าน"+val.village.village_name
                    }
                }
                searchResultText.value = text
            }
            else{
                if(val.organize){
                    searchResultText.value = val.organize
                }
            }
        }
        return {
            selectState,
            options,
            valueSelected,
            handleModal,
            showModal,
            handleClickCancel,
            handleSearch,
            searchResult,
            searchResultText,
            handleSelectedChange,
            showDropdownFilter,
            handleOpenDropdown
        }
    }
})
</script>

<style lang="scss" scoped>
input[type="radio"] {
  display: none;
}
.box-shadow{
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}
.vs__dropdown-toggle{
    background: #FFF;
}
.vs__search{
    font-size: 0.75rem;
}
</style>