<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div>
        <div class="w-1/6 h-1/6 mx-auto" v-if="loadingQuestion">
            <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
        </div>
        <div v-else>
            <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-6">
                <h1 class="text-lg text-primary-2 mr-auto">
                    แบบสอบถามเพื่อการบูรณาการบริการสำหรับเด็กพิการ
                </h1>
            </div>
            <div class="intro-y box py-10 sm:py-20 mt-5">
                <formCwd :questionList="question_data?.[0].data" isAdminManagement @saveSuccess="handleSavedModal" :currentStepObj="currentStep" @deleteQuestion="handleDeleteQuestion" :questionnaireData="questionnaire_data"/>
            </div>
        </div>
    </div>
  </template-layout>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import formCwd from '@/components/add-cwd-wizard/index.vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { useStore } from 'vuex'
import axios from "axios"
import Swal from "sweetalert2";


const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const store = useStore()
const loadingQuestion = ref(false)
const question_data = ref(null)
const currentStep = ref(null)
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};
const questionnaire_data = ref({})

const getQuestionList = async () => {
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const params = {
        question_nare_name_en: "cwd",
        status: "all"
    }
    loadingQuestion.value = !loadingQuestion.value
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        question_data.value = res.data
        loadingQuestion.value = !loadingQuestion.value
        questionnaire_data.value = {
            questionnaire_name: res.data?.[0].question_naire_name,
            questionnaire_name_en: res.data[0].question_naire_name_en,
            questionnaire_name_short: res.data[0].question_naire_short_name,
            questionnaire_id: res.data[0].question_naire_id,
            code_name: res.data[0].code_name,
        }
        // surveyStore.setItem('cwd', JSON.parse(JSON.stringify(res.data)))
    }
}

onMounted(async ()=>{
    await getQuestionList()
})

const handleSavedModal = (evt, current) => {
    if(evt){
        currentStep.value = current
        getQuestionList()
    }
}
const handleDeleteQuestion = (ques_id, current) => {
    swalCustom.fire({
        text:"ยืนยันลบข้อมูล",
        icon:"warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
    }).then(async(result) => {
        if (result.isConfirmed) {
            const res = await axios.delete(`${process.env.VUE_APP_BASE_API}admin/question/${ques_id}`, {headers}).catch(() =>{
                swalCustom.fire({
                    text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                    icon: "warning",
                    timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                });
            });
            if(res.status == 200){
                swalCustom.fire({
                    text: "สำเร็จ",
                    icon: "success",
                    timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                }).then(() => {
                    currentStep.value = current
                    getQuestionList()
                });
            }
        }
    })
}
const breadcrumbComputed = computed(()=>{
    return [
        {
            name: "แบบสอบถามเด็กพิการ",
            active: true,
        }
    ]
})
</script>

<style lang="scss">
</style>