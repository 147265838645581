<template>
  <div class="w-72 mt-1 drop-shadow-lg z-[70] fixed bg-white" v-if="valueSelected != 'ทั้งประเทศ' && isOpen">
    <div class="p-3">
      <div class="grid grid-cols-12 gap-3" v-if="valueSelected == 'จังหวัด'">
        <div class="col-span-12">
          <label class="from-label text-md text-gray-600 inline-flex mb-1" for="province-select">จังหวัด</label>
          <div class="w-full">
            <v-select
              class="bg-white"
              data-test="province"
              id="province-select"
              v-model="provinceSelected"
              :options="provinceOption"
              label="province_name"
              :clearable="false"
              :searchable="true"
              placeholder="จังหวัด"
              @option:selected="getDistrict"
            >
              <template #no-options="{}">
              ไม่พบข้อมูล
              </template>
              <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-span-12">
          <label class="from-label text-md text-gray-600 inline-flex mb-1 items-center" for="district-select">
            เขต/อำเภอ/อปท
            <span class="t-tooltip flex justify-center items-center w-4 h-4 ml-1">
              <InfoIcon class="w-4 h-4"/>
              <span class="t-tooltip-text">
                กรณีที่อยู่ใน อปท ให้เลือก อปท เป็นหลัก
              </span>
            </span>
          </label>
          <div class="w-full">
            <v-select
              class="bg-white"
              data-test="district"
              id="district-select"
              v-model="districtSelected"
              :options="districtOption"
              label="amphur_name"
              :clearable="true"
              :searchable="true"
              placeholder="อำเภอ"
              @option:selected="getSubdistrict"
            >
              <template #no-options="{}">
              ไม่พบข้อมูล
              </template>
              <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-span-12">
          <label class="from-label text-md text-gray-600 inline-flex mb-1" for="subdistrict-select">แขวง/ตำบล</label>
          <div class="w-full">
            <v-select
              class="bg-white"
              data-test="subdistrict"
              id="subdistrict-select"
              v-model="subdistrictSelected"
              :options="subdistrictOption"
              label="tumbol_name"
              :clearable="true"
              :searchable="true"
              placeholder="ตำบล"
              @option:selected="getVillage"
            >
              <template #no-options="{}">
              ไม่พบข้อมูล
              </template>
              <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-span-12">
          <label class="from-label text-md text-gray-600 inline-flex mb-1" for="village-select">หมู่บ้าน</label>
          <div class="w-full">
            <v-select
              class="bg-white"
              data-test="village"
              id="village-select"
              v-model="villageSelected"
              :options="villageOption"
              label="village_name"
              :clearable="true"
              :searchable="true"
              placeholder="หมู่บ้าน"
            >
              <template #no-options="{}">
              ไม่พบข้อมูล
              </template>
              <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-3" v-else>
        <div class="col-span-12">
          <label class="from-label text-md text-gray-600 inline-flex mb-1" for="organize-select">สสว.</label>
          <div class="w-full">
            <v-select
              class="bg-white"
              data-test="organize"
              id="organize-select"
              v-model="organizeSelected"
              :options="organizeOption"
              :clearable="false"
              :searchable="true"
              placeholder="สสว."
            >
              <template #no-options="{}">
              ไม่พบข้อมูล
              </template>
              <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><ChevronDownIcon class="w-5 h-5"/></span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="flex items-center mt-3">
          <button
            class="btn btn-outline-secondary w-32 ml-auto"
            @click="handleClickCancel"
          >
          ยกเลิก
          </button>
          <button
            class="btn btn-primary w-32 ml-2"
            @click="handleClickSave"
            v-if="valueSelected == 'จังหวัด'"
            :disabled="!provinceSelected"
          >
          ตกลง
          </button>
          <button
            class="btn btn-primary w-32 ml-2"
            @click="handleClickSave"
            v-else
            :disabled="!organizeSelected"
          >
          ตกลง
          </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { onMounted, reactive, watch, ref } from "vue";
import axios from "axios"
const props = defineProps({
  modelValue: Boolean,
  valueSelected: {
    type: String,
  },
});
const emits = defineEmits(["update:modelValue", "click:cancel", "click:save"]);
const organizeOption = reactive(["สำนักงานส่งเสริมและสนับสนุนวิชาการ 1","สำนักงานส่งเสริมและสนับสนุนวิชาการ 2","สำนักงานส่งเสริมและสนับสนุนวิชาการ 3","สำนักงานส่งเสริมและสนับสนุนวิชาการ 4","สำนักงานส่งเสริมและสนับสนุนวิชาการ 5","สำนักงานส่งเสริมและสนับสนุนวิชาการ 6","สำนักงานส่งเสริมและสนับสนุนวิชาการ 7","สำนักงานส่งเสริมและสนับสนุนวิชาการ 8","สำนักงานส่งเสริมและสนับสนุนวิชาการ 9","สำนักงานส่งเสริมและสนับสนุนวิชาการ 10","สำนักงานส่งเสริมและสนับสนุนวิชาการ 11"])
const organizeSelected = ref(null)
const provinceSelected = ref(null)
const districtSelected = ref(null)
const subdistrictSelected = ref(null)
const villageSelected = ref(null)
const provinceOption = ref()
const districtOption = ref()
const subdistrictOption = ref()
const villageOption = ref()
const isOpen = ref(false)

watch(
  () => props.modelValue,
  (newval) => {
    if (newval) {
      isOpen.value = true
    } else {
      hideDropdown();
    }
  }
);


const hideDropdown = () => {
  setTimeout(() => {
    emits("update:modelValue", false);
  }, 450);
};

const handleClickCancel = () => {
  hideDropdown();
  organizeSelected.value = null
  provinceSelected.value = null
  districtSelected.value = null
  subdistrictSelected.value = null
  villageSelected.value = null
  emits("click:cancel");

};

const handleClickSave = () => {
  isOpen.value = false
  let result = {}
  result.province = props.valueSelected == "จังหวัด" ? provinceSelected.value : null
  result.district = props.valueSelected == "จังหวัด" ? districtSelected.value: null
  result.subdistrict = props.valueSelected == "จังหวัด" ? subdistrictSelected.value : null
  result.village = props.valueSelected == "จังหวัด" ? villageSelected.value : null
  result.organize = props.valueSelected == "สำนักงานส่งเสริมและสนับสนุนวิชาการ" ? organizeSelected.value : null
  if(props.valueSelected == "จังหวัด"){
    organizeSelected.value = null
  }
  else{
    provinceSelected.value = null
    districtSelected.value = null
    subdistrictSelected.value = null
    villageSelected.value = null
  }
  hideDropdown();
  emits("click:save",result);

};
const getProvince = () => {
    try {
        axios.get(`${process.env.VUE_APP_BASE_API}refadms/`)
        .then((response) => {
            provinceOption.value =  response.data.sort((a,b) => a.province_name > b.province_name ? 1 : -1 )
        })
    } catch (error) {
        console.log(error.message);
    }
};
const getDistrict = (val) =>{
    districtSelected.value = null
    subdistrictSelected.value = null
    villageSelected.value = null
    try {
        axios.get(`${process.env.VUE_APP_BASE_API}refadms/`,{
            params: {
                prov: val._id.province_ID
            }
        })
        .then((response) => {
            if (response.status === 200){
                districtOption.value =  response.data.sort((a,b) => a.amphur_name > b.amphur_name ? 1 : -1 )
            }
            else {
                console.log(response.status.statusText);
            }
        })
    } catch (error) {
        console.log(error.message);
    }
}
const getSubdistrict = (val) =>{
    subdistrictSelected.value = null
    villageSelected.value = null
    try {
        axios.get(`${process.env.VUE_APP_BASE_API}refadms/`,{
        params: {
            amp: val._id.amphur_ID
        }
        })
        .then((response) => {
        if (response.status === 200){
            subdistrictOption.value =  response.data.sort((a,b) => a.tumbol_name > b.tumbol_name ? 1 : -1 )
        }
        else {
            console.log(response.status.statusText);
        }
        })
    } catch (error) {
        console.log(error.message);
    }
}

const getVillage = (val) => {
  villageSelected.value = null
  try {
    axios.get(`${process.env.VUE_APP_BASE_API}refadms/`,{
        params: {
            tam: val._id.tumbol_ID
        }
    })
    .then((response) => {
      if (response.status === 200){
        villageOption.value =  response.data.sort((a,b) => a.village_name > b.village_name ? 1 : -1 )
      }
      else {
        console.log(response.status.statusText);
      }
      })
  } catch (error) {
    console.log(error.message);
  }
};
onMounted(()=>{
    getProvince()
})
</script>
