<template>
  <FilterLayout :filter="filter">
    <AddressSection
      :province="filterState.address.province"
      :amphur="filterState.address.amphur"
      :tumbol="filterState.address.tumbol"
      :village="filterState.address.village"
      @update:province="updateAddress($event, 'province')"
      @update:amphur="updateAddress($event, 'amphur')"
      @update:tumbol="updateAddress($event, 'tumbol')"
      @update:village="updateAddress($event, 'village')"
      parentComponent="household"
      key="household"
    />
    <section data-test="filter-address-date-section" class="grid grid-cols-12 gap-4">
      <div class="mt-2 col-span-12 sm:col-span-6">
        <AddressNumber
          class="mt-2"
          :address_number="filterState.address_number"
          @update:address_number="filterState.address_number = $event"
        />
      </div>
      <div class="mt-2 col-span-12 sm:col-span-6">
        <SurveyDate
          class="mt-2"
          :start_date="filterState.start_date"
          :end_date="filterState.end_date"
          @update:start_date="filterState.start_date = $event"
          @update:end_date="filterState.end_date = $event"
        />
      </div>
    </section>
    <section data-test="filter-status-section" class="grid grid-cols-12 gap-4">
      <div class="mt-2 col-span-12 sm:col-span-6">
        <SurveyStatusSection
          class="mt-2"
          v-model="filterState.house_survey_status"
          :options="options"
        />
      </div>
    </section>

    <div class="flex justify-end mt-3">
      <button class="btn btn-outline-secondary mr-2" @click="resetState">
        ล้าง
      </button>
      <button class="btn btn-primary bg-primary-btn border-primary-btn" @click="sendToStore">
        ค้นหา
      </button>
    </div>
  </FilterLayout>
</template>

<script setup>
import AddressSection from "./component/AddressSection.vue";
import FilterLayout from "./layout/index.vue";
import { reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SurveyDate from "./component/SurveyDate.vue";
import AddressNumber from "./component/AddressNumber.vue";
import SurveyStatusSection from "./component/SurveyStatusSection"
// START: declare data
const INIT_FILTER_VALUE = {
  address: {
    amphur: null,
    province: null,
    tumbol: null,
    village: null,
  },
  end_date: null,
  start_date: null,
  address_number: null,
  house_survey_status: null
};
const store = useStore();
const filterState = reactive(JSON.parse(JSON.stringify(INIT_FILTER_VALUE)));
const options = reactive([
  {label: 'ร่าง', value: "DRAFT"},
  {label: 'สำเร็จ', value: "COMPLETE"}
])
onMounted(() => {
  Object.assign(
    filterState,
    JSON.parse(JSON.stringify(store.getters["householdFilter/getAllFilter"]))
  );
});

const resetState = () => {
  const initFilter = JSON.parse(JSON.stringify(INIT_FILTER_VALUE))
  if(store.getters["userAuth/getUserProvinceCode"].length == 2)
    initFilter.address.province = filterState.address.province;
  if(store.getters["userAuth/getUserAmphurCode"].length == 4)
    initFilter.address.amphur = filterState.address.amphur;
  if(store.getters["userAuth/getUserTumbolCode"].length == 6)
    initFilter.address.tumbol = filterState.address.tumbol;
  Object.assign(filterState, initFilter);
};
const sendToStore = () => {
  let result = filterState;
  store.dispatch(
    "householdFilter/setStore",
    JSON.parse(JSON.stringify(result))
  );
};

const updateAddress = (evt, key) => {
  filterState.address[key] = evt;
};
</script>
