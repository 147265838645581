<template>
  <Chart
    type="pie"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
    :sumTotal="sumTotal"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store'
const props = defineProps({
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  series:{
    type: Array,
    default: ()=> []
  },
  chartLabel:{
    type: Array,
    default: ()=>[]
  }
})

const store = useStore()
const darkMode = computed(() => store.state.main.darkMode)
const data = computed(() => {
  return {
    labels: props.chartLabel,
    datasets: [
      {
        data: props.series,
        backgroundColor: ['#1C4E80', '#0091D5', '#A5D8DD', '#EA6A47', '#DBAE58', '#1b6279', '#488A99', '#6AB187', '#7E909A'],
        hoverBackgroundColor: ['#1C4E80', '#0091D5', '#A5D8DD', '#EA6A47', '#DBAE58', '#1b6279', '#488A99', '#6AB187', '#7E909A'],
        borderWidth: 3,
        borderColor: '#fff',
      }
    ]
  }
})

const sumTotal = computed(()=>{
  if(props.series.length > 0)
    return props.series.reduce((a,v) => a+v, 0)
  return null
})
const options = computed(() => {
  return {
    // layout:{
    //   padding: 40
    // },
    plugins:{
      legend: {
        labels: {
          fontSize: 14,
          boxWidth: 10,
          padding: 15,
        },
        position: 'top',
        align: "center",
        padding:{
          top: 20
        },
        display: false,
      },
      tooltip: {
        // usePointStyle: true,
        enabled: false,
        external:externalTooltipHandler,
        position: 'nearest',
        callbacks: {
          label: (tooltipItems) => {
            const data = tooltipItems.chart.data;
            const datasetIndex = tooltipItems.datasetIndex;
            const index = tooltipItems.dataIndex;
            const datasetLabel = data.labels[index] || "";
            let sum = data.datasets[datasetIndex].data.reduce((sum, dataset) => {
              return sum + dataset;
            }, 0);
            let percent = data.datasets[datasetIndex].data[index] / sum * 100;
            if(Math.round(percent) !== percent) {
              percent = percent.toFixed(2)
            }
            if(data.datasets[datasetIndex].data[index])
              return `${datasetLabel}: ${data.datasets[datasetIndex].data[index]} (${percent}%)`;
            return;
          }
        }
      },
    },
    responsive:true,
  }
})
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div.custom-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('custom-tooltip')

    const parentDiv = document.createElement('div');
    parentDiv.classList.add('tooltip-wrap')
    parentDiv.style.maxWidth = '160px'
    parentDiv.style.minWidth = '50px'

    tooltipEl.appendChild(parentDiv);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const divTitle = document.createElement('div');
    divTitle.classList.add('tooltip-title')

    titleLines.forEach(title => {
      const text = document.createTextNode(title);
      divTitle.appendChild(text);
    });

    const divBody = document.createElement('div');
    divBody.classList.add('tooltip-body')
    bodyLines.forEach((body, i) => {
      if(body.length){
        const colors = tooltip.labelColors[i];
        const divWrap = document.createElement('div')
        divWrap.classList.add('tooltip-item')
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = 'rgba(254, 254, 254, 0.88)'//colors.borderColor;
        const text = document.createTextNode(body);
        divWrap.appendChild(span)
        divWrap.appendChild(text)
        divBody.appendChild(divWrap);
      }
    });

    const divRoot = tooltipEl.querySelector('div.tooltip-wrap');

    // Remove old children
    while (divRoot.firstChild) {
      divRoot.firstChild.remove();
    }

    // Add new children
    divRoot.appendChild(divTitle);
    divRoot.appendChild(divBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};


</script>
