<template>
  <template-layout :breadCrumbList="breadcrumbComputed" :customMenu="customMenu">
    <div class="py-12  px-2 sm:px-4">
      <nav class="mb-2">
        <h1 class="text-2xl text-primary-2">
          ข้อมูลครัวเรือน
        </h1>
      </nav>
      <div class="w-1/6 h-1/6 mx-auto" v-if="householdInformationLoading">
        <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
      </div>
      <div class="grid grid-cols-2 gap-6" v-else>
        <div class="col-span-2">
          <Infomation
            :information="householdInformation"
          />
        </div>
        <div class="col-span-2 lg:col-span-1" v-if="onlineComputed">
          <CardLayout
            headerText="รูปภาพครัวเรือน"
            headerButtonText="+ เพิ่มรูปภาพ"
            @click-header-button="openEditImageModal(true)"
            :showHeaderButton="true"
            isItemCenter
            :disable="!getPermissionAccessMenu('update')"
          >
            <ModalLayout
              id="edit-householdimage-modal"
              v-model="editImage"
              @click:save="handleImageSaveEdit"
            >
              <span>เพิ่มรูปภาพครัวเรือน</span>
              <div class="flex flex-row justify-end">
                <button class="btn btn-primary" @click="addImageInput">
                  + เพิ่มรูปภาพ
                </button>
              </div>
              <div v-for="(item, index) in tempImage" :key="item">
                <FileInput
                  class="my-2"
                  :id="`inputImageFile${index}`"
                  v-model="tempImage[index].image"
                  @click:delete="handelDeleteImage($event, index)"
                  :show-label="false"
                  accept-file="image/*"
                />
              </div>
            </ModalLayout>
                <div class="mx-6 w-full">
                  <TinySlider
                    :options="{
                      controls: true,
                      autoplay: false,
                      items: 1,
                      responsive: {
                        1024: {
                          items: 2,
                        },
                        720: {
                          items: 2,
                        },
                        500: {
                          items: 2,
                        },
                      },
                    }"
                  >
                    <div
                      v-for="(item, index) in householdInformation.house_images"
                      :key="item.file_name"
                      class="h-[10rem]"
                      style="vertical-align: unset;"
                    >
                      <PreviewFile
                        class="m-2"
                        :value="item"
                        :id="'preview-image-modal' + index"
                      />
                    </div>
                  </TinySlider>
                </div>
          </CardLayout>
        </div>
        <div class="h-auto col-span-2 lg:col-span-1" v-if="onlineComputed">
          <CardLayout
            headerText="พิกัดบ้าน"
            class="map-height"
            @click-header-button="handleClickEditMap"
            :showHeaderButton="true"
            :disable="!getPermissionAccessMenu('update')"
            isRelative
          >
            <ModalLayout
              id="edit-map-modal"
              v-model="editMap"
              @click:cancel="handleMapCancelEdit"
              @click:save="handleMapSaveEdit"
            >
              <div class="map-modal-height">
                <LeafletMap
                  :edit="editMap"
                  :lat="
                    tempLat ? tempLat : null
                  "
                  :lng="
                    tempLng ? tempLng : null
                  "
                  @update:lat="handleUpdateLat"
                  @update:lng="handleUpdateLng"
                  id="edit-map"
                />
              </div>
            </ModalLayout>
            <LeafletMap
              v-if="householdInformation.latitude && householdInformation.longitude"
                id="information-map"
                :lat="
                  householdInformation ? householdInformation.latitude : null
                "
                :lng="
                  householdInformation
                    ? householdInformation.longitude
                    : null
                "
            />
            <ModalLayout
              id="view-map-modal"
              v-model="fullScreenMap"
              :show-footer-button="false"
              :is-body-padding="false"
            >
              <div class="map-modal-height relative">
                <LeafletMap
                  v-if="householdInformation.latitude && householdInformation.longitude"
                  id="view-map"
                  :lat="
                    householdInformation ? householdInformation.latitude : null
                  "
                  :lng="
                    householdInformation
                      ? householdInformation.longitude
                      : null
                  "
                />
                <div class="absolute top-3 right-3 z-[400]" v-if="householdInformation.latitude && householdInformation.longitude">
                  <button class="btn p-1 bg-white border-2 border-gray-500" @click="fullScreenMap = false"><Minimize2Icon class="h-6 w-6"/></button>
                </div>
              </div>
              <template v-slot:btn>
                <div class="flex flex-row justify-end items-center p-3" >
                  <button class="btn btn-secondary mr-2" @click="fullScreenMap = false">
                    ปิด
                  </button>
                </div>
              </template>
            </ModalLayout>
            <div class="absolute top-6 right-6 z-[400]" v-if="householdInformation.latitude && householdInformation.longitude">
              <button class="btn p-1 bg-white border-2 border-gray-500" @click="fullScreenMap = !fullScreenMap"><Maximize2Icon class="h-6 w-6"/></button>
            </div>
          </CardLayout>
        </div>
        <div class="col-span-2">
          <nav class="flex justify-end items-center mb-4">
            <!-- <button
              class="btn btn-lg btn-primary mr-1 mb-2"
              @click="downloadHHJson()"
            >
              Download ข้อมูลครัวเรือน (JSON)
            </button> -->
            <button
              @click="router.push({ name: 'add-member', params: { hhid: route.params.hhid, surveyid: route.params.surveyid}})"
              class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
              :disabled="!getPermissionAccessMenu('create') && onlineComputed"
            >
            + เพิ่มสมาชิก
            </button>
          </nav>
          <CardLayout headerText="สมาชิกในครัวเรือน" :showHeaderButton="false">
            <MemberDatatable :data-array="dataArray" @update-house="onlineComputed ? getDataHouse() : getDataHouseOffline()"/>
          </CardLayout>
        </div>
      </div>
    </div>
  </template-layout>
</template>

<script setup>
import { reactive, ref, watch, onMounted, computed } from "vue";
import Infomation from "./container/Infomation.vue";
import CardLayout from "@/components/profile/layout/CardLayout.vue";
import LeafletMap from "@/components/leaflet-map/index.vue";
import CustomAccordion from "@/components/base/custom-accordion/index.vue";
import MemberDatatable from "./ProfileDatatable.vue";
import ModalLayout from "@/components/base/modal-layout/ModalLayout.vue";
import TemplateLayout from "@/layouts/side-menu/Main.vue";
import FileInput from "@/components/base/file-input/index.vue";
import PreviewFile from "@/components/base/preview-file/index.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { uploadfile, compressImage } from "@/utils/minio.js";
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission";

//import {download} from "downloadjs";


const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});

const store = useStore();
const route = useRoute();
const router = useRouter()

const role = ref("");
const householdInformation = ref(null);
const householdInformationLoading = ref(true);
const editMap = ref(false);
const dataArray = ref([]);
const latlng = ref({
  lat: null,
  lng: null,
});

const customMenu = ref([])
const editImage = ref(false);
const tempImage = ref([
  {
    image: null
  }
]);
const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const tempLat = ref(13.720564133220474)
const tempLng = ref(100.49031257629396)
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const token = store.getters["userAuth/getToken"];
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": `application/json; charset=utf-8`,
};

const fullScreenMap = ref(false)

const downloadHHJson = async(val) => {

  const token = store.getters["userAuth/getToken"];
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };

  const url = `${process.env.VUE_APP_BASE_API}housesurveyandmember/house/${route.params.hhid}`;  
  
  axios.get(url, { headers }).catch(() =>
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
  ).then(

    response => {
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'hhdata'
        link.click()
        URL.revokeObjectURL(link.href)
    }
    //response => { console.log(response.data);}
  );
  //console.log(res);
  //download(res, `hh_${route.params.hhid}.json`, "application/json");

}


onMounted(() => {
  if(onlineComputed.value)
    getDataHouse();
  else
    getDataHouseOffline()
  // checkRole();
});
const checkRole = () => {
  role.value = store.getters["userAuth/getUserRoleCode"];
};


const handleUpdateLat = (evt) => {
  tempLat.value = evt;
};
const handleUpdateLng = (evt) => {
  tempLng.value = evt;
};
const handleClickEditMap = () => {
    editMap.value = !editMap.value;
};
const handleMapCancelEdit = () => {
  if(householdInformation.value.latitude && householdInformation.value.longitude){
    tempLat.value = householdInformation.value.latitude
    tempLng.value = householdInformation.value.longitude
  }
};
const handleMapSaveEdit = () => {
  updateHouseHold({ latitude: tempLat.value, longitude: tempLng.value})
};
const openEditImageModal = (bool) => {
  if (bool) {
    if (householdInformation.value.house_images.length) {
      let temp = householdInformation.value.house_images.map((item) => {
        item.file_name = item.img_house_url
        item.img_url = process.env.VUE_APP_IMG_API+item.img_house_url
        if (typeof item.image === "undefined")
          return { image: item };
        return item;
      });

      temp = JSON.parse(JSON.stringify(temp));
      tempImage.value = temp;
    } else {
      tempImage.value = [
        {
          image: null
        },
      ];
    }
  }
  editImage.value = bool;
};
const handleImageCancelEdit = () => {};
const handleImageSaveEdit = async () => {
  const result = [];
  let errorState = false;
  for (let index = 0; index < tempImage.value.length; index++) {
    if (tempImage.value[index].image) {
      let value = tempImage.value[index].image;
      errorState = false;
      if (!value) continue;
      if (!(value instanceof File)) {
        const temp = {
          img_house_url: value.img_house_url,
          img_date: value.img_date,
          img_user: value.img_user,
        }
        result.push(temp);
        continue;
      }

      value = await compressImage(value).catch((err) => {
        console.log(err);
        return err;
      });

      const res = await uploadfile(
        value,
        `house-image`
      ).catch(() => {
        errorState = true;
        swalCustom.fire({
          icon: "warning",
          text: `บันทึกไฟล์ไม่สำเร็จ`,
          confirmButtonText: "ปิด",
        });
      });
      if (res.res.status === 200) {
        result.push({
          img_house_url: res.url,
          img_date: new Date().toISOString(),
          img_user: store.getters["userAuth/getUserID"],
        });
      } else {
        errorState = true;
      }
    }
  }
  if (!errorState) {
    if (result.length > 0) updateHouseHold({house_surveys: { image: result, id: route.params.surveyid }});
    else updateHouseHold({house_surveys: { image: null, id: route.params.surveyid }});
  } else {
    swalCustom.fire({
      icon: "warning",
      text: `บันทึกไฟล์ ${value.name} ไม่สำเร็จ`,
      confirmButtonText: "ปิด",
    });
  }
};
const addImageInput = () => {
  tempImage.value.push({
    image: null
  });
};
const handelDeleteImage = (evt, index) => {
  tempImage.value = tempImage.value.filter((item, i) => index != i);
};
// const updateImageLabel = (event, index) => {
//   tempImage.value[index].img_label = event;
// };
const updateHouseHold = async (update) => {
  const url = `${process.env.VUE_APP_BASE_API}house`;
  const res = await axios.put(url, {...update, id: route.params.hhid, update_by: store.getters["userAuth/getUserID"]}, {headers}).catch(() => {
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      //timer: 3000, //timeOut for auto-close
      confirmButtonText: "ปิด",
    });
  });
  if (res.status === 200) {
    swalCustom.fire({
      text: "สำเร็จ",
      icon: "success",
      timer: 3000,
      confirmButtonText: "ปิด",
    }).then(()=>{
      getDataHouse();
    });
  }
};

const getDataHouse = async () => {
  householdInformationLoading.value = true;
  const url = `${process.env.VUE_APP_BASE_API}house/${route.params.hhid}`;
  const res = await axios.get(url, { headers }).catch(() =>
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
  );
  householdInformationLoading.value = false;
  if (res.status === 200) {
    householdInformation.value = res.data;
    householdInformation.value["addressResult"] = ""
    if (res.data.address_num)
      householdInformation.value["addressResult"] += res.data.address_num;
    if (res.data.village_name)
      householdInformation.value["addressResult"] += ` ${res.data.village_name}`;
    if (res.data.soi)
      householdInformation.value["addressResult"] += ` ซอย${res.data.soi}`;
    if (res.data.road)
      householdInformation.value["addressResult"] += ` ถนน${res.data.road}`;
    if (res.data.tumbol_name)
      householdInformation.value["addressResult"] += ` ${
        res.data.province_name !== "กรุงเทพมหานคร"
          ? `ตำบล`
          : `แขวง`
      }${res.data.tambol_name}`;
    if (res.data.amphur_name)
      householdInformation.value["addressResult"] += ` ${
        res.data.province_name !== "กรุงเทพมหานคร"
          ? `อำเภอ`
          : `เขต`
      }${res.data.amphur_name}`;
    if (res.data.province_name)
      householdInformation.value["addressResult"] += ` จังหวัด${res.data.province_name}`;
    if(res.data.postcode)
      householdInformation.value["addressResult"] += ` ${res.data.postcode}`
    if(householdInformation.value.house_surveys?.length > 0){
      let sub_menu = []
      for(let i = householdInformation.value.house_surveys.length; i > 0; i-- ){
        sub_menu.push({
          icon: '',
          pageName: 'household-profile',
          title: `ครั้งที่ ${i}` ,
          params: {
            hhid: householdInformation.value._id,
            surveyid: householdInformation.value.house_surveys[i-1]["_id"]
          },
          path: `/household/${householdInformation.value._id}/${householdInformation.value.house_surveys[i-1]["_id"]}`
        })
      }
      householdInformation.value["nextHouseId"] = householdInformation.value.house_surveys[householdInformation.value.house_surveys.length - 2] ? householdInformation.value.house_surveys[householdInformation.value.house_surveys.length - 2]["_id"] : null
      customMenu.value = [
        {key: "devider"},
        {
          icon: "FileTextIcon",
          title: "แบบสำรวจ",
          subMenu: sub_menu
        }
      ]
      const idx = householdInformation.value.house_surveys.findIndex(x=>x._id == route.params.surveyid)
      householdInformation.value.house_images = householdInformation.value.house_surveys[idx]?.image ?? []
      dataArray.value = householdInformation.value.house_surveys[idx]?.member_surveys ?? []
      if(dataArray.value.length > 0){
        let member_menu = []
        for(let i = 0; i < dataArray.value.length; i++ ){
          member_menu.push({
            pageName: 'view-member',
            title: `${dataArray.value[i].name} ${dataArray.value[i].surname}` ,
            params: {
              hhid: householdInformation.value._id,
              surveyid: householdInformation.value.house_surveys[idx]._id,
              memberid: dataArray.value[i].member_id
            },
            path: `/member/view/${householdInformation.value._id}/${householdInformation.value.house_surveys[idx]._id}/${dataArray.value[i].member_id}`,
            image: dataArray.value[i]?.image?.img_url ? process.env.VUE_APP_IMG_API+dataArray.value[i].image.img_url : "default"
          })
        }
        customMenu.value = [...customMenu.value, {key: "devider"}, {icon: "UsersIcon",title: "สมาชิกในครัวเรือน",subMenu: member_menu}]
      }
      //survey gender
      householdInformation.value.genderObj = {
        male: 0,
        female: 0,
        other: 0
      }
      if(Array.isArray(householdInformation.value.house_surveys[idx]?.gender)){
         householdInformation.value.genderObj = {
          male: householdInformation.value.house_surveys[idx].gender.find(x=>x.gender == "ชาย")?.count ?? 0,
          female: householdInformation.value.house_surveys[idx].gender.find(x=>x.gender == "หญิง")?.count ?? 0,
          other: householdInformation.value.house_surveys[idx].gender.find(x=>x.gender == "อื่นๆ")?.count ?? 0
        }
      }
      //survey age range
      householdInformation.value.ageRange = {
        baby: 0,
        kid: 0,
        teenager: 0,
        adult: 0,
        elder: 0
      }
      if(Array.isArray(householdInformation.value.house_surveys[idx]?.age_range)){
         householdInformation.value.ageRange = {
          baby: householdInformation.value.house_surveys[idx].age_range.find(x=>x.age_range == "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)")?.count ?? 0,
          kid: householdInformation.value.house_surveys[idx].age_range.find(x=>x.age_range == "วัยเรียน (อายุ 5-14 ปี)")?.count ?? 0,
          teenager: householdInformation.value.house_surveys[idx].age_range.find(x=>x.age_range == "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)")?.count ?? 0,
          adult: householdInformation.value.house_surveys[idx].age_range.find(x=>x.age_range == "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)")?.count ?? 0,
          elder: householdInformation.value.house_surveys[idx].age_range.find(x=>x.age_range == "วัยผู้สูงอายุ (60 ปีขึ้นไป)")?.count ?? 0
        }
      }
      householdInformation.value.member_cnt = householdInformation.value.house_surveys[idx].member_cnt ?? 0
      householdInformation.value.member_reg_cnt = householdInformation.value.house_surveys[idx].member_reg_cnt ?? 0
      householdInformation.value.status = householdInformation.value.house_surveys[idx].status ?? 0
      householdInformation.value["mainImage"] = householdInformation.value.house_surveys[idx].headOfHousehole_image ? process.env.VUE_APP_IMG_API+householdInformation.value.house_surveys[idx].headOfHousehole_image.img_url : null
      householdInformation.value["houseHeadName"] = householdInformation.value.house_surveys[idx].headOfHousehole_name ? `${householdInformation.value.house_surveys[idx].headOfHousehole_prefix_name} ${householdInformation.value.house_surveys[idx].headOfHousehole_name} ${householdInformation.value.house_surveys[idx].headOfHousehole_surname}` : null

      if(Array.isArray(householdInformation.value.house_images)){
        householdInformation.value.house_images.map((item) => {
          item.img_url = process.env.VUE_APP_IMG_API+item.img_house_url
          return item;
        });
      }
    }

    if (householdInformation.value.latitude == null) {
      // householdInformation.value.latitude = 14.080147;
      // householdInformation.value.latitude = 13.720564133220474;
    } else {
      householdInformation.value.latitude = res.data.latitude;
      tempLat.value = res.data.latitude;
    }

    if (householdInformation.value.longitude == null) {
      // householdInformation.value.longitude = 100.601207;
      // householdInformation.value.longitude = 100.49031257629396;
    } else {
      householdInformation.value.longitude = res.data.longitude;
      tempLng.value = res.data.longitude;
    }
    /* if(Array.isArray(householdInformation.value.house_surveys.image)){
      householdInformation.value.house_surveys.image.map((item) => {
        item.img_url = process.env.VUE_APP_IMG_API+item.img_house_url
        return item;
      });
    } */
  }
};
const breadcrumbComputed = computed(() => {
  let array = [
    {
      name: "ครัวเรือน",
      path: "main-house-hold",
    },
  ];

  if (householdInformation) {
    array.push({
      name: "ข้อมูลครัวเรือน",
      active: true,
    });
  }
  return array;
});


watch(() =>route.params, (val)=>{
  if(val && route.name == "household-profile"){
    //call api to get data when route route params change
    getDataHouse();
  }
})

const getDataHouseOffline = () => {
  houseStore.getItem(route.params.hhid).then((value)=>{
    householdInformationLoading.value = false;
    householdInformation.value = value
    householdInformation.value.genderObj = {
      male: 0,
      female: 0,
      other: 0
    }
    if(householdInformation.value.house_surveys.members){
      let gender_group_count = [...householdInformation.value.house_surveys.members
        .reduce((r, {gender}) => {
          const item = r.get(gender)
          item ? item.count ++ : r.set(gender, {gender, count: 1})
          return r
        }, new Map)
        .values()
      ]
      if(Array.isArray(gender_group_count)){
          householdInformation.value.genderObj = {
          male: gender_group_count.find(x=>x.gender == "ชาย")?.count ?? 0,
          female: gender_group_count.find(x=>x.gender == "หญิง")?.count ?? 0,
          other: gender_group_count.find(x=>x.gender == "อื่นๆ")?.count ?? 0
        }
      }
    }
    householdInformation.value.ageRange = {
      baby: 0,
      kid: 0,
      teenager: 0,
      adult: 0,
      elder: 0
    }
    if(householdInformation.value.house_surveys.members){
      let age_range_group_count = [...householdInformation.value.house_surveys.members
        .reduce((r, {member_surveys}) => {
          member_surveys.age_range.forEach(age_range=>{
            const item = r.get(age_range)
            item ? item.count++ : r.set(age_range, {age_range, count: 1})
          })
          return r
        }, new Map)
        .values()
      ]
      if(Array.isArray(age_range_group_count)){
         householdInformation.value.ageRange = {
          baby: age_range_group_count.find(x=>x.age_range == "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)")?.count ?? 0,
          kid: age_range_group_count.find(x=>x.age_range == "วัยเรียน (อายุ 5-14 ปี)")?.count ?? 0,
          teenager: age_range_group_count.find(x=>x.age_range == "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)")?.count ?? 0,
          adult: age_range_group_count.find(x=>x.age_range == "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)")?.count ?? 0,
          elder: age_range_group_count.find(x=>x.age_range == "วัยผู้สูงอายุ (60 ปีขึ้นไป)")?.count ?? 0
        }
      }
    }
    householdInformation.value.member_cnt = householdInformation.value.house_surveys.member_cnt ?? 0
    householdInformation.value.member_reg_cnt = householdInformation.value.house_surveys.member_reg_cnt ?? 0
    householdInformation.value.status = householdInformation.value.house_surveys.status ?? 0
    householdInformation.value["addressResult"] = ""
    if (householdInformation.value.address_num)
      householdInformation.value["addressResult"] += householdInformation.value.address_num;
    if (householdInformation.value.village_name)
      householdInformation.value["addressResult"] += ` ${householdInformation.value.village_name}`;
    if (householdInformation.value.tambol_name)
      householdInformation.value["addressResult"] += ` ${
        householdInformation.value.province_name !== "กรุงเทพมหานคร"
          ? `ตำบล`
          : `แขวง`
      }${householdInformation.value.tambol_name}`;
    if (householdInformation.value.amphur_name)
      householdInformation.value["addressResult"] += ` ${
        householdInformation.value.province_name !== "กรุงเทพมหานคร"
          ? `อำเภอ`
          : `เขต`
      }${householdInformation.value.amphur_name}`;
    if (householdInformation.value.province_name)
      householdInformation.value["addressResult"] += ` จังหวัด${householdInformation.value.province_name}`;
    let memberList = householdInformation.value.house_surveys.members ?? []
    memberList = memberList.map(elm=>{
      elm.relation = elm.member_surveys.relation
      elm.age_range = elm.member_surveys.age_range
      elm.NEET = elm.member_surveys?.NEET
      elm.CWD = elm.member_surveys?.CWD
      elm.status = elm.member_surveys.status
      return elm
    })
    if(memberList.length){
      let headData = memberList.find(elm => elm.member_surveys.relation.code == 1)
      if(headData){
        householdInformation.value["houseHeadName"] = headData.prefix_name ? `${headData.prefix_name} ${headData.name} ${headData.surname}` : null
      }
    }
    dataArray.value = memberList
  })
}
</script>

<style lang="scss" scoped>
.map-height {
  height: 100%;
}
.map-modal-height {
  height: 60vh;
  min-height: 400px;
}
.edit-famtree-height {
  height: 95%;
}
.family-modal-height {
  height: 70vh;
  @media screen and (max-width: 600px) {
    height: 60vh;
  }
}
</style>
