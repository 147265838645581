<template>
  <div class="overflow-x-auto scrollbar-hidden">
    <DatatableProfile
      :data-array="dataOfflineComputed"
      :data-array-table="dataArrayTable"
      :key="key"
      @row-clicked="clickEvent"
    />
  </div>
</template>

<script setup>
import { reactive, onMounted, watch, computed, ref } from 'vue'
import DatatableProfile from "@/components/datatable/datatableProfile.vue"
import { useRouter } from 'vue-router'

const props = defineProps({
  dataArray:{
    type: Array
  }
})
const router = useRouter()


let customNameMutator = (value, data, type, params, component) => {
  if(data.house_surveys?.members){
    let headData = data.house_surveys.members.find(elm => elm.member_surveys.relation.code == 1)
    if(headData){
      return headData.prefix_name ? `${headData.prefix_name} ${headData.name} ${headData.surname}` : "ไม่มีหัวหน้าครัวเรือน"
    }
  }
  return "ไม่มีหัวหน้าครัวเรือน"
}
let customAddressMutator = (value, data, type, params, component) => {
  return `${data.address_num ? data.address_num:""} ${data.village_name?data.village_name: ""} ${data.soi?"ซอย"+data.soi:""} ${data.road?"ถนน"+data.road:""} ${data.tambol_name?data.tambol_name: ""} ${data.amphur_name?data.amphur_name: ""} ${data.province_name?data.province_name: ''} ${data.postcode?data.postcode:""}`
}
let customProblemMutator = (value, data, type, params, component) => {
  return {
    "health": false,
    "housing": false,
    "education": false,
    "occupation": false,
    "accessibility": false
  }
}

const key = ref(0)
const listData = ref([])
watch(()=>props.dataArray, ()=>{
  key.value++
  
})
const dataOfflineComputed = computed(()=>{
    return props.dataArray
  })

const dataArrayTable = reactive([
  {title:"หัวหน้าครัวเรือน", field:"full_name", resizable:true, formatter:"textarea", minWidth: 200, width: 220, mutator: customNameMutator},
  {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300, width: 300, mutator: customAddressMutator},
  {title:"ปัญหา 5 มิติ", field:"problem", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, resizable:true, mutator: customProblemMutator,
    formatter:function(cell, formatterParams){
      var value = cell.getValue();
      if(value){
          let temp = ""
          for(const [key, val] of Object.entries(value)){
              let icon = "", text = ""
              if(key === "housing"){
                  icon = "home-icon"
                  text = "ครอบครัว"
              }
              if(key === "health"){
                  icon = "heart-icon"
                  text = "สุขภาพอนามัย"
              }
              if(key === "occupation"){
                  icon = "dollar-sign-icon"
                  text = "การมีงานทำและมีรายได้"
              }
              if(key === "education"){
                  icon = "book-open-icon"
                  text = "การศึกษา"
              }
              if(key === "accessibility"){
                  icon = "card-icon"
                  text = "การเข้าไม่ถึงสิทธิและความเป็นธรรมในสังคม"
              }
              temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-primary-2 hover:bg-primary-2':'bg-theme-37 hover:bg-theme-37'}'>\
                          <span class="t-tooltip-text">${text}</span>\
                          <span class="img-icon ${icon}"></span>\
                      </button>`
          }
          let res = `<div class="flex flex-wrap justify-center">${temp}</div>`
          return res
      }
      return null
    }
  },
  {title:"จำนวนสมาชิกในครัวเรือน", field:"house_surveys", hozAlign:"center", resizable:true, minWidth: 195, width: 220, formatter:function(cell, formatterParams){
    return cell.getValue().member_cnt ? cell.getValue().member_cnt : 0
  }},
  {title:"จำนวนสมาชิกตามทะเบียนบ้าน", field:"house_surveys", hozAlign:"center", resizable:true, minWidth: 220, width: 240, formatter:function(cell, formatterParams){
    return cell.getValue().member_reg_cnt ? cell.getValue().member_reg_cnt : 0
  }},
  {title:"จำนวนครั้งที่สำรวจ", field:"house_surveys_count", hozAlign:"center", resizable:true, minWidth: 175, width: 200,
  formatter:function(cell, formatterParams){
    return 1
  }},
])

const clickEvent = (evt) =>{
  router.push({
    name: 'household-profile',
    params: {
      hhid: evt.id,
      surveyid: evt.house_surveys.id
    }
  })
}

</script>

