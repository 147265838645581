<template>
    <div role="alert" class="relative rounded-md px-5 py-2 flex items-center mb-6 justify-between" :class="alertTheme">
        <span class="flex items-center h-[30px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-1.5 w-6 h-6 mr-2"><path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
            {{title}}
        </span>
        <slot name="content-end"/>
    </div>
</template>

<script setup>
import { computed,ref } from "vue"

const props = defineProps({
    title:{
        type: String,
        required: true
    },
    themeColor:{
        type: String,
        default: 'dark'
    }
})

const color = {
    dark: 'bg-[#b3bfd8] text-[#000]',
    primary: 'bg-[#b4c3e7] text-[#1f3a8b]',
    warning: 'bg-[#f7c2008f] text-dark'
}

const alertTheme = computed(()=> color[props.themeColor])

</script>

<style>

</style>