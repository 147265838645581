<template>
  <div class="column h-full slide-image content-center overflow-hidden relative">
    <!-- BEGIN: Modal Content -->
    <div :id="id" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="flex flex-col justify-center items-center">
              <img
                class="object-center object-contain image-modal-preview-width-heigh w-full"
                :title="labelComputed"
                :src="previewFile"
              />
              <p class="text-2xl my-2" v-if="labelComputed">
                {{ labelComputed }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <label class="content-center text-center cursor-pointer h-full" @click="showModal">
      <div v-if="previewFile" class="h-full">
        <img
          class="object-center object-cover h-full w-full"
          :title="labelComputed"
          :src="previewFile"
        />
      </div>
    </label>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "previewImageModal",
  },
  value: Object,
  type: {
    type: String,
    default: "image",
  },
});

const modal = ref(false);
const previewFile = computed(() => {
  if (props.type === "consent") {
    return require("@/assets/images/consent-image.png");
  }
  return props.value.url ? props.value.url : props.value.img_url;
});

const labelComputed = computed(() => {
  if (props.value.img_label) return props.value.img_label;
  if (props.value.file_label) return props.value.file_label;
  return "";
});

// Show modal
const showModal = () => {
  cash(`#${props.id}`).modal("show");
};

// Hide modal
const hideModal = () => {
  cash(`#${props.id}`).modal("hide");
};
</script>

<style lang="scss" scoped>
.slide-image {
  cursor: pointer;
  // height: 100px;
  // width: 110px;
  background-color: rgb(237, 241, 245);
  border: 3px solid #7988bf;
  border-radius: 15px;
}
.image-modal-preview-width-heigh {
  min-height: 400px;
  min-width: 350px;
  max-width: 800px;
  max-height: 600px;
}
</style>
