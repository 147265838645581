<template>
  <fieldset class="flex flex-row items-center">
    <!-- <span class="mr-2">{{desc}}</span> -->
    <input
    class="form form-control flex-grow-0 w-auto mr-2"
    type="text"
    v-model="labelComputed"
    :disabled="disabledLabel"
    v-if="showLabel"
    />
    <input
      type="file"
      :id="id"
      class="hidden"
      @change="handelChange"
      accept="image/*,application/pdf"
    />
    <label :for="id" class="flex-grow mr-4">
      <div
        class="flex flex-row cursor-pointer rounded border border-gray-500"
        v-if="!isEmptyModelValue"
      >
        <div class="p-2 bg-gray-400 text-theme-38 font-bold rounded-l">
          เลือก
        </div>
        <div class="p-2 bg-gray-300 text-theme-38 rounded-r w-full">
          ไม่ได้เลือกไฟล์
        </div>
      </div>
      <div
        class="flex flex-row cursor-pointer rounded border border-gray-500"
        v-else
      >
        <div class="p-2 bg-gray-300 text-theme-38 rounded-r w-full">
          {{ fileName }}
        </div>
      </div>
    </label>
    <button v-if="!hideDeleteButton" class="btn text-white bg-theme-24 border-theme-24" @click="clickDelete"><Trash2Icon class="h-4 w-4" /></button>
  </fieldset>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  modelValue: { type: [Object, null], default: null },
  id: {
    type: String,
    default: "inputFile",
  },
  label: {
      type: String,
      default: ''
  },
  disabledLabel: {
      type: Boolean,
      default: false,
  },
  showLabel: {
      type: Boolean,
      default: false
  },
  hideDeleteButton: {
      type: Boolean,
      default: false
  }
});
const emits = defineEmits(["update:modelValue", "click:delete", "update:label"]);

const isEmptyModelValue = computed(() => {
  return !!props.modelValue;
});

const fileName = computed(() =>
  props.modelValue.name ? props.modelValue.name : props.modelValue.file_name
);

const labelComputed = computed({
    get(){
        return props.label
    },
    set(val){
        emits('update:label', val)
    }
})

const handelChange = (evt) => {
  emits("update:modelValue", evt.target.files[0]);
};
const clickDelete = () => {
  emits("click:delete");
};
</script>
