<template>
  <BarChart
    type="bar"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
    :plugins="[ChartDataLabels]"
  />
</template>

<script setup>
import { computed } from 'vue'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const props = defineProps({
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  chartLabel:{
    type: Array,
    default: ()=>[]
  },
  series:{
    type: Array,
    default: ()=> []
  },
})
  const data = computed(() => {
    return {
      labels: props.chartLabel,
      datasets: props.series
    }
  })
    const options = computed(() => {
      return {
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          }
        },
        plugins: {
          //custom tooltip for stacked100 replaceTooltipLabel: false
          // tooltip: {
          //   callbacks: {
          //     label: (tooltipItem) => {
          //       const data = tooltipItem.chart.data;
          //       const datasetIndex = tooltipItem.datasetIndex;
          //       const index = tooltipItem.dataIndex;
          //       const datasetLabel = data.datasets[datasetIndex].label || "";
          //       // You can use two type values.
          //       // `data.originalData` is raw values,
          //       // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
          //       const originalValue = data.originalData[datasetIndex][index];
          //       const rateValue = data.calculatedData[datasetIndex][index];
          //       return `${datasetLabel}: ${rateValue}% (${originalValue})`;
          //     },
          //   },
          // },
          legend: {
            labels: {
              fontSize: 14,
              boxWidth: 6
            },
            display: true,
            position: "bottom",
            align: "center"
          },
          stacked100:{
            enable: true,
            // replaceTooltipLabel: false
          },
          datalabels:{
            // anchor: 'end',
            // align: 'end',
            color: '#FFF',
            font: {
              size: 16,
            },
            formatter: (value, context)=>{
              return `${context.chart.data.calculatedData[context.datasetIndex][context.dataIndex]}%`
            }
          }
        },
        responsive:true,
      }
    })

   
</script>
