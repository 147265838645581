<template>
  <div class="overflow-x-auto scrollbar-hidden member-datatable" data-test="member-datatable">
      <Datatable :api-params="paramsComputed" :api-url="'member'" :method-request="'GET'" :init-sorter="[{field: 'UPDATE_DATETIME', dir: 'desc'}]" :data-array="dataArray" :data-array-table="dataArrayTable" :key="key" :downloadFile="downloadFile" @getDataComplete="exportFile" @rowClicked="handleRowClick" question-group="member" />
  </div>
</template>

<script setup>
import Datatable from "@/components/datatable/index.vue"
import { reactive,ref, watch, computed } from "vue"
import { utils, writeFile } from "xlsx"
import Swal from 'sweetalert2'
import { useRouter } from "vue-router";
import { getPermissionAccessMenu } from "@/utils/getPermission"
import { useStore } from "vuex";
// import axios from "axios"
const props = defineProps({
    filter:{
        type: Object
    },
    downloadCsv:{
        type: String
    },
    downloadExcel: {
        type: String
    }
})
const emits = defineEmits(["downloadCsvComplete", "downloadExcelComplete"])
const dataArray = ref()
const params = ref({start: 1,draw: 1,length: 10})
const key = ref(1)
const downloadFile = ref(false)
const downloadFormat = ref(null)
const currentFileType = ref("")
const router = useRouter()
const store = useStore()
const onlineComputed = computed(()=> store.getters["network/isOnline"])
watch(()=>props.filter, ()=>{
    key.value++
})
const paramsComputed = computed(() => ({...params.value, ...props.filter}))
var customAgeRange = (value, data, type, params, component) => {
    return {
        "baby": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)") : false,
        "kid": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยเรียน (อายุ 5-14 ปี)") : false,
        "teenager": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)") : false,
        "adult": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)") : false,
        "elder": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้สูงอายุ (60 ปีขึ้นไป)") : false
    }
}
const dataArrayTable = reactive([
    {title:"ชื่อ-นามสกุล", field:"full_name", minWidth: 200, resizable:true, formatter:"textarea"},
    {title:"เลขบัตรประจำตัวประชาชน", field:"NID", hozAlign:"left",minWidth: 200, width: 230, resizable:true, formatter:function(cell, formatterParams){
        return cell.getValue() ?? 'ไม่มีบัตรประจำตัวประชาชน'
    }},
    {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300},
    {title:"ช่วงวัย", field:"age_range", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, mutator: customAgeRange,
        formatter:function(cell, formatterParams){
            var value = cell.getValue();
            if(value){
                let temp = ""
                for(const [key, val] of Object.entries(value)){
                    let icon = "", text = ""
                    if(key === "baby"){
                        icon = "baby-icon"
                        text = "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)"
                    }
                    if(key === "kid"){
                        icon = "kid-icon"
                        text = "วัยเรียน (อายุ 5-14 ปี)"
                    }
                    if(key === "teenager"){
                        icon = "teenager-icon"
                        text = "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)"
                    }
                    if(key === "adult"){
                        icon = "adult-icon"
                        text = "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)"
                    }
                    if(key === "elder"){
                        icon = "elder-icon"
                        text = "วัยผู้สูงอายุ (60 ปีขึ้นไป)"
                    }
                    temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                                <span class="t-tooltip-text">${text}</span>\
                                <span class="img-icon ${icon}"></span>\
                            </button>`
                }
                let res = `<div class="flex flex-wrap justify-start lg:justify-center">${temp}</div>`
                return res
            }
            return null
        }
    },
    {title:"วันที่ปรับปรุงข้อมูลล่าสุด", field:"UPDATE_DATETIME", hozAlign:"center", minWidth: 200, width: 220, variableHeight:true,
        formatter:function(cell, formatterParams){
            if(cell.getValue()){
                let date = cell.getValue().split`T`[0]
                date = new Date(date)
                return [String(date.getDate()).padStart(2, '0'),
                        String(date.getMonth() + 1).padStart(2, '0'),
                  date.getFullYear() + 543
                ].join('/');
            }
            return null
        }
    },
    {title:"สถานะ", field:"member_surveys.status", hozAlign:"center", resizable:true, minWidth: 130, width: 150, formatter:"textarea", formatter:function(cell, formatterParams){
    return cell.getValue() == "DRAFT" ? "ร่าง" : cell.getValue() == "COMPLETE" ? "สำเร็จ" : null
  }},
])
const handleRowClick = (row) =>{
    if(getPermissionAccessMenu("read") || !onlineComputed.value){
        router.push({
            name: 'view-member',
            params: {
                hhid: row.house_id,
                surveyid: row.house_surveys_id,
                memberid: row._id
            },
        })
    }
}


watch(()=> props.downloadCsv, (val)=>{
    if(val){
        downloadFile.value = props.downloadCsv
        currentFileType.value = "csv"
        downloadFormat.value = val
    }
})

watch(()=> props.downloadExcel, (val)=>{
    if(val){
        downloadFile.value = props.downloadExcel
        currentFileType.value = "xlsx"
        downloadFormat.value = val
    }
})

const exportFile = (evt, questions) => {
    if(evt.status === 200){
        let columns_header = ["HH001","IN001","IN003"]
        columns_header = [...columns_header, ...questions.map(q => {
            if(q.question_code == "prefix_name")
                return ["IN002"]
            if(q.question_code == "name")
                return ["IN004"]
            if(q.question_code == "gender")
                return ["IN005", "IN005-1"]
            if(q.question_code == "relation")
                return ["IN006"]
            if(q.question_code == "birthdate")
                return ["IN007"]
            if(q.question_code == "age")
                return ["IN008"]
            if(q.question_code == "marital_status")
                return ["IN009", "IN009-1"]
            if(q.question_code == "religion")
                return ["IN010", "IN010-1"]
            if(q.question_code == "mobile")
                return ["IN011"]
            if(q.question_type == "checkbox" && q.is_checkbox_group){
                const res = q.choices.map(c => {
                    if(c?.specify_value?.length > 1 && !c.is_multiple_answer){
                        let tempCh = []
                        for(let i = 1 ; i <= c.specify_value.length; i++){
                            tempCh.push(`${c.option.code}-${i}`)
                        }
                        return tempCh
                    }
                    return c.option.code
                }).flat()
                return res
            }
            if(q.question_type == "checkbox" && q.choices.some(c => c.option.hasOwnProperty("order"))){
                let haveSpecify = q.choices.find(c => c.specify_value)
                let temp = []
                for(let i = 1; i<=q.answer_limit; i++){
                    temp.push(`${q.question_code}-${i}`)
                    if(haveSpecify){
                        for(let j = 1 ; j <= haveSpecify.specify_value.length; j++){
                            temp.push(`${q.question_code}-${i}-${j}`)
                        }
                    }
                }
                return temp
            }
            if((q.question_type == "radio" || q.question_type == "checkbox") && q.choices.some(c => c.specify_value)){
                let specify = q.choices.find(c=>c.specify_value)
                let tempCode = []
                if(specify){
                    for(let i = 1 ; i <= specify.specify_value.length; i++){
                        tempCode.push(`${q.question_code}-${i}`)
                    }
                }
                return [q.question_code,...tempCode]
            }
            if(q.question_type == "multiple-inputs-answer"){
                if(q.choices.length > 1){
                    let tempCh = []
                    for(let i = 1 ; i <= q.choices.length; i++){
                        tempCh.push(`${q.question_code}-${i}`)
                    }
                    return tempCh
                }
                return [q.question_code]
            }
            return [q.question_code]
        }).flat()
        ]
        columns_header = columns_header.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
        )
        columns_header.unshift("province_name", "amphur_name", "tambol_name", "village_name", "age_year", "age_month")
        columns_header.push("survey_status", "survey_start_date", "survey_end_date", "survey_start_time", "survey_end_time", "member_id", "house_id")
        let newArr = evt.data.map((item)=>{
            let newItem = {}
            for(let col of columns_header){
                if(item[col])
                    newItem[col] = item[col]
                else
                    newItem[col] = null
            }
            //sort column
            if(item.house_number_ID)
                newItem["HH001"] = item.house_number_ID
            // if(item.prefix_name){
            //     newItem["IN002"] = item.prefix_name
            // }
            // if(item.NID || item.IDNumber){
            //     newItem["IN003"] = item.NID ? item.NID : item.IDNumber
            // }
            // if(item.name && item.surname){
            //     newItem["IN004"] = item.name+' '+item.surname
            // }
            if(item.gender){
                newItem["IN005"] = item.gender
            }
            if(item.gender_other){
                newItem["IN005-1"] = item.gender_other
            }
            // if(item.birthdate){
            //     newItem["IN007"] = item.birthdate
            // }
            // if(item.age_year || item.age_year == 0){
            //     newItem["IN008"] = item.age_year+" ปี "+ item.age_month+ " เดือน"
            // }
            if(item.marital_status){
                newItem["IN009"] = item.marital_status
            }
            if(item.marital_status_other){
                newItem["IN009-1"] = item.marital_status_other
            }
            if(item.religion){
                newItem["IN010"] = item.religion
            }
            if(item.religion_other){
                newItem["IN010-1"] = item.religion_other
            }
            // if(item.mobile){
            //     newItem["IN011"] = item.mobile
            // }
            if(item.member_surveys){
                const keys = Object.keys(item.member_surveys)
                if(item.member_surveys.age_year || item.member_surveys.age_year == 0)
                    newItem["IN008"] = item.member_surveys.age_year+" ปี "+ item.member_surveys.age_month+ " เดือน"
                if(item.member_surveys.relation){
                    if(downloadFormat.value == "desc"){
                        newItem["IN006"] = item.member_surveys.relation.desc
                    }
                    else{
                        newItem["IN006"] = item.member_surveys.relation.code
                    }
                }
                if(item.member_surveys.status)
                    newItem["survey_status"] = item.member_surveys.status
                if(item.member_surveys.survey_start_date)
                    newItem["survey_start_date"] = item.member_surveys.survey_start_date
                if(item.member_surveys.survey_end_date)
                    newItem["survey_end_date"] = item.member_surveys.survey_end_date
                keys.forEach((elm)=>{
                    if(item.member_surveys[elm] || item.member_surveys[elm] == 0){
                        if(typeof item.member_surveys[elm] === "object"){
                            let tempObj = questions.find(({question_code}) => elm === question_code)
                            if(Array.isArray(item.member_surveys[elm]) && tempObj){
                                if(questions.some(q=>q.question_code == elm && q.is_checkbox_group)){
                                    let temp = []
                                    item.member_surveys[elm].forEach((it)=>{
                                        const choice = tempObj.choices.find(x => x.option.code === it.code)
                                        let tempDesc = ""
                                        if(choice?.is_multiple_answer){
                                            if(choice?.specify_value && it?.answers){
                                                let ans_idx = 0
                                                for(let val of it.answers){
                                                    let i = 0
                                                    let ansDesc = ""
                                                    for(let ch of choice.specify_value){
                                                        if(ch.key == "other"){
                                                            ansDesc += `${val[ch.key]} `
                                                        }
                                                        else{
                                                            if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                                            }
                                                        }
                                                        i++
                                                    }

                                                    tempDesc += `${ansDesc}${ans_idx == it.answers.length - 1 ? '' : ' , '}`
                                                    ans_idx++
                                                }
                                                temp.push({code: it.code, value: tempDesc})
                                            }
                                        }
                                        else{
                                            if(choice?.specify_value){
                                                let i = 0
                                                let codeNum = null
                                                for(let ch of choice.specify_value){
                                                    if(choice.specify_value.length == 1){
                                                        codeNum = it.code
                                                    }
                                                    else{
                                                        codeNum = `${it.code}-${i+1}`
                                                    }
                                                    if(it[ch.key] || it[ch.key] == 0){
                                                        temp.push({code: codeNum, value: it[ch.key]})
                                                    }
                                                    i++
                                                }
                                            }
                                        }

                                    })
                                    const newData = temp.reduce((a,v)=> ({...a, [v.code]: v.value}),{})
                                    return newItem = {...newItem, ...newData}
                                }
                                else{
                                    if(item.member_surveys[elm].some(x => x.order)){
                                        const res = item.member_surveys[elm].reduce((temp,v, idx)=> {
                                            const choice = tempObj.choices.find(x => x.option.code === v.code)
                                            let tempDesc = ""
                                            let tempAnswerObj = {}
                                            if(downloadFormat.value == "desc"){
                                                tempDesc = choice ? choice.option.desc : v.desc
                                            }
                                            else{
                                                tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                                            }
                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                                            if(choice?.is_multiple_answer){
                                                if(choice?.specify_value && v?.answers){
                                                    let ans_idx = 0
                                                    for(let val of v.answers){
                                                        let i = 0
                                                        let ansDesc = ""
                                                        tempDesc += (ans_idx == 0) ? ` (` : ''
                                                        for(let ch of choice.specify_value){
                                                            if(ch.key == "other"){
                                                                ansDesc += ` ${val[ch.key]} `
                                                            }
                                                            else if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' / '}`
                                                            }
                                                            i++
                                                        }

                                                        tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                                                        ans_idx++
                                                    }
                                                    tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                                                }
                                            }
                                            else{
                                                if(choice?.specify_value){
                                                    let i = 0
                                                    for(let ch of choice.specify_value){
                                                        i++
                                                        if(v[ch.key] || v[ch.key] == 0){
                                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}-${i}`]: v[ch.key]}
                                                        }
                                                    }
                                                }
                                            }
                                            return {...temp, ...tempAnswerObj}
                                        },{})
                                        return newItem = {...newItem,...res}
                                    }
                                    else{
                                        let tempAnswerObj = {}
                                        const res = item.member_surveys[elm].reduce((prevVal,v,idx)=>{
                                            const choice = tempObj.choices.find(x => x.option.code === v.code)
                                            let tempDesc = ""
                                            if(downloadFormat.value == "desc"){
                                                tempDesc = choice ? choice.option.desc : v.desc
                                            }
                                            else{
                                                tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                                            }
                                            if(choice?.is_multiple_answer){
                                                if(choice?.specify_value && v?.answers){
                                                    let ans_idx = 0
                                                    for(let val of v.answers){
                                                        let i = 0
                                                        let ansDesc = ""
                                                        tempDesc += (ans_idx == 0) ? ` (` : ''
                                                        for(let ch of choice.specify_value){
                                                            if(ch.key == "other"){
                                                                ansDesc += ` ${val[ch.key]} `
                                                            }
                                                            else if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' / '}`
                                                            }
                                                            i++
                                                        }

                                                        tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                                                        ans_idx++
                                                    }
                                                }
                                            }
                                            else{
                                                if(choice?.specify_value){
                                                    let i = 0
                                                    for(let ch of choice.specify_value){
                                                        i++
                                                        if(choice.specify_value.some(sp => sp.key == "other")){
                                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: v[ch.key]}
                                                        }
                                                        else if(v[ch.key] || v[ch.key] == 0){
                                                            tempDesc += (i == 1) ? ` (` : ''
                                                            tempDesc += `${v[ch.key]}${i == choice.specify_value.length ? '' : ', '}`
                                                            tempDesc += (i == choice.specify_value.length) ? `)` : ''
                                                        }
                                                    }
                                                }
                                            }

                                            return idx == 0 ? tempDesc : prevVal + ', ' + tempDesc
                                        }, '')
                                        newItem = {...newItem, ...{[elm]: res, ...tempAnswerObj}}
                                        return newItem
                                    }
                                }
                            }
                            else{
                                if(item.member_surveys[elm]?.code && tempObj.question_type != "relation"){
                                    const choice = tempObj.choices.find(x => x.option.code === item.member_surveys[elm].code)
                                    let tempDesc = ""
                                    let tempAnswerObj = {}
                                    if(downloadFormat.value == "desc"){
                                        tempDesc = choice ? choice.option.desc : item.member_surveys[elm].desc
                                    }
                                    else{
                                        tempDesc = choice ? choice.option.code.split('-')?.[1] ? choice.option.code.split('-')[1] : choice.option.code.split('-')[0]  : item.member_surveys[elm].code.split('-')?.[1] ? item.member_surveys[elm].code.split('-')[1] : item.member_surveys[elm].code.split('-')[0]
                                    }
                                    tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}
                                    if(choice?.is_multiple_answer){
                                        if(choice?.specify_value && item.member_surveys[elm]?.answers){
                                            let ans_idx = 0
                                            for(let val of item.member_surveys[elm].answers){
                                                let i = 0
                                                let ansDesc = ""
                                                tempDesc += (ans_idx == 0) ? ` (` : ''
                                                for(let ch of choice.specify_value){
                                                    if(ch.key == "other"){
                                                        ansDesc += ` ${val[ch.key]} `
                                                    }
                                                    else{
                                                        if(val[ch.key] || val[ch.key] == 0){
                                                            ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' / '}`
                                                        }
                                                    }
                                                    i++
                                                }

                                                tempDesc += `${ansDesc}${ans_idx == item.member_surveys[elm].answers.length - 1 ? ')' : ' , '}`
                                                ans_idx++
                                            }
                                            tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}
                                        }
                                    }
                                    else{
                                        if(choice?.specify_value){
                                            let i = 0
                                            for(let ch of choice.specify_value){
                                                i++
                                                if(item.member_surveys[elm][ch.key] || item.member_surveys[elm][ch.key] == 0){
                                                    tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: item.member_surveys[elm][ch.key]}
                                                }
                                            }
                                        }
                                    }
                                    return newItem = {...newItem, ...tempAnswerObj}
                                }
                                else if(tempObj?.question_type == "multiple-inputs-answer"){//object answer has no desc only this type
                                    const choice = tempObj.choices
                                    let i = 0
                                    let tempInp = {}
                                    for(let ch of choice){
                                        let codeNum = null
                                        if(choice.length == 1)
                                            codeNum = elm
                                        else
                                            codeNum = `${elm}-${i+1}`
                                        if(item.member_surveys[elm][ch.key] || item.member_surveys[elm][ch.key] == 0){
                                            tempInp = {...tempInp, [codeNum]: item.member_surveys[elm][ch.key]}
                                        }
                                        i++
                                    }
                                    return newItem = {...newItem, ...tempInp}
                                }
                            }
                        }
                        return newItem[elm] = item.member_surveys[elm]
                    }
                })
            }
            return newItem
        })
        newArr = newArr.map(({status, create_by, update_by, age_range, survey_date, NEET, CWD, CREATE_DATETIME, UPDATE_DATETIME, month, relation, surname, _id, ...rest}) => ({...rest}));
        const ws = utils.json_to_sheet(newArr)
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        let date = new Date()
        let current_date = [date.getFullYear(),String(date.getMonth() + 1).padStart(2, '0'),String(date.getDate()).padStart(2, '0'),String(date.getHours()).padStart(2, '0'),String(date.getMinutes()).padStart(2, '0'),String(date.getSeconds()).padStart(2, '0')].join('')
        let file_name = `${props.filter?.village_ID ? props.filter.village_ID : props.filter?.tambol_ID ? props.filter.tambol_ID : props.filter?.amphur_ID ? props.filter.amphur_ID : props.filter?.province_ID ? props.filter.province_ID : "" }_สมาชิก_${downloadFormat.value == "code" ? 'รหัส' : 'คำอธิบาย'}_${current_date}`
        //for csv
        if(currentFileType.value == "csv"){
            writeFile(wb, `${file_name}.csv`);
        }
        //for excel
        if(currentFileType.value == "xlsx"){
            writeFile(wb, `${file_name}.xlsx`);
        }
    }else{
        Swal.fire({
        icon: "warning",
        text: "ระบบผิดพลาด",
        })
    }
    downloadFile.value = false
    emits("downloadCsvComplete")
    emits("downloadExcelComplete")
}

</script>

<style lang="scss">
.member-datatable {

}
</style>