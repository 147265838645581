<template>
  <div class="overflow-x-auto scrollbar-hidden member-datatable">
    <DatatableProfile
      :data-array="dataOfflineComputed"
      :data-array-table="dataArrayTable"
      :key="key"
      @row-clicked="clickEvent"
    />
  </div>
</template>

<script setup>
import { reactive, onMounted, watch, computed, ref } from 'vue'
import DatatableProfile from "@/components/datatable/datatableProfile.vue"
import { useRouter } from 'vue-router'

const props = defineProps({
  dataArray:{
    type: Array
  }
})
const router = useRouter()
const key = ref(0)
const listData = ref([])
watch(()=>props.dataArray, ()=>{
  key.value++
})
const dataOfflineComputed = computed(()=>{
  return props.dataArray
})

var customAgeRange = (value, data, type, params, component) => {
  return {
    "baby": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)") : false,
    "kid": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยเรียน (อายุ 5-14 ปี)") : false,
    "teenager": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)") : false,
    "adult": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)") : false,
    "elder": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้สูงอายุ (60 ปีขึ้นไป)") : false
  }
}
const dataArrayTable = reactive([
    {title:"ชื่อ-นามสกุล", field:"full_name", minWidth: 200, resizable:true, formatter:"textarea"},
    {title:"เลขบัตรประจำตัวประชาชน", field:"NID", hozAlign:"left",minWidth: 200, width: 230, resizable:true, formatter:function(cell, formatterParams){
        return cell.getValue() ?? 'ไม่มีบัตรประจำตัวประชาชน'
    }},
    {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300},
    {title:"ช่วงวัย", field:"age_range", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, mutator: customAgeRange,
        formatter:function(cell, formatterParams){
            var value = cell.getValue();
            if(value){
                let temp = ""
                for(const [key, val] of Object.entries(value)){
                    let icon = "", text = ""
                    if(key === "baby"){
                        icon = "baby-icon"
                        text = "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)"
                    }
                    if(key === "kid"){
                        icon = "kid-icon"
                        text = "วัยเรียน (อายุ 5-14 ปี)"
                    }
                    if(key === "teenager"){
                        icon = "teenager-icon"
                        text = "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)"
                    }
                    if(key === "adult"){
                        icon = "adult-icon"
                        text = "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)"
                    }
                    if(key === "elder"){
                        icon = "elder-icon"
                        text = "วัยผู้สูงอายุ (60 ปีขึ้นไป)"
                    }
                    temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                                <span class="t-tooltip-text">${text}</span>\
                                <span class="img-icon ${icon}"></span>\
                            </button>`
                }
                let res = `<div class="flex flex-wrap justify-start lg:justify-center">${temp}</div>`
                return res
            }
            return null
        }
    },
])

const clickEvent = (evt) =>{
  router.push({
    name: 'view-member',
    params: {
        hhid: evt.house_id,
        surveyid: evt.house_surveys_id,
        memberid: evt.member_id
    },
  })
}

</script>

