<template>
  <div
    id="tabulator"
    ref="tableRef"
    class="mt-5 table-report table-report--tabulator"
    :data-test="`${tableName}-data-table`"
  />
</template>

<script setup>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
// import axios from "axios";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";



const props = defineProps({
  dataArrayTable: {
    type: Array,
  },
  paginationSize: {
    type: Number,
    default: 10,
  },
  paginationSizeArray: {
    type: Array,
    default: () => [10, 20, 50, 100],
  },
  dataArray: {
    type: Array,
  },
  apiUrl: {
    type: String,
  },
  tableName: {
    type: String,
    default: "init"
  },
  routerName:{
    type: String
  },
  apiParams:{
    type: Object
  },
  totalList:{
    type: Number
  },
  methodRequest:{
    type: String
  }
})
const emits = defineEmits(["rowClicked"])
const tableRef = ref();
const tabulator = ref();
const router = useRouter()
const route = useRoute()
const initTabulator = () => {
  tabulator.value = new Tabulator(tableRef.value, {
    locale: "th-th",
    langs: {
      "th-th": {
        pagination: {
          page_size: "แสดง",
        },
      },
    },
    data: props.dataArray,
    rowClick: function(e, row){
      emits("rowClicked", row._row.data)
    },
    pageLoaded: function(e, row){
      if(window.innerWidth > 1005){
        document.querySelectorAll(".tabulator-col").forEach((col)=>{
          if(col.style.display == 'none'){
            col.style.display = 'inline-block' 
            col.setAttribute("hide","true")
          }
          //check cell field name equal to header column and set width
          document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
            if(cell.getAttribute('tabulator-field') == col.getAttribute('tabulator-field')){
              cell.style.width = cell.style.width ? cell.style.width : cell.style.minWidth
            }
          })
        })
        document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
          if(cell.style.display == 'none')
            cell.style.display = 'inline-flex'
        })
      }
    },
    layout:"fitColumns",
    responsiveLayout:'collapse',
    pagination: 'local',
    headerSort:true,
    paginationSize: props.paginationSize,
    paginationSizeSelector: props.paginationSizeArray,
    cellVertAlign:"middle",
    placeholder:"ไม่มีข้อมูล",
    footerElement:`<span class="mb-1">ทั้งหมด <span class="totalList">${props.dataArray.length}</span> รายการ</span>`,
    columns: props.dataArrayTable,
    columnHeaderSortMulti:true,
    renderComplete() {
      feather.replace({
        'stroke-width': 2
      })
    }
  })
}
const testSort = () =>{
  let ee = tabulator.value.getSorters()
}
// Redraw table onresize
const reInitOnResizeWindow = () => {
  //when load
  if(window.innerWidth > 1005){
    document.querySelectorAll(".tabulator-col").forEach((col)=>{
      if(col.style.display == 'none'){
        col.style.display = 'inline-block'
        col.setAttribute("hide","true")
      }
      //check cell field name equal to header column and set width
      document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
        if(cell.getAttribute('tabulator-field') == col.getAttribute('tabulator-field')){
          cell.style.width = cell.style.width ? cell.style.width : cell.style.minWidth
        }
      })
    })
    document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
      if(cell.style.display == 'none')
        cell.style.display = 'inline-flex'
    })
  }

  window.addEventListener("resize", () => {
    tabulator.value.redraw();
    if(window.innerWidth <= 1005){
      document.querySelectorAll(".tabulator-col").forEach((col)=>{
        if(col.hasAttribute('hide')){
          document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
            if(col.style.display == 'inline-block' && (cell.getAttribute('tabulator-field') == col.getAttribute('tabulator-field'))){
              cell.style.display = 'none'
              cell.style.removeProperty('height')
            }
          })
        }
        else{//when resize from small to large screen
          document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
            if(col.style.display == 'none' && (cell.getAttribute('tabulator-field') == col.getAttribute('tabulator-field'))){
              cell.style.display = 'none'
              cell.style.removeProperty('height')
            }
          })
        }
        })
    }
    else{
      // always display every column
      document.querySelectorAll(".tabulator-col").forEach((col)=>{
        if(col.style.display == 'none'){
          col.style.display = 'inline-block'
          col.setAttribute("hide","true")
        }
      })
      document.querySelectorAll(".tabulator-cell").forEach((cell)=>{
          cell.style.display = 'inline-flex'
          cell.style.removeProperty('height')
      })
    }
    feather.replace({
      "stroke-width": 1.5,
    });
  });
};

onMounted(() => {
  initTabulator()
  reInitOnResizeWindow()
})



</script>
<style lang="scss">
.tabulator .tabulator-row .tabulator-responsive-collapse{
  display: none;
}
/* .tabulator .tabulator-header .tabulator-headers .tabulator-col, .tabulator .tabulator-row .tabulator-cell{
  display: inline-block !important;
  min-width: 80px;
  width: 200px;
} */
.tabulator .tabulator-row .tabulator-responsive-collapse td strong {
  font-weight: 600 !important;
}
.tabulator-cell[tabulator-field=full_name]{
  font-weight: 600;
}
@media only screen and (max-width:1005px){
  .tabulator {
    .tabulator-tableHolder {
      // overflow: unset !important;
      .tabulator-table{
        white-space: normal !important;
        width: 100%;
        .tabulator-row .tabulator-responsive-collapse{
          display: block;
        }
      }
    }
  }
}


</style>
