<template>
  <FilterLayout :filter="filter">
    <AddressSection
      :province="filterState.address.province"
      :amphur="filterState.address.amphur"
      :tumbol="filterState.address.tumbol"
      :village="filterState.address.village"
      @update:province="updateAddress($event, 'province')"
      @update:amphur="updateAddress($event, 'amphur')"
      @update:tumbol="updateAddress($event, 'tumbol')"
      @update:village="updateAddress($event, 'village')"
      parentComponent="dashboard-cwd-neets"
      key="dashboard-cwd-neets"
    />
    <div class="flex justify-end mt-3">
      <button class="btn btn-outline-secondary mr-2" @click="resetState">
        ล้าง
      </button>
      <button class="btn btn-primary bg-primary-btn border-primary-btn" @click="sendToStore">
        ค้นหา
      </button>
    </div>
  </FilterLayout>
</template>

<script setup>
import AddressSection from "./component/AddressSection.vue";
import FilterLayout from "./layout/index.vue";
import { reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";

const INIT_FILTER_VALUE = {
  address: {
    amphur: null,
    province: null,
    tumbol: null,
    village: null,
  },
};
const store = useStore();
const filterState = reactive(JSON.parse(JSON.stringify(INIT_FILTER_VALUE)));

onMounted(() => {
  const test = store.getters["dashboardCWDNEETsFilter/getFilterForApi"];
  Object.assign(
    filterState,
    JSON.parse(JSON.stringify(store.getters["dashboardCWDNEETsFilter/getAllFilter"]))
  );
});

const resetState = () => {
  const initFilter = JSON.parse(JSON.stringify(INIT_FILTER_VALUE))
  if(store.getters["userAuth/getUserProvinceCode"].length == 2)
    initFilter.address.province = filterState.address.province;
  if(store.getters["userAuth/getUserAmphurCode"].length == 4)
    initFilter.address.amphur = filterState.address.amphur;
  if(store.getters["userAuth/getUserTumbolCode"].length == 6)
    initFilter.address.tumbol = filterState.address.tumbol;
  Object.assign(filterState, initFilter);
};
const sendToStore = () => {
  let result = filterState;
  store.dispatch(
    "dashboardCWDNEETsFilter/setStore",
    JSON.parse(JSON.stringify(result))
  );
};

const updateAddress = (evt, key) => {
  filterState.address[key] = evt;
  if(key == "province" && evt){
    store.dispatch(
      "dashboardCWDNEETsFilter/setProvince",
      JSON.parse(JSON.stringify(evt))
    );
  }
  if(key == "amphur" && evt){
    store.dispatch(
      "dashboardCWDNEETsFilter/setAmphur",
      JSON.parse(JSON.stringify(evt))
    );
  }
  if(key == "tumbol" && evt){
    store.dispatch(
      "dashboardCWDNEETsFilter/setTumbol",
      JSON.parse(JSON.stringify(evt))
    );
  }
  if(key == "village" && evt){
    store.dispatch(
      "dashboardCWDNEETsFilter/setVillage",
      JSON.parse(JSON.stringify(evt))
    );
  }
};
</script>
