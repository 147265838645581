<template>
    <div
        :id="id"
        class="modal"
        data-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl !mb-32">
            <div class="modal-content">
                <div class="modal-header px-0 justify-between">
                    <h4
                        class="md:text-base xs:text-sm md:mr-auto font-bold px-3"
                    >
                        {{header}}
                    </h4>
                    <button class="btn btn-light border-none shadow-none" @click="hideModal('hide')" v-if="closeButton">
                        <XIcon class="w-5 h-5" />
                    </button>
                </div>
                <div class="modal-body p-4 md:p-10">
                    <slot></slot>
                </div>
                <div class="flex flex-row justify-between items-center p-3">
                    <div class="form-checkbox-custom pl-6">
                        <label
                            class="form-check-label align-middle flex items-baseline"
                            :for="`form-checkbox-${id}`"
                        >
                            <input
                                :id="`form-checkbox-${id}`"
                                class="form-check-input align-middle"
                                type="checkbox"
                                :value="true"
                                @change="handleHideModal"
                                v-model="checkboxValue"
                            > ไม่แสดงหน้านี้อีก
                        </label>
                    </div>
                    <div>
                        <button class="btn btn-secondary mr-2" @click="hideModal('hide')">
                        ออก
                        </button>
                        <button class="btn btn-primary" @click="handleClickOk">
                        ตกลง
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<script setup>
import { onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
const props = defineProps({
    modelValue: Boolean,
    id: {
        type: String,
        default: "custom-modal",
    },
    loading: {
        type: Boolean,
        default: false,
    },
    closeButton: {
        type: Boolean,
        default: false,
    },
    header:{
        type: String,
    },
    modalName:{
        type: String,
        required: true
    }
});
const emits = defineEmits(["update:modelValue", "click:cancel", "click:ok"]);
const checkboxValue = ref(false)
const store = useStore()

onMounted(() => {
    if (props.modelValue) {
        showModal();
    }
});

watch(
    () => props.modelValue,
    (newval) => {
        if (newval) {
            showModal();
        } else {
            hideModal();
        }
    }
);

const showModal = () => {
    const checkHide = JSON.parse(localStorage.getItem(props.modalName))
    if (checkHide) {
        //check has expired and user id match current if false then remove item and show modal again
        if ((checkHide.isHide && new Date(checkHide.expired).getTime() <= new Date().getTime()) || checkHide.id != store.getters["userAuth/getTokenParsed"].sub) {
            localStorage.removeItem(props.modalName);
            cash(`#${props.id}`).modal("show");
        }
    }
    else {
        cash(`#${props.id}`).modal("show");
    }
};

const hideModal = (hide) => {
    cash(`#${props.id}`).modal("hide");
    setTimeout(() => {
        emits("update:modelValue", false);
        if(hide)
            emits("click:cancel");
    }, 450);
};


const handleClickOk = () => {
//   setTimeout(() => {
    emits("click:ok");
//   }, 450);
};

const handleHideModal = () =>{
    if(checkboxValue.value){
        localStorage.setItem(props.modalName,
            JSON.stringify({
                isHide: true,
                expired: new Date(new Date().setDate(new Date().getDate() + 1)),
                id: store.getters["userAuth/getTokenParsed"].sub
            })
        )
    }
    else{
        localStorage.removeItem(props.modalName)
    }
}
</script>
