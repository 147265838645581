import axios from "axios";
import store from "./store";

export function jwtInterceptor() {
  //add a request interceptor
  axios.interceptors.request.use(
    (config) => {
        if (!config.headers["Content-Type"])
            config.headers["Content-Type"] = "application/json";
        if (
            !config.headers["Authorization"] &&
            ("authenticated", store.getters["userAuth/getAuthenticated"])
        ) {
            config.headers[
            "Authorization"
            ] = `Bearer ${store.getters["userAuth/getToken"]}`;
        }
        return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status == 401) {
        const loginUrl = store.getters["userAuth/getAllKeycloak"].createLoginUrl();
        window.location.replace(loginUrl);
      }
      return Promise.reject(error);
    }
  );
}
