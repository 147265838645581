<template>
  <template-layout :breadCrumbList="breadcrumbComputed" :customMenu="customMenu">
    <div>
        <div class="w-1/6 h-1/6 mx-auto" v-if="loadingQuestion && (isEdit || isView ? loadingSurveyData : loadingQuestion)">
            <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
        </div>
        <div v-else>
            <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-6">
                <h1 class="text-lg font-medium mr-auto">
                {{`${isView ? 'ดูข้อมูล' : isEdit ? 'แก้ไข' :'เพิ่ม'}ครัวเรือน`}}
                </h1>
            </div>
            <div class="intro-y box pb-10 pt-10 sm:pb-20 sm:pt-8 mt-5">
                <formHousehold :houseData="householdData" :isView="isView" :isEdit="isEdit" @exit-form="exitForm" :isAddSurvey="isAddSurvey" @complete-form="completeForm" :questionList="question_data?.[0].data"/>
            </div>
        </div>
    </div>
    <SurveyInformationModal
      id="modal-house-survey-info"
      v-model="showModal"
      modal-size="modal-lg"
      close-button
      @click:cancel="completeForm"
      @click:ok="showModal = false"
      header="แนะนำตัว และวัตถุประสงค์ของการสัมภาษณ์"
      modal-name="house-survey"
    >
      <div>
        <p>สวัสดีค่ะ/ครับ ดิฉัน/หนู/ผม ชื่อ ...(ชื่อของผู้สัมภาษณ์)... เป็นตัวแทนของศูนย์อำนวยการขจัดความยากจนและพัฒนาคนทุกช่วงวัยอย่างยั่งยืนตามหลักปรัชญาของเศรษฐกิจพอเพียง หรือ ศจพ. พวกเรากําลังสํารวจข้อมูลเกี่ยวกับสถานการณ์คุณภาพชีวิตของท่านและสมาชิกในครัวเรือน เพื่อ<strong>ใช้สำหรับการศึกษา
        วิเคราะห์ และการออกแบบนโยบายและมาตรการต่าง ๆ ที่จะช่วยสนับสนุนท่านและคนไทยอีกหลายล้านคนให้มีคุณภาพชีวิตที่ดีขึ้น</strong> ได้อย่างเหมาะสมและตรงกับปัญหาความต้องการมากที่สุด โดยเรา <strong>ขอความอนุเคราะห์ให้ท่านตอบข้อมูลตามความเป็นจริง</strong> และขอให้ท่านมั่นใจว่า <strong>ข้อมูลที่เราพูดคุยกันวันนี้จะถูกเก็บเป็นความลับ และจะไม่ถูกนำไปใช้ในการพิจารณายกเลิกสิทธิและสวัสดิการ หรือทำให้ท่านสูญเสียสิทธิประโยชน์ที่ท่านพึงได้ หรือมีค่าใช้จ่ายอื่นใดเกิดขึ้นในภายหลัง</strong></p>
        <p><strong>การสัมภาษณ์จะแบ่งออกเป็นสองส่วน</strong> คือ <strong>ส่วนที่หนึ่ง การสอบถามข้อมูลของครัวเรือน และส่วนที่สอง การสอบถามข้อมูลของสมาชิกทุกคนในครัวเรือน</strong> ใช้เวลาทั้งสิ้นประมาณ 60-120 นาที <strong>สำหรับในส่วนแรกนี้ จะเป็นการสอบถามข้อมูลทั่วไปของครัวเรือน</strong> ครอบคลุมเรื่องของจำนวนสมาชิก ที่อยู่อาศัย ค่าใช้จ่าย และการได้รับความช่วยเหลือจากแหล่งต่าง ๆ ค่ะ/ครับ หากท่านไม่ต้องการตอบคําถามข้อใด หรือหยุดการสัมภาษณ์ ท่านสามารถแจ้งให้เราทราบได้ตลอดเวลา ท่านมีข้อสงสัยหรืออยากสอบถามอะไรเพิ่มเติมไหมคะ/ครับ …………………….  หากไม่มีขออนุญาตเริ่มการสัมภาษณ์ค่ะ/ครับ
        </p>
        <p class="mt-3"><u>หมายเหตุ</u></p>
        <p>1. ควรทำการสำรวจ ณ บ้านพักอาศัยของผู้ถูกสัมภาษณ์ เพื่อที่จะได้สังเกตสภาพแวดล้อมของที่อยู่อาศัยได้อย่างถูกต้องตามความเป็นจริง</p>
        <p>2. ควรขอดูทะเบียนบ้าน เพื่อบันทึกเลขที่บ้าน ที่อยู่ ชื่อของสมาชิก และหมายเลขบัตรประชาชนของทุกคนในครัวเรือนได้อย่างถูกต้อง</p>
        <p>3. สำรวจข้อมูลของสมาชิกทุกคนในครัวเรือน หากสมาชิกท่านใดไม่อยู่บ้าน เป็นผู้พิการ หรือผู้สูงอายุที่ไม่สามารถให้ข้อมูลได้ ให้หัวหน้าครัวเรือน บุคคลที่อาศัยอยู่ในครัวเรือน หรือผู้ที่ได้รับความยินยอมจากบุคคลในครัวเรือนเป็นผู้ให้ข้อมูลแทนได้ และในกรณีเด็กอายุต่ำกว่า 15 ปี ให้ผู้ปกครองเป็นผู้ให้ข้อมูลแทนได้</p>
      </div>
    </SurveyInformationModal>
  </template-layout>
</template>

<script setup>
import { onMounted, reactive, ref, computed } from 'vue'
import formHousehold from '@/components/add-house-hold-modal/form_wizard.vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from "axios";
import Swal from "sweetalert2";
import SurveyInformationModal from "@/components/survey-information-modal/Main.vue";
import localForage from "localforage"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

const surveyStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'survey'
});

const form = reactive({
    step1:{
        member_cnt: null,
        member_reg_cnt: null
    }
})
const submitClicked = ref(false)
const submitform = () =>{
    submitClicked.value = !submitClicked.value
}
const store = useStore()
const route = useRoute()
const router = useRouter()
const householdData = ref(null)
const isView = ref(false)
const isEdit = ref(false)
const isAddSurvey = ref(false)
const customMenu = ref([])
const showModal = ref(false)
const isPromptRouteLeave = ref(true)
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const loadingQuestion = ref(false)
const loadingSurveyData = ref(false)
const question_data = ref(null)
const getSurvey = async() =>{
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}house/${route.params.hhid}`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    loadingSurveyData.value = !loadingSurveyData.value
    const res = await axios.get(url, { headers }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        householdData.value = res.data
        loadingSurveyData.value = !loadingSurveyData.value
        if(householdData.value.house_surveys?.length > 0){
            householdData.value["questionnaires"] = householdData.value.house_surveys.find(x=>x._id == route.params.surveyid)
        }
    }
}
const getQuestionList = async () => {
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const params = {
        question_nare_name_en: "household"
    }
    loadingQuestion.value = !loadingQuestion.value
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        question_data.value = res.data
        loadingQuestion.value = !loadingQuestion.value
        surveyStore.setItem('household', JSON.parse(JSON.stringify(res.data)))
    }
}
const getCustomMenu = () => {
    if(householdData.value?.house_surveys?.length > 0){
        let sub_menu = []
        for(let i = householdData.value.house_surveys.length; i > 0; i-- ){
            sub_menu.push({
                icon: '',
                pageName: 'household-profile',
                title: `ครั้งที่ ${i}` ,
                params: {
                    hhid: householdData.value._id,
                    surveyid: householdData.value.house_surveys[i-1]["_id"]
                },
                path: `/household/${householdData.value._id}/${householdData.value.house_surveys[i-1]["_id"]}`
            })
        }
        customMenu.value = [
            {key: "devider"},
            {
                icon: "FileTextIcon",
                title: "แบบสำรวจ",
                subMenu: sub_menu
            }
        ]
        const idx = householdData.value.house_surveys.findIndex(x=>x._id == route.params.surveyid)
        const dataMember = householdData.value.house_surveys[idx]?.member_surveys ?? []
        if(dataMember.length > 0){
            let member_menu = []
            for(let i = 0; i < dataMember.length; i++ ){
                member_menu.push({
                    pageName: 'view-member',
                    title: `${dataMember[i].name} ${dataMember[i].surname}` ,
                    params: {
                        hhid: householdData.value._id,
                        surveyid: householdData.value.house_surveys[idx]._id,
                        memberid: dataMember[i].member_id
                    },
                    path: `/member/view/${householdData.value._id}/${householdData.value.house_surveys[idx]._id}/${dataMember[i].member_id}`,
                    image: dataMember[i]?.image?.img_url ? process.env.VUE_APP_IMG_API+dataMember[i].image.img_url : "default"
                })
            }
            customMenu.value = [...customMenu.value, {key: "devider"}, {icon: "UsersIcon",title: "สมาชิกในครัวเรือน",subMenu: member_menu}]
        }
    }
}
const breadcrumbComputed = computed(()=>{
    return [
    {
        name: "ครัวเรือน",
        path: "main-house-hold",
    },
    {
        name: `${route.name === "view-household" ? 'ข้อมูลครัวเรือน' : route.name === "edit-household" ? 'แก้ไขครัวเรือน' : 'เพิ่มครัวเรือน'}`,
        active: true,
    }
]
})
const getOfflineData = () => {
    houseStore.getItem(route.params.hhid).then((value)=>{
        householdData.value = value
        if(householdData.value.house_surveys && route.name != "add-household-survey"){
            householdData.value["questionnaires"] = householdData.value.house_surveys
        }
    })
}

const getQuestionListOffline = async () =>{
    let res = null
    await surveyStore.getItem("household").then((value)=>{
        res = value
    })
    question_data.value = res
}
onMounted(async ()=>{
    if(route.name === "add-household")
        showModal.value = true
    if(onlineComputed.value){
        await getQuestionList()
        if(route.name === "view-household" || route.name === "edit-household" || route.name === "add-household-survey"){
            if(route.name === "view-household"){
                isView.value = true
                isPromptRouteLeave.value = false
            }
            if(route.name === "edit-household")
                isEdit.value = true
            if(route.name === "add-household-survey"){
                isAddSurvey.value = true
                showModal.value = true
            }
            await getSurvey()
            getCustomMenu()
        }
        // else{
        //     showModal.value = true
        // }
    }else{
        await getQuestionListOffline()
        if(route.name === "view-household"||route.name === "edit-household" || route.name === "add-household-survey"){
            if(route.name === "view-household"){
                isView.value = true
                isPromptRouteLeave.value = false
            }
            if(route.name === "edit-household")
                isEdit.value = true
            if(route.name === "add-household-survey"){
                isAddSurvey.value = true
                showModal.value = true
            }
            getOfflineData()
        }
    }

})

const completeForm = () => {
    showModal.value = false
    isPromptRouteLeave.value = false
    if(route.name == "add-household-survey"){
        router.go(-1)
    }
    else{
        router.push({
            name: "main-house-hold"
        });
    }
}

const exitForm = () =>{
    router.go(-1)
}
onBeforeRouteLeave((to, from, next) => {
    if(isPromptRouteLeave.value){
        swalCustom.fire({
            html: isView.value ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
                <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                showModal.value = false
                next()
            } else {
                next(false)
            }
        })
    }
    else{
        next()
    }
})
</script>

<style lang="scss">
</style>