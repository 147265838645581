const menuDefault = [
  // {
  //   icon: "GlobeIcon",
  //   pageName: "landing-page",
  //   title: "หน้าหลัก",
  // },
  /*
  {
    icon: "HomeIcon",
    pageName: "side-menu-dashboard",
    title: "รายงาน",
    subMenu: [
      {
        icon: "",
        pageName: "side-menu-dashboard-overview-1",
        title: "Dashboard 1",
      },
      {
        icon: "",
        pageName: "side-menu-dashboard-overview-2",
        title: "Dashboard 2",
      },
    ],
  },
  */
  {
    icon: "PieChartIcon",
    title: "Dashboard",
    routeName: "dashboard-overview",
    subMenu: [
      {
        icon: "BarChart2Icon",
        pageName: "dashboard-overview",
        title: "ภาพรวม"
      },
      {
        icon: "BarChartIcon",
        pageName: "dashboard-summary",
        title: "NEETS & เด็กพิการ"
      },
    ],
  },
  {
    icon: "HomeIcon",
    pageName: "main-house-hold",
    title: "ครัวเรือน",
    routeName: "household"
  },
  // {
  //   icon: "UsersIcon",
  //   pageName: "family-page",
  //   title: "ครอบครัว",
  // },
  {
    icon: "UsersIcon",
    pageName: "member-page",
    title: "สมาชิก",
    routeName: "member"
  },
  {
    icon: "NeetsIcon",
    pageName: "neets",
    title: "เด็ก NEETs",
    routeName: "neets"
  },
  {
    icon: "DisabilityIcon",
    pageName: "cwd",
    title: "เด็กพิการ",
    routeName: "cwd"
  },
  // {
  //   key: "devider",
  //   isAdmin: true
  // },
  {
    icon: "LayoutIcon",
    title: "ผู้ดูแลระบบ",
    routeName: "admin-management",
    subMenu: [
      {
        icon: "FormIcon",
        title: "แบบสอบถาม",
        isSubmenu: true,
        subMenu: [
          {
            icon: "FormIcon",
            pageName: "survey-datatable",
            title: "จัดการแบบสอบถาม",
            routeName: "survey-datatable"
          },
          "devider",
          {
            icon: "FormIcon",
            pageName: "house-survey-management",
            title: "ครัวเรือน",
            routeName: "household"
          },
          {
            icon: "FormIcon",
            pageName: "member-survey-management",
            title: "สมาชิก",
            routeName: "member"
          },
          {
            icon: "FormIcon",
            pageName: "neets-survey-management",
            title: "เด็ก NEETs",
            routeName: "neets"
          },
          {
            icon: "FormIcon",
            pageName: "cwd-survey-management",
            title: "เด็กพิการ",
            routeName: "cwd"
          },
        ],
      },
      {
        icon: "UserIcon",
        pageName: "permission-management",
        title: "จัดการสิทธิ์"
      },
    ]
  }
]

const state = () => {
  return {
    menu: [...menuDefault]
  };
};

// getters
const getters = {
  menu: (state, getters, rootState, rootGetters) => {
    return state.menu.filter(x=> {
      if(rootGetters["network/isOnline"])
        return x.permission || x?.subMenu?.some(s=> s.permission)
      return x.routeName != "admin-management" && x.routeName != "dashboard-overview"
    })
  }
};

// actions
const actions = {
  setPermissionToMenu({commit}, data){
    commit("setPermissionToMenu", data)
  }
};

// mutations
const mutations = {
  setPermissionToMenu(state, data){
    let currentMenuDefault = JSON.parse(JSON.stringify(menuDefault))
    let newMenu = data.menu.filter(item=> item.code_name != "CWD" && item.code_name != "NEET" && item.code_name != "IN" && item.code_name != "HH")
    let adminIdx = currentMenuDefault.findIndex(item=> item.routeName == "admin-management")
    for(let menu of newMenu){
      let checkAdminIdx = currentMenuDefault[adminIdx].subMenu?.[0].subMenu.findIndex(item=> item.routeName == menu.name_en)
      if(checkAdminIdx == -1){
        currentMenuDefault[adminIdx].subMenu[0].subMenu.push(
          {
            icon: "FormIcon",
            pageName: "survey-management",
            params: {survey: menu.name_en},
            title: menu.short_name,
            path: `/admin/${menu.name_en}`
          }
        )
      }
    }
    let temp = currentMenuDefault.map(x=>{
      if(x.subMenu){
        x.subMenu = x.subMenu.filter(s=>{
          if(data.route.includes(s.pageName) || (s.isSubmenu && data.route.includes("house-survey-management"))){
            s.permission = true
          }
          return s.permission
        })
      }
      else{
        if(data.route.includes(x.pageName))
          x.permission = true
      }
      if((data.route.includes("permission-management") || data.route.includes("house-survey-management")) && x.key == "devider")
        x.permission = true
      return x
    })
    state.menu = temp
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
