<template>
  <BarChart
    type="bar"
    :data="data"
    :options="options"
    customLegend
    :columnClass="['col-span-8', 'col-span-4']"
    legendPosition="bottom"
    legendBoxStyle="bg-[#f3f7fa] !p-2"
    :legendInRow="6"
    mobileModeLayout
  />
</template>

<script setup>
import {  computed } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  chartLabel:{
    type: Array,
    default: ()=>[]
  },
  series:{
    type: Array,
    default: ()=> []
  },
  isMobileScreen:{
    type: Boolean,
    default: false
  }
})
const data = computed(() => {
  return {
    labels: getChartLabel(),
    datasets: [
      {
        label: 'พิการทางการเห็น',
        backgroundColor: '#1C4E80',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_2_count')
      },
      {
        label: 'พิการทางการได้ยินหรือสื่อความหมาย',
        backgroundColor: '#0091D5',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_3_count')
      },
      {
        label: 'พิการทางการเคลื่อนไหวหรือร่างกาย',
        backgroundColor: '#A5D8DD',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_4_count')
      },
      {
        label: 'พิการทางจิตใจหรือพฤติกรรม',
        backgroundColor: '#EA6A47',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_5_count')
      },
      {
        label: 'พิการทางสติปัญญา',
        backgroundColor: '#DBAE58',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_6_count')
      },
      {
        label: 'พิการทางการเรียนรู้',
        backgroundColor: '#1b6279',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_7_count')
      },
      {
        label: 'ออทิสติก',
        backgroundColor: '#488A99',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_8_count')
      },
      {
        label: 'พิการมากกว่า 1 ประเภท',
        backgroundColor: '#6AB187',
        barPercentage: 0.7,
        categoryPercentage: 1.0,
        data: getSeriesData('IN104_9_count')
      }
    ]
  }
})
const options = computed(() => {
  if(props.isMobileScreen){
    return {
      scales: {
        x: {
          stacked: true,
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            autoSkip: false,
            font: {
              size: 10
            }
          },
        },
        y: {
          stacked: true,
          display: true,
          grid: {
            display: true,
            drawBorder: false,
            color: "#f3f3f3",
            borderColor: "#f3f3f3",
          },
          ticks:{
            color: "#bfbfbf",
            stepSize: getStepSizeValue
          }
        }
      },
      plugins: {
        tooltip: {
          mode: 'index',
          callbacks: {
            title: function(tooltipItems, data) {
            let sum = 0;
              tooltipItems.forEach(function(tooltipItem) {
                sum += tooltipItem.raw;
              });
              return `${tooltipItems[0].label} (รวม: ${new Intl.NumberFormat().format(sum)})`;
            },
            label: (tooltipItems) => {
              const data = tooltipItems.chart.data;
              const datasetIndex = tooltipItems.datasetIndex;
              const index = tooltipItems.dataIndex;
              const datasetLabel = data.datasets[datasetIndex].label || "";
              let sum = data.datasets.reduce((sum, dataset) => {
                return sum + dataset.data[index];
              }, 0);
              let percent = data.datasets[datasetIndex].data[index] / sum * 100;
              if(Math.round(percent) !== percent) {
                percent = percent.toFixed(2)
              }
              if(data.datasets[datasetIndex].data[index])
                return `${datasetLabel}: ${new Intl.NumberFormat().format(data.datasets[datasetIndex].data[index])} (${percent}%)`;
              return;
            }
          }
        },
        legend: {
          display: false,
          position: "bottom",
          align: "start",
        }
      },
      maintainAspectRatio: false,
      responsive:true,
    }
  }
  return {
    indexAxis: 'y',
    scales: {
      x: {
        display: true,
        stacked: true,
        grid: {
          display: true,
          color: "#f3f3f3",
          borderColor: "#f3f3f3",
          drawBorder: false,
        },
        ticks:{
          color: "#bfbfbf",
          stepSize: getStepSizeValue
        }
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false
        },
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        enabled: false,
        external: externalTooltipHandler,
        position: 'average',
        callbacks: {
          title: function(tooltipItems, data) {
           let sum = 0;
            tooltipItems.forEach(function(tooltipItem) {
              sum += tooltipItem.raw;
            });
            return `${tooltipItems[0].label} (รวม: ${new Intl.NumberFormat().format(sum)})`;
          },
          label: (tooltipItems) => {
            const data = tooltipItems.chart.data;
            const datasetIndex = tooltipItems.datasetIndex;
            const index = tooltipItems.dataIndex;
            const datasetLabel = data.datasets[datasetIndex].label || "";
            let sum = data.datasets.reduce((sum, dataset) => {
              return sum + dataset.data[index];
            }, 0);
            let percent = data.datasets[datasetIndex].data[index] / sum * 100;
            if(Math.round(percent) !== percent) {
              percent = percent.toFixed(2)
            }
            if(data.datasets[datasetIndex].data[index])
              return `${datasetLabel}: ${data.datasets[datasetIndex].data[index]} (${percent}%)`;
            return;
          }
        }
      },
      legend: {
        labels: {
          fontSize: 14,
          boxWidth: 6
        },
        display: false,
        position: "bottom",
        align: "start",
      }
    },
    maintainAspectRatio: false,
    responsive:true,
  }
})
const getSeriesData = (key) =>{
  if(props.series){
    return props.series.map(x => x[key])
  }
  return []
}
const getChartLabel = () =>{
  if(props.series){
    return props.series.map(x=> x.area_name)
  }
  return []
}
const getStepSizeValue = () =>{
  let max = 0
  for(let i = 0; i < data.value.labels.length; i++){
    let sum_datasets = 0
    for(let j = 0; j < data.value.datasets.length; j++){
      sum_datasets += data.value.datasets[j].data[i]
    }
    if(sum_datasets > max){
      max = sum_datasets
    }
  }

  return Math.ceil(max/3)
}
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div.custom-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('custom-tooltip')

    const parentDiv = document.createElement('div');
    parentDiv.classList.add('tooltip-wrap')

    tooltipEl.appendChild(parentDiv);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const divTitle = document.createElement('div');
    divTitle.classList.add('tooltip-title')

    titleLines.forEach(title => {
      const text = document.createTextNode(title);
      divTitle.appendChild(text);
    });

    const divBody = document.createElement('div');
    divBody.classList.add('tooltip-body')
    bodyLines.forEach((body, i) => {
      if(body.length){
        const colors = tooltip.labelColors[i];
        const divWrap = document.createElement('div')
        divWrap.classList.add('tooltip-item')
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = 'rgba(254, 254, 254, 0.88)'//colors.borderColor;
        const text = document.createTextNode(body);
        divWrap.appendChild(span)
        divWrap.appendChild(text)
        divBody.appendChild(divWrap);
      }
    });

    const divRoot = tooltipEl.querySelector('div.tooltip-wrap');

    // Remove old children
    while (divRoot.firstChild) {
      divRoot.firstChild.remove();
    }

    // Add new children
    divRoot.appendChild(divTitle);
    divRoot.appendChild(divBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

</script>
