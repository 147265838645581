<template>
  <div class="overflow-x-auto scrollbar-hidden cwd-datatable" data-test="cwd-datatable">
      <Datatable :api-params="paramsComputed" :api-url="'member'" :method-request="'GET'" :init-sorter="[{field: 'UPDATE_DATETIME', dir: 'desc'}]" :data-array="dataArray" :data-array-table="dataArrayTable" :key="key" :downloadFile="downloadFile" @getDataComplete="exportFile" @rowClicked="handleRowClick" question-group="cwd" />
  </div>
</template>

<script setup>
import Datatable from "@/components/datatable/index.vue"
import { reactive,ref, watch, computed } from "vue"
import { utils, writeFile } from "xlsx"
import Swal from 'sweetalert2'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const props = defineProps({
    filter:{
        type: Object
    },
    downloadCsv:{
        type: String
    },
    downloadExcel: {
        type: String
    }
})
const emits = defineEmits(["downloadCsvComplete", "downloadExcelComplete"])
const dataArray = ref()
const params = ref({start: 1,draw: 10,length: 10, cwd:1})
const key = ref(1)
const downloadFile = ref(false)
const currentFileType = ref("")
const downloadFormat = ref(null)
const router = useRouter()
const store = useStore()
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
};
const onlineComputed = computed(()=>store.getters["network/isOnline"])
watch(()=>props.filter, ()=>{
    key.value++
})
const paramsComputed = computed(() => ({...params.value, ...props.filter}))
var customNameMutator = (value, data, type, params, component) => {
    return `${data.title?data.title:""}${data.name?data.name:""} ${data.surname?data.surname:""} `
}
var customAddressMutator = (value, data, type, params, component) => {
    return `${data.hnum?data.hnum:""} ${data.moo?'หมู่ '+data.moo:''} ${data.village_name?data.village_name: ''} ${data.soi?'ซอย '+data.soi: ''} ${data.road?'ถนน '+data.road: ''} ${data.tumbol_name?'ตำบล'+data.tumbol_name: ''}  ${data.amphur_name?'อำเภอ'+data.amphur_name: ''} ${data.province_name?'จังหวัด'+data.province_name: ''} ${data.postcode?data.postcode:""}`
}
var customAgeRange = (value, data, type, params, component) => {
    return {
        "baby": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)") : false,
        "kid": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยเรียน (อายุ 5-14 ปี)") : false,
        "teenager": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)") : false,
        "adult": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)") : false,
        "elder": data.member_surveys?.age_range ? data.member_surveys.age_range.some(x=> x === "วัยผู้สูงอายุ (60 ปีขึ้นไป)") : false
    }
}
const dataArrayTable = reactive([
    {title:"ชื่อ-นามสกุล", field:"full_name", minWidth: 200, resizable:true, formatter:"textarea"},
    {title:"เลขบัตรประจำตัวประชาชน", field:"NID", hozAlign:"left",minWidth: 200, width: 230, resizable:true, formatter:function(cell, formatterParams){
        return cell.getValue() ?? 'ไม่มีบัตรประจำตัวประชาชน'
    }},
    {title:"ที่อยู่", field:"full_address", hozAlign:"left", formatter:"textarea", resizable:true, minWidth: 300},
    {title:"ช่วงวัย", field:"age_range", hozAlign:"center", minWidth: 200, width: 250, variableHeight:true, mutator: customAgeRange,
        formatter:function(cell, formatterParams){
            var value = cell.getValue();
            if(value){
                let temp = ""
                for(const [key, val] of Object.entries(value)){
                    let icon = "", text = ""
                    if(key === "baby"){
                        icon = "baby-icon"
                        text = "เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)"
                    }
                    if(key === "kid"){
                        icon = "kid-icon"
                        text = "วัยเรียน (อายุ 5-14 ปี)"
                    }
                    if(key === "teenager"){
                        icon = "teenager-icon"
                        text = "วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)"
                    }
                    if(key === "adult"){
                        icon = "adult-icon"
                        text = "วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)"
                    }
                    if(key === "elder"){
                        icon = "elder-icon"
                        text = "วัยผู้สูงอายุ (60 ปีขึ้นไป)"
                    }
                    temp+= `<button class='t-tooltip btn btn-secondary mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1 text-white ${val ? 'bg-theme-1 hover:bg-theme-1':'bg-theme-37 hover:bg-theme-37'}'>\
                                <span class="t-tooltip-text">${text}</span>\
                                <span class="img-icon ${icon}"></span>\
                            </button>`
                }
                let res = `<div class="flex flex-wrap justify-start lg:justify-center">${temp}</div>`
                return res
            }
            return null
        }
    },
    {title:"วันที่ปรับปรุงข้อมูลล่าสุด", field:"UPDATE_DATETIME", hozAlign:"center", minWidth: 200, width: 220, variableHeight:true,
        formatter:function(cell, formatterParams){
            if(cell.getValue()){
                let date = cell.getValue().split`T`[0]
                date = new Date(date)
                return [String(date.getDate()).padStart(2, '0'),
                        String(date.getMonth() + 1).padStart(2, '0'),
                  date.getFullYear() + 543
                ].join('/');
            }
            return null
        }
    },
    {title:"การดำเนินการ", field:"action", hozAlign:"center", minWidth: 150, width: 200,
        formatter:function(cell, formatterParams){
            const editCallback = (e) => {
                //prevent default row click
                e.stopPropagation();
                if(cell.getData().cwd_survey_id){
                    router.push({
                        name: 'edit-cwd',
                        params:{
                            surveyid: cell.getData().house_surveys_id,
                            memberid: cell.getData()._id,
                            cwdid: cell.getData().cwd_survey_id
                        }
                    })
                }
            };
            const deleteCallback = (e) =>{
                //prevent default row click
                e.stopPropagation();
                swalCustom.fire({
                    text:"ยืนยันลบข้อมูล",
                    icon:"warning",
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ยกเลิก',
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        const res = await axios.delete(`${process.env.VUE_APP_BASE_API}cwdSurveys/${cell.getData().cwd_survey_id}`, {headers}).catch(() =>{
                            swalCustom.fire({
                                text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                                icon: "warning",
                                timer: 3000, //timeOut for auto-close
                                confirmButtonText: "ปิด",
                            });
                        });
                        if(res.status == 200){
                            swalCustom.fire({
                                text: "สำเร็จ",
                                icon: "success",
                                timer: 3000, //timeOut for auto-close
                                confirmButtonText: "ปิด",
                            }).then(() => {
                                key.value++
                            });
                        }
                    }
                })
            }

            const addEvent = (e) =>{
                //prevent default row click
                e.stopPropagation();
                router.push({
                    name: 'add-cwd',
                    params:{
                        surveyid: cell.getData().house_surveys_id,
                        memberid: cell.getData()._id
                    }
                })
            }

            const viewEvent = (e) =>{
                //prevent default row click
                e.stopPropagation();
                if(cell.getData().cwd_survey_id){
                    router.push({
                        name: 'view-cwd',
                        params:{
                            surveyid: cell.getData().house_surveys_id,
                            memberid: cell.getData()._id,
                            cwdid: cell.getData().cwd_survey_id
                        }
                    })
                }
            }

            let divElm = document.createElement("div")
            divElm.className = "flex lg:justify-center items-center gap-1"
            /* let addBtn = document.createElement("span");
            addBtn.className = "flex items-center hover:text-theme-1"
            let iconAdd = document.createElement("i");
            iconAdd.setAttribute("data-feather","plus-square")
            iconAdd.className = "w-4 h-4 mr-1"

            addBtn.addEventListener("click", addEvent);
            addBtn.append(iconAdd);
            addBtn.append("เพิ่ม")
            divElm.append(addBtn)

            let viewBtn = document.createElement("span");
            viewBtn.className = "flex items-center hover:text-theme-1"
            let iconView = document.createElement("i");
            iconView.setAttribute("data-feather","eye")
            iconView.className = "w-4 h-4 mr-1"

            viewBtn.addEventListener("click", viewEvent);
            viewBtn.append(iconView);
            viewBtn.append("แสดง")
            divElm.append(viewBtn)

            let editBtn = document.createElement("span");
            editBtn.className = "flex items-center hover:text-theme-1"
            let icon = document.createElement("i");
            icon.setAttribute("data-feather","edit")
            icon.className = "w-4 h-4 mr-1"

            editBtn.addEventListener("click", editCallback);
            editBtn.append(icon);
            editBtn.append("แก้ไข")
            divElm.append(editBtn)

            let delBtn = document.createElement("a")
            delBtn.className = "flex items-center text-theme-24"
            let delIcon = document.createElement("i");
            delIcon.setAttribute("data-feather","trash-2")
            delIcon.className = "w-4 h-4 mr-1"
            delBtn.addEventListener("click", deleteCallback);
            delBtn.append(delIcon)
            delBtn.append("ลบ")
            divElm.append(delBtn) */
            let addDiv = document.createElement("div");
            addDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let addBtn = document.createElement("button");
            addBtn.className = "btn btn-secondary border-primary-1 bg-primary-1/[0.8] hover:bg-primary-1/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1"
            let addText = document.createElement("span");
            addText.className = "t-tooltip-text"
            addText.append("เพิ่ม")
            let iconAdd = document.createElement("i");
            iconAdd.setAttribute("data-feather","plus")
            iconAdd.className = "w-7 h-7"
            if(cell.getData().cwd_survey_id || !getPermissionAccessMenu("create"))
                addBtn.setAttribute("disabled","disabled")

            addBtn.addEventListener("click", addEvent);
            addBtn.append(iconAdd);
            addDiv.append(addText);
            addDiv.append(addBtn)
            divElm.append(addDiv)

            let viewDiv = document.createElement("div");
            viewDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let viewBtn = document.createElement("button");
            viewBtn.className = "btn btn-secondary bg-primary-2/[0.8] hover:bg-primary-2/[0.65] text-white mr-1 border-0 rounded w-7 h-7 p-1"
            let viewText = document.createElement("span");
            viewText.className = "t-tooltip-text"
            viewText.append("แสดง")
            let iconView = document.createElement("i");
            iconView.setAttribute("data-feather","eye")
            iconView.className = "w-7 h-7"
            if(!cell.getData().cwd_survey_id || !getPermissionAccessMenu("read"))
                viewBtn.setAttribute("disabled","disabled")

            viewBtn.addEventListener("click", viewEvent);
            viewBtn.append(iconView);
            viewDiv.append(viewText)
            viewDiv.append(viewBtn)
            divElm.append(viewDiv)

            let editDiv = document.createElement("div");
            editDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let editBtn = document.createElement("button")
            editBtn.className = "btn btn-secondary bg-primary-3/[0.7] hover:bg-primary-3/[0.6] text-white mr-1 border-0 rounded w-7 h-7 p-1"
            let editText = document.createElement("span");
            editText.className = "t-tooltip-text"
            editText.append("แก้ไข")
            let icon = document.createElement("i");
            icon.setAttribute("data-feather","edit")
            icon.className = "w-7 h-7"
            if(!cell.getData().cwd_survey_id || !getPermissionAccessMenu("update"))
                editBtn.setAttribute("disabled","disabled")

            editBtn.addEventListener("click", editCallback);
            editBtn.append(icon);
            editDiv.append(editText)
            editDiv.append(editBtn)
            divElm.append(editDiv)

            let delDiv = document.createElement("div");
            delDiv.className = "t-tooltip flex justify-center w-7 h-7"
            let delBtn = document.createElement("button")
            delBtn.className = "btn btn-secondary bg-theme-24/[0.8] hover:bg-theme-24/[0.5] text-white mr-1 border-0 rounded w-7 h-7 p-1"
            let delText = document.createElement("span");
            delText.className = "t-tooltip-text"
            delText.append("ลบ")
            let delIcon = document.createElement("i");
            delIcon.setAttribute("data-feather","trash-2")
            delIcon.className = "w-7 h-7"

            if(!cell.getData().cwd_survey_id || !getPermissionAccessMenu("delete"))
                delBtn.setAttribute("disabled","disabled")

            delBtn.addEventListener("click", deleteCallback);
            delBtn.append(delIcon)
            delDiv.append(delText)
            delDiv.append(delBtn)
            divElm.append(delDiv)

            const b = cash(divElm)
            return b[0]
        }
    },
])
const handleRowClick = (row) =>{
    if(row.cwd_survey_id && (getPermissionAccessMenu("read") || !onlineComputed.value)){
        router.push({
            name: 'view-cwd',
            params: {
                surveyid: row.house_surveys_id,
                memberid: row._id,
                cwdid: row.cwd_survey_id
            },
        })
    }
}


watch(()=> props.downloadCsv, (val)=>{
    if(val){
        downloadFile.value = props.downloadCsv
        currentFileType.value = "csv"
        downloadFormat.value = val
    }
})

watch(()=> props.downloadExcel, (val)=>{
    if(val){
        downloadFile.value = props.downloadExcel
        currentFileType.value = "xlsx"
        downloadFormat.value = val
    }
})

const exportFile = (evt, questions) => {
    if(evt.status === 200){
        let columns_header = []
        columns_header = [...columns_header, ...questions.map(q => {
            if(q.question_type == "checkbox" && q.is_checkbox_group){
                const res = q.choices.map(c => {
                    if(c?.specify_value?.length > 1 && !c.is_multiple_answer){
                    let tempCh = []
                    for(let i = 1 ; i <= c.specify_value.length; i++){
                        tempCh.push(`${c.option.code}-${i}`)
                    }
                    return tempCh
                    }
                    return c.option.code
                }).flat()
                return res
            }
            if(q.question_type == "checkbox" && q.choices.some(c => c.option.hasOwnProperty("order"))){
                let haveSpecify = q.choices.find(c => c.specify_value)
                let temp = []
                for(let i = 1; i<=q.answer_limit; i++){
                    temp.push(`${q.question_code}-${i}`)
                    if(haveSpecify){
                        for(let j = 1 ; j <= haveSpecify.specify_value.length; j++){
                            temp.push(`${q.question_code}-${i}-${j}`)
                        }
                    }
                }
                return temp
            }
            if(q.question_type == "multiple-inputs-answer"){
                if(q.choices.length > 1){
                    let tempCh = []
                    for(let i = 1 ; i <= q.choices.length; i++){
                    tempCh.push(`${q.question_code}-${i}`)
                    }
                    return tempCh
                }
                return [q.question_code]
            }
            if((q.question_type == "radio" || q.question_type == "checkbox") && q.choices.some(c => c.specify_value)){
                let specify = q.choices.find(c=>c.specify_value)
                let tempCode = []
                if(specify){
                    for(let i = 1 ; i <= specify.specify_value.length; i++){
                        tempCode.push(`${q.question_code}-${i}`)
                    }
                }
                return [q.question_code,...tempCode]
            }
            return [q.question_code]
        }).flat()
        ]
        columns_header = columns_header.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
        )
        columns_header.unshift("province_name", "amphur_name", "tambol_name", "village_name", "HH001", "NID", "full_name", "age_year", "age_month", "age_range", "gender", "gender_other", "birthdate", "full_address", "marital_status", "marital_status_other", "religion", "religion_other", "mobile")
        columns_header.push("survey_status", "survey_start_date", "survey_end_date", "survey_start_time", "survey_end_time", "cwd_id", "house_id", "member_id")
        let newArr = evt.data.map((item)=>{
            let newItem = {}
            for(let col of columns_header){
                if(item[col])
                    newItem[col] = item[col]
                else
                    newItem[col] = null
            }
            // newItem["NID"] = item.NID ? item.NID : "ไม่มีบัตรประจำตัวประชาชน"
            if(item.house_number_ID){
                newItem["HH001"] = item.house_number_ID
            }
            // if(item.age){
            //     newItem["age"] = item.age.year+" ปี "+ item.age.month+ " เดือน"
            // }
            if(item.age_range){
                newItem["age_range"] = item.age_range.join(", ")
            }
            if(item.member_surveys.member_id){
                newItem["member_id"] = item.member_surveys.member_id
            }
            if(item.cwd_survey._id){
                newItem["cwd_id"] = item.cwd_survey._id
            }
            if(item.cwd_survey){
                const keys = Object.keys(item.cwd_survey)
                if(item.cwd_survey.status)
                    newItem["survey_status"] = item.cwd_survey.status
                if(item.cwd_survey.survey_start_date)
                    newItem["survey_start_date"] = item.cwd_survey.survey_start_date
                if(item.cwd_survey.survey_end_date)
                    newItem["survey_end_date"] = item.cwd_survey.survey_end_date
                keys.forEach((elm)=>{
                    if(item.cwd_survey[elm] || item.cwd_survey[elm] == 0){
                        if(typeof item.cwd_survey[elm] === "object"){
                            let tempObj = questions.find(({question_code}) => elm === question_code)
                            if(Array.isArray(item.cwd_survey[elm]) && tempObj){
                                if(questions.some(q=>q.question_code == elm && q.is_checkbox_group)){
                                    let temp = []
                                    item.cwd_survey[elm].forEach((it)=>{
                                        const choice = tempObj.choices.find(x => x.option.code === it.code)
                                        let tempDesc = ""
                                        if(choice?.is_multiple_answer){
                                            if(choice?.specify_value && it?.answers){
                                                let ans_idx = 0
                                                for(let val of it.answers){
                                                    let i = 0
                                                    let ansDesc = ""
                                                    for(let ch of choice.specify_value){
                                                        if(ch.key == "other"){
                                                            ansDesc += `${val[ch.key]} `
                                                        }
                                                        else{
                                                            if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                                            }
                                                        }
                                                        i++
                                                    }

                                                    tempDesc += `${ansDesc}${ans_idx == it.answers.length - 1 ? '' : ' , '}`
                                                    ans_idx++
                                                }
                                                temp.push({code: it.code, value: tempDesc})
                                            }
                                        }
                                        else{
                                            if(choice?.specify_value){
                                                let i = 0
                                                let codeNum = null
                                                for(let ch of choice.specify_value){
                                                    if(choice.specify_value.length == 1){
                                                        codeNum = it.code
                                                    }
                                                    else{
                                                        codeNum = `${it.code}-${i+1}`
                                                    }
                                                    if(it[ch.key] || it[ch.key] == 0){
                                                        temp.push({code: codeNum, value: it[ch.key]})
                                                    }
                                                    i++
                                                }
                                            }
                                        }
                                    })
                                    const newData = temp.reduce((a,v)=> ({...a, [v.code]: v.value}),{})
                                    return newItem = {...newItem, ...newData}
                                }
                                else{
                                    if(item.cwd_survey[elm].some(x => x.order)){
                                        const res = item.cwd_survey[elm].reduce((temp,v, idx)=> {
                                            const choice = tempObj.choices.find(x => x.option.code === v.code)
                                            // let tempDesc = choice ? choice.option.desc : v.desc
                                            let tempDesc = ""
                                            let tempAnswerObj
                                            if(downloadFormat.value == "desc"){
                                                tempDesc = choice ? choice.option.desc : v.desc
                                            }
                                            else{
                                                tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                                            }
                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                                            if(choice?.is_multiple_answer){
                                                if(choice?.specify_value && v?.answers){
                                                    let ans_idx = 0
                                                    for(let val of v.answers){
                                                        let i = 0
                                                        let ansDesc = ""
                                                        tempDesc += (ans_idx == 0) ? ` (` : ''
                                                        for(let ch of choice.specify_value){
                                                            if(ch.key == "other"){
                                                                ansDesc += ` ${val[ch.key]} `
                                                            }
                                                            else if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                                            }
                                                            i++
                                                        }

                                                        tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                                                        ans_idx++
                                                    }
                                                    tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}`]: tempDesc}
                                                }
                                            }
                                            else{
                                                if(choice?.specify_value){
                                                    let i = 0
                                                    for(let ch of choice.specify_value){
                                                        i++
                                                        if(v[ch.key] || v[ch.key] == 0){
                                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${idx+1}-${i}`]: v[ch.key]}
                                                        }
                                                    }
                                                }
                                            }
                                            return {...temp, ...tempAnswerObj}
                                        },{})
                                        return newItem = {...newItem,...res}
                                    }
                                    else{
                                        let tempAnswerObj = {}
                                        const res = item.cwd_survey[elm].reduce((prevVal,v,idx)=>{
                                            const choice = tempObj.choices.find(x => x.option.code === v.code)
                                            // let tempDesc = choice ? choice.option.desc : v.desc
                                            let tempDesc = ""
                                            if(downloadFormat.value == "desc"){
                                                tempDesc = choice ? choice.option.desc : v.desc
                                            }
                                            else{
                                                tempDesc = choice ? choice.option.code.split('-')[1] : v.code.split('-')[1]
                                            }
                                            if(choice?.is_multiple_answer){
                                                if(choice?.specify_value && v?.answers){
                                                    let ans_idx = 0
                                                    for(let val of v.answers){
                                                        let i = 0
                                                        let ansDesc = ""
                                                        tempDesc += (ans_idx == 0) ? ` (` : ''
                                                        for(let ch of choice.specify_value){
                                                            if(ch.key == "other"){
                                                                ansDesc += ` ${val[ch.key]} `
                                                            }
                                                            else if(val[ch.key] || val[ch.key] == 0){
                                                                ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                                            }
                                                            i++
                                                        }

                                                        tempDesc += `${ansDesc}${ans_idx == v.answers.length - 1 ? ')' : ' , '}`
                                                        ans_idx++
                                                    }
                                                }
                                            }
                                            else{
                                                if(choice?.specify_value){
                                                    let i = 0
                                                    for(let ch of choice.specify_value){
                                                        i++
                                                        if(choice.specify_value.some(sp => sp.key == "other")){
                                                            tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: v[ch.key]}
                                                        }
                                                        else if(v[ch.key] || v[ch.key] == 0){
                                                            tempDesc += (i == 1) ? ` (` : ''
                                                            tempDesc += `${v[ch.key]}${i == choice.specify_value.length ? '' : ', '}`
                                                            tempDesc += (i == choice.specify_value.length) ? `)` : ''
                                                        }
                                                    }
                                                }
                                            }

                                            return idx == 0 ? tempDesc : prevVal + ', ' + tempDesc
                                        }, '')
                                        newItem = {...newItem, ...{[elm]: res, ...tempAnswerObj}}
                                        return newItem
                                    }
                                }
                            }
                            else{
                                if(item.cwd_survey[elm]?.desc){
                                    const choice = tempObj.choices.find(x => x.option.code === item.cwd_survey[elm].code)
                                    // let tempDesc = choice ? choice.option.desc : item.cwd_survey[elm].desc
                                    let tempAnswerObj = {}
                                    let tempDesc = ""
                                    if(downloadFormat.value == "desc"){
                                        tempDesc = choice ? choice.option.desc : item.cwd_survey[elm].desc
                                    }
                                    else{
                                        tempDesc = choice ? choice.option.code.split('-')?.[1] ? choice.option.code.split('-')[1] : choice.option.code.split('-')[0]  : item.cwd_survey[elm].code.split('-')?.[1] ? item.cwd_survey[elm].code.split('-')[1] : item.cwd_survey[elm].code.split('-')[0]
                                    }
                                    tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}
                                    if(choice?.is_multiple_answer){
                                        if(choice?.specify_value && item.cwd_survey[elm]?.answers){
                                            let ans_idx = 0
                                            for(let val of item.cwd_survey[elm].answers){
                                                let i = 0
                                                let ansDesc = ""
                                                tempDesc += (ans_idx == 0) ? ` (` : ''
                                                for(let ch of choice.specify_value){
                                                    if(ch.key == "other"){
                                                        ansDesc += ` ${val[ch.key]} `
                                                    }
                                                    else{
                                                        if(val[ch.key] || val[ch.key] == 0){
                                                            ansDesc += `${val[ch.key]}${i == choice.specify_value.length - 1 ? '' : ' '}`
                                                        }
                                                    }
                                                    i++
                                                }

                                                tempDesc += `${ansDesc}${ans_idx == item.cwd_survey[elm].answers.length - 1 ? ')' : ' , '}`
                                                ans_idx++
                                            }
                                            tempAnswerObj = {...tempAnswerObj, [elm]: tempDesc}
                                        }
                                    }
                                    else{
                                        if(choice?.specify_value){
                                            let i = 0
                                            for(let ch of choice.specify_value){
                                                i++
                                                if(item.cwd_survey[elm][ch.key] || item.cwd_survey[elm][ch.key] == 0){
                                                    tempAnswerObj = {...tempAnswerObj, [`${elm}-${i}`]: item.cwd_survey[elm][ch.key]}
                                                }
                                            }
                                        }
                                    }
                                    return newItem = {...newItem, ...tempAnswerObj}
                                }
                                else if(tempObj?.question_type == "multiple-inputs-answer"){//object answer has no desc only this type
                                    const choice = tempObj.choices
                                    let i = 0
                                    let tempInp = {}
                                    for(let ch of choice){
                                        let codeNum = null
                                        if(choice.length == 1)
                                            codeNum = elm
                                        else
                                            codeNum = `${elm}-${i+1}`
                                        if(item.cwd_survey[elm][ch.key] || item.cwd_survey[elm][ch.key] == 0){
                                            tempInp = {...tempInp, [codeNum]: item.cwd_survey[elm][ch.key]}
                                        }
                                        i++
                                    }
                                    return newItem = {...newItem, ...tempInp}
                                }
                            }
                        }
                        return newItem[elm] = item.cwd_survey[elm]
                    }
                })
            }
            return newItem
        })
        newArr = newArr.map(({status, survey_date, _id, ...rest}) => ({...rest}));
        const ws = utils.json_to_sheet(newArr)
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        let date = new Date()
        let current_date = [date.getFullYear(),String(date.getMonth() + 1).padStart(2, '0'),String(date.getDate()).padStart(2, '0'),String(date.getHours()).padStart(2, '0'),String(date.getMinutes()).padStart(2, '0'),String(date.getSeconds()).padStart(2, '0')].join('')
        let file_name = `${props.filter?.village_ID ? props.filter.village_ID : props.filter?.tambol_ID ? props.filter.tambol_ID : props.filter?.amphur_ID ? props.filter.amphur_ID : props.filter?.province_ID ? props.filter.province_ID : "" }_เด็กพิการ_${downloadFormat.value == "code" ? 'รหัส' : 'คำอธิบาย'}_${current_date}`
        //for csv
        if(currentFileType.value == "csv"){
            writeFile(wb, `${file_name}.csv`);
        }
        //for excel
        if(currentFileType.value == "xlsx"){
            writeFile(wb, `${file_name}.xlsx`);
        }
    }else{
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    }
    downloadFile.value = false
    emits("downloadCsvComplete")
    emits("downloadExcelComplete")
}

</script>

<style lang="scss">

// .tabulator .tabulator-row .tabulator-cell[tabulator-field="action"]:hover{
//     cursor:crosshair !important;
// }
</style>