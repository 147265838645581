<template>
    <div>
        <Accordion
            accordion-id="question_step"
            class="px-6"
            :customClass="['!border-0 !shadow-none', 'bg-[#f6f6f6]']"
            isOpen
        >
            <template v-slot:header-content>
                <h2 class="text-md text-theme-2 font-semibold">
                    หัวข้อหลัก
                </h2>
            </template>
            <template v-slot:custom-icon>
            <div class="flex flex-row items-center">
              <button class="btn btn-primary bg-primary-btn mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1">
                  <ChevronDownIcon class="h-6 w-6" />
              </button>
            </div>
        </template>
        <template v-slot:body-content>
            <div class="col-span-12">
                <div class="wizard flex flex-col justify-center py-4 gap-2">
                    <div class="intro-x flex items-center z-10 steps"
                        :class="{ 'mt-5': key > 0 }" v-for="(header, key) in stepForm" :key="key"
                    >
                        <div class="relative">
                            <button class="w-10 h-10 rounded-full btn" :class="[
                                header.step == currentStep.step ||
                                allSaveStep.includes(header.step)
                                    ? 'bg-primary-2 text-white'
                                    : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
                                ]"
                                @click="isView || isEdit || isAdminManagement ? jumpToStep(header.step) : null"
                            >
                                {{ header.step }}
                            </button>
                            <div class="
                                w-4
                                h-4
                                flex
                                items-center
                                justify-center
                                absolute
                                rounded-full
                                text-white
                                bg-theme-10
                                bottom-0
                                "
                                style="left: 53%"
                                v-show="allSaveStep.includes(header.step) && !isAdminManagement"
                            >
                                <CheckIcon class="w-3 h-3 text-white" />
                            </div>
                        </div>
                        <div class="
                            font-medium
                            text-base
                            ml-3
                        ">
                            {{ header.title }}
                        </div>
                    </div>
                    <!-- <div class="
                        wizard__line
                        hidden
                        lg:block
                        w-2/3
                        bg-gray-200
                        dark:bg-dark-1
                        absolute
                        mt-5
                        " /> -->
                </div>
            </div>
        </template>
        </Accordion>
        <hr class="mt-8" />
        <AddQuestionForm class="p-6 pb-0 mb-[-0.75rem] flex justify-end" :editData="editData" :currentStep="currentStep" :questionList="questionList" @saveSuccess="emit('saveSuccess', $event, currentStep), editData = null, currentHeaderData = null" :currentHeaderData="currentHeaderData" v-model="openAddQuestionModal" :questionnaireData="questionnaireData" v-if="isAdminManagement"/>

        <div class="p-6" v-if="questionList" ref="questionSection">
            <Step1 :class="[currentStep.form == 1 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @skip-step="skipStep" :house-data="houseData" :isView="isView" :isAddSurvey="isAddSurvey" @next-step-view="nextstep(findStep($event))" :isEdit="isEdit" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :checkValidate="requiredFound" />

            <Step2 :class="[currentStep.form == 2 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @prev-step="prevstep" @skip-step="skipStep" :house-data="houseData" :isView="isView" @next-step-view="nextstep(findStep($event))" :isDefaultHide="defaultHide" :houseMemberCount="houseMemberCount" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :checkValidate="requiredFound" />

            <Step3 :class="[currentStep.form == 3 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @prev-step="prevstep" @skip-step="skipStep" :house-data="houseData" :isView="isView" @next-step-view="nextstep(findStep($event))" :isDefaultHide="defaultHide" :houseMemberCount="houseMemberCount" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :checkValidate="requiredFound" />

            <Step4 :class="[currentStep.form == 4 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @prev-step="prevstep" @skip-step="skipStep" :house-data="houseData" :isView="isView" @next-step-view="nextstep(findStep($event))" :isDefaultHide="defaultHide" :houseMemberCount="houseMemberCount" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :checkValidate="requiredFound" />

            <Step5 :class="[currentStep.form == 5 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @prev-step="prevstep" @skip-step="skipStep" :house-data="houseData" :isView="isView" @next-step-view="nextstep(findStep($event))" :isDefaultHide="defaultHide" :houseMemberCount="houseMemberCount" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :checkValidate="requiredFound" />

            <Step6 :class="[currentStep.form == 6 ? 'block' : 'hidden']" @exit-form="exitForm" @next-step="checkFormDisplay" @prev-step="prevstep" :house-data="houseData"  :houseMemberCount="houseMemberCount" :isView="isView" :questionList="questionList" @edit-question="editData = $event, openAddQuestionModal = true" :isAdminManagement="isAdminManagement" @update-status="updateQuestionStatus" @delete-question="emit('deleteQuestion', $event, currentStep)" :isDisabledSubmitBtn="isDisabledSubmitBtn" @addQuestion="currentHeaderData = $event, openAddQuestionModal = true" :update-result="dataUpdate" :house_id="house_id" />
        </div>

    </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import Step1 from "./step-1/index.vue";
import Step2 from "./step-2/index.vue";
import Step3 from "./step-3/index.vue";
import Step4 from "./step-4/index.vue";
import Step5 from "./step-5/index.vue";
import Step6 from "./step-6/index.vue";
import { uploadfile } from "@/utils/minio.js";
import Swal from "sweetalert2";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import "@vuepic/vue-datepicker/dist/main.css";
import store from "@/store";
import localForage from "localforage"
import AddQuestionForm from "@/components/add-question-form/index.vue"
import Accordion from "@/components/base/custom-accordion/index.vue"
const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

var houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const isDisabledSubmitBtn = ref(false)


const onlineComputed = computed(()=> store.getters["network/isOnline"])
const questionSection = ref(null)
const props = defineProps({
    isEdit: {type: Boolean, default: false},
    isView: {type: Boolean, default: false},
    isAddSurvey: {type: Boolean, default: false},
    houseData : {type: Object},
    addHouseholdModal: { type: Boolean, default: false },
    reloadTable: { type: Boolean, default: false },
    editModal: { type: Boolean, default: false },
    cancelModal: {
        type: Boolean,
        default: false,
    },
    questionList:{
        type: Array
    },
    isAdminManagement:{
        type: Boolean,
        default: false
    },
    currentStepObj: {
        type: Object
    },
    questionnaireData: {
        type: Object
    }
});

const currentStep = ref({
    step: 1,
    form: 1,
});
const allSaveStep = reactive([]);
const submitBtn = ref(false);

const formLabel = [
    "ข้อมูลพื้นฐานของครัวเรือน",
    "ข้อมูลด้านกายภาพและระบบสาธารณูปโภคของครัวเรือน",
    "สถานะทางเศรษฐกิจของครัวเรือน",
    "การเข้าถึงความคุ้มครองทางสังคม",
    "การย้ายถิ่นฐานและการส่งเงินกลับ",
    "สำหรับทีมสำรวจข้อมูล",
];

// "พัฒนาการของเด็ก",
// "การศึกษา",
// "การฝึกอบรม",
// "ทักษะที่จำเป็น",
// "สุขภาพ",
// "อาชีพ",
// "รายได้",
// "รายจ่าย",
// "สวัสดิการ",

// var dataForm = reactive({
//     step01: {},
//     step02: {},
//     step03: {},
//     step04: {},
//     step05: {},
//     step06: {}
// });

var step = reactive([]);
const jobless = ref(false);
const stepForm = ref({});
const render = ref(true);
const reloadTable = ref(false);
const saveStep = ref(0);
const router = useRouter();
const route = useRoute();
const tempID = ref(route.params.hhid ?? null)
const currentVillageID = ref(null)
const houseNumberID = ref(null)
const openAddQuestionModal = ref(false)
const currentHeaderData = ref(false)
const dataUpdate = ref(null)
const requiredFound = ref(null)

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
var time = today.getHours().toString() + ":" + today.getMinutes().toString();
today = yyyy + mm + dd + time;

let image;
let temp_arr_image = [];
let aar_b = [];
const defaultHide = ref(false)
const houseMemberCount = ref(0)
const editData = ref(null)

const getScreen = () =>{
  if(window.innerWidth < 1024){
    defaultHide.value = true
  }
  else{
    defaultHide.value = false
  }
}
onMounted(()=>{
    if(onlineComputed.value)
        initValue();
    getScreen()
    window.addEventListener("resize", getScreen)
})
onUnmounted(()=>{
    window.removeEventListener("resize", getScreen);
})
// watch(
//     () => props.addHouseholdModal,
//     async () => {
//         if (props.addHouseholdModal) {
//             cash("#header-footer-modal-preview-household").modal("show");
//             initValue();
//         }
//     }
// );

// function
const initialStepForm = (form) => {
    step = [];
    if(props.questionList){
        form.forEach((e, i) => {
            let stepObj = props.questionList.find(({step})=> step.step_number == e).step
            step.push({
                title: stepObj.step_title,
                step: i + 1,
                form: e,
                id: stepObj.step_id
            });
        });
    }
    return step;
};

const prepareStepForm = (age) => {
    var f = [1, 2, 3, 4, 5, 6];
    // if (age <= 12) {
    //   f.push(2);
    // }
    // if (age >= 3) {
    //   f.push(3);
    // }

    // if (age >= 12) {
    //   f.push(5);
    // }
    // if (age >= 15) {
    //   f.push(4, 7);
    // }

    f.sort((a, b) => {
        return a - b;
    });

    return f;
};

const findStep = (formnumber) => {
    return stepForm.value.find((e) => e.form == formnumber);
};

const exitForm = () => {
    emit("exit-form")
    /* swalCustom
    .fire({
        html: props.isView ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
                <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        allowOutsideClick: false,
    })
    .then((result) => {
        if (result.isConfirmed) {
            router.go(-1)
        }
    }); */
};

const checkFormDisplay = (data, formNumb) => {
    // console.log(data);
    const data_questionnaire = {}
    switch (formNumb) {
        case 1:
            data_questionnaire["step1"] = Object.assign({}, data)
            submitForm(data_questionnaire, formNumb);
            break;
        case 2:
            data_questionnaire["questionnaires"] = Object.assign({}, data)
            data_questionnaire["questionnaires"]["status"] = "DRAFT"
            submitForm(data_questionnaire, formNumb);
            break;
        case 3:
            data_questionnaire["questionnaires"] = Object.assign({}, data)
            data_questionnaire["questionnaires"]["status"] = "DRAFT"
            submitForm(data_questionnaire, formNumb);
            break;
        case 4:
            data_questionnaire["questionnaires"] = Object.assign({}, data)
            data_questionnaire["questionnaires"]["status"] = "DRAFT"
            submitForm(data_questionnaire, formNumb);
            break;
        case 5:
            data_questionnaire["questionnaires"] = Object.assign({}, data)
            data_questionnaire["questionnaires"]["status"] = "DRAFT"
            submitForm(data_questionnaire, formNumb);
            break;
        case 6:
            data_questionnaire["questionnaires"] = Object.assign({}, data)
            data_questionnaire["step"] = "last"
            submitForm(data_questionnaire, formNumb);
            break;
    }

};

const nextstep = (current) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
    var step = current.step + 1;
    var next = stepForm.value.find((e) => {
        return e.step == step;
    });
    currentStep.value = next;
    if(!allSaveStep.includes(current.step)){
        // saveStep.value = current.step
        allSaveStep.push(current.step)
    }
};

const prevstep = (currentForm) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    var current = findStep(currentForm);
    if (current.step == stepForm.value[0].step) return;
    var step = current.step - 1;
    var pre = stepForm.value.find((e) => {
        return e.step == step;
    });
    currentStep.value = pre;
};

const skipStep = (currentForm) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    let current = findStep(currentForm);
    //last step cannot skip
    if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
    let step = current.step + 1;
    currentStep.value = stepForm.value.find((e) => {
        return e.step == step;
    });
}

const jumpToStep = (toStep) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    if(!allSaveStep.includes(1) && !props.isView && !props.isAdminManagement) return;
    currentStep.value = stepForm.value.find((e) => {
        return e.step == toStep;
    });
}
const prepareData = (dataObj) => {
    if (dataObj?.questionnaires && Object.keys(dataObj?.questionnaires)) {
        Object.keys(dataObj.questionnaires).forEach((q) => {
            if(dataObj.questionnaires[q] === undefined || dataObj.questionnaires[q] === "" || ((typeof dataObj.questionnaires[q] === 'object' && dataObj.questionnaires[q] !== null && !(dataObj.questionnaires[q] instanceof Date)) ? Array.isArray(dataObj.questionnaires[q]) ? dataObj.questionnaires[q].length == 0 : Object.keys(dataObj.questionnaires[q]).length == 0 : false)){
                dataObj.questionnaires[q] = null;
            }
        });
    }
    return dataObj
};


const allkey = {}
const house_id = ref(route.params.hhid ?? null)//update step1//add survey
const house_surveys_id = ref(route.params.surveyid ?? null) //update questionnaire by id
const saveHousehold = (data) => {
  return new Promise((resolve, reject)=>{
    const config = {
      method: (house_id.value || house_surveys_id.value) ? 'PUT' : 'POST',
      url: `${process.env.VUE_APP_BASE_API}house`,
      data: data,
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      },
    }
    axios(config)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

const getSequence = async (village_ID) => {
    if(village_ID && onlineComputed.value){
        if( currentVillageID.value === village_ID)
            return;

        const res = await axios.get(`${process.env.VUE_APP_BASE_API}autoincreament`,{
            params: {sequenceName: village_ID},
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
        }).catch(err => err);
        if(res.status === 200){
            currentVillageID.value = village_ID
            return `${res.data.sequenceName}${String(res.data.sequenceValue).padStart(6, '0')}`
        }
    }
    return;
}
const checkRelateResult = ({relate_question}, answerObj) => {
    if(relate_question.length == 0)
        return true

    let tempQuestionAppear = []
    for(const {code, answer, type} of relate_question){
        let has_answer
        if(type == "radio"){
            has_answer = answer.includes(answerObj[code]?.code)
        }
        else if(type == "checkbox"){
            has_answer = answerObj[code].findIndex(x => answer.includes(x.code)) != -1 ? true : false
        }
        else if(type == "number"){//for type number fix answer at index 0 and condition is greater than only
            has_answer = answerObj[code] > 0
        }
        else if(type == "select"){
            has_answer = answer.includes(answerObj[code])
        }
        else{
            has_answer = answerObj[code] ? true : false
        }
        tempQuestionAppear = [...tempQuestionAppear, has_answer]
    }

    return tempQuestionAppear.every(elm => elm)
}
const getSurvey = async() =>{
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}house/${house_id.value}`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const res = await axios.get(url, { headers }).catch(() =>
      "error"
    );
    if (res.status === 200) {
        return res.data
    }
}

const submitForm = async (dataObj, stepNum) =>{
    try{
        isDisabledSubmitBtn.value = true
        const dataForm = prepareData(dataObj)
        let payload_data, gotoNextStep = true
        if(dataForm.step1){
            const house_number_sequence = await getSequence(dataForm.step1.villageSelected._id.village_ID)
            if(house_number_sequence)
                houseNumberID.value = house_number_sequence

            payload_data = {
                house_number_ID: houseNumberID.value,
                township: dataForm.step1.township,
                postcode: dataForm.step1.postcode,
                house_number_11: dataForm.step1.house_number_11,
                address_num: dataForm.step1.address_num,
                // moo: dataForm.step1.moo,
                soi: dataForm.step1.soi,
                road: dataForm.step1.road,
                sector: dataForm.step1.sector,
                // latitude: dataForm.step1.latitude,
                // longitude: dataForm.step1.longitude,
                village_ID: dataForm.step1.villageSelected._id.village_ID,
                village_name: dataForm.step1.villageSelected.village_name,
                tambol_ID: dataForm.step1.villageSelected._id.tumbol_ID,
                tambol_name: dataForm.step1.villageSelected.tumbol_name,
                amphur_ID: dataForm.step1.villageSelected._id.amphur_ID,
                amphur_name: dataForm.step1.villageSelected.amphur_name,
                province_ID: dataForm.step1.villageSelected._id.province_ID,
                province_name: dataForm.step1.villageSelected.province_name,
                house_surveys:{
                    village_ID: dataForm.step1.villageSelected._id.village_ID,
                    village_name: dataForm.step1.villageSelected.village_name,
                    tambol_ID: dataForm.step1.villageSelected._id.tumbol_ID,
                    tambol_name: dataForm.step1.villageSelected.tumbol_name,
                    amphur_ID: dataForm.step1.villageSelected._id.amphur_ID,
                    amphur_name: dataForm.step1.villageSelected.amphur_name,
                    province_ID: dataForm.step1.villageSelected._id.province_ID,
                    province_name: dataForm.step1.villageSelected.province_name,
                    informant_prefix_name: dataForm.step1.informant_prefix_name,
                    informant_name: dataForm.step1.informant_name,
                    informant_surname: dataForm.step1.informant_surname,
                    position: dataForm.step1.position,
                    member_cnt: dataForm.step1.member_cnt,
                    member_reg_cnt: dataForm.step1.member_reg_cnt,
                    status: "DRAFT"
                }
            }
            if(house_id.value){
                payload_data["id"] = house_id.value
                payload_data["update_by"] = store.getters["userAuth/getUserID"]
            }
            else{
                payload_data["create_by"] = store.getters["userAuth/getUserID"]
            }
            if(house_surveys_id.value)
                payload_data["house_surveys"]["id"] = house_surveys_id.value
            
            //set member count as props value
            houseMemberCount.value = payload_data.house_surveys.member_cnt
            /* if (!((dataForm.step1?.imageFile?.length === temp_arr_image.length) && dataForm.step1.imageFile.every((val, index) => (val.name === temp_arr_image[index].name) && (val.size === temp_arr_image[index].size)))) {
                gotoNextStep = false//set to false to prevent goto next step before success
                temp_arr_image = [...dataForm.step1.imageFile]
                let arr_image = []
                for (let i = 0; i < dataForm.step1.imageFile.length; i++) {
                    try{
                        const result = await uploadfile(
                            dataForm.step1.imageFile[i],
                            `house-image`
                        )
                        if (result.res.status === 200) {
                            image = {
                                // file_name: result.file_name,
                                img_house_url: result.url,
                                img_date: new Date().toISOString(),
                                img_user: store.getters["userAuth/getUserID"],
                            };
                            arr_image.push(image);
                        }
                    }
                    catch(err){
                        swalCustom.fire({
                            text: "อัพโหลดรูปภาพไม่สำเร็จ โปรดลองอีกครั้ง",
                            icon: "warning",
                            // timer: 3000,
                            confirmButtonText: "ปิด",
                        });
                        break;
                    }
                }
                if (arr_image.length > 0) {
                    gotoNextStep = true //success
                    payload_data["image"] = arr_image
                }
            } */
        }else{
            payload_data = {
                house_surveys: {...dataForm.questionnaires, id: house_surveys_id.value},
                id: house_id.value,
                update_by: store.getters["userAuth/getUserID"]
            }
        }
        if(gotoNextStep){
            if(dataForm?.step == "last"){
                let infoText = "<div>ยืนยันบันทึกข้อมูล</div>"
                let requiredQuestion
                if(onlineComputed.value){
                    requiredFound.value = null
                    const resultAnswer = await getSurvey()
                    const {house_surveys, ...rest} = resultAnswer
                    const answerList = {...rest, ...resultAnswer.house_surveys[0]}
                    let requiredQuestionList = props.questionList.reduce((a, v)=>{
                        let question = v.questions.reduce((list,ques)=>{
                            let new_question = ques.question_lists.filter(e=>e.required || e.choices.some(({required}) => required) || e.required_count_member).map((item)=> {
                                item.step = v.step
                                return item
                            })
                            return [...list, ...new_question]
                        },[])
                        return [...a, ...question]
                    },[])
                    requiredQuestion = requiredQuestionList.find((item)=>{
                        return (item.required ? item.required_count_member ? answerList[item.question_code] > houseMemberCount.value : answerList[item.question_code] == undefined : item.required_count_member ? answerList[item.question_code] > houseMemberCount.value : answerList[item.question_code] == undefined) && checkRelateResult(item, answerList) && item.question_type != "multiple-inputs-group" && item.question_type != "date-range" && item.step.step_number != 6 && (item.question_code == "address_num" ? answerList[item.question_code] : true) && (item.question_code == "provinceSelected" ? answerList["province_name"] == undefined : true) && (item.question_code == "districtSelected" ? answerList["amphur_name"] == undefined : true) && (item.question_code == "subDistrictSelected" ? answerList["tambol_name"] == undefined : true) && (item.question_code == "villageSelected" ? answerList["village_name"] == undefined : true)
                    })
                    if(requiredQuestion){
                        infoText = `<div>ไม่สามารถบันทึกได้ กรุณากรอกข้อมูลที่บังคับกรอกให้ครบถ้วน</div> <div>กดปุ่ม <strong style="color: #246bc3;">"ตกลง"</strong> เพื่อกรอกข้อมูลเพิ่มเติม </div><div>หรือกดปุ่ม <strong style="color: #393939;">"บันทึกแบบร่าง"</strong> เพื่อดำเนินการต่อในภายหลัง</div>`
                    }
                }
                swalCustom
                .fire({
                    html: infoText,
                    icon: "info",
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: "ตกลง",
                    cancelButtonText: onlineComputed.value ? "บันทึกแบบร่าง" : "ยกเลิก",
                })
                .then(async (result) => {
                    if (result.isConfirmed && onlineComputed.value) {
                        if(requiredQuestion){
                            let stepJump = stepForm.value.find((s) => s.form == requiredQuestion.step.step_number)?.step
                            if(stepJump){
                                requiredFound.value = requiredQuestion
                                setTimeout(()=>{
                                    jumpToStep(stepJump)
                                    isDisabledSubmitBtn.value = false
                                }, 500)
                            }
                            return
                        }

                        payload_data["house_surveys"]["status"] = "COMPLETE"
                    }
                    else if(result.dismiss === Swal.DismissReason.cancel){
                        payload_data["house_surveys"]["status"] = "DRAFT"
                    }
                    try{
                        if(onlineComputed.value){
                            const res = await saveHousehold(payload_data)
                            if (res.status === 200) {
                                swalCustom
                                .fire({
                                    text: "สำเร็จ",
                                    icon: "success",
                                    timer: 3000,
                                    confirmButtonText: "ปิด",
                                })
                                .then(() => {
                                    //handle before route leave for complete form
                                    emit("complete-form")
                                    isDisabledSubmitBtn.value = false
                                    // router.push({
                                    //     name: "main-house-hold"
                                    // });
                                });
                            }
                            else{
                                isDisabledSubmitBtn.value = false
                                swalCustom.fire({
                                    text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                                    icon: "warning",
                                    // timer: 3000,
                                    confirmButtonText: "ปิด",
                                });
                            }
                        }
                        else{
                            if(result.isConfirmed){
                                let keyId = props.isEdit ? route.params.hhid : String(tempID.value)
                                await houseStore.getItem(keyId).then((value)=>{
                                    payload_data.house_surveys.status = "DRAFT"
                                    if(props.isEdit)
                                        payload_data["house_surveys"]["id"] = value["house_surveys"]["id"]
                                    else
                                        payload_data["house_surveys"]["id"] = 1
                                    value.update_by = store.getters["userAuth/getUserID"]
                                    value.house_surveys = {...value.house_surveys, ...payload_data.house_surveys}
                                    houseStore.setItem(keyId, JSON.parse(JSON.stringify(value)))
                                })
                                isDisabledSubmitBtn.value = false
                                emit("complete-form")
                            }
                            else{
                                isDisabledSubmitBtn.value = false
                            }
                        }
                    }
                    catch(error){
                        isDisabledSubmitBtn.value = false
                        console.log("error: ",error);
                        swalCustom.fire({
                            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                            icon: "warning",
                            // timer: 3000,
                            confirmButtonText: "ปิด",
                        });
                    }
                })

            }else{
                if(onlineComputed.value){
                    const res = await saveHousehold(payload_data)
                    if (res.status === 200) {
                        if(res.data.lastErrorObject?.upserted)
                            house_id.value = res.data.lastErrorObject.upserted
                        if(res.data?.house_surveys_id)
                            house_surveys_id.value = res.data.house_surveys_id

                        isDisabledSubmitBtn.value = false
                        nextstep(findStep(stepNum));
                    }
                    else{
                        isDisabledSubmitBtn.value = false
                        swalCustom.fire({
                            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                            icon: "warning",
                            // timer: 3000,
                            confirmButtonText: "ปิด",
                        });
                    }
                }
                else{
                    if(!props.isEdit){
                        //prevent no data record for len 0 
                        const houseLen = await houseStore.length()
                        const keysList = houseLen ? await houseStore.keys() : []
                        const keys = keysList.length ? Number(keysList[keysList.length-1]) : 0

                        if(tempID.value){//update at step1 2 ..5
                            await houseStore.getItem(String(tempID.value)).then((value)=>{
                                if(stepNum == 1){
                                    //update data outside house_surveys from step1
                                    payload_data["house_number_ID"] = value["house_number_ID"]
                                    const {house_surveys, ...rest} = payload_data
                                    value = {...value, ...rest}
                                }
                                payload_data["house_surveys"]["id"] = value["house_surveys"]["id"]
                                // if(payload_data.update_by)
                                    // value.update_by = payload_data.update_by
                                value.update_by = store.getters["userAuth/getUserID"]
                                value.house_surveys = {...value.house_surveys, ...payload_data.house_surveys}
                                houseStore.setItem(String(tempID.value), JSON.parse(JSON.stringify(value)))
                            })
                        }
                        else{//insert step1 first time
                            let date = new Date()
                            payload_data["house_number_ID"] = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}-${("0" + date.getHours()).slice(-2)}${("0" + date.getMinutes()).slice(-2)}${("0" + date.getSeconds()).slice(-2)}-${tempID.value ?? keys+1}`//YYYYDDMM-HHMMSS-Running
                            payload_data["house_surveys"]["id"] = 1
                            payload_data["id"] = tempID.value ?? keys + 1

                            tempID.value = tempID.value ?? keys + 1
                            houseStore.setItem(String(tempID.value), JSON.parse(JSON.stringify(payload_data)))
                        }
                        isDisabledSubmitBtn.value = false
                        nextstep(findStep(stepNum));
                    }
                    else{
                        //update house
                        houseStore.getItem(route.params.hhid).then((value)=>{
                            if(stepNum == 1){
                                //update data outside house_surveys from step1
                                //check village with payload and value if not match change house_number_ID
                                if(payload_data.village_ID != value.village_ID){
                                    let date = new Date()
                                    payload_data["house_number_ID"] = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}-${("0" + date.getHours()).slice(-2)}${("0" + date.getMinutes()).slice(-2)}${("0" + date.getSeconds()).slice(-2)}-${tempID.value}`
                                }
                                else{
                                    payload_data["house_number_ID"] = value["house_number_ID"]
                                }
                                //don't change value of house_id and house_surveys_id in case sync and save member, neet, cwd failed that use to update house data when sync again
                                const {house_surveys, id, house_id, house_surveys_id, ...rest} = payload_data
                                value = {...value, ...rest}
                            }
                            value.update_by = store.getters["userAuth/getUserID"]
                            payload_data.house_surveys.id = value.id
                            value.house_surveys = {...value.house_surveys, ...payload_data.house_surveys}
                            houseStore.setItem(route.params.hhid, JSON.parse(JSON.stringify(value)))
                            isDisabledSubmitBtn.value = false
                            nextstep(findStep(stepNum));
                        })
                    }
                }
            }
        }
    }
    catch(error){
        isDisabledSubmitBtn.value = false
        console.log("error! ",error);
        swalCustom.fire({
            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
            icon: "warning",
            // timer: 3000,
            confirmButtonText: "ปิด",
        });
    }

}

const initValue = () => {
    stepForm.value = initialStepForm([1, 2, 3, 4, 5, 6]);
    if(props.currentStepObj){
        currentStep.value = props.currentStepObj
    }
    else{
        currentStep.value = stepForm.value.find((e) => {
            return e.step == 1;
        });
    }
    // render.value = true;
};



const emit = defineEmits(["modal-value", "exit-form", "complete-form", "saveSuccess", "deleteQuestion"]);
// const houseCode = ref('');
const sumId = ref(true);
// const districtArr = ref([]);
const showHouseheadLayout = ref(false);
const checkClickbtn = ref(null);
// const municipality_code = ref('');
// const municipality_name = ref('');
// const editModal = ref(props.editModal);

/* var state = reactive({
    addHouseholdModal: false,
    houseNo: "",
    houseCode: "",
    countRoom: "",
    householdHeadFirstName: "",
    householdHeadLastName: "",
    householdHeadIdCardNo: "",
    postCode: "",
    villageSelected: null,
    provinceSelected: null,
    districtSelected: null,
    subDistrictSelected: null,
    townshipSelected: null,
    titleSelected: null,
    statusInformantSelected: null,
    houseTypeSelected: { id: null, label: null },
    statusHouseTypeSelected: { id: null, label: null },
    conditionHouseTypeSelected: { id: null, label: null },
    typeofFamilySelected: null,
    envSuitableOption: 1,
    houseNoOption: 1,
    houseHigenic: 1,
    houseSolidOption: 1,
    birthdate: null,
    subHouseTypeSelected: { id: null, label: null },
    name_operator: null,
    department: null,
    house_date: null,
    mobile_num: null,
    user_id: null,
    operatorObj: {
        method_operator: [],
    },
});

watch(
    () => state.householdHeadIdCardNo,
    () => {
        if (state.householdHeadIdCardNo.length === 13) {
            sumId.value = idState();

            if (!editModal.value) {
                checkClickbtn.value = false;
                showHouseheadLayout.value = false;
            }
        }
    }
); */

watch(()=> props.houseData, (val)=>{
    if(val.village_ID)
        currentVillageID.value = val.village_ID
    if(val.house_number_ID)
        houseNumberID.value = val.house_number_ID
    if(val.house_surveys){
        houseMemberCount.value = val.house_surveys?.member_cnt
    }
})
watch(()=> props.questionList, ()=>{
    if(!onlineComputed.value){
        initValue();
    }
})
const updateQuestionStatus = async (data) => {
    dataUpdate.value = null
    const res = await axios.put(
        `${process.env.VUE_APP_BASE_API}admin/question/status/${data._id}`,{},
        {
            headers: {
                Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
                "Content-Type": `application/json; charset=utf-8`,
            }
        }
    ).catch(() => {
        swalCustom.fire({
            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
            icon: "warning",
            //timer: 3000, //timeOut for auto-close
            confirmButtonText: "ปิด",
        });
    }).then(()=>{
        dataUpdate.value = data
    });

    if(res.status == 200){
        if(data.status == "active"){
            data.status = "inactive"
        }
        else{
            data.status = "active"
        }
        dataUpdate.value = data
    }
}


/* const idState = () => {
    if (state.householdHeadIdCardNo.length == 13) {
        var sum, i;
        if (state.householdHeadIdCardNo.substring(0, 1) == 0) return false;
        for (i = 0, sum = 0; i < 12; i++)
            sum += parseFloat(state.householdHeadIdCardNo.charAt(i)) * (13 - i);
        if (
            (11 - (sum % 11)) % 10 !=
            parseFloat(state.householdHeadIdCardNo.charAt(12))
        )
            return false;
        return true;
    } else if (state.householdHeadIdCardNo.length > 1) {
        return false;
    } else {
        return null;
    }
}; */


</script>

<style lang="scss">
.add-photo {
    height: 100px;
    width: 110px;
    background-color: rgb(237, 241, 245);
    border: 3px solid #7988bf;
    border-radius: 15px;
}


// .selecthh{
// z-index:20000 !important;
// }
.selecthh .vs__dropdown-menu {
    z-index: 20000 !important;
}


.form-check-input {
    border-color: black !important;
}
</style>
