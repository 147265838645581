<template>
  <template-layout :customMenu="customMenu" :breadCrumbList="breadcrumbComputed">
    <div>
      <div class="w-1/6 h-1/6 mx-auto mt-8" v-if="(loadingQuestion && (isEdit || isView ? loadingMember : loadingQuestion))">
        <LoadingIcon icon="oval" class="w-8 h-8 text-white" />
      </div>
      <div v-else>
        <div class="intro-y flex sm:flex-row items-center mt-8 justify-end" :class="{'hidden': loadingMember || loadingQuestion}">
          <h1 class="text-lg font-medium mr-auto">
            {{isView ? 'ดูข้อมูลสมาชิก' : isEdit ? 'แก้ไขข้อมูลสมาชิก' :'เพิ่มสมาชิก'}}
          </h1>
          <button
            class="btn btn-primary bg-primary-btn border-primary-btn mr-1"
            @click="isEdit = true, isPromptRouteLeave = true, isView = false"
            v-if="isView && memberData?.member_surveys.status == 'DRAFT' && (getPermissionAccessMenu('update') || !onlineComputed)"
          >
            <Edit2Icon class="h-3 w-3 mr-2" />แก้ไข
          </button>
        </div>
    
        <Information class="mt-5 intro-y" :class="{'hidden': loadingMember || loadingQuestion}" :information="memberData" v-if="(isView || isEdit) && memberData" />
        <div class="intro-y box pb-10 pt-10 sm:pb-20 sm:pt-8 mt-5" :class="{'hidden': loadingMember || loadingQuestion}">
          <form-wizard :memberData="memberData" :isView="isView" :isEdit="isEdit" @exit-form="exitForm" @complete-form="completeForm" :key="route.params.memberid" :questionList="question_data?.[0].data"/>
        </div>
      </div>
    </div>
    <SurveyInformationModal
      id="modal-member-survey-info"
      v-model="showModal"
      modal-size="modal-lg"
      close-button
      @click:cancel="completeForm(true)"
      @click:ok="showModal = false"
      header="แนะนำตัว และวัตถุประสงค์ของการสัมภาษณ์"
      modal-name="member-survey"
    >
      <div>
        <p>สำหรับในส่วนนี้ จะเป็น <strong>การสอบถามข้อมูลของสมาชิกทุกท่านที่อาศัยอยู่ในครัวเรือนนี้</strong> หากสมาชิกท่านใดไม่อยู่บ้าน หรือไม่สามารถตอบคำถามได้ ท่านสามารถตอบแทนได้เช่นกันค่ะ/ครับ หากคำถามใดไม่สามารถตอบแทนได้ ให้ข้ามคำถามข้อนั้นไป โดยข้อคำถามจะครอบคลุมเรื่องพัฒนาการของเด็ก (กรณีที่ครัวเรือนของท่านมีเด็กเล็ก) การศึกษา สุขภาพ อาชีพ รายได้ หนี้สิน เงินออม และสวัสดิการต่าง ๆ ที่ท่านได้รับ และเช่นเดิม หากท่านไม่ต้องการตอบคำถามข้อใด หรือหยุดการสัมภาษณ์ ท่านสามารถแจ้งให้เราทราบได้ตลอดเวลา</p>
        <p>ขออนุญาตเรียนให้ท่านทราบอีกครั้งว่าข้อมูลทุกอย่างที่เราสอบถามจะ<strong>ใช้สำหรับการศึกษา วิเคราะห์ และการออกแบบนโยบายและมาตรการต่าง ๆ ที่จะช่วยสนับสนุน
        ท่านและคนไทยอีกหลายล้านคนให้มีคุณภาพชีวิตที่ดีขึ้น</strong> ได้อย่างเหมาะสมและตรงกับปัญหาความต้องการมากที่สุด โดยเรา <strong>ขอความอนุเคราะห์ให้ท่านตอบข้อมูลตามความเป็นจริง</strong> และขอให้ท่านมั่นใจว่า <strong>ข้อมูลที่เราพูดคุยกันวันนี้จะถูกเก็บเป็นความลับ ไม่มีการเปิดเผยตัวตนผู้ตอบแบบสอบถาม และจะไม่ถูกนำไปใช้ในการพิจารณายกเลิกสิทธิ สวัสดิการ หรือสิทธิประโยชน์ที่ท่านพึงได้หรือมีค่าใช้จ่ายอื่นใดเกิดขึ้นในภายหลัง</strong></p>
        <p class="mt-3"><u>หมายเหตุ</u></p>
        <p>1. ควรทำการสำรวจ ณ บ้านพักอาศัยของผู้ถูกสัมภาษณ์ เพื่อที่จะได้สังเกตสภาพแวดล้อมของที่อยู่อาศัยได้</p>
        <p>2. ควรขอดูทะเบียนบ้าน เพื่อบันทึกเลขที่บ้าน ที่อยู่ ชื่อของสมาชิก และหมายเลขบัตรประชาชนของทุกคนในครัวเรือนได้ถูกต้อง</p>
        <p>3. สำรวจข้อมูลของสมาชิกทุกคนในครัวเรือน หากสมาชิกท่านใดไม่อยู่บ้าน เป็นผู้พิการ หรือผู้สูงอายุที่ไม่สามารถให้ข้อมูลได้ ให้หัวหน้าครัวเรือน บุคคลที่อาศัยอยู่
        ในครัวเรือน หรือผู้ที่ได้รับความยินยอมจากบุคคลในครัวเรือนเป็นผู้ให้ข้อมูลแทนได้ และในกรณีเด็กอายุต่ำกว่า 15 ปี ให้ผู้ปกครองเป็นผู้ให้ข้อมูลแทนได้</p>
      </div>
    </SurveyInformationModal>
  </template-layout>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import formWizard from '@/components/add-member-wizard/index.vue'
import TemplateLayout from "@/layouts/side-menu/Main.vue"
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from "axios"
import Swal from "sweetalert2";
import Information from "@/components/info-section/Main.vue"
import SurveyInformationModal from "@/components/survey-information-modal/Main.vue";
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission"

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});

const surveyStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'survey'
});

const route = useRoute()
const router = useRouter()
const store = useStore()
const memberData = ref(null)
const isView = ref(false)
const isEdit = ref(false)
const customMenu = ref([])
const householdData = ref(null)
const houseSurveysId = ref(route.params.surveyid)
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const loadingMember = ref(false)
const showModal = ref(false)
const isPromptRouteLeave = ref(true)
const loadingQuestion = ref(false)
const routeChangeLoading = ref(false)
const question_data = ref(null)
const getSurvey = async() =>{
  loadingMember.value = true
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}member/${route.params.memberid}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const params = {
    house_survey_id: houseSurveysId.value
  }
  const res = await axios.get(url, { params ,headers }).catch(() =>{
    Swal.fire({
      icon: "warning",
      text: "ระบบผิดพลาด",
    })
    loadingMember.value = false
  }
  );
  if (res.status === 200) {
    loadingMember.value = false
    memberData.value = res.data
  }
}
const getHouseSurvey = async() =>{
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}house/${route.params.hhid}`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const res = await axios.get(url, { headers }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        householdData.value = res.data
        if(householdData.value.house_surveys?.length > 0){
            householdData.value["questionnaires"] = householdData.value.house_surveys.find(x=>x._id == houseSurveysId.value)
        }
    }
}
const getCustomMenu = () => {
  if(householdData.value?.house_surveys?.length > 0){
    let sub_menu = []
    for(let i = householdData.value.house_surveys.length; i > 0; i-- ){
      sub_menu.push({
        icon: '',
        pageName: 'household-profile',
        title: `ครั้งที่ ${i}` ,
        params: {
          hhid: householdData.value._id,
          surveyid: householdData.value.house_surveys[i-1]["_id"]
        },
        path: `/household/${householdData.value._id}/${householdData.value.house_surveys[i-1]["_id"]}`
      })
    }
    customMenu.value = [
      {key: "devider"},
      {
        icon: "FileTextIcon",
        title: "แบบสำรวจ",
        subMenu: sub_menu
      }
    ]
    const idx = householdData.value.house_surveys.findIndex(x=>x._id == houseSurveysId.value)
    const dataMember = householdData.value.house_surveys[idx]?.member_surveys ?? []
    if(dataMember.length > 0){
      let member_menu = []
      for(let i = 0; i < dataMember.length; i++ ){
        member_menu.push({
          pageName: 'view-member',
          title: `${dataMember[i].name} ${dataMember[i].surname}` ,
          params: {
            hhid: householdData.value._id,
            surveyid: householdData.value.house_surveys[idx]._id,
            memberid: dataMember[i].member_id
          },
          path: `/member/view/${householdData.value._id}/${householdData.value.house_surveys[idx]._id}/${dataMember[i].member_id}`,
          image: dataMember[i]?.image?.img_url ? process.env.VUE_APP_IMG_API+dataMember[i].image.img_url : "default"
        })
      }
      customMenu.value = [...customMenu.value, {key: "devider"}, {icon: "UsersIcon",title: "สมาชิกในครัวเรือน",subMenu: member_menu}]
    }
  }
}
const getOfflineData = () => {
  houseStore.getItem(route.params.hhid).then((value)=>{
    memberData.value =  value.house_surveys?.members.find(x=> x.member_id == route.params.memberid)
    memberData.value.full_address = `${value.address_num} ${value.village_name} ${value.soi ?? ""} ${value.road ?? ""} ${value.tambol_name} ${value.amphur_name} ${value.province_name} ${value.postcode}`
    memberData.value.full_name = `${memberData.value.prefix_name} ${memberData.value.name} ${memberData.value.surname}`
    houseSurveysId.value = memberData.value.house_surveys_id
  })
}
const getQuestionList = async () => {
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json; charset=utf-8`,
    };
    const params = {
      question_nare_name_en: "member"
    }
    loadingQuestion.value = !loadingQuestion.value
    const res = await axios.get(url, { headers, params }).catch(() =>
        Swal.fire({
            icon: "warning",
            text: "ระบบผิดพลาด",
        })
    );
    if (res.status === 200) {
        question_data.value = res.data
        loadingQuestion.value = !loadingQuestion.value
        surveyStore.setItem('member', JSON.parse(JSON.stringify(res.data)))
    }
}
const getQuestionListOffline = async () =>{
    let res = null
    await surveyStore.getItem("member").then((value)=>{
      res = value
    })
    question_data.value = res
}
onMounted(async ()=>{
  if(route.name === "view-member"){
    isView.value = true
    isPromptRouteLeave.value = false
  }
  else if(route.name === "edit-member"){
    isEdit.value = true
  }
  else{
    showModal.value = true
  }
  if(onlineComputed.value){
    await getQuestionList()
    await getHouseSurvey()
    getCustomMenu()
    if(route.name === "view-member" || route.name === "edit-member"){
      await getSurvey()
    }
  }
  else{
    await getQuestionListOffline()
    if(route.name === "view-member" || route.name === "edit-member"){
      getOfflineData()
    }
  }
})
watch(() =>route.params, async (val)=>{
  if(val && (route.name == "view-member" || route.name == "edit-member") && onlineComputed.value){
    if(route.name === "view-member"){
      isView.value = true
      isPromptRouteLeave.value = false
    }
    //call api to get member data load into form when route params change
    await getSurvey()
  }
})
const breadcrumbComputed = computed(()=>{
  return [
    {
        name: "ครัวเรือน",
        path: "main-house-hold",
    },
    {
        name: "ข้อมูลครัวเรือน",
        path: "household-profile",
        param:{
          hhid: route.params.hhid,
          surveyid: houseSurveysId.value
        }
    },
    {
        name: `${route.name == "view-member" ? 'ข้อมูลสมาชิก' : route.name == "edit-member" ? 'แก้ไขสมาชิก' : 'เพิ่มสมาชิก'}`,
        active: true,
    }
  ]
})
const completeForm = (backStatus=null) => {
    showModal.value = false
    isPromptRouteLeave.value = false
    if(backStatus){
      router.go(-1)
    }
}
const exitForm = () =>{
  router.go(-1)
}
onBeforeRouteLeave((to, from, next) => {
  if(isPromptRouteLeave.value){
    swalCustom.fire({
      html: isView.value ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
          <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        showModal.value = false
        next()
      } else {
        next(false)
      }
    })
  }
  else{
    next()
  }
})
</script>

<style lang="scss">
</style>