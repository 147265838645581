<template>
  <div>
    <Accordion
      accordion-id="question_step"
      class="px-6"
      :customClass="['!border-0 !shadow-none', 'bg-[#f6f6f6]']"
      isOpen
    >
      <template v-slot:header-content>
          <h2 class="text-md text-theme-2 font-semibold">
              หัวข้อหลัก
          </h2>
      </template>
      <template v-slot:custom-icon>
        <div class="flex flex-row items-center">
          <button class="btn btn-primary bg-primary-btn mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1">
              <ChevronDownIcon class="h-6 w-6" />
          </button>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="col-span-12">
          <div class="wizard short-wizard flex flex-col pt-1 gap-2" id="member_wizard">
            <div class="flex items-center z-10 steps"
                :class="{ 'mt-5': key > 0 }" v-for="(header, key) in stepForm" :key="key"
            >
              <div class="relative">
                <button class="w-10 h-10 rounded-full btn" :class="[
                    header.step == currentStep.step ||
                    allSaveStep.includes(header.step)
                        ? 'bg-primary-2 text-white'
                        : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
                    ]"
                    @click="isView || isEdit || isAdminManagement ? jumpToStep(header.step) : null"
                >
                    {{ header.step }}
                </button>
                <div class="
                  w-4
                  h-4
                  flex
                  items-center
                  justify-center
                  absolute
                  rounded-full
                  text-white
                  bg-theme-10
                  bottom-0
                  "
                  style="left: 53%"
                  v-show="allSaveStep.includes(header.step) && !isAdminManagement"
                >
                  <CheckIcon class="w-3 h-3 text-white" />
                </div>
              </div>
              <div class="
                  font-medium
                  text-base
                  ml-3
              ">
                  {{ header.title }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Accordion>
    <hr class="mt-8" />
    <AddQuestionForm class="p-6 pb-0 mb-[-0.75rem] flex justify-end" :editData="editData" :currentStep="currentStep" :questionList="questionList" @saveSuccess="emit('saveSuccess', $event, currentStep), editData = null, currentHeaderData = null" :currentHeaderData="currentHeaderData" v-model="openAddQuestionModal" :questionnaireData="questionnaireData" v-if="isAdminManagement"/>

    <div class="p-6" v-if="questionList" ref="questionSection">
      <Step1
        :class="[currentStep.form == 1 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />
      <Step2
        :class="[currentStep.form == 2 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step3
        :class="[currentStep.form == 3 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        :member-data="memberData"
        :isView="isView"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import Swal from "sweetalert2";
import Step1 from "./step-1/index.vue";
import Step2 from "./step-2/index.vue";
import Step3 from "./step-3/index.vue";

import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import localForage from "localforage"
import AddQuestionForm from "@/components/add-question-form/index.vue"
import Accordion from "@/components/base/custom-accordion/index.vue"

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const store = useStore();
const route = useRoute();
const router = useRouter()
const editData = ref(null)

const onlineComputed = computed(()=> store.getters["network/isOnline"])
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
var time = today.getHours().toString() + ":" + today.getMinutes().toString();
today = yyyy + mm + dd + time;

// variable
const props = defineProps({
  memberData : {type: Object},
  isEdit: {type: Boolean, default: false},
  isView: {type: Boolean, default: false},
  addMemberModal: {
    type: Boolean,
    default: false,
  },
  editModal: {
    type: Boolean,
    default: false,
  },
  questionList:{
    type: Array
  },
  isAdminManagement:{
    type: Boolean,
    default: false
  },
  currentStepObj: {
    type: Object
  },
  questionnaireData: {
    type: Object
  }

});

const emit = defineEmits(["modal-value", "exit-form", "complete-form", "saveSuccess", "deleteQuestion"]);

const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const headerStep = reactive([
  {
    title: "สภาพแวดล้อม พฤติกรรม ประสบการณ์",
    step: 1,
    show: true,
  },
  {
    title: "ความสนใจ ความคาดหวัง และการสนับสนุนที่ต้องการ",
    step: 2,
    show: true,
  },
  {
    title: "สำหรับทีมสำรวจข้อมูล",
    step: 3,
    show: true,
  },
]);

const openAddQuestionModal = ref(false)
const currentHeaderData = ref(false)
const questionSection = ref(null)
const currentStep = ref({
  step: 1,
  form: 1,
});
const saveStep = ref(0);
const allSaveStep = reactive([]);
const submitBtn = ref(false);
const formLabel = [
  "สภาพแวดล้อม พฤติกรรม ประสบการณ์",
  "ความสนใจ ความคาดหวัง และการสนับสนุนที่ต้องการ",
  "สำหรับทีมสำรวจข้อมูล"
];
var step = reactive([]);
const stepForm = ref({});
const ageInMonth = ref()
const dataForm = {}
const neet_survey_id = ref(route.params.neetid ?? null)
let surveyid, houseid
const isDisabledSubmitBtn = ref(false)
const dataUpdate = ref(null)
const requiredFound = ref(null)


// function
const initialStepForm = (form) => {
  step = [];
  if(props.questionList){
    form.forEach((e, i) => {
      let stepObj = props.questionList.find(({step})=> step.step_number == e).step
      step.push({
        title: stepObj.step_title,
        step: i + 1,
        form: e,
        id: stepObj.step_id
      });
    });
  }
  return step;
};

/* const prepareStepForm = (age) => {
  var f = [1, 2, 3];
  if (age <= 12) {
    f.push(2);
  }
  if (age >= 3) {
    f.push(3);
  }

  if (age >= 12) {
    f.push(5);
  }
  if (age >= 15) {
    f.push(4, 7);
  }

  f.sort((a, b) => {
    return a - b;
  });
  return f;
}; */

const findStep = (formnumber) => {
  return stepForm.value.find((e) => e.form == formnumber);
};

const closeForm = () => {
  emit("exit-form")
  // const result = headerStep.find( ({ step }) => step === saveStep.value + 1 );
  /* swalCustom.fire({
    html: props.isView ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
                <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
    icon:"warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก'
  }).then((result) => {
    if (result.isConfirmed) {
      router.go(-1)
    }
  }) */
};

const checkFormDisplay = (data, formNumb) => {
  switch (formNumb) {
    case 1:
      dataForm.questionnaires = Object.assign({}, data);
      // ageInMonth.value = data.ageMonth
      // currentAgeInYear = data.age
      // let form = prepareStepForm(data.age);
      // stepForm.value = initialStepForm(form);
      submitForm(formNumb);
      break;
    case 2:
      // dataForm.step1 = null
      dataForm.questionnaires = Object.assign({},data)
      submitForm(formNumb);
      break;
    case 3:
      // dataForm.step1 = null
      dataForm.questionnaires = Object.assign({},data)
      submitComplete(formNumb);
      break;
  }

};

const nextstep = (current) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
  var step = current.step + 1;
  var next = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = next;
  if(!allSaveStep.includes(current.step)){
    // saveStep.value = current.step
    allSaveStep.push(current.step)
  }
};

const jumpToStep = (toStep) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  //if step 1 not complete cannot jump to another step
  if(!allSaveStep.includes(1) && !props.isView && !props.isAdminManagement) return;
  currentStep.value = stepForm.value.find((e) => {
      return e.step == toStep;
  });
}


const skipStep = (currentForm) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    let current = findStep(currentForm);
    //last step cannot skip
    if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
    let step = current.step + 1;
    currentStep.value = stepForm.value.find((e) => {
        return e.step == step;
    });
}

const prevstep = (currentForm) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  var current = findStep(currentForm);
  if (current.step == stepForm.value[0].step) return;
  var step = current.step - 1;
  var pre = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = pre;
};

const prepareData = () => {
  // remove null variabled
  if (dataForm?.questionnaires && Object.keys(dataForm?.questionnaires)) {
    Object.keys(dataForm.questionnaires).forEach((q) => {
      if(dataForm.questionnaires[q] === null || dataForm.questionnaires[q] === undefined || dataForm.questionnaires[q] === "" || ((typeof dataForm.questionnaires[q] === 'object' && dataForm.questionnaires[q] !== null) ? Array.isArray(dataForm.questionnaires[q]) ? dataForm.questionnaires[q].length == 0 : Object.keys(dataForm.questionnaires[q]).length == 0 : false))
        dataForm.questionnaires[q] = null;

      if(q === "consent_info" && ageInMonth.value >= 240)
        delete dataForm.questionnaires[q]
    });
  }
};

const saveNeetSurvey = (data) => {
  return new Promise((resolve, reject)=>{
    const config = {
      method: neet_survey_id.value ? 'PUT' : 'POST',
      url: `${process.env.VUE_APP_BASE_API}neetSurveys`,
      data: data,
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      },
    }
    axios(config)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

const checkRelateResult = ({relate_question}, answerObj) => {
  if(relate_question.length == 0)
    return true

  let tempQuestionAppear = []
  for(const {code, answer, type} of relate_question){
    let has_answer
    if(type == "radio"){
      has_answer = answer.includes(answerObj[code]?.code)
    }
    else if(type == "checkbox"){
      has_answer = answerObj[code].findIndex(x => answer.includes(x.code)) != -1 ? true : false
    }
    else if(type == "number"){//for type number fix answer at index 0 and condition is greater than only
      has_answer = answerObj[code] > 0
    }
    else if(type == "select"){
      has_answer = answer.includes(answerObj[code])
    }
    else{
      has_answer = answerObj[code] ? true : false
    }
    tempQuestionAppear = [...tempQuestionAppear, has_answer]
  }
  return tempQuestionAppear.every(elm => elm)
}
const checkRelateAge = ({start, end}) =>{
  if(typeof start == "number" && typeof end == "number")
    return (ageInMonth.value >= start) && (ageInMonth.value <= end)
  else if(typeof start == "number" && !end)
    return ageInMonth.value >= start
  return true
}
const getSurvey = async() =>{
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}neetSurveys/${neet_survey_id.value}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const res = await axios.get(url, { headers }).catch(() =>
    "error"
  );
  if (res.status === 200) {
    return res.data
  }
}

let tempId = route.params.neetid ?? null
let alertConfig = {
  icon: "success"
}
const alertConfigHaveSurvey = {
  html: `<div style="font-size: 20px;">สำเร็จ</div><div style="margin-top: 10px;font-size: 25px;">ทำแบบสอบถาม เด็กพิการ ต่อหรือไม่</div>`,
  confirmButtonText: "ตกลง",
  cancelButtonText: "ทำภายหลัง",
  allowOutsideClick: false,
  showCancelButton: true,
  reverseButtons: true
}
const alertConfigNoSurvey = {
  html: `<div style="font-size: 20px;">สำเร็จ</div>`,
  timer: 3000,
  confirmButtonText: "ปิด"
}
const submitComplete = async () =>{
  let infoText = "<div>ยืนยันบันทึกข้อมูล</div>"
  let requiredQuestion
  if(onlineComputed.value){
    requiredFound.value = null
    const resultAnswer = await getSurvey()
    const answerList = resultAnswer.neet_survey
    let requiredQuestionList = props.questionList.reduce((a, v)=>{
      let question = v.questions.reduce((list,ques)=>{
        let new_question = ques.question_lists.filter(e=>e.required || e.choices.some(({required}) => required)).map((item)=> {
          item.step = v.step
          return item
        })
        return [...list, ...new_question]
      },[])
      return [...a, ...question]
    },[])
    requiredQuestion = requiredQuestionList.find((item)=>{
      return answerList[item.question_code] == undefined && checkRelateResult(item, answerList) && checkRelateAge(item.relate_age_month) && item.question_type != "multiple-inputs-group" && item.question_type != "date-range" && item.step.step_number != 3
    })
    if(requiredQuestion){
      infoText = `<div>ไม่สามารถบันทึกได้ กรุณากรอกข้อมูลที่บังคับกรอกให้ครบถ้วน</div> <div>กดปุ่ม <strong style="color: #246bc3;">"ตกลง"</strong> เพื่อกรอกข้อมูลเพิ่มเติม </div><div>หรือกดปุ่ม <strong style="color: #393939;">"บันทึกแบบร่าง"</strong> เพื่อดำเนินการต่อในภายหลัง</div>`
    }
  }
  swalCustom
  .fire({
    html: infoText,
    icon: "info",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: onlineComputed.value ? "บันทึกแบบร่าง" : "ยกเลิก",
  })
  .then(async (result) => {
      try{
        isDisabledSubmitBtn.value = true
        const config_data = {
          house_id: houseid,
          member_id: route.params.memberid,
          survey_id: surveyid,
          neet_survey: {...dataForm.questionnaires, id: neet_survey_id.value, status: "COMPLETE"},
          update_by: store.getters["userAuth/getUserID"]
        }
        if(onlineComputed.value){
          if (result.isConfirmed) {
            if(requiredQuestion){
              let stepJump = stepForm.value.find((s) => s.form == requiredQuestion.step.step_number)?.step
              if(stepJump){
                requiredFound.value = requiredQuestion
                setTimeout(()=>{
                  jumpToStep(stepJump)
                  isDisabledSubmitBtn.value = false
                }, 500)
              }
              return
            }
          }
          const res = await saveNeetSurvey(config_data)
          if (res.status === 200) {
            if(props.memberData.member_surveys.CWD && !props.memberData.cwd_survey){
              alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
            }
            else{
              alertConfig = {...alertConfig, ...alertConfigNoSurvey}
            }
            swalCustom
            .fire(alertConfig)
            .then((result) => {
              if(result.isConfirmed){
                isDisabledSubmitBtn.value = false
                if(props.memberData.member_surveys.CWD && !props.memberData.cwd_survey){
                  router.replace({
                    name: 'add-cwd',
                    params:{
                      surveyid: route.params.surveyid,
                      memberid: route.params.memberid
                    }
                  })
                  emit("complete-form")
                }
                else{
                  emit("complete-form", true)
                }
              }
              else{
                emit("complete-form", true)
              }
            });
          }
          else{
            isDisabledSubmitBtn.value = false
            swalCustom.fire({
              text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
              icon: "warning",
              // timer: 3000,
              confirmButtonText: "ปิด",
            });
          }
        }
        else{
          if (result.isConfirmed) {
            await houseStore.getItem(route.params.surveyid).then((value)=>{
              //get member index by route
              const memberIdx = value.house_surveys.members.findIndex(x=> x.member_id == route.params.memberid)
              //save the neet_survey obj into members

              if(tempId){//update
                //remove id cause when save neet use neet_survey no need id and will add id to neet_survey when sync update only
                const {id, ...neetRest} = config_data.neet_survey
                value.house_surveys.members[memberIdx].neet_survey_data.neet_survey =  {...value.house_surveys.members[memberIdx].neet_survey_data.neet_survey, ...neetRest}
                value.house_surveys.members[memberIdx].neet_survey_data.update_by = store.getters["userAuth/getUserID"]
                houseStore.setItem(route.params.surveyid, JSON.parse(JSON.stringify(value)))
              }
            })
            isDisabledSubmitBtn.value = false
            if(props.memberData.member_surveys.CWD && !props.memberData?.cwd_survey_data){
              alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
            }
            else{
              alertConfig = {...alertConfig, ...alertConfigNoSurvey}
            }
            swalCustom
            .fire(alertConfig)
            .then((result2) => {
              if(result2.isConfirmed){
                isDisabledSubmitBtn.value = false
                if(props.memberData.member_surveys.CWD && !props.memberData?.cwd_survey_data){
                  router.replace({
                    name: 'add-cwd',
                    params:{
                      surveyid: route.params.surveyid,
                      memberid: route.params.memberid
                    }
                  })
                  emit("complete-form")
                }
                else{
                  emit("complete-form", true)
                }
              }
              else{
                emit("complete-form", true)
              }
            });
          }
          else{
            isDisabledSubmitBtn.value = false
          }
        }
      }
      catch(error){
        isDisabledSubmitBtn.value = false
        console.log("error: ",error);
        swalCustom.fire({
          text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
          icon: "warning",
          // timer: 3000,
          confirmButtonText: "ปิด",
        });
      }
  })
}

const submitForm = async (stepNum) => {
  try{
    isDisabledSubmitBtn.value = true
    prepareData()
    let config_data
    config_data = {
      house_id: houseid,
      member_id: route.params.memberid,
      survey_id: surveyid,
      neet_survey: {
        ...dataForm.questionnaires, status: "DRAFT"
      }
    }
    if(neet_survey_id.value){
      config_data["neet_survey"]["id"] = neet_survey_id.value
      config_data["update_by"] = store.getters["userAuth/getUserID"]
    }
    else{
      config_data["create_by"] = store.getters["userAuth/getUserID"]
    }
    if(onlineComputed.value){
      const res = await saveNeetSurvey(config_data)
      if (res.status === 200) {
        if(res.data?.neet_survey_id)
          neet_survey_id.value = res.data.neet_survey_id

        isDisabledSubmitBtn.value = false
        nextstep(findStep(stepNum));
      }
      else{
        isDisabledSubmitBtn.value = false
        swalCustom.fire({
          text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
          icon: "warning",
          // timer: 3000,
          confirmButtonText: "ปิด",
        });
      }
    }
    else{
      houseStore.getItem(route.params.surveyid).then((value)=>{
        //get member index by route
        const memberIdx = value.house_surveys.members.findIndex(x=> x.member_id == route.params.memberid)
        //save the neet_survey obj into members
        if(tempId){//update
          const {id, ...neetRest} = config_data.neet_survey
          value.house_surveys.members[memberIdx].neet_survey_data.neet_survey =  {...value.house_surveys.members[memberIdx].neet_survey_data.neet_survey, ...neetRest}
          value.house_surveys.members[memberIdx].neet_survey_data.update_by = store.getters["userAuth/getUserID"]
          houseStore.setItem(route.params.surveyid, JSON.parse(JSON.stringify(value)))
        }
        else{//insert
          value.house_surveys.members[memberIdx]["neet_survey_data"] =  config_data
          tempId = 1
          houseStore.setItem(route.params.surveyid, JSON.parse(JSON.stringify(value)))
        }
        isDisabledSubmitBtn.value = false
        nextstep(findStep(stepNum));
      })
    }
  }
  catch(error){
    isDisabledSubmitBtn.value = false
    console.log("error: ",error);
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      // timer: 3000,
      confirmButtonText: "ปิด",
    });
  }
}

const initValue = async () => {
  stepForm.value = initialStepForm([1, 2, 3]);
  if(props.currentStepObj){
    currentStep.value = props.currentStepObj
  }
  else{
    currentStep.value = stepForm.value.find((e) => {
      return e.step == 1;
    });
  }
  // render.value = true;
};

/* const clearValue = () => {
  dataForm = {
    step01: {},
    step02: {},
    step03: {},
    step04: {},
    step05: {},
    step06: {},
    step07: {},
    step08: {},
    step09: {},
    step10: {},
    step11: {},
  };

  currentStep.value = {
    step: 1,
    form: 1,
  };

  render.value = false;
}; */

const showArrow = ref(false)
const resizeHandle = () =>{
  let div = document.getElementById('member_wizard');
  let hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  if(hasHorizontalScrollbar)
    showArrow.value = true
  else
    showArrow.value = false
}
watch(stepForm, ()=>{
  setTimeout(()=>{
    resizeHandle()
  }, 200)
})
const defaultHide = ref(false)
const getScreen = () =>{
  if(window.innerWidth < 1024){
    defaultHide.value = true
  }
  else{
    defaultHide.value = false
  }
}

onMounted(()=>{
  if(onlineComputed.value)
    initValue();
  getScreen()
  window.addEventListener("resize", resizeHandle)
  window.addEventListener("resize", getScreen)
})
onUnmounted(()=>{
    window.removeEventListener("resize", resizeHandle);
    window.removeEventListener("resize", getScreen);
})

watch(()=> props.memberData,(val)=>{
  surveyid = val.house_surveys_id
  houseid = val.house_id
  if(val.member_surveys){
    ageInMonth.value = (val.member_surveys.age.year*12)+val.member_surveys.age.month
  }
})
watch(()=> props.questionList, ()=>{
  if(!onlineComputed.value){
    initValue();
  }
})

const updateQuestionStatus = async (data) => {
  dataUpdate.value = null
  const res = await axios.put(
    `${process.env.VUE_APP_BASE_API}admin/question/status/${data._id}`,{},
    {
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      }
    }
  ).catch(() => {
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      //timer: 3000, //timeOut for auto-close
      confirmButtonText: "ปิด",
    });
  })
  .then(()=>{
    dataUpdate.value = data
  });

  if(res.status == 200){
    if(data.status == "active"){
      data.status = "inactive"
    }
    else{
      data.status = "active"
    }
    dataUpdate.value = data
  }
}
</script>
<style lang="scss">
.form-check-input {
  border-color: black !important;
}
.accordion-button .feather-play{
  transition: transform .1s ease;
  fill: #2244b0;
}
.accordion-button[aria-expanded="true"] .feather-play {
  transform: rotate(-90deg);
  transition: transform .1s ease;
}
</style>
