<template>
  <div class="toggle-container">
    <div id="toggle-modal" v-if="show">
      <h3>ขณะนี้ตรวจพบ{{ isOnline ? "สัญญาณ" : "การขาดหายของสัญญาณ" }}</h3>
      <h5>ต้องการเปลี่ยนไปใช้โหมด {{ isOnline ? "Online" : "Offline" }} หรือไม่</h5>
      <div class="flex justify-end mt-2">
        <button class="btn btn-secondary mr-2" @click="closeToggleMode('Offline')" v-if="isOnline">ยืนยันออฟไลน์</button>
        <button class="btn btn-primary" @click="onConfirm">ยืนยัน</button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, provide, computed, watch } from "vue";
import { useStore } from "vuex";
import {insertHouse} from "@/indexeddb"
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2"

const isOnline = ref(true);
const show = ref(false);
const store = useStore()
const route = useRoute()
const router = useRouter()

provide("isOnline", isOnline);

const showSwitchMode = () => {
  isOnline.value = navigator.onLine;
  //not show if current offline and network detect offline
  if(!navigator.onLine && !store.getters["network/isOnline"]){
    show.value = false;
  }
  else{
    show.value = true
  }
};


const closeToggleMode = (mode=null) => {
  show.value = false;

  //cancel from online detect then continue offline
  if(navigator.onLine && mode === "Offline")
    navigator.serviceWorker.controller.postMessage({value: false})
};

const onConfirm = () => {
  isOnline.value = navigator.onLine;
  if(store.getters["userAuth/getUserTumbolCode"].length != 6 && !navigator.onLine){
    console.log("You cannot use offline mode");
    Swal.fire({
      html: "<p>บัญชีผู้ใช้งานนี้ ไม่สามารถใช้งานโหมดออฟไลน์ได้</p>",
      icon: "info",
      // timer: 3000,
      confirmButtonText: "ตกลง",
    })
  }
  //confirm click set mode as network detect
  navigator.serviceWorker.controller.postMessage({value: navigator.onLine})
  closeToggleMode();
  // window.addEventListener('keyup', function(ev) {
  //   if(ev.keyCode === 90){
  //     insertHouse()
  //   }
  // })
};

onMounted(() => {
  window.addEventListener("online", showSwitchMode);
  window.addEventListener("offline", showSwitchMode);
});

onUnmounted(() => {
  window.removeEventListener("online", showSwitchMode);
  window.removeEventListener("offline", showSwitchMode);
});
const onlineComputed = computed(()=> store.getters["network/isOnline"])
</script>

<style scoped lang="scss">
.toggle-container {
  position: relative;
}
#toggle-modal {
  position: fixed;
  z-index: 1000;
  background-color: white;
  bottom: 10px;
  right: 10px;
  padding: 1rem;
  border-radius: 1rem;
}
</style>
