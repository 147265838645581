<template>
    <div>
        <!-- <button class="btn btn-primary bg-primary-btn border-primary-btn whitespace-pre" @click="openAddQuestionModal" v-if="questionList?.find(item=> item.step.step_id == currentStep?.id)?.questions.some(q=>q.header_info)">
            <PlusIcon class="h-3 w-3" /> เพิ่มคำถาม
        </button> -->
        <ModalLayout
            id="modal-question-form"
            v-model="modelValueComputed"
            modal-size="modal-xl"
            closeButton
            :header="`${isEdit ? 'แก้ไข' : 'เพิ่ม'}คำถาม`"
            @click:save="submitForm"
            @click:cancel="clearModal"
            :isDone="isDone"
        >
            <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="col-span-12">
                    <label class="form-label">
                        หัวข้อย่อย
                        <!-- <span class="text-theme-24 text-md"> *</span> -->
                    </label>
                    <v-select
                        data-test="select-header"
                        :options="headerList"
                        label="header"
                        :clearable="false"
                        :searchable="true"
                        placeholder="เลือกหัวข้อย่อย"
                        :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': false }"
                        :disabled="false"
                        :reduce="(label) => label._id"
                        v-model="form.header_id"
                    >
                        <template #no-options="{search}">
                            {{search ? 'ไม่พบข้อมูล' : 'ไม่มีหัวข้อย่อย'}}
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"
                                ><ChevronDownIcon class="w-5 h-5"
                            /></span>
                        </template>
                    </v-select>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        ประเภทคำถาม
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <v-select
                        data-test="select-header"
                        :options="questionTypeOptions"
                        v-model="validate.question_type.$model"
                        :clearable="false"
                        :searchable="true"
                        placeholder="เลือกประเภทคำถาม"
                        :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate.question_type.$error }"
                        :disabled="isEdit"
                        label="label"
                        :reduce="(label) => label.type"
                    >
                        <template #no-options="{}">
                            ไม่พบข้อมูล
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"
                                ><ChevronDownIcon class="w-5 h-5"
                            /></span>
                        </template>
                    </v-select>
                    <template v-if="validate.question_type.$error">
                        <div
                            v-for="(error, index) in validate.question_type.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                    <div class="mt-2" v-if="checkConditionQuestionType.isOnlyNumber">
                        <div class="form-checkbox-custom pl-6">
                            <label
                                class="form-check-label align-middle flex items-baseline"
                                for="only-number"
                            >
                                <input
                                id="only-number"
                                class="form-check-input align-middle"
                                type="checkbox"
                                value="true"
                                v-model="form.is_only_number"
                                :disabled="false"
                                > กรอกได้เฉพาะตัวเลขเท่านั้น
                            </label>
                        </div>
                    </div>
                    <div class="mt-2" v-if="checkConditionQuestionType.isCheckboxGroup">
                        <div class="form-checkbox-custom pl-6">
                            <label
                                class="form-check-label align-middle flex items-baseline"
                                for="only-number"
                            >
                                <input
                                    id="only-number"
                                    class="form-check-input align-middle"
                                    type="checkbox"
                                    value="true"
                                    v-model="form.is_checkbox_group"
                                    :disabled="false"
                                > รูปแบบกลุ่มคำถามย่อย
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 sm:col-span-6" v-if="form.question_type == 'number'">
                    <div class="form-checkbox-custom pl-6">
                        <label
                            class="form-check-label align-middle flex items-baseline"
                            for="is-negative-number-check"
                        >
                            <input
                                id="is-negative-number-check"
                                class="form-check-input align-middle"
                                type="checkbox"
                                value="true"
                                v-model="form.is_negative"
                            /> อนุญาตให้กรอกจำนวนเต็มลบ
                        </label>
                    </div>
                </div>
                <div class="col-span-12 sm:col-span-6" v-if="form.question_type == 'number'">
                    <div class="form-checkbox-custom pl-6">
                        <label
                            class="form-check-label align-middle flex items-baseline"
                            for="is-decimal-number-check"
                        >
                            <input
                                id="is-decimal-number-check"
                                class="form-check-input align-middle"
                                type="checkbox"
                                value="true"
                                v-model="form.is_decimal"
                            /> อนุญาตให้กรอกจำนวนทศนิยม
                        </label>
                    </div>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        คำถาม
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <input
                        id="question_desc"
                        type="text"
                        class="form-control"
                        placeholder="คำถาม"
                        data-test="question_desc"
                        :disabled="false"
                        :class="{ 'border-theme-24': validate.question_desc.$error}"
                        v-model="validate.question_desc.$model"
                    />
                    <template v-if="validate.question_desc.$error">
                        <div
                            v-for="(error, index) in validate.question_desc.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12" v-if="checkConditionQuestionType.isPlaceholder">
                    <label class="form-label">
                        Placeholder
                        <!-- <span class="text-theme-24 text-md"> *</span> -->
                    </label>
                    <input
                        id="placeholder_text"
                        type="text"
                        class="form-control"
                        placeholder="placeholder"
                        data-test="placeholder_text"
                        :disabled="false"
                        :class="{ 'border-theme-24': false}"
                        v-model="form.placeholder"
                    />
                </div>
                <div class="col-span-12" v-if="checkConditionQuestionType.isLabel">
                    <label class="form-label">
                        Label
                        <!-- <span class="text-theme-24 text-md"> *</span> -->
                    </label>
                    <input
                        id="label_text"
                        type="text"
                        class="form-control"
                        placeholder="label"
                        data-test="label_text"
                        :disabled="false"
                        :class="{ 'border-theme-24': false}"
                        v-model="form.question_label"
                    />
                </div>
                <div class="col-span-12 sm:col-span-6" v-if="checkConditionQuestionType.isLimitMaxLength">
                    <label class="form-label">
                        จำกัดความยาวตัวอักษร (ตัวเลขเท่านั้น)
                    </label>
                    <input
                        id="max_length_input"
                        type="number"
                        min="1"
                        class="form-control"
                        placeholder="จำนวน"
                        data-test="max_length_input"
                        :disabled="false"
                        :class="{ 'border-theme-24': validate.max_length.$error}"
                        v-model="validate.max_length.$model"
                    />
                    <template v-if="validate.max_length.$error">
                        <div
                            v-for="(error, index) in validate.max_length.$errors"
                            :key="index"
                            class="text-theme-24 mt-1 text-sm"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12 sm:col-span-6" v-if="checkConditionQuestionType.isLimitMaxLength">
                    <label class="form-label">
                        ข้อความตรวจสอบความยาวตัวอักษร (ถ้ามี)
                    </label>
                    <!--validate required max length first if input this field-->
                    <input
                        id="label_text"
                        type="text"
                        class="form-control"
                        placeholder="ข้อความตรวจสอบความยาวตัวอักษร"
                        data-test="max_length_message"
                        :disabled="false"
                        :class="{ 'border-theme-24': false}"
                        v-model="form.max_length_message"
                    />
                </div>
                <div class="col-span-12">
                    <div class="grid grid-cols-12 gap-4 gap-y-5" v-if="questionnaireData.questionnaire_name_en != 'household'">
                        <label class="form-label col-span-12 mb-[-0.75rem]">
                            ช่วงอายุการตอบคำถาม
                        </label>
                        <div class="col-span-12 sm:col-span-6">
                            <label>อายุเริ่มต้น (เดือน)</label>
                            <div class="relative flex items-center">
                                <input
                                    id="age_range_start"
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    placeholder="จำนวนเดือน"
                                    data-test="age_range_start"
                                    :disabled="false"
                                    v-model="validate.relate_age_month.start.$model"
                                    :class="{ 'border-theme-24': validate.relate_age_month.start.$error}"
                                />
                            </div>
                            <template v-if="validate.relate_age_month.start.$error">
                                <div
                                    v-for="(error, index) in validate.relate_age_month.start.$errors"
                                    :key="index"
                                    class="text-theme-24 mt-1 text-sm"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <label>อายุสิ้นสุด (เดือน)</label>
                            <div class="relative flex items-center">
                                <input
                                    id="age_range_end"
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    placeholder="จำนวนเดือน"
                                    data-test="age_range_end"
                                    :disabled="false"
                                    v-model="form.relate_age_month.end"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 sm:col-span-6" v-if="form.question_type == 'number' && questionnaireData.questionnaire_name_en == 'household'">
                    <div class="form-checkbox-custom pl-6">
                        <label
                            class="form-check-label align-middle flex items-baseline"
                            for="required-count-member-check"
                        >
                            <input
                                id="required-count-member-check"
                                class="form-check-input align-middle"
                                type="checkbox"
                                value="true"
                                v-model="form.required_count_member"
                            /> จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน
                        </label>
                    </div>
                </div>
                <div class="col-span-12" v-if="checkConditionQuestionType.isChoice">
                    <label class="form-label">
                        ตัวเลือก
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <div class="grid grid-cols-12 gap-4 gap-y-5" v-if="form.question_type != 'select'">
                        <div class="col-span-12" v-for="(ch, tIndex) in form.tempChoices" :key="tIndex">
                            <div class="grid grid-cols-12 gap-4 gap-y-5">
                                <div class="col-span-12">
                                    <div class="flex gap-2 items-center">
                                        <span>({{tIndex+1}})</span>
                                        <input
                                            :id="`question_desc${tIndex}`"
                                            type="text"
                                            class="form-control"
                                            placeholder="คำตอบ"
                                            :data-test="`question_desc${tIndex}`"
                                            :disabled="false"
                                            :class="{ 'border-theme-24': validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.desc?.length}"
                                            v-model="ch.desc"
                                        />
                                        <button class="btn btn-danger bg-theme-24 border-theme-24" @click="removeChoice(form.question_type,tIndex)" v-if="form.tempChoices.length > 1">
                                            <MinusIcon class="h-4 w-4" />
                                        </button>
                                        <button class="btn btn-primary bg-primary-btn border-primary-btn" @click="addMoreChoice(ch.code)" v-if="tIndex==form.tempChoices.length-1">
                                            <PlusIcon class="h-4 w-4" />
                                        </button>
                                    </div>
                                    <template v-if="validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.desc?.length">
                                        <div
                                            v-for="(error, index) in validate.tempChoices.$each.$response.$errors[tIndex].desc"
                                            :key="index"
                                            class="text-theme-24 mt-1 text-sm ml-6"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </template>
                                    <!-- <button class="ml-6 text-primary-1 py-1" @click="handleShowInput(tIndex)" v-if="form.question_type != 'select' && (!ch.specify_value && tIndex == 0)"> +เพิ่มกล่องข้อความ </button> -->
                                    <div class="mx-6 mt-4">
                                        <div class="grid grid-cols-12 gap-4">
                                            <div class="sm:col-span-6 col-span-12 form-checkbox-custom pl-6">
                                                <label
                                                    class="form-check-label align-middle flex items-baseline"
                                                    :for="`other-option${tIndex}`"
                                                >
                                                    <input
                                                        :id="`other-option${tIndex}`"
                                                        class="form-check-input align-middle"
                                                        type="checkbox"
                                                        value="true"
                                                        :checked="'other' in ch || ch?.specify_value?.some(item=>item.key == 'other')"
                                                        @change="otherOption($event,tIndex)"
                                                    /> อื่นๆ ระบุ
                                                </label>
                                            </div>
                                            <div class="sm:col-span-6 col-span-12 form-checkbox-custom pl-6" v-if="ch?.specify_value?.length && form.question_type == 'checkbox'">
                                                <label
                                                    class="form-check-label align-middle flex items-baseline"
                                                    :for="`required-option${tIndex}`"
                                                >
                                                    <input
                                                        :id="`required-option${tIndex}`"
                                                        class="form-check-input align-middle"
                                                        type="checkbox"
                                                        value="true"
                                                        :checked="ch.required"
                                                        @change="handleChoiceOption($event, tIndex, 'required')"
                                                    /> Required
                                                </label>
                                            </div>
                                            <div class="sm:col-span-6 col-span-12 form-checkbox-custom pl-6" v-if="ch?.specify_value?.length">
                                                <label
                                                    class="form-check-label align-middle flex items-baseline"
                                                    :for="`multiple-option${tIndex}`"
                                                >
                                                    <input
                                                        :id="`multiple-option${tIndex}`"
                                                        class="form-check-input align-middle"
                                                        type="checkbox"
                                                        value="true"
                                                        :checked="ch.is_multiple_answer"
                                                        @change="handleChoiceOption($event, tIndex, 'is_multiple_answer')"
                                                    /> อนุญาตให้เพิ่มกล่องข้อความได้มากกว่า 1
                                                </label>
                                            </div>
                                        </div>
                                        <div v-for="(input, sIdx) in ch.specify_value" :key="sIdx">
                                            <div class="grid grid-cols-12 gap-4 p-3 border-1 rounded-md relative mt-5">
                                                <button class="btn absolute right-[-5px] top-[-10px] h-5 w-5 p-0 border-0 rounded-full remove-spc-btn" v-if="tIndex == 0 && input.key != 'other'" @click="removeSpecifyInput(tIndex,sIdx,input.key)">
                                                    <XCircleIcon class="text-theme-24 h-5 w-5 fill-[#fff]" />
                                                </button>
                                                <div class="col-span-12 sm:col-span-6">
                                                    <v-select
                                                        :data-test="`select_input_type${sIdx}`"
                                                        :options="inputOptionType"
                                                        :clearable="false"
                                                        placeholder="เลือกประเภทกล่องข้อความ"
                                                        :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.specify_value?.[0]?.$response?.$errors?.[sIdx]?.type?.length }"
                                                        :disabled="false"
                                                        label="label"
                                                        :reduce="(label) => label.type"
                                                        v-model="input.type"
                                                    >
                                                        <template #no-options="{}">
                                                            ไม่พบข้อมูล
                                                        </template>
                                                        <template #open-indicator="{ attributes }">
                                                            <span v-bind="attributes"
                                                                ><ChevronDownIcon class="w-5 h-5"
                                                            /></span>
                                                        </template>
                                                    </v-select>
                                                    <template v-if="validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.specify_value?.[0]?.$response?.$errors?.[sIdx]?.type?.length">
                                                        <div
                                                            v-for="(error, index) in validate.tempChoices.$each.$response.$errors[tIndex].specify_value[0]?.$response.$errors[sIdx].type"
                                                            :key="index"
                                                            class="text-theme-24 mt-1 text-sm"
                                                        >
                                                            {{ error.$message }}
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="col-span-12 sm:col-span-6">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="label"
                                                        :data-test="`input_label${sIdx}`"
                                                        v-model="input.label"
                                                    />
                                                </div>
                                                <div class="col-span-12 sm:col-span-6">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="required message"
                                                        :data-test="`input_message${sIdx}`"
                                                        v-model="input.message"
                                                        :class="{ 'border-theme-24': validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.specify_value?.[0]?.$response?.$errors?.[sIdx]?.message?.length}"
                                                    />
                                                    <template v-if="validate.tempChoices.$each?.$response?.$errors?.[tIndex]?.specify_value?.[0]?.$response?.$errors?.[sIdx]?.message?.length">
                                                        <div
                                                            v-for="(error, index) in validate.tempChoices.$each.$response.$errors[tIndex].specify_value[0].$response.$errors[sIdx].message"
                                                            :key="index"
                                                            class="text-theme-24 mt-1 text-sm"
                                                        >
                                                            {{ error.$message }}
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="col-span-12 sm:col-span-6">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="หน่วย (ถ้ามี)"
                                                        :data-test="`input_unit${sIdx}`"
                                                        v-model="input.unit"
                                                    />
                                                </div>
                                                <div class="col-span-12 sm:col-span-6" v-if="input.type == 'number'">
                                                    <div class="form-checkbox-custom pl-6">
                                                        <label
                                                            class="form-check-label align-middle flex items-baseline"
                                                            :for="`is-negative-number-check-${tIndex}${sIdx}`"
                                                        >
                                                            <input
                                                                :id="`is-negative-number-check-${tIndex}${sIdx}`"
                                                                class="form-check-input align-middle"
                                                                type="checkbox"
                                                                value="true"
                                                                v-model="input.is_negative"
                                                            /> อนุญาตให้กรอกจำนวนเต็มลบ
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-span-12 sm:col-span-6" v-if="input.type == 'number'">
                                                    <div class="form-checkbox-custom pl-6">
                                                        <label
                                                            class="form-check-label align-middle flex items-baseline"
                                                            :for="`is-decimal-number-check-${tIndex}${sIdx}`"
                                                        >
                                                            <input
                                                                :id="`is-decimal-number-check-${tIndex}${sIdx}`"
                                                                class="form-check-input align-middle"
                                                                type="checkbox"
                                                                value="true"
                                                                v-model="input.is_decimal"
                                                            /> อนุญาตให้กรอกจำนวนทศนิยม
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-span-12 sm:col-span-6" v-if="input.type == 'number' && sIdx > 0 && ch.specify_value[sIdx - 1]?.type == 'number'">
                                                    <div class="form-checkbox-custom pl-6">
                                                        <label
                                                            class="form-check-label align-middle flex items-baseline"
                                                            :for="`is-max-value-number-check-${tIndex}${sIdx}`"
                                                        >
                                                            <input
                                                                :id="`is-max-value-number-check-${tIndex}${sIdx}`"
                                                                class="form-check-input align-middle"
                                                                type="checkbox"
                                                                value="true"
                                                                v-model="input.is_max_value"
                                                            /> จำนวนต้องไม่เกินจำนวนแรก
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-span-12 sm:col-span-6" v-if="input.type == 'number' && questionnaireData.questionnaire_name_en == 'household'">
                                                    <div class="form-checkbox-custom pl-6">
                                                        <label
                                                            class="form-check-label align-middle flex items-baseline"
                                                            :for="`required-count-member-check-${tIndex}${sIdx}`"
                                                        >
                                                            <input
                                                                :id="`required-count-member-check-${tIndex}${sIdx}`"
                                                                class="form-check-input align-middle"
                                                                type="checkbox"
                                                                value="true"
                                                                v-model="input.required_count_member"
                                                            /> จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex justify-end" v-if="sIdx == ch.specify_value.length - 1 && tIndex == 0 && input.key != 'other'">
                                                <button class="mt-2 btn btn-primary bg-primary-btn border-primary-btn" @click="addSpecifyInput(tIndex, input.key)">
                                                    <PlusIcon class="h-4 w-4"/>
                                                </button>
                                            </div>
                                        </div>
                                        <button class="text-primary-1 py-1" @click="handleShowInput(tIndex)" v-if="(ch?.specify_value ? ch.specify_value.every(item => item.key == 'other') : true) && tIndex == 0"> +เพิ่มกล่องข้อความ </button>
                                    </div>
                                    <hr class="my-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12" v-if="checkConditionQuestionType.isMultipleInput">
                    <label class="form-label">
                        กล่องข้อความ
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <div v-for="(input, tIndex) in form.tempMultipleInputs" :key="tIndex">
                        <div class="grid grid-cols-12 gap-4 p-3 border-1 rounded-md relative mt-5">
                            <button class="btn absolute right-[-5px] top-[-10px] h-5 w-5 p-0 border-0 rounded-full remove-spc-btn" v-if="tIndex > 0" @click="removeInput(tIndex)">
                                <XCircleIcon class="text-theme-24 h-5 w-5 fill-[#fff]" />
                            </button>
                            <div class="col-span-12 sm:col-span-6">
                                <v-select
                                    :data-test="`multi_input_type${tIndex}`"
                                    :options="inputOptionType"
                                    :clearable="false"
                                    placeholder="เลือกประเภทกล่องข้อความ"
                                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate.tempMultipleInputs.$each.$response.$errors?.[tIndex]?.type?.length }"
                                    :disabled="false"
                                    label="label"
                                    :reduce="(label) => label.type"
                                    v-model="input.type"
                                >
                                    <template #no-options="{}">
                                        ไม่พบข้อมูล
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes"
                                            ><ChevronDownIcon class="w-5 h-5"
                                        /></span>
                                    </template>
                                </v-select>
                                <template v-if="validate.tempMultipleInputs.$each.$response.$errors?.[tIndex]?.type?.length">
                                    <div
                                        v-for="(error, index) in validate.tempMultipleInputs.$each.$response.$errors[tIndex].type"
                                        :key="index"
                                        class="text-theme-24 mt-1 text-sm"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>
                            <div class="col-span-12 sm:col-span-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="label"
                                    :data-test="`multi_inputs_label${tIndex}`"
                                    v-model="input.label"
                                />
                            </div>
                            <!-- <div class="col-span-12 sm:col-span-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="required message"
                                    :data-test="`multi_inputs_message${tIndex}`"
                                    v-model="input.message"
                                />
                            </div> -->
                            <div class="col-span-12 sm:col-span-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="หน่วย (ถ้ามี)"
                                    :data-test="`multi_inputs_unit${tIndex}`"
                                    v-model="input.unit"
                                >
                            </div>
                            <div class="col-span-12" v-if="input.type == 'number'">
                                <div class="grid grid-cols-12 gap-4">
                                    <div class="col-span-12 sm:col-span-6">
                                        <div class="form-checkbox-custom pl-6">
                                            <label
                                                class="form-check-label align-middle flex items-baseline"
                                                :for="`is-negative-number-check-${tIndex}`"
                                            >
                                                <input
                                                    :id="`is-negative-number-check-${tIndex}`"
                                                    class="form-check-input align-middle"
                                                    type="checkbox"
                                                    value="true"
                                                    v-model="input.is_negative"
                                                /> อนุญาตให้กรอกจำนวนเต็มลบ
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-span-12 sm:col-span-6">
                                        <div class="form-checkbox-custom pl-6">
                                            <label
                                                class="form-check-label align-middle flex items-baseline"
                                                :for="`is-decimal-number-check-${tIndex}`"
                                            >
                                                <input
                                                    :id="`is-decimal-number-check-${tIndex}`"
                                                    class="form-check-input align-middle"
                                                    type="checkbox"
                                                    value="true"
                                                    v-model="input.is_decimal"
                                                /> อนุญาตให้กรอกจำนวนทศนิยม
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-span-12 sm:col-span-6" v-if="questionnaireData.questionnaire_name_en == 'household'">
                                        <div class="form-checkbox-custom pl-6">
                                            <label
                                                class="form-check-label align-middle flex items-baseline"
                                                :for="`required-count-member-check-${tIndex}`"
                                            >
                                                <input
                                                    :id="`required-count-member-check-$${tIndex}`"
                                                    class="form-check-input align-middle"
                                                    type="checkbox"
                                                    value="true"
                                                    v-model="input.required_count_member"
                                                /> จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-span-12 sm:col-span-6" v-if="tIndex > 0 && form.tempMultipleInputs[tIndex - 1]?.type == 'number'">
                                        <div class="form-checkbox-custom pl-6">
                                            <label
                                                class="form-check-label align-middle flex items-baseline"
                                                :for="`is-max-value-number-check-${tIndex}`"
                                            >
                                                <input
                                                    :id="`is-max-value-number-check-${tIndex}`"
                                                    class="form-check-input align-middle"
                                                    type="checkbox"
                                                    value="true"
                                                    v-model="input.is_max_value"
                                                /> จำนวนต้องไม่เกินจำนวนแรก
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end" v-if="tIndex == form.tempMultipleInputs.length - 1">
                            <button class="mt-2 btn btn-primary bg-primary-btn border-primary-btn" @click="addInput(input.key)">
                                <PlusIcon class="h-4 w-4"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-span-12" v-if="form.question_type == 'select'">
                    <label class="form-label">
                        ตัวเลือก
                        <span class="text-theme-24 text-md"> *</span>
                    </label>
                    <div class="grid grid-cols-12 gap-4 gap-y-5">
                        <div class="col-span-12" v-for="(option, tIndex) in form.tempSelectOption" :key="tIndex">
                            <div class="grid grid-cols-12 gap-4 gap-y-5">
                                <div class="col-span-12 mb-5">
                                    <div class="flex gap-2 items-center">
                                        <span>({{tIndex+1}})</span>
                                        <input
                                            id="question_desc"
                                            type="text"
                                            class="form-control"
                                            placeholder="คำตอบ"
                                            data-test="question_desc"
                                            :disabled="false"
                                            :class="{ 'border-theme-24': validate.tempSelectOption.$each.$response.$errors?.[tIndex]?.label?.length}"
                                            v-model="option.label"
                                        />
                                        <button class="btn btn-danger bg-theme-24 border-theme-24" @click="removeChoice(form.question_type, tIndex)" v-if="form.tempChoices.length > 1">
                                            <MinusIcon class="h-4 w-4" />
                                        </button>
                                        <button class="btn btn-primary bg-primary-btn border-primary-btn" @click="addMoreOption" v-if="tIndex==form.tempSelectOption.length-1">
                                            <PlusIcon class="h-4 w-4" />
                                        </button>
                                    </div>
                                    <template v-if="validate.tempSelectOption.$each.$response.$errors?.[tIndex]?.label?.length">
                                        <div
                                            v-for="(error, index) in validate.tempSelectOption.$each.$response.$errors[tIndex].label"
                                            :key="index"
                                            class="text-theme-24 mt-1 text-sm ml-6"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12" v-if="form.question_type != 'multiple-inputs-group'">
                    <div class="form-checkbox-custom pl-6">
                        <label
                            class="form-check-label align-middle flex items-baseline"
                            for="required-check"
                        >
                            <input
                                id="required-check"
                                class="form-check-input align-middle"
                                type="checkbox"
                                value="true"
                                v-model="form.required"
                                :disabled="false"
                            /> Required
                        </label>
                    </div>
                    <div class="mt-2 ml-6" v-if="form.required">
                        <label class="form-label">
                            Required Message
                            <span class="text-theme-24 text-md"> *</span>
                        </label>
                        <input
                            id="required_message"
                            type="text"
                            class="form-control"
                            placeholder="ข้อความ"
                            data-test="required_message"
                            :disabled="false"
                            :class="{ 'border-theme-24': validate.required_message.$error}"
                            v-model="validate.required_message.$model"
                        />
                        <template v-if="validate.required_message.$error">
                            <div
                                v-for="(error, index) in validate.required_message.$errors"
                                :key="index"
                                class="text-theme-24 mt-1 text-sm"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-span-12" v-if="checkConditionQuestionType.isLimitAnswer && !form.is_checkbox_group">
                    <div class="flex gap-3 items-baseline">
                        <div class="form-checkbox-custom pl-6">
                            <label
                                class="form-check-label align-middle flex items-baseline"
                                for="required-answer-limit"
                            >
                                <input
                                    id="required-answer-limit"
                                    class="form-check-input align-middle"
                                    type="checkbox"
                                    value="true"
                                    v-model="answer_limit_checked"
                                    :disabled="false"
                                /> จำกัดคำตอบ (เรียงลำดับ)
                            </label>
                        </div>
                        <v-select
                            v-if="answer_limit_checked"
                            data-test="select-limit"
                            :options="answerList"
                            :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': false }"
                            :disabled="false"
                            :clearable="false"
                            :searchable="false"
                            v-model="form.answer_limit"
                            style="width:70px;"
                        >
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes"
                                    ><ChevronDownIcon class="w-5 h-5"
                                /></span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="col-span-12">
                    <label class="form-label">
                        คำอธิบาย
                    </label>
                    <DocumentEditor id="additional-editor" @ready="onReady" :config="configEditor" v-model="form.additional_detail"/>
                </div>
                <div class="col-span-12">
                    <CustomAccordion
                        accordion-id="relate_question_list"
                        headerText="เลือกคำถามที่ต้องตอบก่อนแสดงคำถามนี้"
                    >
                    <template v-slot:body-content>
                        <div class="col-span-12 ml-[-1rem] mr-[-1rem]">
                            <hr>
                            <div class="p-4">
                                <label class="form-label">
                                    ค้นหาคำถาม
                                </label>
                                <input
                                    id="search_question_list"
                                    type="text"
                                    class="form-control"
                                    placeholder="พิมพ์คำค้นหาที่นี่"
                                    data-test="search_question_list"
                                    :disabled="false"
                                    v-model="searchWord"
                                />
                            </div>
                            <div v-for="(ques, index) in FilteredRelateQuestionList" :key="index">
                                <label
                                    class="form-check-label align-middle flex items-center hover:bg-gray-100 ml-0" :class="{'bg-gray-100':form.relate_question.some(({code})=> code == ques.question_code)}"
                                    :for="`input-question-${ques.question_code}`"
                                >
                                    <div class="p-5">
                                        <div class="header flex items-center">
                                            <input
                                                :id="`input-question-${ques.question_code}`"
                                                class="form-check-header-input align-middle mr-2"
                                                type="checkbox"
                                                :value="ques.relate_option"
                                                v-model="form.relate_question"
                                            >
                                            {{ques.question_desc ?? ques.placeholder}}
                                        </div>
                                        <div class="body mt-5 text-gray-700 dark:text-gray-600 leading-relaxed gap-4 gap-y-5 grid grid-cols-12" v-if="(ques.question_type == 'radio' || ques.question_type == 'checkbox' || ques.question_type == 'select') && form.relate_question.some(({code})=> code == ques.question_code)">
                                            <div class="col-span-12 ml-6" v-for="(ch, chIdx) in ques.choices" :key="chIdx">
                                                <div class="form-checkbox-custom pl-6">
                                                    <label
                                                        class="form-check-label align-middle flex items-baseline ml-0"
                                                        :for="`form-input-choice-${ques.question_code}-${chIdx}`"
                                                    >
                                                        <input
                                                            :id="`form-input-choice-${ques.question_code}-${chIdx}`"
                                                            class="form-check-input align-middle"
                                                            type="checkbox"
                                                            :value="ques.question_type == 'select' ? ch.label : ch.option.code"
                                                            v-model="form.relate_question[form.relate_question.findIndex(x => x.code == ques.question_code)].answer"
                                                        >{{ques.question_type == 'select' ? ch.label : ch.option.desc}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                                <hr class="mr-4 ml-4">
                            </div>
                        </div>
                    </template>
                    </CustomAccordion>
                </div>
                <div class="col-span-12">
                    <CustomAccordion
                        accordion-id="order_question_list"
                        headerText="เลือกตำแหน่งคำถามเพื่อกำหนดลำดับการแสดงผลโดยคำถามปัจจุบันจะแทนที่ตำแหน่งคำถามที่เลือก"
                    >
                    <template v-slot:body-content>
                        <div class="col-span-12 ml-[-1rem] mr-[-1rem]">
                            <hr>
                            <div class="p-4">
                                <label class="form-label">
                                    ค้นหาคำถาม
                                </label>
                                <input
                                    id="search_question_list_order"
                                    type="text"
                                    class="form-control"
                                    placeholder="พิมพ์คำค้นหาที่นี่"
                                    data-test="search_question_list_order"
                                    :disabled="false"
                                    v-model="searchWordOrder"
                                />
                            </div>
                            <div v-for="(ques, index) in FilteredOrderQuestionList" :key="index">
                                <label
                                    class="form-check-label align-middle flex items-center hover:bg-gray-100 ml-0" :class="{'bg-gray-100' : new_position == ques.position}"
                                    :for="`input-position-${ques.question_code}`"
                                >
                                    <div class="p-5">
                                        <div class="header flex items-center">
                                            <input
                                                :id="`input-position-${ques.question_code}`"
                                                class="form-check-header-input align-middle mr-2"
                                                type="checkbox"
                                                :value="ques.position"
                                                :disabled="ques.disabled"
                                                v-model="new_position"
                                            >
                                            {{ques.question_type === "multiple-inputs-group" && !ques.question_desc ? ques.choices.map(c=>c.question_desc).join(" ") : ques.question_desc}}
                                        </div>
                                    </div>
                                </label>
                                <hr class="mr-4 ml-4">
                            </div>
                        </div>
                    </template>
                    </CustomAccordion>
                </div>
                <div class="col-span-12">
                    <div class="form-check flex-col items-start mt-3">
                        <label for="active-status" class="form-check-label ml-0 mb-2">เปิด/ปิดการใช้งาน</label>
                        <input id="active-status" class="form-check-switch" type="checkbox" v-model="form.status" true-value="active" false-value="inactive">
                    </div>
                </div>
            </div>
        </ModalLayout>
    </div>
</template>
<script setup>
import ModalLayout from "@/components/base/custom-modal/CustomModalLayout.vue"
import CustomAccordion from "@/components/base/custom-accordion/index.vue";
import { reactive, ref, watch, computed, toRefs } from "vue"
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf, minLength, maxLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";

const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});
const emit = defineEmits(["saveSuccess", "update:modelValue"])
const props =  defineProps({
    currentStep: {
        type: Object
    },
    questionList: {
        type: Array
    },
    questionnaireData: {
        type: Object,
        required: true
    },
    editData:{
        type: Object
    },
    currentHeaderData: {
        type: Object
    },
    modelValue:{
        type: Boolean
    }
})

const configEditor = ref({
    fontColor: {
        colors: [
            {
	 			color: 'hsl(0, 0%, 0%)',
	 			label: 'Black'
	 		},
	 		{
	 			color: 'hsl(0, 0%, 30%)',
	 			label: 'Dim grey'
	 		},
	 		{
	 			color: 'hsl(0, 0%, 60%)',
	 			label: 'Grey'
	 		},
	 		{
	 			color: 'hsl(0, 0%, 90%)',
	 			label: 'Light grey'
	 		},
	 		{
	 			color: 'hsl(351, 100%, 86%)',
	 			label: 'Light pink'
	 		},
	 		{
	 			color: 'hsl(0, 75%, 60%)',
	 			label: 'Red'
	 		},
	 		{
	 			color: 'hsl(30, 75%, 60%)',
				label: 'Orange'
	 		},
	 		{
	 			color: 'hsl(15, 72%, 70%)',
				label: 'Dark salmon'
	 		},
	 		{
				color: 'hsl(60, 75%, 60%)',
	 			label: 'Yellow'
	 		},
	 		{
	 			color: 'hsl(90, 75%, 60%)',
	 			label: 'Light green'
	 		},
	 		{
	 			color: 'hsl(120, 75%, 60%)',
	 			label: 'Green'
	 		},
	 		{
	 			color: 'hsl(150, 75%, 60%)',
	 			label: 'Aquamarine'
	 		},
	 		{
	 			color: 'hsl(180, 75%, 60%)',
	 			label: 'Turquoise'
	 		},
	 		{
	 			color: 'hsl(210, 75%, 60%)',
	 			label: 'Light blue'
	 		},
	 		{
	 			color: 'hsl(240, 75%, 60%)',
	 			label: 'Blue'
	 		},
	 		{
	 			color: 'hsl(270, 75%, 60%)',
	 			label: 'Purple'
	 		},
	 		{
	 			color: 'hsl(19, 56%, 40%)',
	 			label: 'Sienna'
	 		},
	 		{
	 			color: 'hsl(16, 100%, 66%)',
	 			label: 'Coral'
	 		},
	 		{
	 			color: 'hsl(51, 100%, 50%)',
	 			label: 'Gold'
	 		},
	 		{
	 			color: 'hsl(203, 92%, 75%)',
	 			label: 'Light sky blue'
	 		}
        ]
    },
    toolbar: {
        items: [
            'undo', 'redo',
            '|', 'fontColor',
            '|', 'bold', 'underline', 'italic', 'strikethrough',
            '|', 'alignment',
            '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
        ],
        shouldNotGroupWhenFull: true
    },
    placeholder: 'รายละเอียดคำอธิบาย',
    language: 'th',
    mediaEmbed: {
        previewsInData: true
    }
})
const questionTypeOptions = reactive([
    {type: "radio", label: "เลือกคำตอบได้เพียง 1 ข้อ (Radio list)"},
    {type: "checkbox", label: "เลือกคำตอบได้มากกว่า 1 ข้อ (Checkbox list)"},
    {type: "text", label: "พิมพ์คำตอบในกล่องข้อความ (Text input)"},
    {type: "number", label: "พิมพ์คำตอบในกล่องข้อความ เฉพาะตัวเลขเท่านั้น (Number input)"},
    {type: "select", label: "คำตอบแบบเลือกจากรายการ (Select)"},
    {type: "multiple-inputs-answer", label: "พิมพ์คำตอบในกล่องข้อความ มากกว่า 1 กล่องข้อความ (Multiple inputs)"},
])
const store = useStore()
const showForm = ref(false)
const answer_limit_checked = ref(false)
const inputOptionType = reactive([
    {type: "text", label: "พิมพ์คำตอบในกล่องข้อความ (Text input)"},
    {type: "number", label: "พิมพ์คำตอบในกล่องข้อความ เฉพาะตัวเลขเท่านั้น (Number input)"},
])
const searchWord = ref("")
const searchWordOrder = ref("")
const showInput = ref(false)
const isDone = ref(false)
const default_header_id = ref(null)
const isEdit = ref(false)
const form = reactive({
    header_id: null,
    question_code: null,
    question_desc: null,
    question_label: null,
    required: false,
    required_message: null,
    question_type: null,
    additional_detail: "",
    placeholder: null,
    max_length: null,
    max_length_message: null,
    disable: false,
    answer_limit: null,
    is_column: null,
    is_start_date: null,
    is_end_date: null,
    required_count_member: null,
    is_only_number: null,
    is_checkbox_group: null,
    edit_only: false,
    tempChoices: [],
    tempMultipleInputs: [],
    tempSelectOption: [],
    status: "inactive",
    position: null,
    relate_age_month: {
        start: null,
        end: null
    },
    relate_question: [],
    choices: [],
    is_negative: null,
    is_decimal: null
})
const token = store.getters["userAuth/getToken"];
const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
};
const formrules = computed(()=>{
    return {
        question_desc: {
            required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=>form.question_type != "multiple-inputs-group")),
        },
        question_type: {
            required: helpers.withMessage(`กรุณาระบุ`, required),
        },
        max_length:{
            required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=>form.max_length_message)),
        },
        required_message:{
            required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=>form.required)),
        },
        tempChoices:{
            $each: helpers.forEach({
                desc:{
                    required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=> form.question_type == "radio" || form.question_type == "checkbox")),
                },
                specify_value:{
                    $each: helpers.forEach({
                        type: {required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=> form.question_type == "radio" || form.question_type == "checkbox"))},
                        message: {required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=> form.question_type == "radio" || form.question_type == "checkbox"))},
                    })
                }
            })
        },
        tempSelectOption:{
            $each: helpers.forEach({
                label:{
                    required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=> form.question_type == "select")),
                },
            })
        },
        tempMultipleInputs:{
            $each: helpers.forEach({
                type: {required: helpers.withMessage(`กรุณาระบุ`, requiredIf(()=> form.question_type == "multiple-inputs-answer"))},
            })
        },
        relate_age_month: {
            start: {
                required: helpers.withMessage(`กรุณากรอกอายุเริ่มต้น`, requiredIf(() =>form.relate_age_month.end && props.questionnaireData?.questionnaire_name_en != "household"))
            }
        }
    }
})


const validate = useVuelidate(formrules, form)
const onReady = ( editor ) => {
    // Insert the toolbar before the editable area.
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
}
const addMoreOption = () => {
    form.tempSelectOption.push({label: null})
}
const addMoreChoice = (ch_code) => {
    let temp_code = null
    if(ch_code){
        temp_code = ch_code.split("-")
        temp_code = temp_code?.[1] ? `${temp_code[0]}-${Number(temp_code[1])+1}` : null
    }
    let obj = {code: temp_code, desc: null}
    const { code, desc, other, answers, ...rest} = JSON.parse(JSON.stringify(form.tempChoices[0]))
    if(rest?.specify_value?.some(item => item.key == "other")){
        rest.specify_value = rest.specify_value.filter(item => item.key != "other")
    }
    if(Object.keys(rest).length)
        obj = {...obj, ...rest}
    form.tempChoices.push(obj)
}
const removeChoice = (type, idx) => {
    if(type == "select")
        form.tempSelectOption.splice(idx, 1)
    else
        form.tempChoices.splice(idx, 1)
}
const removeInput = (idx) => {
    form.tempMultipleInputs.splice(idx, 1)
}
const addInput = (key) => {
    const keyNum = key.match(/\d+/)?.[0] ?? 0
    form.tempMultipleInputs.push({key: `value${Number(keyNum)+1}`, type: null, label: null, unit: null, code: null})
}
const addSpecifyInput = (index, key) => {
    const keyNum = key.match(/\d+/)?.[0] ?? 0
    const runningNum = Number(keyNum)+1
    form.tempChoices.map((item)=>{
        item[`value${runningNum}`] = null
        item.specify_value.push({key: `value${runningNum}`, type: null, label: null, message: "กรุณาระบุ", unit: null, code: null})
        return item
    })
}
const removeSpecifyInput = (index, specify_index, key) => {
    form.tempChoices.map(item=>{
        delete item[key]
        item.specify_value.splice(specify_index, 1)
        if(item.specify_value.length == 0)
            delete item.specify_value
        return item
    })
}

const question_lists = ref()
let tempPosition = null
let current_position = null
let position_in_new_header = null
const new_position = ref([])
let question_lists_header = []
watch(()=>props.currentStep, (value)=>
    {
        form.header_id = null
        default_header_id.value = null
        if(props.questionList?.length){
            question_lists.value = props.questionList.find(x=> x.step.step_number == value.step)?.questions.reduce((acc,v)=>{
                let newList = v.question_lists.map(obj => ({ ...obj, relate_option: {code: obj.question_code, answer: [], type: obj.question_type}}))
                return [...acc, ...newList]
            },[])
        }
    }
)

const headerList = computed(()=>{
    if(props.questionList?.length){
        question_lists_header = props.questionList.find(({step})=> step.step_number == props.currentStep.form)
        return question_lists_header?.questions.reduce((acc,curr, idx)=>{
                if(curr.header){
                    acc = [...acc, curr.header_info]
                    if(idx == 0){
                        form.header_id = curr.header_info?._id
                    }
                }else{
                    default_header_id.value = curr.header_info?._id
                    if(!isEdit.value){
                        let curr_lists = question_lists_header?.questions.find(({header_info}) => header_info?._id == default_header_id.value )
                        //note first time no position
                        if(curr_lists.question_lists.length == 0 ){
                            tempPosition = 1
                        }
                        else{
                            tempPosition = curr_lists.question_lists[curr_lists.question_lists.length-1]?.position + 1
                        }
                        form.position = tempPosition
                    }
                }
            return acc
        },[])
    }
    return []
})

watch(()=>form.header_id, (value, old)=>{
    if((value && !isEdit.value) || (old && isEdit.value && (props.editData?.header_id != value))){
        let curr_lists = question_lists_header.questions.find(({header_info}) => header_info._id == value )
        tempPosition = curr_lists.question_lists.length == 0 ? 1 : curr_lists.question_lists[curr_lists.question_lists.length-1]?.position + 1
        form.position = tempPosition
        new_position.value = []
        if(isEdit.value){
            position_in_new_header = form.position
        }
    }
    if(old && isEdit.value && (props.editData?.header_id == value)){
        form.position = props.editData?.position
        tempPosition = props.editData?.position
        position_in_new_header = null
    }
},
{deep: true})

const FilteredRelateQuestionList = computed(()=>{
    //extract from group-input as single
    if(question_lists.value && modelValueComputed.value){
        let newList = question_lists.value.reduce((a,v)=>{
            let temp = []
            if(v.question_type == "multiple-inputs-group"){
                let newChoices = v.choices.map(obj => ({...obj, relate_option: isEdit.value ? obj.relate_option : {code: obj.question_code, answer: [], type: obj.question_type}}))
                a = [...a, ...newChoices]
            }
            else{
                v.relate_option = isEdit.value ? v.relate_option : {code: v.question_code, answer: [], type: v.question_type}
                temp = [...temp, v]
            }
            return [...a, ...temp]
        }, [])
       return newList.filter(x=>x.question_desc?.includes(searchWord.value) || x.question_code.includes(searchWord.value) || x.choices.some(({question_desc})=>question_desc?.includes(searchWord.value)) || (!x.question_desc ? x?.placeholder.includes(searchWord.value) : null))
    }
    return []
})
const FilteredOrderQuestionList = computed(()=>{
    let temp_header_id = form.header_id ?? default_header_id.value
    if(question_lists.value){
       return question_lists.value.filter(x=>x.header_id == temp_header_id && (x.question_desc?.includes(searchWordOrder.value) || x.question_code.includes(searchWordOrder.value)))
    }
    return []
})
const answerList = computed(()=>{
    let count = form.tempChoices.length
    let temp =[]
    for(let i = 1; i <= count; i++){
        temp.push(i)
    }
    return temp
})
const handleShowInput = (index) =>{
    form.tempChoices.map((item)=>{
        item[`value0`] = null
        if(item["specify_value"]){
            item["specify_value"].push({key: "value0",type: null,label: null,message: "กรุณาระบุ",unit: null,code: null})
        }
        else{
            item["specify_value"] = [{key: "value0",type: null,label: null,message: "กรุณาระบุ",unit: null,code: null}]
        }
        return item
    })
}

const otherOption = (evt,index) =>{
    if(evt.target.checked){
        form.tempChoices[index]["other"] = null
        if(form.tempChoices[index].specify_value){
            form.tempChoices[index].specify_value.unshift({key: "other",type: "text",label: null,message: "กรุณาระบุ",unit: null,code: null})
        }
        else{
            form.tempChoices[index].specify_value = [{key: "other",type: "text",label: null,message: "กรุณาระบุ",unit: null,code: null}]
        }
    }
    else{
        delete form.tempChoices[index]["other"]
        form.tempChoices[index].specify_value.splice(0, 1)
        if(form.tempChoices[index].specify_value.length == 0)
            delete form.tempChoices[index].specify_value
    }
}

const handleChoiceOption = (evt, index, key) => {
    if(evt.target.checked){
        form.tempChoices[index] = {...form.tempChoices[index], [key]: true}
    }
    else{
        delete form.tempChoices[index][key]
    }

}

const checkConditionQuestionType = computed(()=>{
    if(form.question_type){
        if(form.question_type == "text" || form.question_type == "number"){
            let option =  {
                isPlaceholder: true,
                isLabel: true,
                isChoice: false,
                isCheckboxGroup: false,
                isLimitMaxLength: true
            }
            if(form.question_type == "text")
                option.isOnlyNumber = true
            else
                option.isOnlyNumber = false
        
            return option
        }
        else if(form.question_type == "select"){
            if(form.tempSelectOption.length == 0 && !isEdit.value)
                form.tempSelectOption.push({label: null})
            return {
                isOnlyNumber: false,
                isPlaceholder: true,
                isChoice: false,
                isCheckboxGroup: false,
                isLabel: true
            }
        }
        else if(form.question_type == "radio"){
            if(form.tempChoices.length == 0 && !isEdit.value){
                // form.tempChoices.push({option: [{code: 1, desc: null}]})
                form.tempChoices.push({code: null, desc: null})
            }

            return {
                isOnlyNumber: false,
                isPlaceholder: false,
                isChoice: true,
                isCheckboxGroup: false
            }
        }
        else if(form.question_type == "checkbox"){
            //for group set qustion_code to GROUP_with running number+1 and tempChoice code use running number+1
            if(form.tempChoices.length == 0 && !isEdit.value)
                form.tempChoices.push({code: null, desc: null})
                // form.tempChoices.push({option: {code: 1, desc: null}})
            if(answer_limit_checked.value)
                form.answer_limit = form.tempChoices.length < 3 ? form.tempChoices.length : 3

            return {
                isOnlyNumber: false,
                isPlaceholder: false,
                isChoice: true,
                isCheckboxGroup: true,
                isLimitAnswer: true
            }
        }
        else if(form.question_type == "multiple-inputs-answer"){
            if(form.tempMultipleInputs.length == 0 && !isEdit.value)
                form.tempMultipleInputs.push({key: "value0",type: null,label: null,unit: null,code: null})
            //multiple-inputs-answer
            return {
                isOnlyNumber: false,
                isPlaceholder: false,
                isChoice: false,
                isCheckboxGroup: false,
                isMultipleInput: true
            }
        }
    }
    return {
        isOnlyNumber: false,
        isPlaceholder: false,
        isChoice: false,
        isCheckboxGroup: false,
        isLabel: false,
        isMultipleInput: false,
        isLimitMaxLength: false
    }
})
const getSequence = () => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_BASE_API}autoincreament`;
        const params = {
            sequenceName: props.questionnaireData?.questionnaire_name_en == "household" ? "house" : props.questionnaireData?.questionnaire_name_en
        }
        axios.get(url, { headers, params }).then((res)=>resolve(res.data)).catch((err)=>reject(err))
    })
}

const submitForm = async () => {
    validate.value.$touch();
    if (validate.value.$invalid) {
        return;
    }
    swalCustom.fire({
        text: "ยืนยันบันทึกข้อมูล",
        icon: "info",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
    }).then(async(result)=>{
        if (result.isConfirmed) {
            try{
                let sequence = !form.question_code ? await getSequence() : {sequenceValue: Number(form.question_code.replace(/\D+/g, ''))}
                let code_text = props.questionnaireData.code_name
                // if(props.questionGroup == "household")
                //     code_text = "HH"
                // else if(props.questionGroup == "member")
                //     code_text = "IN"
                // else if(props.questionGroup == "neets")
                //     code_text = "NEET"
                // else
                //     code_text = "CWD"
                if(sequence){
                    const config_data = {
                        step_id: props.currentStep.id,
                        header_id: form.header_id ?? default_header_id.value,
                        question_naire_id: props.questionnaireData.questionnaire_id,
                        question_code: isEdit.value ? form.question_code : `${form.is_checkbox_group ? `GROUP_${code_text}${String(sequence.sequenceValue).padStart(3, '0')}` : `${code_text}${String(sequence.sequenceValue).padStart(3, '0')}`}`,
                        question_desc: form.question_desc,
                        question_label: form.question_label,
                        required: form.required,
                        required_message: form.required ? form.required_message : null,
                        question_type: form.question_type,
                        additional_detail: form.additional_detail,
                        placeholder: form.placeholder,
                        max_length: form.max_length,
                        max_length_message: form.max_length_message,
                        disable: form.disable,
                        edit_only: form.edit_only,
                        answer_limit: form.answer_limit,
                        required_count_member: form.required_count_member,
                        is_only_number: form.is_only_number,
                        is_checkbox_group: form.is_checkbox_group,
                        relate_age_month: typeof form.relate_age_month.start == "number" ? form.relate_age_month : {},
                        relate_question: form.relate_question,
                        status: form.status
                    }
                    if(form.is_negative){
                        config_data["is_negative"] = form.is_negative
                    }
                    if(form.is_decimal){
                        config_data["is_decimal"] = form.is_decimal
                    }
                    //for add question set position
                    if((form.position && !isEdit.value && !current_position) || (position_in_new_header && !current_position)){
                        config_data["position"] = form.position
                    }
                    //in case edit update position only when change new position
                    if(current_position){
                        config_data["current_position"] = current_position
                        config_data["position"] = form.position
                    }
                    if((form.question_type == "radio" || (form.question_type == "checkbox" && !form.is_checkbox_group))){
                        form.choices.map((item, index)=>{
                            if(!isEdit.value)
                                item.option.code = `${code_text}${String(sequence.sequenceValue).padStart(3, '0')}-${index+1}`
                            if(item.specify_value){
                                item.specify_value.map(sp=>sp.code = null)
                            }
                        })
                        config_data.choices = form.choices
                    }
                    else if(form.question_type == "checkbox" && form.is_checkbox_group){
                        const result = await Promise.all(form.choices.map(async(item, index)=>{
                            if(index == 0){
                                //if edit and option.code = null then set new code value
                                if(!item.option.code)
                                    item.option.code = `${code_text}${String(sequence.sequenceValue).padStart(3, '0')}`
                                if(item.specify_value){
                                    for(let i = 0; i < item.specify_value.length; i++){
                                        if(i==0){
                                            //if edit and option.code = null then set new code value
                                            if(!item.specify_value[i].code)
                                                item.specify_value[i].code = `${code_text}${String(sequence.sequenceValue).padStart(3, '0')}`
                                        }else{
                                            //if edit and option.code = null then set new code value
                                            if(!item.specify_value[i].code){
                                                const newSeq = await getSequence()
                                                if(newSeq)
                                                    item.specify_value[i].code = `${code_text}${String(newSeq.sequenceValue).padStart(3, '0')}`
                                            }
                                        }
                                    }
                                }
                            }
                            else{
                                //get new seq
                                let newSeqCode = !item.option.code ? await getSequence() : {sequenceValue: Number(item.option.code .replace(/\D+/g, ''))}

                                if(newSeqCode){
                                    if(!item.option.code)
                                        item.option.code = `${code_text}${String(newSeqCode.sequenceValue).padStart(3, '0')}`

                                    if(item.specify_value){
                                        for(let i = 0; i < item.specify_value.length; i++){
                                            if(i == 0){
                                                if(!item.specify_value[i].code)
                                                    item.specify_value[i].code = `${code_text}${String(newSeqCode.sequenceValue).padStart(3, '0')}`
                                            }else{
                                                if(!item.specify_value[i].code){
                                                    const newSeq2 = await getSequence()
                                                    if(newSeq2)
                                                        item.specify_value[i].code = `${code_text}${String(newSeq2.sequenceValue).padStart(3, '0')}`
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            return item
                        }))
                        config_data.answer_limit = null
                        config_data.choices = result
                    }
                    else{
                        config_data.choices = form.choices
                    }
                    const config_axios = {
                        method: isEdit.value ? 'PUT' : 'POST',
                        url: `${process.env.VUE_APP_BASE_API}admin/question${props.editData?._id ? `/${props.editData._id}` : ``}`,
                        data: config_data,
                        headers: headers
                    }
                    const response = await axios(config_axios).catch(()=>{
                        swalCustom.fire({
                            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                            icon: "warning",
                            // timer: 3000, //timeOut for auto-close
                            confirmButtonText: "ปิด",
                        });
                    })
                    if(response.status == 200){
                        // swalCustom.fire({
                        //     text:"สำเร็จ",
                        //     icon: 'success',
                        //     timer: 3000,
                        //     confirmButtonText: "ปิด",
                        // }).then(() => {
                            modelValueComputed.value = false
                            isDone.value = true
                            clearModal(true)
                        // });
                    }
                }
                else{
                    swalCustom.fire({
                        text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                        icon: "warning",
                        // timer: 3000, //timeOut for auto-close
                        confirmButtonText: "ปิด",
                    });
                }
            }
            catch{
                swalCustom.fire({
                    text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                    icon: "warning",
                    // timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                });
            }
        }
    })

}

const clearModal = (isUpdate=null) => {
    validate.value.$reset()
    isDone.value = false
    isEdit.value = false
    default_header_id.value = null
    answer_limit_checked.value = false
    current_position = null
    position_in_new_header = null
    new_position.value = []
    Object.assign(form,{
        header_id: null,
        question_code: null,
        question_desc: null,
        question_label: null,
        required: false,
        required_message: null,
        question_type: null,
        additional_detail: "",
        placeholder: null,
        max_length: null,
        max_length_message: null,
        disable: false,
        answer_limit: null,
        is_column: null,
        is_start_date: null,
        is_end_date: null,
        required_count_member: null,
        is_only_number: null,
        is_checkbox_group: null,
        edit_only: false,
        tempChoices: [],
        tempMultipleInputs: [],
        tempSelectOption: [],
        status: "inactive",
        position: null,
        relate_age_month: {
            start: null,
            end: null
        },
        relate_question: [],
        choices: []
    })
    setTimeout(()=>{
        emit("saveSuccess",isUpdate)
    },500)
}
watch(()=>form.tempChoices, (value)=>{
    if(value.length){
        const res = value.reduce((acc,v)=>{
            let {specify_value, is_multiple_answer, required, disable, ...rest} = v
            let temp_is_multiple_answer = {}
            let haveRequired = {}
            let haveDisable = {}
            if(v.is_multiple_answer){
                for(let val of v.specify_value){
                    delete rest[val.key]
                }
                rest = {...rest, answers: []}
                temp_is_multiple_answer = {is_multiple_answer: v.is_multiple_answer}
            }
            let newObj = {option: rest}
            if(v.specify_value){
                newObj = {...newObj, ...{specify_value: v.specify_value}}
            }
            if(v.required){
                haveRequired = {required: v.required}
            }
            if('disable' in v && 'order' in rest){
                haveDisable = {disable: v.disable}
            }

            newObj = {...newObj, ...temp_is_multiple_answer, ...haveRequired, ...haveDisable}
            return [...acc, newObj]
        },[])
        form.choices = res
    }
},
{deep: true})
watch(()=>form.tempMultipleInputs, (value)=>{
    form.choices = value
},
{deep: true})
watch(()=>form.tempSelectOption, (value)=>{
    form.choices = value
},
{deep: true})
watch(()=>new_position.value, (value)=>{
    FilteredOrderQuestionList.value.map((item)=>{
        if(value?.[0] && (item.position != value?.[0]))
            item.disabled = true
        else
            item.disabled = false
    })
    if(value?.length){
        if(!isEdit.value){
            form.position = value[0]
        }
        else{
            current_position = form.position
            form.position = value[0]
        }
    }
    else{
        form.position = tempPosition
        current_position = null
    }
})

watch(() => answer_limit_checked.value, (value) => {
    if(value){
        form.tempChoices.map((item)=>{
            item["order"] = null
            item["disable"] = false
        })
    }
    else{
        form.tempChoices.map((item)=>{
            delete item["order"]
            delete item["disable"]
        })
    }
})


watch(()=>props.editData,(val)=>{
    if(val){
        if(!default_header_id.value)
            form.header_id = val.header_id
        isEdit.value = true
        form.question_code = val.question_code ?? null
        form.question_desc = val.question_desc ?? null
        form.question_label = val.question_label ?? null
        form.required = val.required
        form.required_message = val.required_message ?? null
        form.question_type = val.question_type ?? null,
        form.additional_detail = val.additional_detail ?? ""
        form.placeholder = val.placeholder ?? null
        form.max_length = val.max_length ?? null
        form.max_length_message = val.max_length_message ?? null
        form.disable = val.disable
        form.answer_limit = val.answer_limit ?? null
        form.is_only_number = val.is_only_number ?? null
        form.is_checkbox_group = val.is_checkbox_group ?? null
        form.status = val.status ?? "inactive"
        form.position = val.position ?? null
        tempPosition = val.position ?? null
        form.edit_only = val.edit_only
        form.choices = val.choices
        form.required_count_member = val.required_count_member ?? null
        form.is_negative = val.is_negative ?? null
        form.is_decimal = val.is_decimal ?? null
        form.relate_question = val.relate_question

        if(val?.relate_age_month && Object.keys(val.relate_age_month).length){
            form.relate_age_month = val.relate_age_month
        }


        if(form.relate_question){
            for(let ques of form.relate_question){
                let idx = question_lists.value.findIndex(x=>x.question_code == ques.code)
                if(idx != -1){
                    question_lists.value[idx].relate_option = ques
                }
            }
        }
        if(val.question_type == "checkbox" || val.question_type == "radio"){
            form.tempChoices = val.choices.reduce((a,v)=>{
                let newObj = {...v.option}
                if(v.is_multiple_answer){
                    let { answers, ...rest} = newObj
                    let key_input = {}
                    for(let val of v.specify_value){
                        key_input = {...key_input, [val.key]: null}
                    }
                    newObj = {...rest, ...key_input, ...{is_multiple_answer: v.is_multiple_answer}}
                }
                if(v.specify_value){
                    newObj = {...newObj, ...{specify_value: v.specify_value}}
                }
                if(v.required){
                    newObj = {...newObj, ...{required: v.required}}
                }
                if('disable' in v && 'order' in newObj){
                    newObj = {...newObj, ...{disable: v.disable}}
                    answer_limit_checked.value = true
                }
                return [...a, newObj]
            },[])
        }
        else if(val.question_type == "select"){
            form.tempSelectOption = val.choices
        }
        else if(val.question_type == "multiple-inputs-answer"){
            form.tempMultipleInputs = val.choices
        }
    }
})

watch(()=> props.currentHeaderData , (val)=>{
    if(val){
        if(val.header_info.header){
            form.header_id = val.header_info._id
        }
        else{
            default_header_id.value = val.header_info._id
        }
    }
})
/* const openAddQuestionModal = () =>{
    showForm.value = true
    if(!default_header_id.value && temp_current_step_header_default){
        form.header_id = temp_current_step_header_default
    }
} */

const modelValueComputed = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

</script>
<style lang="scss">
.ck.ck-tooltip .ck-tooltip__text{
    color: #FFF !important;
}
.ck.ck-editor__editable > .ck-placeholder::before {
    font-style: italic;
    font-weight: 300;
}
.ck-content{
    ul {
        list-style-type: disc;
        margin-left: 1.5rem
    }
    ol{
        list-style-type: decimal;
        margin-left: 1.5rem
    }
}

.vs__dropdown-menu{
    min-width: 70px !important;
}
.remove-spc-btn{
    box-shadow: unset !important;
}
</style>