<template>
    <!-- <section data-test="filter-age-section" class="grid grid-cols-12 gap-4">
        <div class="mt-2 col-span-12 sm:col-span-6"> -->
        <div>
            <label class="from-label text-medium text-gray-600" for="survey-status-select">สถานะการสำรวจ</label
            >
            <v-select
                data-test="survey-status-select"
                id="survey-status-select"
                v-model="statusComputed"
                :options="options"
                label="label"
                :reduce="(label) => label.value"
                :clearable="true"
                placeholder="กรุณาเลือกสถานะการสำรวจ"
                :appendToBody="true"
            >
                <template #no-options="">
                <p class="text-sm font-normal text-gray-500">
                    กรุณาเลือกสถานะการสำรวจ
                </p>
                </template>
                <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                    <ChevronDownIcon class="w-5 h-5"
                /></span>
                </template>
            </v-select>
        </div>
        <!-- </div>
    </section> -->
</template>

<script setup>
import { computed, reactive } from "vue";
const props = defineProps({
  modelValue: Boolean,
  options: Array
});

const emits = defineEmits(["update:modelValue"]);
const statusComputed = computed({
  get() {
    return props.modelValue;
  },
  set(evt) {
    emits("update:modelValue", evt);
  },
});
</script>

<style>

</style>