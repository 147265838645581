<template>
  <!-- hh_code -->
  <div>
    <div class="box rounded-md shadow-md">
      <div class="flex justify-end mx-6 pt-2 flex-wrap">
        <div class="hidden sm:block">
          <!-- <button
            class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
            v-if="onlineComputed"
            @click="router.push({ name: 'add-household-survey', params: {hhid: route.params.hhid}})"
            disabled
          >
          + เพิ่มการสำรวจ
          </button> -->
          <button
            class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
            @click="router.push({
              name: 'view-household', params: {hhid: route.params.hhid, surveyid: route.params.surveyid}
            })"
            :disabled="!getPermissionAccessMenu('read') && onlineComputed"
          >
            <EyeIcon class="h-3 w-3 mr-2" /> ดูข้อมูล
          </button>
          <button
            class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
            @click="router.push({ name: 'edit-household', params: {id: route.params.hhid}})"
            v-if="information.status === 'DRAFT'"
            :disabled="!getPermissionAccessMenu('update') && onlineComputed"
          >
            <Edit2Icon class="h-3 w-3 mr-2" />แก้ไข
          </button>
          <button
            class="btn bg-theme-24 text-white mb-2"
            data-test="delete-household"
            @click="deleteHouseholdSurvey"
            v-if="onlineComputed && information.status === 'DRAFT' && information.house_surveys.length > 1"
            :disabled="!getPermissionAccessMenu('delete')"
          >
            <Trash2Icon class="h-3 w-3 mr-2" />
            ลบแบบสำรวจนี้
          </button>
          <button
            class="btn bg-theme-24 text-white mb-2"
            data-test="delete-household"
            @click="deleteHousehold"
            :disabled="!getPermissionAccessMenu('delete') && onlineComputed"
          >
            <Trash2Icon class="h-3 w-3 mr-2" />
            ลบครัวเรือน
          </button>
        </div>
        <div class="sm:ml-0 flex sm:hidden bottom-[-5px] mb-2">
          <button
            class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
            @click="router.push({
              name: 'view-household', params: {hhid: route.params.hhid, surveyid: route.params.surveyid}
            })"
            :disabled="!getPermissionAccessMenu('read') && onlineComputed"
          >
            <EyeIcon class="h-3 w-3 mr-2" /> ดูข้อมูล
          </button>
          <div class="relative ml-auto">
            <div class="dropdown">
              <button class="dropdown-toggle btn btn-primary bg-primary-btn border-primary-btn transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 rounded-md font-medium cursor-pointer px-2 !box text-slate-500" aria-expanded="false" :disabled="!getPermissionAccessMenu('update') && !getPermissionAccessMenu('delete') && onlineComputed">
                <MoreVerticalIcon class="w-5 h-5"/>
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <!-- <button
                    data-dismiss="dropdown"
                    class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200 text-theme-43"
                    v-if="onlineComputed"
                    @click="router.push({ name: 'add-household-survey', params: {hhid: route.params.hhid}})"
                    disabled
                    :class="{'text-theme-43 cursor-not-allowed' : true}"
                  >
                  + เพิ่มการสำรวจ
                  </button> -->
                  <button
                    data-dismiss="dropdown"
                    class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200 text-primary-1"
                    @click="router.push({ name: 'edit-household', params: {id: route.params.hhid}})"
                    v-if="information.status === 'DRAFT'"
                    :disabled="!getPermissionAccessMenu('update') && onlineComputed"
                    :class="{'text-theme-43 cursor-not-allowed' : !getPermissionAccessMenu('update')}"
                  >
                    <Edit2Icon class="h-3 w-3 mr-2" />แก้ไข
                  </button>
                  <button
                    data-dismiss="dropdown"
                    class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200 text-theme-24"
                    data-test="delete-household"
                    @click="deleteHouseholdSurvey"
                    v-if="onlineComputed && information.status === 'DRAFT' && information.house_surveys.length > 1"
                    :disabled="!getPermissionAccessMenu('delete')"
                    :class="{'text-theme-43 cursor-not-allowed' : !getPermissionAccessMenu('delete')}"
                  >
                    <Trash2Icon class="h-3 w-3 mr-2" />
                    ลบแบบสำรวจนี้
                  </button>
                  <button
                    data-dismiss="dropdown"
                    class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200 text-theme-24"
                    data-test="delete-household"
                    @click="deleteHousehold"
                    :disabled="!getPermissionAccessMenu('delete') && onlineComputed"
                    :class="{'text-theme-43 cursor-not-allowed' : !getPermissionAccessMenu('delete')}"
                  >
                    <Trash2Icon class="h-3 w-3 mr-2" />
                    ลบครัวเรือน
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
      <div class="p-5 flex flex-wrap justify-center">
        <div class="flex-shrink-0 mx-8 flex flex-col items-center mb-5">
          <!-- <div class="bg-theme-33 rounded-full h-28 w-28 mb-2"> -->
          <!-- <img v-if="getMemberData.image" :src="getMemberData.image.img_url" class="object-cover object-center h-full"/> -->
          <!-- </div> -->
          <div
            class="relative h-28 w-28 rounded-full mb-2 flex-shrink-0"
            :class="{
              'bg-theme-41': information ? information.gender === 'ชาย' : '',
              'bg-theme-36': information ? information.gender === 'หญิง' : '',
            }"
          >
            <div class="h-full w-full rounded-full overflow-hidden">
              <img
                v-if="information.mainImage"
                :src="information.mainImage"
                class="object-cover object-center h-full w-full"
              />
              <img
                v-else
                src="@/assets/images/user.png"
                class="object-cover object-center h-full w-full"
              />
            </div>
          </div>
          <button class="btn bg-theme-42 text-white rounded-3xl">
            {{ "หัวหน้าครัวเรือน" }}
          </button>
        </div>
        <div class="flex flex-col min-w-full md:min-w-0 md:flex-grow">
          <h3 class="text-xl text-theme-37 font-semibold mb-1">
            {{ `${information.houseHeadName ? information.houseHeadName : ""}` }}

            <!-- Static Data -->
          </h3>
          <span class="text-base font-normal text-theme-37 mb-1"
            >รหัสประจำบ้าน
            {{ information.house_number_11 ? information.house_number_11 : "" }}</span
          >

          <p class="text-base text-theme-37 font-normal">
            {{ information.addressResult }}
          </p>
          <hr class="mt-3" />
          <div class="flex flex-wrap justify-center items-center py-6 gap-2">
            <div class="flex-grow my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-3">
                สมาชิกในครัวเรือน
              </h5>
              <div>
                <h3 class="inline text-5xl text-theme-1 mr-2 font-semibold">
                  {{ `${information.member_cnt ? information.member_cnt : "0"}` }}
                </h3>
                <p class="inline text-base text-theme-37">(คน)</p>
              </div>
            </div>
            <div class="flex-grow my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-3">
                สมาชิกตามทะเบียนบ้าน
              </h5>
              <div>
                <h3 class="inline text-5xl text-theme-1 mr-2 font-semibold">
                  {{ `${information.member_reg_cnt ? information.member_reg_cnt : "0"}` }}
                </h3>
                <p class="inline text-base text-theme-37">(คน)</p>
              </div>
            </div>
            <div class="flex-grow flex flex-col my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-6">เพศ</h5>
              <div class="flex flex-wrap items-center pb-1 gap-3">
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-41 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศชาย</span>
                    <img class="h-5 w-5 " src="@/assets/images/icon/male.png" />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{ information.genderObj.male }}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-36 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศหญิง</span>
                    <img class="h-5 w-5" src="@/assets/images/icon/female.png" />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{information.genderObj.female}}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-13 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เพศอื่นๆ</span>
                    <img class="h-5 w-5" src="@/assets/images/icon/unisex.svg" />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{information.genderObj.other}}
                  </p>
                </div>
              </div>
            </div>

            <div class="flex-grow flex flex-col my-2">
              <h5 class="text-sm font-semibold text-theme-37 mb-6">
                ช่วงวัย
              </h5>
              <div class="flex flex-wrap md:flex-row items-center pb-1 gap-2">
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-1 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)</span>
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/baby-boy.png"
                    />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{information.ageRange.baby}}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-1 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">วัยเรียน (อายุ 5-14 ปี)</span>
                    <img class="h-5 w-5 " src="@/assets/images/icon/kid.png" />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{ information.ageRange.kid}}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-1 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)</span
                    >
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/teenager.png"
                    />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{ information.ageRange.teenager }}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-1 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)</span
                    >
                    <img class="h-5 w-5 " src="@/assets/images/icon/man.png" />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{ information.ageRange.adult }}
                  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div
                    class="t-tooltip rounded-full bg-theme-1 flex w-8 h-8 justify-center items-center"
                  >
                    <span class="t-tooltip-text">วัยผู้สูงอายุ (60 ปีขึ้นไป)</span>
                    <img
                      class="h-5 w-5 "
                      src="@/assets/images/icon/old-man.png"
                    />
                  </div>
                  <p class="inline ml-2 mr-2 text-theme-37">:</p>
                  <p class="inline text-theme-37">
                    {{ information.ageRange.elder }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import localForage from "localforage"
import { getPermissionAccessMenu } from "@/utils/getPermission";

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
// import AddFamilyModal from "@/components/add-family-modal/index.vue";
// import AddFamilyModal from "@/components/add-family-modal/index.vue";

const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const props = defineProps({
  information: Object,
  familyProfile: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["showEditModal"]);
const route = useRoute();
const router = useRouter()
const store = useStore()

const getMemberData = ref([]);
const getLengthMembers = ref(0);
const getLengthMembersReg = ref(0);
const getLengthGenderMen = ref(0);
const getLengthGenderWoman = ref(0);
const ageclass_infant_cnt = ref(0);
const ageclass_child_cnt = ref(0);
const ageclass_teenage_cnt = ref(0);
const ageclass_adult_cnt = ref(0);
const ageclass_elderly_cnt = ref(0);
const headHouseName = ref("");
let addressResult = ref("");
// const editModal = ref(false)
// const addFamilyModal = ref(false);
// const addHouseholdModal = ref(false);
const editModal = ref(false)
const onlineComputed = computed(()=> store.getters["network/isOnline"])

const deleteHouseholdSurvey = () => {
  const token = store.getters["userAuth/getToken"];
  swalCustom.fire({
    text:"ยืนยันลบแบบสำรวจนี้",
    icon:"warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  }).then(async (result)=>{
    if(result.isConfirmed){
      if(onlineComputed.value){
        const config = {
          url: `${process.env.VUE_APP_BASE_API}houseSurveys/${route.params.surveyid}`,
          method: "DELETE",
          headers:{
            Authorization: `Bearer ${token}`,
            "Content-Type": `application/json; charset=utf-8`,
          }
        }
        try{
          const response = await axios(config)
          if(response){
            swalCustom.fire({
              text:"สำเร็จ",
              icon: 'success',
              timer: 3000,
              confirmButtonText: "ปิด",
            }).then(() => {
              //goto survey latest
              if(props.information.nextHouseId){
                router.replace({
                  name: 'household-profile',
                  params: {
                      hhid: route.params.hhid,
                      surveyid: props.information.nextHouseId
                  },
                })
              }
              else{
                router.replace({
                  name: 'main-house-hold',
                })
              }
            });
          }
        }
        catch(error){
          swalCustom.fire({
            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
            icon: "warning",
            //timer: 3000, //timeOut for auto-close
            confirmButtonText: "ปิด",
          });
          console.log(error);
        }
      }

    }
  })
}
const deleteHousehold = () => {
  const token = store.getters["userAuth/getToken"];
  swalCustom.fire({
    text:"ยืนยันลบครัวเรือนนี้",
    icon:"warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  }).then(async (result)=>{
    if(result.isConfirmed){
      if(onlineComputed.value){
        const config = {
          url: `${process.env.VUE_APP_BASE_API}house/${route.params.hhid}`,
          method: "DELETE",
          headers:{
            Authorization: `Bearer ${token}`,
            "Content-Type": `application/json; charset=utf-8`,
          }
        }
        try{
          const response = await axios(config)
          if(response){
            swalCustom.fire({
              text:"สำเร็จ",
              icon: 'success',
              timer: 3000,
              confirmButtonText: "ปิด",
            }).then(() => {
              router.push({
                name: 'main-house-hold',
              })
            });
          }
        }
        catch(error){
          swalCustom.fire({
            text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
            icon: "warning",
            //timer: 3000, //timeOut for auto-close
            confirmButtonText: "ปิด",
          });
          console.log(error);
        }
      }
      else{
        await houseStore.removeItem(route.params.hhid)
        router.push({name: "main-house-hold"})
      }

    }
  })
}
</script>
