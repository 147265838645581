function globalNumDecFixed(num, dec_amount) {
  var new_num = Number(num);
  if (!(num % 1)) {
    dec_amount = 0;
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: dec_amount,
  }).format(new_num.toFixed(dec_amount));
}
function millionShortNum(num) {
  if (num >= 1000000000000000000000000) {
    return globalNumDecFixed(
      (num / 1000000000000000000000000).toFixed(2).replace(/\.0$/, ""),
      2
    );
  } else if (num >= 1000000000000000000) {
    return globalNumDecFixed(
      (num / 1000000000000000000).toFixed(2).replace(/\.0$/, ""),
      2
    );
  } else if (num >= 1000000000000) {
    return globalNumDecFixed(
      (num / 1000000000000).toFixed(2).replace(/\.0$/, ""),
      2
    );
  } else if (num >= 1000000) {
    return globalNumDecFixed((num / 1000000).toFixed(2).replace(/\.0$/, ""), 2);
  }else {
    return globalNumDecFixed(num, 0);
  }
}

function millionShortString(num) {
  if (num >= 1000000000000000000000000) {
    return "ล้านล้านล้านล้าน";
  }
  if (num >= 1000000000000000000) {
    return "ล้านล้านล้าน";
  }
  if (num >= 1000000000000) {
    return "ล้านล้าน";
  }
  if (num >= 1000000) {
    return "ล้าน";
  }
  return "";
}
export { millionShortNum, millionShortString, globalNumDecFixed };
