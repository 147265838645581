<template>
  <div>
    <ToggleMode :showToggle="true"></ToggleMode>
    <router-view />
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, watch, computed, onMounted} from "vue";
import ToggleMode from "@/components/toggle-online-offline/Main.vue"
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import localForage from "localforage";
import axios from "axios";
import Swal from "sweetalert2"

const villageStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'village'
});
const provinceStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'province'
});

const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const surveyStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'survey'
});
// const syncStatus = ref(false)
const store = useStore()
const router = useRouter()
const route = useRoute()
const result = ref(null)
const userProvCode = computed(
  () => parseInt(store.getters["userAuth/getUserProvinceCode"])
);


navigator.serviceWorker.onmessage = (event) => {
  if (event.data) {
    store.dispatch('network/setIsOnline', event.data.value)
    // store.dispatch('network/setIsOfflineWhileOnline', event.data.offlineWhileOnline)
  }
};

const onlineComputed = computed(()=>store.getters['network/isOnline'])
const statusSyncComputed = computed(()=> store.getters['network/statusSync'])

watch(onlineComputed, ()=>{
  if(onlineComputed.value && store.getters["userAuth/getUserTumbolCode"].length == 6){
    checkLocalData()
  }else{
    if(route.name != "main-house-hold"){
      router.push({
        name: "main-house-hold"
      })
    }
  }
})
const checkLocalData = () => {
  houseStore.length().then(async (keys)=>{
    if(navigator.onLine && onlineComputed.value && keys > 0){
      store.dispatch('network/setStatusSync', true)
      router.push({
        name: "sync-page"
      })
    }
    else{
      if(route.name != "main-house-hold"){
        router.push({
          name: "main-house-hold"
        })
      }
    }
  })
}
const getProvinceList = async () => {
  try{
    const headers = {
      Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
      "Content-Type": `application/json; charset=utf-8`,
    };
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}refadms/`, {headers});
    if (res.status === 200) {
      let data = await res.data.sort((a, b) =>
        a.province_name.localeCompare(b.province_name,'th')
      );
      provinceStore.setItem("province", JSON.parse(JSON.stringify(data)))
    } else {
      console.log("get province error!");
    }
  }
  catch{
    console.log("get province error!");
  }
};
const getVillage = async () => {
  try{
    const url = `${process.env.VUE_APP_BASE_API}refadms/?prov=${userProvCode.value}&amp=${store.getters["userAuth/getUserAmphurCode"]}&tam=${store.getters["userAuth/getUserTumbolCode"]}`;
    const headers = {
      Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
      "Content-Type": `application/json; charset=utf-8`,
    };
    const res = await axios.get(url, {headers});
    if (res.status === 200) {
      let data = res.data.sort((a, b) =>
        a.village_name.localeCompare(b.village_name, undefined, { numeric: true })
      );
      //store village data for use offline
      villageStore.setItem("options", JSON.parse(JSON.stringify(data)))
    } else {
      console.log("get village error!");
    }
  }
  catch{
    console.log("get village error!");
  }
}
const getQuestionList = async (question_type) => {
    const token = store.getters["userAuth/getToken"];
    const url = `${process.env.VUE_APP_BASE_API}admin/question`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json; charset=utf-8`,
    };
    const params = {
      question_nare_name_en: question_type
    }
    const res = await axios.get(url, { headers, params }).catch(() =>
      Swal.fire({
          icon: "warning",
          text: "ระบบผิดพลาด",
      })
    );
    if (res.status === 200) {
      surveyStore.setItem(question_type, JSON.parse(JSON.stringify(res.data)))
    }
}
watch(userProvCode, async()=>{
  if(onlineComputed.value){
    if(userProvCode.value){
      getQuestionList("household");
      getQuestionList("member");
      getQuestionList("neets");
      getQuestionList("cwd");
      getProvinceList();
      if(store.getters["userAuth/getUserAmphurCode"].length == 4 && store.getters["userAuth/getUserTumbolCode"].length == 6){
        getVillage()
      }
      const headers = {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      };
      if(store.getters["userAuth/getUserTumbolCode"].length == 6){
        const zipcode = await axios
          .get(`${process.env.VUE_APP_BASE_API}zipcode/${store.getters["userAuth/getUserTumbolCode"]}`, {
            headers
          })
        if(zipcode.status == 200)
          provinceStore.setItem("zipcode", zipcode.data.ZIPCODE)
      }
      //add sector and zipcode data for offline use
      const sector = await axios
      .get(`${process.env.VUE_APP_BASE_API}region/${userProvCode.value}`, {
        headers
      })
      if(sector.status == 200)
        provinceStore.setItem("sector", sector.data.region_name)
    }
  }
})

const registration = ref(null)
const updateExists = ref(false)
const refreshing = ref(false)
const refreshApp = () => {
  updateExists.value = false
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value || !registration.value.waiting) return
  // Send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}
const checkUpdateAvailable = (event) => {
  registration.value = event.detail
  updateExists.value = true
  refreshApp()
}

document.addEventListener('swUpdated',(event)=>{
  checkUpdateAvailable(event)
}, { once: true })
navigator.serviceWorker.addEventListener('controllerchange', () => {
  // We'll also need to add 'refreshing' to our data originally set to false.
  if (refreshing.value) return
  refreshing.value = true
  // reload page
  window.location.reload()
})
onMounted(()=>{
  const json = window.localStorage.getItem("mpi-logbook-auth");
  const auth = JSON.parse(json);
  if(!onlineComputed.value && auth && auth.tokenParsed.accessible_area_code.length != 6){
    Swal.fire({
      html: "<p>บัญชีผู้ใช้งานนี้ ไม่สามารถใช้งานโหมดออฟไลน์ได้</p>",
      icon: "info",
      // timer: 3000,
      confirmButtonText: "ตกลง",
    })
  }
})
</script>
<style lang="scss">
.spinner {
    -webkit-animation: spinner 2s infinite linear;
    animation: spinner 2s infinite linear
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
</style>
