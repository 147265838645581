<template>
    <template-layout :breadCrumbList="breadcrumbComputed">
        <div class="py-12 px-2 sm:px-4">
            <nav class="flex justify-between items-center mb-6">
                <h1 class="text-2xl text-primary-2">
                NEETs & เด็กพิการ
                </h1>
            </nav>
            <DashboardCWDNEETsFilter :filter="filterSummaryStore"/>
            <div class="mt-5">
                <div class="grid grid-cols-12 gap-3 relative">
                    <div class="absolute right-0 top-[1.23rem] sm:top-6">
                        <button class="transparent" :class="{'text-primary-1 underline': sortStatus == 'alphabet'}" @click="sortStatus = 'alphabet'">{{tumbolLevel ? 'เรียงตามหมู่บ้าน' : 'เรียงตามตัวอักษร'}}</button> |
                        <button :class="{'text-primary-1 underline': sortStatus == 'number'}" @click="sortStatus = 'number'">เรียงตามจำนวนคน</button>
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-5">
                        <div class="flex items-center mb-2">
                            <h2 class="text-md sm:text-xl font-medium mr-5">เด็กพิการ</h2>
                        </div>
                        <div class="box h-full p-5 relative flex flex-col">
                            <div class="flex items-center mb-3">
                                <div class="flex rounded-md border border-gray-400 overflow-hidden">
                                    <fieldset class="flex-grow text-center">
                                        <input
                                            class="peer"
                                            type="radio"
                                            name="category"
                                            id="firstOptionId"
                                            value="disability"
                                            v-model="selectState"
                                        >
                                        <label
                                            class="inline-block w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
                                            for="firstOptionId"
                                        >แสดงจำนวนเด็กพิการ</label>
                                    </fieldset>
                                    <fieldset class="flex-grow text-center border-l border-gray-300">
                                        <input
                                            class="peer"
                                            type="radio"
                                            name="category"
                                            id="secondOptionId"
                                            value="survey_status"
                                            v-model="selectState"
                                        >
                                        <label
                                            class="inline-block w-full py-2 px-2 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-1 peer-checked:text-white select-none"
                                            for="secondOptionId"
                                        >แสดงสถานะการสำรวจ</label>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="absolute left-0 top-0 w-full h-full" v-if="loadingDisability">
                                <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                    <div class="w-20 h-20">
                                        <LoadingIcon
                                            icon="three-dots"
                                            class="loading-icon"
                                        />
                                    </div>
                                </div>
                                <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                </div>
                            </div>
                            <div class="text-center mb-2">
                                <div class="text-md">เด็กพิการ [อายุ 0 - 18 ปี]</div>
                                <div class="text-lg">{{level}}</div>
                                <div class="text-md">จำนวน {{isNaN(countCWD) ? countCWD : new Intl.NumberFormat().format(countCWD)}} คน</div>
                                <div class="text-theme-37">จากจำนวนอายุเด็ก 0 - 18 ปีที่สำรวจ {{!isNaN(countAllCWDSurvey) ? new Intl.NumberFormat().format(countAllCWDSurvey) : countAllCWDSurvey}} คน</div>
                            </div>
                            <div v-if="selectState == 'disability'">
                                <StackedBarChartCWD
                                    chart-id="cwdBarChart"
                                    :key="key"
                                    :series="disabilitySeriesData"
                                    :isMobileScreen="isMobileScreen"
                                />
                            </div>
                            <div v-else>
                                <StackedBarChartSurvey
                                    chart-id="cwdSurveyBarChart"
                                    :key="key"
                                    :series="disabilitySeriesData"
                                    :isMobileScreen="isMobileScreen"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-5">
                        <div class="flex items-center mb-2 mt-3 lg:mt-0">
                            <h2 class="text-md sm:text-xl font-medium mr-5">เยาวชนกลุ่ม NEETS</h2>
                        </div>
                        <div class="box h-full p-5 relative flex flex-col">
                            <div class="absolute left-0 top-0 w-full h-full" v-if="loadingNeets">
                                <div class="absolute flex justify-center items-center z-[60] w-full h-full ">
                                    <div class="w-20 h-20">
                                        <LoadingIcon
                                            icon="three-dots"
                                            class="loading-icon"
                                        />
                                    </div>
                                </div>
                                <div class="absolute bg-white opacity-70 w-full h-full z-50 rounded-md flex justify-center items-center">
                                </div>
                            </div>
                            <div class="lg:mt-[50px]">
                                <div class="text-center mb-2">
                                    <div class="text-md">เยาวชนกลุ่ม NEETs</div>
                                    <div class="text-lg">{{level}}</div>
                                    <div class="text-md">จำนวน {{isNaN(countNEETs) ? countNEETs : new Intl.NumberFormat().format(countNEETs)}} คน</div>
                                    <div class="text-theme-37">จากจำนวนเยาวชนอายุ 15-30 ปีที่สำรวจ {{!isNaN(countAllNEETsSurvey) ? new Intl.NumberFormat().format(countAllNEETsSurvey) : countAllNEETsSurvey}} คน</div>
                                </div>
                                <StackedBarChartSurvey
                                    chart-id="neetsBarChart"
                                    :key="key"
                                    :series="neetsSeriesData"
                                    :isMobileScreen="isMobileScreen"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template-layout>
</template>

<script setup>
import TemplateLayout from "@/layouts/side-menu/Main.vue";
import DashboardCWDNEETsFilter from "@/components/filter-search/DashboardCWDNEETsFilter.vue";
import StackedBarChartCWD from "./cwd-stacked-bar-chart/Main.vue"
import StackedBarChartSurvey from "./survey-stacked-bar-chart/Main.vue"
import axios from "axios";
import { onMounted, ref, reactive, computed, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const store = useStore()
const token = store.getters["userAuth/getToken"];
const key = ref(0)
const loadingDisability = ref(false)
const loadingNeets = ref(false)
const filterValue = computed(
  () => store.getters["dashboardCWDNEETsFilter/getFilterForApi"]
);
const filterSummaryStore = computed(
  () => store.getters["dashboardCWDNEETsFilter/getFilterSummary"]
);
const selectState = ref('disability')
const disabilitySeriesData = ref([])
const neetsSeriesData = ref([])
const sortStatus = ref("alphabet")
const countCWD = ref("...")
const countAllCWDSurvey = ref("...")
const countNEETs = ref("...")
const countAllNEETsSurvey = ref("...")
const isMobileScreen = ref(false)
const currentAddress = computed(() => store.getters["dashboardCWDNEETsFilter/getAddress"])
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const tumbolLevel = ref(false)

const level = computed(()=>{
    tumbolLevel.value = false
    if(filterValue.value.province_id && filterValue.value.amphur_id && filterValue.value.tambol_id && filterValue.value.village_id){
        return `${currentAddress.value?.village?.village_name}`
    }
    else if(filterValue.value.province_id && filterValue.value.amphur_id && filterValue.value.tambol_id){
        tumbolLevel.value = true
        return currentAddress.value?.province?.province_name == "กรุงเทพมหานคร" ? 'แขวง' : `ตำบล${currentAddress.value?.tumbol?.tumbol_name}`
    }
    else if(filterValue.value.province_id && filterValue.value.amphur_id){
        return currentAddress.value?.province?.province_name == "กรุงเทพมหานคร" ? 'เขต' : `อำเภอ${currentAddress.value?.amphur?.amphur_name}`
    }
    else if(filterValue.value.province_id){
        return `จังหวัด${currentAddress.value?.province?.province_name}`
    }
    else{
        return "ประเทศไทย"
    }
})

watch(filterValue, () => {
    if(onlineComputed.value){
        key.value++
        getDisabilityCount()
        getNeetsSurveyCount()
    }
})
watch(()=>selectState.value, (val)=>{
    getDisabilityCount()
})
watch(()=>sortStatus.value, (val)=>{
    if(val == "alphabet"){
        disabilitySeriesData.value = disabilitySeriesData.value.sort(compareAlphabet)
        neetsSeriesData.value = neetsSeriesData.value.sort(compareAlphabet)
    }
    else{
        disabilitySeriesData.value = disabilitySeriesData.value.sort(selectState.value == "disability" ? compareValueCount : compareValueSurvey)
        neetsSeriesData.value = neetsSeriesData.value.sort(compareValueSurvey)
    }
})
const compareValueCount = (a, b) => {
    const a_total = a.IN104_2_count + a.IN104_3_count + a.IN104_4_count + a.IN104_5_count + a.IN104_6_count + a.IN104_7_count + a.IN104_8_count + a.IN104_9_count;
    const b_total = b.IN104_2_count + b.IN104_3_count + b.IN104_4_count + b.IN104_5_count + b.IN104_6_count + b.IN104_7_count + b.IN104_8_count + b.IN104_9_count;
    if (a_total < b_total) {
        return 1;
    }
    if (a_total > b_total) {
        return -1;
    }
    return 0;
}
const compareValueSurvey = (a, b) => {
    const a_total = a.is_survey_true + a.is_survey_false;
    const b_total = b.is_survey_true + b.is_survey_false;
    if (a_total < b_total) {
        return 1;
    }
    if (a_total > b_total) {
        return -1;
    }
    return 0;
}

const compareAlphabet = (a, b) =>{
    if(tumbolLevel.value){
        return a.area_name.localeCompare(b.area_name, undefined, { numeric: true })
    }
    return a.area_name.localeCompare(b.area_name,'th')
}

const getDisabilityCount = async () => {
    loadingDisability.value = !loadingDisability.value
    disabilitySeriesData.value = []
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}member/cwd/${selectState.value == "disability" ? 'disableType' : 'getCwdTackingStatus'}`,
        {
            params: filterValue.value,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).catch(err => err);
        if(res.status === 200){
            setTimeout(()=>{loadingDisability.value = !loadingDisability.value}, 500)
            let sortedData = res.data.data
            countAllCWDSurvey.value = res.data.count_all
            countCWD.value = res.data.count_cwd
            if(sortStatus.value == "number"){
                sortedData = sortedData.sort(selectState.value == "disability" ? compareValueCount : compareValueSurvey)
            }
            else{
                sortedData = sortedData.sort(compareAlphabet)
            }
            disabilitySeriesData.value = sortedData
        }
        else{
            setTimeout(()=>{loadingDisability.value = !loadingDisability.value}, 500)
            Swal.fire({
                icon: "warning",
                text: "ระบบผิดพลาด",
            })
        }
}
const getNeetsSurveyCount = async () => {
    loadingNeets.value = !loadingNeets.value
    neetsSeriesData.value = []
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}member/neets/tackingStatus`,
        {
            params: filterValue.value,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).catch(err => err);
        if(res.status === 200){
            setTimeout(()=>{loadingNeets.value = !loadingNeets.value}, 500)
            countAllNEETsSurvey.value = res.data.count_all
            countNEETs.value = res.data.count_neet
            let sortedData = res.data.data
            if(sortStatus.value == "number"){
                sortedData = sortedData.sort(compareValueSurvey)
            }
            else{
                sortedData = sortedData.sort(compareAlphabet)
            }
            neetsSeriesData.value = sortedData
        }
        else{
            setTimeout(()=>{loadingNeets.value = !loadingNeets.value}, 500)
            Swal.fire({
                icon: "warning",
                text: "ระบบผิดพลาด",
            })
        }
}



const breadcrumbComputed = reactive([{
    name: "NEETs & เด็กพิการ",
    active: true
}])

const getScreen = () =>{
  if(window.innerWidth < 1024){
    isMobileScreen.value = true
  }else{
    isMobileScreen.value = false
  }
}

onMounted(()=>{
    if(navigator.onLine && onlineComputed.value){
        getDisabilityCount()
        getNeetsSurveyCount()
        getScreen()
        window.addEventListener("resize", getScreen)
    }
})
onUnmounted(()=>{
    window.removeEventListener("resize", getScreen);
})

</script>

<style lang="scss" scoped>
input[type="radio"] {
  display: none;
}
</style>