<template>
  <div class="box shadow-md rounded-md min-height flex flex-col h-full">
    <header class="py-2 px-6 flex justify-between items-center h-14">
      <slot name="header">
        <h4 class="text-theme-37 font-semibold">
          {{ headerText }}
        </h4>
        <button
          :disabled="disable"
          class="btn btn-primary"
          :class="{ 't-tooltip': showTooltip }"
          v-if="showHeaderButton"
          @click="handleClickHeaderButton"
        >
          <span class="t-tooltip-text">{{ tooltipText }}</span>
          <Edit2Icon class="h-3 w-3 mr-2" v-if="headerButtonText === 'แก้ไข'" />
          {{ headerButtonText }}
        </button>
      </slot>
    </header>
    <hr />
    <div class="p-4 flex-grow" :class="{'flex items-center' : isItemCenter, 'relative' : isRelative}">
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  disable: { type: Boolean, default: false },
  headerText: { type: String, default: "Title" },
  headerButtonText: { type: String, default: "แก้ไข" },
  showHeaderButton: {
    type: Boolean,
    default: true,
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
  isItemCenter: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: "",
  },
  isRelative:{
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(["click-header-button"]);

const handleClickHeaderButton = () => {
  emits("click-header-button");
};
</script>

<style lang="scss" scoped>
.min-height {
  min-height: 10rem;
}
</style>
