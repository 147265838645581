<template>
  <div>
    <Accordion
      accordion-id="question_step"
      class="px-6"
      :customClass="['!border-0 !shadow-none', 'bg-[#f6f6f6]']"
      isOpen
    >
      <template v-slot:header-content>
          <h2 class="text-md text-theme-2 font-semibold">
              หัวข้อหลัก
          </h2>
      </template>
      <template v-slot:custom-icon>
        <div class="flex flex-row items-center">
          <button class="btn btn-primary bg-primary-btn mr-1 border-0 rounded-full w-8 h-8 p-1 mb-1">
              <ChevronDownIcon class="h-6 w-6" />
          </button>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="col-span-12">
          <div class="wizard short-wizard flex flex-col pt-1 gap-2" id="member_wizard">
            <div class="flex items-center z-10 steps"
                :class="{ 'mt-5': key > 0 }" v-for="(header, key) in stepForm" :key="key"
            >
              <div class="relative">
                <button class="w-10 h-10 rounded-full btn" :class="[
                    header.step == currentStep.step ||
                    allSaveStep.includes(header.step)
                        ? 'bg-primary-2 text-white'
                        : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
                    ]"
                    @click="isView || isEdit || isAdminManagement ? jumpToStep(header.step) : null"
                >
                    {{ header.step }}
                </button>
                <div class="
                  w-4
                  h-4
                  flex
                  items-center
                  justify-center
                  absolute
                  rounded-full
                  text-white
                  bg-theme-10
                  bottom-0
                  "
                  style="left: 53%"
                  v-show="allSaveStep.includes(header.step) && !isAdminManagement"
                >
                  <CheckIcon class="w-3 h-3 text-white" />
                </div>
              </div>
              <div class="
                  font-medium
                  text-base
                  ml-3
              ">
                  {{ header.title }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Accordion>
    <hr class="mt-8" />
    <AddQuestionForm class="p-6 pb-0 mb-[-0.75rem] flex justify-end" :editData="editData" :isAdminManagement="isAdminManagement" :currentStep="currentStep" :questionList="questionList" @saveSuccess="emit('saveSuccess', $event, currentStep), editData = null, currentHeaderData = null" :currentHeaderData="currentHeaderData" v-model="openAddQuestionModal" :questionnaireData="questionnaireData" v-if="isAdminManagement"/>

    <div class="p-6" v-if="questionList" ref="questionSection">
      <Step1
        :class="[currentStep.form == 1 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :clearImageFile="clearImageFile"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step2
        :class="[currentStep.form == 2 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step3
        :class="[currentStep.form == 3 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step4
        :class="[currentStep.form == 4 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step5
        :class="[currentStep.form == 5 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step6
        :class="[currentStep.form == 6 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step7
        :class="[currentStep.form == 7 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step8
        :class="[currentStep.form == 8 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :haveOccupation="haveOccupation"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step9
        :class="[currentStep.form == 9 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :isAlcohol="isAlcohol"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step10
        :class="[currentStep.form == 10 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />
      <Step11
        :class="[currentStep.form == 11 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :currentAgeMonth="ageInMonth"
        @skip-step="skipStep"
        :member-data="memberData"
        :isView="isView"
        @next-step-view="nextstep(findStep($event))"
        :isDisability="isDisability"
        :isDefaultHide="defaultHide"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
        :checkValidate="requiredFound"
      />

      <Step12
        :class="[currentStep.form == 12 ? 'block' : 'hidden']"
        @close-modal="closeForm()"
        @next-step="checkFormDisplay"
        @prev-step="prevstep"
        :member-data="memberData"
        :currentAgeMonth="ageInMonth"
        :isView="isView"
        :questionList="questionList"
        :isAdminManagement="isAdminManagement"
        @edit-question="editData = $event, openAddQuestionModal = true"
        @update-status="updateQuestionStatus"
        @delete-question="emit('deleteQuestion', $event, currentStep)"
        :isDisabledSubmitBtn="isDisabledSubmitBtn"
        @addQuestion="currentHeaderData = $event, openAddQuestionModal = true"
        :update-result="dataUpdate"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import Swal from "sweetalert2";
import Step1 from "./step-1/index.vue";
import Step2 from "./step-2/index.vue";
import Step3 from "./step-3/index.vue";
import Step4 from "./step-4/index.vue";
import Step5 from "./step-5/index.vue";
import Step6 from "./step-6/index.vue";
import Step7 from "./step-7/index.vue";
import Step8 from "./step-8/index.vue";
import Step9 from "./step-9/index.vue";
import Step10 from "./step-10/index.vue";
import Step11 from "./step-11/index.vue";
import Step12 from "./step-12/index.vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { uploadfile, uploadCroppedfile } from "@/utils/minio.js";
import localForage from "localforage"
import AddQuestionForm from "@/components/add-question-form/index.vue"
import Accordion from "@/components/base/custom-accordion/index.vue"
const store = useStore();
const route = useRoute();
const router = useRouter()

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
var time = today.getHours().toString() + ":" + today.getMinutes().toString();
today = yyyy + mm + dd + time;

// variable
const props = defineProps({
  memberData : {type: Object},
  isEdit: {type: Boolean, default: false},
  isView: {type: Boolean, default: false},
  addMemberModal: {
    type: Boolean,
    default: false,
  },
  editModal: {
    type: Boolean,
    default: false,
  },
  questionList:{
    type: Array
  },
  isAdminManagement:{
    type: Boolean,
    default: false
  },
  currentStepObj: {
    type: Object
  },
  questionnaireData: {
    type: Object
  }
});
const openAddQuestionModal = ref(false)
const currentHeaderData = ref(false)
const editData = ref(null)
const houseStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'house'
});
const emit = defineEmits(["modal-value", "exit-form", "complete-form", "saveSuccess", "deleteQuestion"]);

const swalCustom = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary m-1",
    cancelButton: "btn btn-outline-secondary m-1",
  },
  buttonsStyling: false,
});
const headerStep = reactive([
  {
    title: "ข้อมูลพื้นฐาน",
    step: 1,
    show: true,
  },
  {
    title: "พัฒนาการของเด็ก",
    step: 2,
    show: true,
  },
  {
    title: "การศึกษา",
    step: 3,
    show: true,
  },
  {
    title: "การฝึกอบรม",
    step: 4,
    show: true,
  },
  {
    title: "ทักษะที่จำเป็น",
    step: 5,
    show: true,
  },
  {
    title: "สุขภาพ",
    step: 6,
    show: true,
  },
  {
    title: "อาชีพ",
    step: 7,
    show: true,
  },
  {
    title: "รายได้",
    step: 8,
    show: true,
  },
  {
    title: "รายจ่าย",
    step: 9,
    show: true,
  },
  {
    title: "เงินออมและหนี้สิน",
    step: 10,
    show: true,
  },
  {
    title: "สวัสดิการ",
    step: 11,
    show: true,
  },
  {
    title: "สำหรับทีมสำรวจข้อมูล",
    step: 12,
    show: true,
  },
]);
const currentStep = ref({
  step: 1,
  form: 1,
});
const questionSection = ref(null)
const saveStep = ref(0);
const allSaveStep = reactive([]);
const submitBtn = ref(false);
const formLabel = [
  "ข้อมูลพื้นฐาน",
  "พัฒนาการของเด็ก",
  "การศึกษา",
  "การฝึกอบรม",
  "ทักษะที่จำเป็น",
  "สุขภาพ",
  "อาชีพ",
  "รายได้",
  "รายจ่าย",
  "เงินออมและหนี้สิน",
  "สวัสดิการ",
  "สำหรับทีมสำรวจข้อมูล",
];
// var dataForm = reactive({
//   step01: {},
//   step02: {},
//   step03: {},
//   step04: {},
//   step05: {},
//   step06: {},
//   step07: {},
//   step08: {},
//   step09: {},
//   step10: {},
//   step11: {},
//   step12: {},
// });
var step = reactive([]);
const stepForm = ref({});
const ageInMonth = ref()
const dataForm = {}
const member_id = ref(route.params.memberid ?? null)
let temp_image = null
const clearImageFile = ref(false)
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const isDisability = ref(false)
const haveOccupation = ref(false)
const isAlcohol = ref(false)
let noStudied = false
let currentAgeInYear = 0
let unemployed = false
let noTraining = false
let allDataForm = {}
const isDisabledSubmitBtn = ref(false)
const dataUpdate = ref(null)
const requiredFound = ref(null)


// function
const initialStepForm = (form) => {
  step = [];
  if(props.questionList){
    form.forEach((e, i) => {
      let stepObj = props.questionList.find(({step})=> step.step_number == e).step
      step.push({
        title: stepObj.step_title,
        step: i + 1,
        form: e,
        id: stepObj.step_id
      });
    });
  }
  return step;
};

const prepareStepForm = (age) => {
  var f = [1, 6, 8, 9, 10, 11, 12];
  if (age <= 12) {
    f.push(2);
  }
  if (age >= 3) {
    f.push(3);
  }

  if (age >= 12) {
    f.push(5);
  }
  if (age >= 15) {
    f.push(4, 7);
  }

  f.sort((a, b) => {
    return a - b;
  });
  return f;
};

const findStep = (formnumber) => {
  return stepForm.value.find((e) => e.form == formnumber);
};

const closeForm = () => {
  emit("exit-form")
  // const result = headerStep.find( ({ step }) => step === saveStep.value + 1 );
  /* swalCustom.fire({
    html: props.isView ? `<div>คุณแน่ใจที่จะออกจากหน้านี้</div>` : `<div>ข้อมูลจะไม่ถูกบันทึก</div>
                <div>คุณแน่ใจที่จะออกจากหน้านี้</div>`,
    icon:"warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก'
  }).then((result) => {
    if (result.isConfirmed) {
      router.go(-1)
    }
  }) */
};

const checkFormDisplay = (data, formNumb) => {
  switch (formNumb) {
    case 1:
      dataForm.step1 = Object.assign({}, data);
      dataForm.step = null
      allDataForm = Object.assign(allDataForm, dataForm.step1)
      ageInMonth.value = (data.age * 12) + data.month
      currentAgeInYear = data.age
      let form = prepareStepForm(data.age);
      stepForm.value = initialStepForm(form);
      submitForm(formNumb);
      break;
    case 2:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 3:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 4:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 5:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 6:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 7:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 8:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 9:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 10:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 11:
      dataForm.step1 = null
      dataForm.step = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm.questionnaires["status"] = "DRAFT"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
    case 12:
      dataForm.step1 = null
      dataForm.questionnaires = Object.assign({},data)
      dataForm["step"] = "last"
      allDataForm = Object.assign(allDataForm, dataForm.questionnaires)
      submitForm(formNumb);
      break;
  }

};

const nextstep = (current) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
  var step = current.step + 1;
  var next = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = next;
  if(!allSaveStep.includes(current.step)){
    // saveStep.value = current.step
    allSaveStep.push(current.step)
  }
};

const jumpToStep = (toStep) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  //if step 1 not complete cannot jump to another step
  if(!allSaveStep.includes(1) && !props.isView && !props.isAdminManagement) return;
  currentStep.value = stepForm.value.find((e) => {
      return e.step == toStep;
  });
}


const skipStep = (currentForm) => {
    let top = questionSection.value.offsetTop;
    window.scrollTo(0, top);
    let current = findStep(currentForm);
    //last step cannot skip
    if (current.step == stepForm.value[stepForm.value.length - 1].step) return;
    let step = current.step + 1;
    currentStep.value = stepForm.value.find((e) => {
        return e.step == step;
    });
}

const prevstep = (currentForm) => {
  let top = questionSection.value.offsetTop;
  window.scrollTo(0, top);
  var current = findStep(currentForm);
  if (current.step == stepForm.value[0].step) return;
  var step = current.step - 1;
  var pre = stepForm.value.find((e) => {
    return e.step == step;
  });
  currentStep.value = pre;
};

const prepareData = () => {
  // remove null variabled
  if (dataForm?.questionnaires && Object.keys(dataForm?.questionnaires)) {
      Object.keys(dataForm.questionnaires).forEach((q) => {
        if(dataForm.questionnaires[q] === undefined || dataForm.questionnaires[q] === "" || ((typeof dataForm.questionnaires[q] === 'object' && dataForm.questionnaires[q] !== null && !(dataForm.questionnaires[q] instanceof Date)) ? Array.isArray(dataForm.questionnaires[q]) ? dataForm.questionnaires[q].length == 0 : Object.keys(dataForm.questionnaires[q]).length == 0 : false)){
          dataForm.questionnaires[q] = null;
        }
      });
  }
};

const saveMember = (data) => {
  return new Promise((resolve, reject)=>{
    const config = {
      method: member_id.value ? 'PUT' : 'POST',
      url: `${process.env.VUE_APP_BASE_API}member`,
      data: data,
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      },
    }
    axios(config)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

const checkRelateResult = ({relate_question}, answerObj) => {
  if(relate_question.length == 0)
    return true
  let tempQuestionAppear = []
  for(const {code, answer, type} of relate_question){
    let has_answer
    if(type == "radio"){
      has_answer = answer.includes(answerObj[code]?.code)
    }
    else if(type == "checkbox"){
      has_answer = answerObj[code].findIndex(x => answer.includes(x.code)) != -1 ? true : false
    }
    else if(type == "number"){//for type number fix answer at index 0 and condition is greater than only
      has_answer = answerObj[code] > 0
    }
    else if(type == "select"){
      has_answer = answer.includes(answerObj[code])
    }
    else{
      has_answer = answerObj[code] ? true : false
    }
    tempQuestionAppear = [...tempQuestionAppear, has_answer]
  }
  return tempQuestionAppear.every(elm => elm)
}
const checkRelateAge = ({start, end}) =>{
  if(typeof start == "number" && typeof end == "number")
    return (ageInMonth.value >= start) && (ageInMonth.value <= end)
  else if(typeof start == "number" && !end)
    return ageInMonth.value >= start
  return true
}
const getSurvey = async() =>{
  const token = store.getters["userAuth/getToken"];
  const url = `${process.env.VUE_APP_BASE_API}member/${member_id.value}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json; charset=utf-8`,
  };
  const params = {
    house_survey_id: route.params.surveyid
  }
  const res = await axios.get(url, { headers, params }).catch(() =>
    "error"
  );
  if (res.status === 200) {
    return res.data
  }
}

const tempID = ref(route.params.memberid ?? null)
let alertConfig = {
  icon: "success"
}
const alertConfigHaveSurvey = {
  html: `<div style="font-size: 20px;">สำเร็จ</div><div style="margin-top: 10px;font-size: 25px;">ทำแบบสอบถาม เด็ก NEETs ต่อหรือไม่</div>`,
  confirmButtonText: "ตกลง",
  cancelButtonText: "ทำภายหลัง",
  allowOutsideClick: false,
  showCancelButton: true,
  reverseButtons: true
}
const alertConfigNoSurvey = {
  html: `<div style="font-size: 20px;">สำเร็จ</div>`,
  timer: 3000,
  confirmButtonText: "ปิด"
}
const submitForm = async (stepNum) => {
  try{
    isDisabledSubmitBtn.value = true
    prepareData()
    let config_data, gotoNextStep = true
    if(dataForm.step1){
      let age_range = []
      if(dataForm.step1.age <= 5){
        age_range.push("เด็กแรกเกิด/ปฐมวัย (อายุ 0-5 ปี)")
      }
      if(dataForm.step1.age >= 5 && dataForm.step1.age <= 14){
        age_range.push("วัยเรียน (อายุ 5-14 ปี)")
      }
      if(dataForm.step1.age >= 15 && dataForm.step1.age <= 21){
        age_range.push("วัยรุ่น/นักศึกษา (อายุ 15-21 ปี)")
      }
      if(dataForm.step1.age >= 18 && dataForm.step1.age <= 59){
        age_range.push("วัยผู้ใหญ่/วัยแรงงาน (อายุ 18-59 ปี)")
      }
      if(dataForm.step1.age >= 60){
        age_range.push("วัยผู้สูงอายุ (60 ปีขึ้นไป)")
      }
      if(dataForm.step1?.member_id){
        member_id.value = dataForm.step1.member_id
      }
      config_data = {
        NID: dataForm.step1.NID ? dataForm.step1.NID : null,
        IDNumber: dataForm.step1.IDNumber ? dataForm.step1.IDNumber : null,
        prefix_name: dataForm.step1.prefix_name,
        name: dataForm.step1.name,
        surname: dataForm.step1.surname,
        gender: dataForm.step1.gender,
        birthdate: dataForm.step1.birthdate,
        marital_status: dataForm.step1.marital_status,
        religion: dataForm.step1.religion,
        mobile: dataForm.step1.mobile,
        house_surveys_id: route.params.surveyid,
        member_surveys: {
          age:{
            year: dataForm.step1.age,
            month: dataForm.step1.month
          },
          age_range: age_range,
          relation: dataForm.step1.relation,
        }
      }
      if(dataForm.step1.relation?.code != 1 && props.memberData?.member_surveys?.relation.code == 1){
        config_data["member_surveys"]["headOfHousehole"] = true
      }
      if(dataForm.step1.gender_other)
        config_data["gender_other"] = dataForm.step1.gender_other
      if(dataForm.step1.marital_status_other)
        config_data["marital_status_other"] = dataForm.step1.marital_status_other
      if(dataForm.step1.religion_other)
        config_data["religion_other"] = dataForm.step1.religion_other
      if(member_id.value){
        config_data["id"] = member_id.value
        config_data["update_by"] = store.getters["userAuth/getUserID"]
      }
      else{
        config_data["create_by"] = store.getters["userAuth/getUserID"]
      }
      clearImageFile.value = false
      if (dataForm.step1?.imageFile) {
        try{
          gotoNextStep = false
          temp_image = dataForm.step1.imageFile
          const result = await uploadCroppedfile(
            dataForm.step1.imageFile,
            `member-image`
          )
          if (result.res.status === 200) {
            clearImageFile.value = true
            gotoNextStep = true
            const image = {
              // file_name: result.file_name,
              img_url: result.url,
              img_date: new Date().toISOString(),
              img_user: store.getters["userAuth/getUserID"],
            };
            config_data["image"] = image
          }
        }
        catch(err){
          gotoNextStep = false
          isDisabledSubmitBtn.value = false
          swalCustom.fire({
            text: "อัพโหลดรูปภาพไม่สำเร็จ โปรดลองอีกครั้ง",
            icon: "warning",
            // timer: 3000,
            confirmButtonText: "ปิด",
          });
        }
      }
    }else{
      config_data = {
        member_surveys: {...dataForm.questionnaires},
        id: member_id.value,
        house_surveys_id: route.params.surveyid,
        update_by: store.getters["userAuth/getUserID"]
      }
    }
    //check condition neet or cwd
    /* //ไม่เคยเรียน หรือไม่เรียนแล้วและออกกลางคัน, neet check
    if(allDataForm?.IN046?.code == "IN046-1" || allDataForm?.IN046?.code == "IN046-3"){
      if(allDataForm?.IN046?.code == "IN046-1")
        noStudied = true
      else if(allDataForm?.IN046?.code == "IN046-3" && allDataForm?.IN049?.code == "IN049-1")
        noStudied = true
      else
        noStudied = false
    } */
    //ไม่ได้อยู่ระหว่างการศึกษา
    noStudied = allDataForm?.IN046 ? allDataForm.IN046.code !== "IN046-2" : false
    //no training, neet check
    noTraining = allDataForm?.IN064?.code == "IN064-2"
    //unemploy, neet check
    unemployed = allDataForm?.IN114?.code ? allDataForm.IN114.code !== "IN114-1" : false
    //disability, cwd check
    isDisability.value = allDataForm?.IN105 ? allDataForm.IN105.some(({code}) => code !== "IN105-1") : false
    //have occupation for question group8 relate with IN114 in question group 7
    haveOccupation.value = allDataForm?.IN114?.code ? allDataForm.IN114.code != "IN114-2" : false
    //check drink alcohol
    isAlcohol.value = (allDataForm?.IN110?.code ? allDataForm.IN110.code !== "IN110-1" : false) || (allDataForm?.IN112?.code ? allDataForm.IN112.code !== "IN112-1" : false)

    if(noStudied && ageInMonth.value >= 180 && ageInMonth.value <= 360 && noTraining && unemployed){
      config_data.member_surveys.NEET = 1
    }
    else{
      config_data.member_surveys.NEET = null
    }
    if(isDisability.value && ageInMonth.value >= 0 && ageInMonth.value <= 216){
      config_data.member_surveys.CWD = 1
    }
    else{
      config_data.member_surveys.CWD = null
    }
    if(gotoNextStep){
      if(dataForm?.step == "last"){
        let infoText = "<div>ยืนยันบันทึกข้อมูล</div>"
        let requiredQuestion
        if(onlineComputed.value){
          requiredFound.value = null
          const resultAnswer = await getSurvey()
          const {member_surveys, ...rest} = resultAnswer
          const answerList = {...rest, ...resultAnswer.member_surveys}
          let requiredQuestionList = props.questionList.reduce((a, v)=>{
            let question = v.questions.reduce((list,ques)=>{
              let new_question = ques.question_lists.filter(e=>e.required || e.choices.some(({required}) => required)).map((item)=> {
                item.step = v.step
                return item
              })
              return [...list, ...new_question]
            },[])
            return [...a, ...question]
          },[])
          requiredQuestion = requiredQuestionList.find((item)=>{
            return answerList[item.question_code] == undefined && checkRelateResult(item, answerList) && checkRelateAge(item.relate_age_month) && item.question_type != "multiple-inputs-group" && item.question_type != "date-range" && item.step.step_number != 12 && (item.about_occupation ? haveOccupation.value : true) && (item.about_alcoholic ? isAlcohol.value : true) && (item.about_disability ? isDisability.value : true)
          })
          if(requiredQuestion){
            infoText = `<div>ไม่สามารถบันทึกได้ กรุณากรอกข้อมูลที่บังคับกรอกให้ครบถ้วน</div> <div>กดปุ่ม <strong style="color: #246bc3;">"ตกลง"</strong> เพื่อกรอกข้อมูลเพิ่มเติม </div><div>หรือกดปุ่ม <strong style="color: #393939;">"บันทึกแบบร่าง"</strong> เพื่อดำเนินการต่อในภายหลัง</div>`
          }
        }
        swalCustom
        .fire({
          html: infoText,
          icon: "info",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "ตกลง",
          cancelButtonText: onlineComputed.value ? "บันทึกแบบร่าง" : "ยกเลิก",
        })
        .then(async (result) => {
          try{
            if(onlineComputed.value){
              if (result.isConfirmed) {
                if(requiredQuestion){
                  let stepJump = stepForm.value.find((s) => s.form == requiredQuestion.step.step_number)?.step
                  if(stepJump){
                    requiredFound.value = requiredQuestion
                    setTimeout(()=>{
                      jumpToStep(stepJump)
                      isDisabledSubmitBtn.value = false
                    }, 500)
                  }
                  return
                }
                config_data["member_surveys"]["status"] = "COMPLETE"
              }
              else if(result.dismiss === Swal.DismissReason.cancel){
                config_data["member_surveys"]["status"] = "DRAFT"
              }
              const res = await saveMember(config_data)
              if (res.status === 200) {
                if((config_data.member_surveys.NEET && !props.memberData?.neet_survey && config_data.member_surveys.CWD && !props.memberData?.cwd_survey) || (config_data.member_surveys.NEET && !props.memberData?.neet_survey)){
                  alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
                }
                else if(config_data.member_surveys.CWD && !props.memberData?.cwd_survey){
                  alertConfigHaveSurvey.html = `<div style="font-size: 20px;">สำเร็จ</div><div style="margin-top: 10px;font-size: 25px;">ทำแบบสอบถาม เด็กพิการ ต่อหรือไม่</div>`
                  alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
                }
                else{
                  alertConfig = {...alertConfig, ...alertConfigNoSurvey}
                }
                swalCustom
                .fire(alertConfig)
                .then((result) => {
                  if(result.isConfirmed){
                    isDisabledSubmitBtn.value = false
                    if((config_data.member_surveys.NEET && !props.memberData?.neet_survey && config_data.member_surveys.CWD && !props.memberData?.cwd_survey) || (config_data.member_surveys.NEET && !props.memberData?.neet_survey)){
                      router.replace({
                        name: 'add-neet',
                        params:{
                          surveyid: route.params.surveyid,
                          memberid: res.data.member_id
                        }
                      })
                      emit("complete-form")
                    }
                    else if(config_data.member_surveys.CWD && !props.memberData?.cwd_survey){
                      router.replace({
                        name: 'add-cwd',
                        params:{
                          surveyid: route.params.surveyid,
                          memberid: res.data.member_id
                        }
                      })
                      emit("complete-form")
                    }
                    else{
                      emit("complete-form", true)
                    }
                  }
                  else{
                    emit("complete-form", true)
                  }
                });
              }
              else{
                isDisabledSubmitBtn.value = false
                swalCustom.fire({
                  text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
                  icon: "warning",
                  // timer: 3000,
                  confirmButtonText: "ปิด",
                });
              }
            }
            else{
              if (result.isConfirmed){
                await houseStore.getItem(route.params.hhid).then((value)=>{
                  const m_idx = value.house_surveys.members.findIndex(x=> x.member_id == tempID.value)
                  config_data.member_surveys.status = "DRAFT"
                  value.house_surveys.members[m_idx].member_surveys = {...value.house_surveys.members[m_idx].member_surveys, ...config_data.member_surveys}
                  value.house_surveys.members[m_idx]["update_by"] = store.getters["userAuth/getUserID"]
                  houseStore.setItem(route.params.hhid, JSON.parse(JSON.stringify(value)))
                })
                isDisabledSubmitBtn.value = false
                if((config_data.member_surveys.NEET && !props.memberData?.neet_survey_data && config_data.member_surveys.CWD && !props.memberData?.cwd_survey_data) || (config_data.member_surveys.NEET && !props.memberData?.neet_survey_data)){
                  alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
                }
                else if(config_data.member_surveys.CWD && !props.memberData?.cwd_survey_data){
                  alertConfigHaveSurvey.html = `<div style="font-size: 20px;">สำเร็จ</div><div style="margin-top: 10px;font-size: 25px;">ทำแบบสอบถาม เด็กพิการ ต่อหรือไม่</div>`
                  alertConfig = {...alertConfig, ...alertConfigHaveSurvey}
                }
                else{
                  alertConfig = {...alertConfig, ...alertConfigNoSurvey}
                }
                swalCustom
                .fire(alertConfig)
                .then((result2) => {
                  if(result2.isConfirmed){
                    isDisabledSubmitBtn.value = false
                    if((config_data.member_surveys.NEET && !props.memberData?.neet_survey_data && config_data.member_surveys.CWD && !props.memberData?.cwd_survey_data) || (config_data.member_surveys.NEET && !props.memberData?.neet_survey_data)){
                      router.replace({
                        name: 'add-neet',
                        params:{
                          surveyid: route.params.hhid,//for offline use hhid as surveyid to get by house key
                          memberid: tempID.value
                        }
                      })
                      emit("complete-form")
                    }
                    else if(config_data.member_surveys.CWD && !props.memberData?.cwd_survey_data){
                      router.replace({
                        name: 'add-cwd',
                        params:{
                          surveyid: route.params.hhid,
                          memberid: tempID.value
                        }
                      })
                      emit("complete-form")
                    }else{
                      emit("complete-form", true)
                    }
                  }
                  else{
                    emit("complete-form", true)
                  }
                });
              }
              else{
                isDisabledSubmitBtn.value = false
              }
            }
          }
          catch(error){
            console.log("error: ",error);
            isDisabledSubmitBtn.value = false
            swalCustom.fire({
              text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
              icon: "warning",
              // timer: 3000,
              confirmButtonText: "ปิด",
            });
          }
        })
      }else{
        if(onlineComputed.value){
          const res = await saveMember(config_data)
          if (res.status === 200) {
            isDisabledSubmitBtn.value = false
            member_id.value = res.data.member_id
            nextstep(findStep(stepNum));
          }
          else{
            isDisabledSubmitBtn.value = false
            swalCustom.fire({
              text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
              icon: "warning",
              // timer: 3000,
              confirmButtonText: "ปิด",
            });
          }
        }
        else{
          houseStore.getItem(route.params.hhid).then((value)=>{
            if(stepNum == 1){
              if(tempID.value){//update member when back to saved step
                const m_idx = value.house_surveys.members.findIndex(x=> x.member_id == tempID.value)
                config_data["house_surveys_id"] = value.house_surveys.id
                config_data["house_id"] = value.id
                config_data["member_surveys"] = {...value.house_surveys.members[m_idx].member_surveys, ...config_data.member_surveys}
                const {id, ...restConfigData} = config_data
                value.house_surveys.members[m_idx] = {...value.house_surveys.members[m_idx], ...restConfigData}
                value.house_surveys.members[m_idx]["update_by"] = store.getters["userAuth/getUserID"]
                houseStore.setItem(route.params.hhid, JSON.parse(JSON.stringify(value)))
              }
              else{
                // config_data["_id"] = value.house_surveys.members ? value.house_surveys.members.length+1 : 1
                //update code in offline datatable use member_id instead
                config_data["member_id"] = value.house_surveys.members ? value.house_surveys.members.length+1 : 1
                config_data["house_surveys_id"] = value.house_surveys.id
                config_data["house_id"] = value.id
                if(value.house_surveys.members?.length)
                  value.house_surveys.members.push(config_data)
                else
                  value.house_surveys.members = [config_data]
                houseStore.setItem(route.params.hhid, JSON.parse(JSON.stringify(value)))
                tempID.value = config_data["member_id"]
              }

            }
            else{
              //get member id
              const m_idx = value.house_surveys.members.findIndex(x=> x.member_id == tempID.value)
              value.house_surveys.members[m_idx].member_surveys = {...value.house_surveys.members[m_idx].member_surveys, ...config_data.member_surveys}
              value.house_surveys.members[m_idx]["update_by"] = store.getters["userAuth/getUserID"]
              houseStore.setItem(route.params.hhid, JSON.parse(JSON.stringify(value)))
            }
            isDisabledSubmitBtn.value = false
            nextstep(findStep(stepNum));
          })
        }
      }
    }
  }
  catch(error){
    console.log("error: ",error);
    isDisabledSubmitBtn.value = false
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      // timer: 3000,
      confirmButtonText: "ปิด",
    });
  }
}

const initValue = async () => {
  if(props.isAdminManagement){
    stepForm.value = initialStepForm([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    if(props.currentStepObj){
        currentStep.value = props.currentStepObj
    }else{
      currentStep.value = stepForm.value.find((e) => {
        return e.step == 1;
      });
    }
  }
  else{
    stepForm.value = initialStepForm([1, 6, 8, 9, 10, 11, 12]);
  }
};

/* const clearValue = () => {
  dataForm = {
    step01: {},
    step02: {},
    step03: {},
    step04: {},
    step05: {},
    step06: {},
    step07: {},
    step08: {},
    step09: {},
    step10: {},
    step11: {},
  };

  currentStep.value = {
    step: 1,
    form: 1,
  };

  render.value = false;
}; */

const showArrow = ref(false)
const resizeHandle = () =>{
  let div = document.getElementById('member_wizard');
  let hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  if(hasHorizontalScrollbar)
    showArrow.value = true
  else
    showArrow.value = false
}
watch(stepForm, ()=>{
  setTimeout(()=>{
    resizeHandle()
  }, 200)
})
const defaultHide = ref(false)
const getScreen = () =>{
  if(window.innerWidth < 1024){
    defaultHide.value = true
  }
  else{
    defaultHide.value = false
  }
}

onMounted(()=>{
  if(onlineComputed.value)
    initValue();
  getScreen()
  window.addEventListener("resize", resizeHandle)
  window.addEventListener("resize", getScreen)

})
onUnmounted(()=>{
  window.removeEventListener("resize", resizeHandle);
  window.removeEventListener("resize", getScreen);
})

watch(()=> props.memberData,(val)=>{
  if(val.member_surveys){
    allDataForm = Object.assign(allDataForm, val.member_surveys)
    let form = prepareStepForm(val.member_surveys.age.year);
    stepForm.value = initialStepForm(form);
    ageInMonth.value = (val.member_surveys.age.year*12)+val.member_surveys.age.month
    if(val.member_surveys?.IN105){
      isDisability.value = val.member_surveys.IN105.some(({code}) => code !== "IN105-1")
    }
    haveOccupation.value = allDataForm?.IN114?.code ? allDataForm.IN114.code != "IN114-2" : false
    isAlcohol.value = (allDataForm?.IN110?.code ? allDataForm.IN110.code !== "IN110-1" : false) || (allDataForm?.IN112?.code ? allDataForm.IN112.code !== "IN112-1" : false)
  }
})
watch(()=> props.questionList, ()=>{
  if(!onlineComputed.value){
    initValue();
  }
})

const updateQuestionStatus = async (data) => {
  dataUpdate.value = null
  const res = await axios.put(
    `${process.env.VUE_APP_BASE_API}admin/question/status/${data._id}`,{},
    {
      headers: {
        Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
        "Content-Type": `application/json; charset=utf-8`,
      }
    }
  ).catch(() => {
    swalCustom.fire({
      text: "ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง",
      icon: "warning",
      //timer: 3000, //timeOut for auto-close
      confirmButtonText: "ปิด",
    });
  }).then(()=>{
    dataUpdate.value = data
  });

  if(res.status == 200){
    if(data.status == "active"){
      data.status = "inactive"
    }
    else{
      data.status = "active"
    }
    dataUpdate.value = data
  }
}

</script>
<style lang="scss">
.form-check-input {
  border-color: black !important;
}
.accordion-button .feather-play{
  transition: transform .1s ease;
  fill: #2244b0;
}
.accordion-button[aria-expanded="true"] .feather-play {
  transform: rotate(-90deg);
  transition: transform .1s ease;
}
</style>
