<template>
  <template-layout :breadCrumbList="breadcrumbComputed">
    <div class="py-12 px-2 sm:px-4">
      <nav class="flex justify-between items-baseline mb-6">
        <h1 class="text-2xl text-primary-2">จัดการแบบสอบถาม</h1>
        <router-link
          :to="{ name: 'add-survey-management' }"
          class="btn btn-primary bg-primary-btn border-primary-btn mr-1 mb-2"
        >
          + เพิ่มแบบสอบถาม
        </router-link>
      </nav>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <Datatable />
        </div>
      </div>
    </div>
  </template-layout>
</template>
<script setup>
import Datatable from "./Datatable.vue";
import { reactive } from "vue";
import TemplateLayout from "@/layouts/side-menu/Main.vue";

const breadcrumbComputed = reactive([{
  name: "จัดการแบบสอบถาม",
  active: true
}])

</script>

<style scoped>
</style>
