<template>
  <div>
    <label class="from-label text-medium text-gray-600">วันที่สำรวจ</label>
    <div class="w-full flex items-baseline gap-3">
      <div class="flex-grow">
        <Datepicker
          v-model="startComputed"
          placeholder="เริ่มต้น"
          :clearable="true"
          position="left"
          @cleared="handleStartDateClear"
          :maxDateProps="endComputed"
          :disableUpdateMaxDate="true"
        />
      </div>
      <span>-</span>
      <div class="flex-grow">
        <Datepicker
          v-model="endComputed"
          placeholder="สิ้นสุด"
          :clearable="true"
          position="right"
          :minDate="startComputed"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
import Datepicker from "@/components/custom-datepicker/DatepickerComponent.vue"
const props = defineProps({
  start_date: Date,
  end_date: Date,
});

const emits = defineEmits(["update:start_date", "update:end_date"]);

const startComputed = computed({
  get() {
    return props.start_date
  },
  set(evt) {
    let date = null
    if(evt){
      date = [evt.getFullYear(),
                String(evt.getMonth() + 1).padStart(2, '0'),
                String(evt.getDate()).padStart(2, '0')
              ].join('-');
    }
    emits("update:start_date", date);
  },
});
const endComputed = computed({
  get() {
    return props.end_date
  },
  set(evt) {
    let date = null
    if(evt){
      date = [evt.getFullYear(),
                String(evt.getMonth() + 1).padStart(2, '0'),
                String(evt.getDate()).padStart(2, '0')
              ].join('-');
    }
    emits("update:end_date", date);
  },
});


const handleStartDateClear = () =>{
  endComputed.value = null
}

</script>
