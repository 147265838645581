import Velocity from 'velocity-animate'


// Setup side menu
const findActiveMenu = (subMenu, route) => {
  let match = false
  subMenu.forEach(item => {
    if ((item.params ? item.path === route.path : item.pageName === route.name) && !item.ignore) {
      match = true
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route)
    }
  })
  return match
}

const nestedMenu = (menu, route) => {
  menu.forEach((item, key) => {
    if (typeof item !== 'string') {
      let menuItem = menu[key]
      menuItem.active =
        ((item.params ? item.path === route.path : item.pageName === route.name) ||
          (item.subMenu && findActiveMenu(item.subMenu, route))) &&
        !item.ignore

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route)
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route)
        }
      }
    }
  })

  return menu
}

const linkTo = (menu, router, event) => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown
  } else {
    event.preventDefault()
    let link = {
      name: menu.pageName
    }
    if(menu.params){
      link["params"] = {...menu.params}
    }
    router.push(link)
  }
}

const enter = (el, done) => {
  Velocity(el, 'slideDown', { duration: 300 }, { complete: done })
}

const leave = (el, done) => {
  Velocity(el, 'slideUp', { duration: 300 }, { complete: done })
}

export { nestedMenu, linkTo, enter, leave }
