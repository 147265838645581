const INIT_FILTER_VALUE = {
  address: {
    amphur: null,
    province: null,
    tumbol: null,
    village: null,
  },
};

const state = () => {
  return { ...INIT_FILTER_VALUE };
};

// actions
const actions = {
  clearStore({ commit }) {
    commit("clearStore");
  },
  setStore({ commit }, store) {
    commit("setStore", store);
  },
};

// mutations
const mutations = {
  clearStore(state) {
    state = { ...INIT_FILTER_VALUE };
  },
  setStore(state, store) {
    Object.assign(state, store);
  },
};

// getters
const getters = {
  province: (state) => state.address.province,
  getAllFilter: (state) => {
    return state;
  },
  getFilterSummary: (state, getters, rootState, rootGetters) => {
    let result = [];

    if (state.address.province && rootGetters["userAuth/getAreaCode"] == "00"){
      result.push(state.address.province.province_name)
    }
    if (state.address.amphur && rootGetters["userAuth/getAreaCode"].length == 2){
      result.push(state.address.amphur.amphur_name)
    }
    if (state.address.tumbol && (rootGetters["userAuth/getAreaCode"].length == 2 || rootGetters["userAuth/getAreaCode"].length == 4)){
      result.push(state.address.tumbol.tumbol_name)
    }
    if (state.address.village){
      result.push(state.address.village.village_name)
    }

    return result;
  },
  getFilterForApi: (state, getters, rootState, rootGetters) => {
    const result = {};
    if ((rootGetters["userAuth/getUserProvinceCode"].length === 2 && rootGetters["userAuth/getUserProvinceCode"] !== "00") || state.address.province)
      result.province_ID = state.address.province ? state.address.province._id.province_ID: Number(rootGetters["userAuth/getUserProvinceCode"])
    if (rootGetters["userAuth/getUserAmphurCode"].length === 4 || state.address.amphur)
      result.amphur_ID = state.address.amphur ? state.address.amphur._id.amphur_ID : Number(rootGetters["userAuth/getUserAmphurCode"]);
    if (rootGetters["userAuth/getUserTumbolCode"].length === 6 || state.address.tumbol)
      result.tambol_ID = state.address.tumbol ? state.address.tumbol._id.tumbol_ID : Number(rootGetters["userAuth/getUserTumbolCode"]);
    if (state.address.village)
      result.village_ID = state.address.village._id.village_ID;
    return result;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
