<template>
  <div
    id="tabulator"
    ref="tableRef"
    class="table-report table-report--tabulator"
    :data-test="`${tableName}-data-table`"
  />
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import feather from "feather-icons";
import Tabulator from "tabulator-tables";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
const props = defineProps({
  dataArrayTable: {
    type: Array,
  },
  paginationSize: {
    type: Number,
    default: 10,
  },
  paginationSizeArray: {
    type: Array,
    default: () => [10, 20, 50, 100],
  },
  dataArray: {
    type: Array,
  },
  apiUrl: {
    type: String,
  },
  tableName: {
    type: String,
    default: "init",
  },
  routerName: {
    type: String,
  },
  apiParams: {
    type: Object,
  },
  totalList: {
    type: Number,
  },
  methodRequest: {
    type: String,
  },
  downloadFile:{
    type: Boolean
  },
  initSorter:{//initial sort with column name define on table
    type: Array
  },
  customSort:{
    type: Array
  },
  questionGroup:{
    type: String
  }
})
const emits = defineEmits(["rowClicked", "getDataComplete"])
const tableRef = ref();
const tabulator = ref();
const totalLists = ref(0);
// console.log("props ", props);    
const router = useRouter();
const store = useStore();    
const token = store.getters["userAuth/getToken"];
const initTabulator = () => {
  tabulator.value = new Tabulator(tableRef.value, {
    locale: "th-th",
    langs: {
      "th-th": {
        pagination: {
          page_size: "แสดง",
        },
      },
    },
    // data: props.dataArray,
    ajaxURL: `${process.env.VUE_APP_BASE_API}${props.apiUrl}`, //call api if using api no need to send dataArray
    ajaxConfig: {
      method: props.methodRequest,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    },
    // initialSort: props.initSorter,//set initial sort with column name define on table
    ajaxParams: props.apiParams,
    ajaxResponse: function(url, params, response) {
      document.querySelector(".totalList").innerHTML =
        new Intl.NumberFormat().format(response.recordsTotal);
      totalLists.value = response.recordsTotal
      return response; //return the tableData property of a response json object
    },
    /*rowSelectionChanged:function(data, rows){
        //update selected row counter on selection change
      console.log("daa ", data);
      console.log("r ", rows);
    },*/
    rowClick: function(e, row) {
      //emit function to parent to handle what to do
      emits("rowClicked", row._row.data)
    },
    pageLoaded: function(e, row) {
      if (window.innerWidth > 1005) {
        tabulator.value.redraw();
        document.querySelectorAll(".tabulator-col").forEach((col) => {
          if (col.style.display == "none") {
            col.style.display = "inline-block";
            col.setAttribute("hide", "true");
          }
          //check cell field name equal to header column and set width
          document.querySelectorAll(".tabulator-cell").forEach((cell) => {
            if (
              cell.getAttribute("tabulator-field") ==
              col.getAttribute("tabulator-field")
            ) {
              cell.style.width = cell.style.width
                ? cell.style.width
                : cell.style.minWidth;
            }
          });
        });
        document.querySelectorAll(".tabulator-cell").forEach((cell) => {
          if (cell.style.display == "none")
            cell.style.display = "inline-flex";
        });
      }
    },
    layout: "fitColumns",
    responsiveLayout: "collapse",
    // pagination: 'local',
    ajaxSorting:true,
    pagination: "remote",
    paginationDataSent: {
      page: "start", //change page request parameter to "pageNo"
      size: "length",
    },
    ajaxRequesting:function(url, params){
    //url - the URL of the request
    //params - the parameters passed with the request
      if(params.sorters.length == 0){
        params.sorters = props.initSorter
      }
      params.sorters = params.sorters.map((item)=>{
        if(item.field == "house_surveys_status")
          item.field = "house_surveys.status"
        return item
      })

      params.sorters = JSON.stringify(params.sorters)
      return params
    },
    paginationDataReceived: {
      last_page: "maxPage", //change last_page parameter name to "max_pages"
    },
    headerSort: true,
    paginationSize: props.paginationSize,
    paginationSizeSelector: props.paginationSizeArray,
    cellVertAlign: "middle",
    placeholder: "ไม่มีข้อมูล",
    footerElement: `<span class="mb-1">ทั้งหมด <span class="totalList"></span> รายการ</span>`,
    columns: props.dataArrayTable,
    columnHeaderSortMulti: true,
    renderComplete() {
      feather.replace({
        "stroke-width": 2,
      });
    },
  });
};

watch(()=> props.downloadFile, async (val)=>{
    if(val){
      const question_lists = await axios.get(`${process.env.VUE_APP_BASE_API}admin/question/questionnaire`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            question_nare_name_en: props.questionGroup
          },
        }
      ).
      catch(err => err);
      const new_question_lists = question_lists.data.reduce((a,v)=>{
        let temp = []
        if(v.question_type == "multiple-inputs-group"){
          a = [...a, ...v.choices]
        }
        else{
          temp = [...temp, v]
        }
        return [...a, ...temp]
      }, [])
      const res = await axios.get(`${process.env.VUE_APP_BASE_API}${props.apiUrl}`,
        {
          params: {...props.apiParams, start: 1, draw: 10, length: totalLists.value, export: true},
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      ).
      catch(err => err);

      emits("getDataComplete", res, new_question_lists)
    }
  })
const testSort = () => {
  let ee = tabulator.value.getSorters();
};
// Redraw table onresize
const reInitOnResizeWindow = () => {
  //when load
  if (window.innerWidth > 1005) {
    document.querySelectorAll(".tabulator-col").forEach((col) => {
      if (col.style.display == "none") {
        col.style.display = "inline-block";
        col.setAttribute("hide", "true");
      }
      //check cell field name equal to header column and set width
      document.querySelectorAll(".tabulator-cell").forEach((cell) => {
        if (
          cell.getAttribute("tabulator-field") ==
          col.getAttribute("tabulator-field")
        ) {
          cell.style.width = cell.style.width
            ? cell.style.width
            : cell.style.minWidth;
        }
      });
    });
    document.querySelectorAll(".tabulator-cell").forEach((cell) => {
      if (cell.style.display == "none") cell.style.display = "inline-flex";
    });
  }

  window.addEventListener("resize", () => {
    tabulator.value.redraw();
    if (window.innerWidth <= 1005) {
      document.querySelectorAll(".tabulator-col").forEach((col) => {
        if (col.hasAttribute("hide")) {
          document.querySelectorAll(".tabulator-cell").forEach((cell) => {
            if (
              col.style.display == "inline-block" &&
              cell.getAttribute("tabulator-field") ==
                col.getAttribute("tabulator-field")
            ) {
              cell.style.display = "none";
              cell.style.removeProperty("height");
            }
          });
        } else {
          //when resize from small to large screen
          document.querySelectorAll(".tabulator-cell").forEach((cell) => {
            if (
              col.style.display == "none" &&
              cell.getAttribute("tabulator-field") ==
                col.getAttribute("tabulator-field")
            ) {
              cell.style.display = "none";
              cell.style.removeProperty("height");
            }
          });
        }
      });
    } else {
      // always display every column
      document.querySelectorAll(".tabulator-col").forEach((col) => {
        if (col.style.display == "none") {
          col.style.display = "inline-block";
          col.setAttribute("hide", "true");
        }
      });
      document.querySelectorAll(".tabulator-cell").forEach((cell) => {
        cell.style.display = "inline-flex";
        cell.style.removeProperty("height");
      });
    }
    feather.replace({
      "stroke-width": 1.5,
    });
  });
};

onMounted(() => {
  initTabulator();
  reInitOnResizeWindow();
});

</script>
<style lang="scss">
.tabulator .tabulator-row .tabulator-responsive-collapse {
  display: none;
}
/* .tabulator .tabulator-header .tabulator-headers .tabulator-col, .tabulator .tabulator-row .tabulator-cell{
  display: inline-block !important;
  min-width: 80px;
  width: 200px;
} */
.tabulator .tabulator-row .tabulator-responsive-collapse td strong {
  font-weight: 600 !important;
}
.tabulator-cell[tabulator-field="full_name"] {
  font-weight: 600;
}
@media only screen and (max-width: 1005px) {
  .tabulator {
    .tabulator-tableHolder {
      // overflow: unset !important;
      .tabulator-table {
        white-space: normal !important;
        width: 100%;
        .tabulator-row .tabulator-responsive-collapse {
          display: block;
        }
      }
    }
  }
}
</style>
