<template>
  <div class="mt-0 p-0">
    <div
      class="grid grid-cols-12 gap-4 gap-y-5 items-baseline"
      data-test="form-step1"
    >
      <div class="col-span-12" v-if="isNotSaved">
        <SurveyAlert />
      </div>
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="col-span-12" :class="[{'sm:col-span-6' : data.question_type != 'radio' && data.question_type != 'checkbox' && data.question_type != 'multiple-inputs-group' && data.question_type != 'multiple-inputs-answer' && data.question_type != 'header' && data.question_code != 'address_num'}]" v-for="(data, key) in question_group1" :key="key">
            <!-- <div class="accordion accordion-boxed" :class="{ 'mt-5': key > 0 }" v-if="data.question_type == 'header' ? checkQuestionAnswer(data.question_list) : false">
              <div class="accordion-item !py-2 bg-[#eaf0ff]">
                {{ data.question_desc ? data.question_desc : data.question_type == 'header' ? 'ไม่มีหัวข้อย่อย': '' }}
              </div>
            </div> -->
            <div class="relative" :class="{'border-1 p-3 pt-4': isAdminManagement && data.question_type != 'header'}">
              <div class="accordion accordion-boxed" :class="{ 'mt-5': key > 0 }" v-if="data.question_type == 'header' ? checkQuestionAnswer(data) : false">
                <div class="accordion-item !py-2 bg-[#eaf0ff]">
                  <span class="text-[#142E71] whitespace-pre-line text-base">
                  {{ data.question_desc ? data.question_desc : data.question_type == 'header' ? 'ไม่มีหัวข้อย่อย': '' }}
                  </span>
                </div>
              </div>
              <div class="absolute right-1 flex gap-1 z-10" :class="data.question_type == 'header' ? 'top-3' : 'top-1'" v-if="isAdminManagement">
                <input
                  class="form-check-switch"
                  type="checkbox"
                  :checked="data.status == 'active'"
                  @change="updateStatusChange($event, data)"
                  v-if="data.question_type != 'header'"
                  :id="`input-check-switch-${data.question_code}`"
                >
                <div class="dropdown">
                  <button class="dropdown-toggle btn bg-transparent p-[2px] rounded border-0 shadow-none rotate-90" aria-expanded="false">
                    <MoreHorizontalIcon class="w-5 h-4"/>
                  </button>
                  <div class="dropdown-menu w-48">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left text-primary hover:bg-gray-200" @click="addEvent(data)" v-if="data.question_type == 'header'">
                            <PlusIcon class="w-4 h-4 mr-2" /> เพิ่มคำถาม
                          </button>
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-gray-200" @click="emit('editQuestion', data)" v-if="data.question_type != 'header'">
                            <EditIcon class="w-4 h-4 mr-2" /> แก้ไข
                          </button>
                          <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left text-theme-24/[0.8] hover:bg-gray-200" @click="emit('deleteQuestion',data._id)" v-if="!data.edit_only && data.question_type != 'header'">
                            <Trash2Icon class="w-4 h-4 mr-2 " /> ลบ
                          </button>
                      </div>
                  </div>
                </div>
              </div>
              <div v-if="data.question_type != 'header' && getRelateResult(data)">
                <div v-if="data.question_code == 'address_num'" >
                  <label class="form-label">
                    {{ !data.edit_only ? data.question_code : null }} {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <div class="grid grid-cols-12 gap-4 gap-y-5">
                    <div class="col-span-6">
                      <RadioCustomButton
                        class="mr-2 w-40"
                        v-model="validate.formObj.haveHouseAddressNum.$model"
                        name="is-iden-radio-group"
                        first-option-id="national-id-option"
                        second-option-id="no-national-id-option"
                        first-option-text="มี"
                        first-option-value="มี"
                        second-option-text="ไม่มี"
                        second-option-value="ไม่มี"
                        @change="clearFormValue"
                        :isDisabled="isView"
                        :class="{ 'border-theme-24': validate.formObj.haveHouseAddressNum.$error }"
                        id="haveHouseAddressNum"
                        tabindex="-1"
                      />
                      <template v-if="validate?.formObj?.haveHouseAddressNum?.$error">
                        <div
                          v-for="(error, index) in validate.formObj.haveHouseAddressNum.$errors"
                          :key="index"
                          class="text-theme-24 mt-1 text-sm"
                        >
                          {{ error.$message }}
                        </div>
                      </template>
                    </div>
                    <div class="col-span-6" v-if="form.formObj.haveHouseAddressNum == 'มี' || isAdminManagement">
                      <div class="relative flex items-center ">
                        <div class="flex-grow">
                          <input
                            v-if="data.is_check_address"
                            :id="data.question_code"
                            :type="data.question_type == 'number' ? 'number' : 'text'"
                            v-model="form.formObj[data.question_code]"
                            class="form-control"
                            :placeholder="data.placeholder"
                            :data-test="data.question_code"
                            :oninput="data.max_length && data.question_type == 'text' ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : null"
                            :maxlength="data.max_length"
                            :disabled="isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                            :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error || (data.is_check_address ? isNotUniqueHouse == false : null), 'pr-[62px]' : data.question_type == 'number' }"
                            @input="setValidate(data.question_code,(data.required || data.required_count_member || data.max_length_message)); data.is_check_address ? debounce(() => checkHouseDuplicate()) : null; handleAcceptInputType(data);"
                            :state="(data.is_check_address ? isNotUniqueHouse : null)"
                            :min="data.question_type == 'number' && !data.is_negative ? '0' : null"
                            @keypress="handleTypeNumber(data.question_type, $event, data.max_length, data.is_decimal, data.is_negative), isCheckingUniqueHouse ? $event.preventDefault() : false"
                            :style="isCheckingUniqueHouse ? 'pointer-events:none;' : null"
                          />
                          <template v-if="validate?.formObj?.[data.question_code]?.$error">
                            <div
                              v-for="(error, index) in validate.formObj[data.question_code].$errors"
                              :key="index"
                              class="text-theme-24 mt-1 text-sm"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                          <template v-if="(data.is_check_address ? isNotUniqueHouse == false : null)">
                            <div
                              class="text-theme-24 mt-1 text-sm"
                            >
                              บ้านเลขที่นี้ถูกใช้งานแล้ว
                            </div>
                          </template>
                        </div>
                        <LoadingIcon
                          v-if="data.is_check_address ? isCheckingUniqueHouse : null"
                          icon="oval"
                          class="loading-icon absolute right-0 p-1"
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div v-if="(data.question_type == 'text' || data.question_type == 'number') && data.question_code != 'address_num'">
                  <label class="form-label">
                    {{ !data.edit_only ? data.question_code : null }} {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <div class="relative flex items-center">
                    <div class="relative flex-grow">
                      <input
                        v-if="data.is_check_address || data.is_check_house_code"
                        :id="data.question_code"
                        :type="data.question_type == 'number' ? 'number' : 'text'"
                        v-model="form.formObj[data.question_code]"
                        class="form-control"
                        :placeholder="data.placeholder"
                        :data-test="data.question_code"
                        :oninput="data.max_length && data.question_type == 'text' ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : null"
                        :maxlength="data.max_length"
                        :disabled="isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                        :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error || (data.is_check_address ? isNotUniqueHouse == false : null) || (data.is_check_house_code ? isNotDuplicateHouseNo11 == false : null), 'pr-[62px]' : data.question_type == 'number' }"
                        @input="setValidate(data.question_code,(data.required || data.required_count_member || data.max_length_message)); data.is_check_address  ? checkHouseDuplicate() : null; data.is_check_house_code ? checkDuplicateHouseNo() : null; handleAcceptInputType(data);"
                        :state="(data.is_check_address ? isNotUniqueHouse : null) || (data.is_check_house_code ? isNotDuplicateHouseNo11 : null)"
                        :min="data.question_type == 'number' && !data.is_negative ? '0' : null"
                        @keypress="handleTypeNumber(data.question_type, $event, data.max_length, data.is_decimal, data.is_negative)"
                      />
                      <input
                        v-else
                        :id="data.question_code"
                        :type="data.question_type == 'number' ? 'number' : 'text'"
                        v-model="form.formObj[data.question_code]"
                        class="form-control"
                        :placeholder="data.placeholder"
                        :data-test="data.question_code"
                        @input="setValidate(data.question_code,(data.required || data.required_count_member || data.max_length_message)); handleAcceptInputType(data);"
                        :oninput="data.max_length && data.question_type == 'text' ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : null"
                        :maxlength="data.max_length"
                        :disabled="isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                        :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error, 'pr-[62px]' : data.question_type == 'number'}"
                        :min="data.question_type == 'number' && !data.is_negative ? '0' : null"
                        @keypress="handleTypeNumber(data.question_type, $event, data.max_length, data.is_decimal, data.is_negative)"
                      />
                      <div
                        v-if="data.question_type == 'number' && !isView && !data.is_decimal"
                        class="absolute right-1 top-0 h-full mt-[6px]"
                      >
                        <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code] <= 0 && !data.is_negative || data.disable}" :disabled="form.formObj[data.question_code] <= 0 && !data.is_negative || data.disable" @click="handleButtonIncreaseDecrease('decrease', data)">
                          <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                        </button>
                        <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : data.disable}" :disabled="data.disable" @click="handleButtonIncreaseDecrease('increase', data)">
                          <PlusIcon class="h-4 w-4 !stroke-2.5" />
                        </button>
                      </div>
                    </div>
                    <!-- </div> -->
                    <LoadingIcon
                      v-if="data.is_check_address ? isCheckingUniqueHouse : null || data.is_check_house_code ? isCheckingDuplicateHouseNo11 : null"
                      icon="oval"
                      class="loading-icon absolute right-0 p-1"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4 gap-y-5" v-if="data.question_type == 'multiple-inputs-group'">
                  <label class="form-label col-span-12 mb-[-0.75rem]">
                    {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.question_desc && data.choices.some(({required}) => required)"> *</span>
                  </label>
                  <div class="col-span-12 sm:col-span-4" v-for="inp in data.choices" :key="inp">
                    <label v-if="inp.question_desc">{{inp.question_desc}}<span class="text-theme-24 text-md" v-if="inp.required"> *</span></label>
                    <div class="relative flex items-center" v-if="inp.question_type == 'number' || inp.question_type == 'text'">
                      <input
                        :id="inp.question_code"
                        :type="inp.question_type == 'number' ? 'number' : 'text'"
                        v-model="form.formObj[inp.question_code]"
                        class="form-control"
                        :placeholder="inp.placeholder"
                        :data-test="inp.question_code"
                        @input="setValidate(inp.question_code,(inp.required || inp.required_count_member || inp.max_length_message)); handleAcceptInputType(inp)"
                        :oninput="inp.max_length ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : null"
                        :maxlength="inp.max_length"
                        :disabled="isView || (inp.new_survey_disable ? isAddSurvey : false ) || inp.disable"
                        :class="{ 'border-theme-24': validate?.formObj?.[inp.question_code]?.$error}"
                        :min="inp.question_type == 'number' ? '0' : null"
                        @keypress="handleTypeNumber(inp.question_type, $event)"
                      />
                    </div>
                    <div v-if="inp.question_type == 'select'" tabindex="-1" :id="inp.question_code">
                      <v-select
                        :data-test="inp.question_code"
                        :options="inp.choices"
                        :clearable="!inp.required"
                        :searchable="true"
                        :placeholder="inp.placeholder"
                        v-model="form.formObj[inp.question_code]"
                        :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[inp.question_code]?.$error }"
                        :disabled="isView || (inp.new_survey_disable ? isAddSurvey : false) || inp.disable"
                        label="label"
                        :reduce="label=>label.label"
                      >
                        <template #no-options="{}">
                          ไม่พบข้อมูล
                        </template>
                        <template #open-indicator="{ attributes }">
                          <span v-bind="attributes"
                            ><ChevronDownIcon class="w-5 h-5"
                          /></span>
                        </template>
                      </v-select>
                    </div>
                    <template v-if="validate?.formObj?.[inp.question_code]?.$error">
                      <div
                        v-for="(error, index) in validate.formObj[inp.question_code].$errors"
                        :key="index"
                        class="text-theme-24 mt-1 text-sm"
                      >
                        {{ error.$message }}
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="data.question_type == 'select'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{ !data.edit_only ? data.question_code : null }} {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <v-select
                    :data-test="data.question_code"
                    :options="data.choices"
                    :clearable="!data.required"
                    :searchable="true"
                    :placeholder="data.placeholder"
                    v-model="form.formObj[data.question_code]"
                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                    :disabled="isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                    label="label"
                    :reduce="label=>label.label"
                  >
                    <template #no-options="{}">
                      ไม่พบข้อมูล
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"
                        ><ChevronDownIcon class="w-5 h-5"
                      /></span>
                    </template>
                  </v-select>
                </div>
                <div v-if="data.question_type == 'multiple-inputs-answer'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{ !data.edit_only && !data.is_multiple_answers_type_except ? data.question_code : null }} {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-12 sm:col-span-6" v-for="inp in data.choices" :key="inp">
                      <label v-if="inp.label">{{inp.label}}</label>
                      <div class="flex items-center">
                        <div class="relative flex-grow">
                          <input
                            :type="inp.type == 'number' ? 'number' : 'text'"
                            class="form-control"
                            v-model="form.formObj[data.question_code][inp.key]"
                            :disabled="isView"
                            :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$error, 'pr-[62px]' : inp.type == 'number' }"
                            :min="inp.type == 'number' && !inp.is_negative ? '0' : null"
                            @keypress="handleTypeNumber(inp.type, $event, null, inp.is_decimal, inp.is_negative)"
                          />
                          <div
                            v-if="inp.type == 'number' && !isView && !inp.is_decimal"
                            class="absolute right-1 top-0 h-full mt-[6px]"
                          >
                            <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][inp.key] <= 0 && !inp.is_negative}" :disabled="form.formObj[data.question_code][inp.key] <= 0 && !inp.is_negative" @click="handleButtonIncreaseDecrease('decrease', data, inp)">
                              <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                            </button>
                            <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" @click="handleButtonIncreaseDecrease('increase', data, inp)">
                              <PlusIcon class="h-4 w-4 !stroke-2.5" />
                            </button>
                          </div>
                        </div>
                        <div class="ml-2" v-if="inp.unit">{{inp.unit}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="data.question_type == 'province'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <v-select
                    :disabled="disableProvince"
                    :data-test="data.question_code"
                    label="province_name"
                    :options="provinceArr"
                    :placeholder="data.placeholder"
                    :clearable="!data.required"
                    :searchable="true"
                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                    v-model="form.formObj[data.question_code]"
                    @option:selected="handleProvinceSelected"
                  >
                    <template #no-options="{}">
                      ไม่พบข้อมูล
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"
                        ><ChevronDownIcon class="w-5 h-5"
                      /></span>
                    </template>
                  </v-select>
                </div>
                <div v-if="data.question_type == 'district'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <v-select
                    :disabled="disabledAmphur || isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                    :data-test="data.question_code"
                    label="amphur_name"
                    :options="districtArr"
                    :placeholder="data.placeholder"
                    :clearable="!data.required"
                    :searchable="true"
                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                    v-model="form.formObj[data.question_code]"
                    @option:selected="handleDistrictSelected"
                  >
                    <template #no-options="{}">
                      ไม่พบข้อมูล
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"
                        ><ChevronDownIcon class="w-5 h-5"
                      /></span>
                    </template>
                  </v-select>
                </div>
                <div v-if="data.question_type == 'subdistrict'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <v-select
                    :disabled="disabledTumbol || isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                    :data-test="data.question_code"
                    label="tumbol_name"
                    :options="subDistrictArr"
                    :placeholder="data.placeholder"
                    :clearable="!data.required"
                    :searchable="true"
                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                    v-model="form.formObj[data.question_code]"
                    @option:selected="handleSubDistrictSelected"
                  >
                    <template #no-options="{}">
                      ไม่พบข้อมูล
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"
                        ><ChevronDownIcon class="w-5 h-5"
                      /></span>
                    </template>
                  </v-select>
                </div>
                <div v-if="data.question_type == 'village'" tabindex="-1" :id="data.question_code">
                  <label class="form-label">
                    {{data.question_desc}}
                    <span class="text-theme-24 text-md" v-if="data.required"> *</span>
                  </label>
                  <v-select
                    :data-test="data.question_code"
                    label="village_name"
                    :options="villageArr"
                    :placeholder="data.placeholder"
                    :clearable="!data.required"
                    :searchable="true"
                    :class="{ 'custom-border-error border-theme-24 border-1 rounded-[0.5rem]': validate?.formObj?.[data.question_code]?.$error }"
                    v-model="form.formObj[data.question_code]"
                    :disabled="isView || (data.new_survey_disable ? isAddSurvey : false) || data.disable"
                    @option:selected="checkHouseDuplicate()"
                  >
                    <template #no-options="{}">
                      ไม่พบข้อมูล
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"
                        ><ChevronDownIcon class="w-5 h-5"
                      /></span>
                    </template>
                  </v-select>
                </div>
                <div v-if="(data.question_type == 'radio' || data.question_type == 'checkbox')" :class="{'border-1 border-theme-24 p-2 rounded-md' : validate.formObj?.[data.question_code]?.$error}" :id="data.question_code" tabindex="-1">
                  <label>{{ !data.is_checkbox_group && !data.edit_only ? data.question_code : null }} {{data.question_desc}} {{data.question_type == "radio" ? ' (เลือกตอบเพียง 1 ข้อ)' : data.question_type == "checkbox" ? ' (เลือกตอบได้มากกว่า 1 ข้อ)' : ''}}<span class="text-theme-24 text-md" v-if="data.required"> *</span></label>
                  <div class="gap-3 grid grid-cols-12 px-2 md:px-4 items-baseline mt-3">
                    <div class="col-span-12" :class="{'sm:col-span-6': data.is_column}" v-for="(choice, key2) in data.choices" :key="key2">
                      <div v-if="data.question_type == 'radio'">
                        <div class="mr-3">
                          <input
                            :id="`form-input-${choice.option.code}`"
                            class="form-check-input align-middle"
                            type="radio"
                            v-model="form.formObj[data.question_code]"
                            :value="choice.option"
                            :disabled="isView"
                            @click="uncheckedChoice(data.question_code, $event); handleMultiple(key, key2)"
                          >
                          <label
                            class="form-check-label align-middle"
                            :for="`form-input-${choice.option.code}`"
                          >{{choice.option.desc}}</label>
                        </div>
                        <div v-if="choice.specify_value && (form.formObj[data.question_code]?.code == choice.option.code) && choice.is_multiple_answer">
                          <div class="flex gap-2 pl-6 items-start" :class="{'mt-2': ans_idx != 0}" v-for="(ans, ans_idx) in choice.option.answers" :key="ans_idx">
                            <div class="grid grid-cols-12 gap-2 flex-grow">
                              <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                                <label v-if="spf.label">{{spf.label}}</label>
                                <div class="flex items-center">
                                  <div class="relative flex-grow">
                                    <input
                                      :type="spf.type"
                                      class="form-control"
                                      :placeholder="spf.message"
                                      v-model="ans[spf.key]"
                                      :disabled="isView || spf.disable"
                                      :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.answers?.$each?.$response?.$errors?.[ans_idx]?.[spf.key]?.length, 'pr-[62px]' : spf.type == 'number'}"
                                      :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                      @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                      @input="handleInput($event, spf_index, data, null, key2, spf, ans_idx)"
                                    >
                                    <div
                                      v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                      class="absolute right-1 top-0 h-full mt-[6px]"
                                    >
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : ans[spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="ans[spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, null, ans_idx)">
                                        <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                      </button>
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, null, ans_idx)">
                                        <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                      </button>
                                    </div>
                                  </div>
                                  <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                </div>

                                <template v-if="validate?.formObj?.[data.question_code]?.answers?.$each?.$response?.$errors?.[ans_idx]?.[spf.key]?.length">
                                  <div
                                    v-for="(error, index) in validate.formObj[data.question_code].answers.$each.$response.$errors[ans_idx][spf.key]"
                                    :key="index"
                                    class="text-theme-24 mt-1 text-sm"
                                  >
                                    {{ error.$message }}
                                  </div>
                                </template>
                              </div>
                            </div>
                            <button class="btn btn-primary bg-primary-btn border-primary-btn" :class="{'mt-[22px]':choice.specify_value.some(({label}) => label)}" @click="addMoreItem(key, key2)" v-if="ans_idx == choice.option.answers.length-1 && !isView">
                              <PlusIcon class="h-4 w-4" />
                            </button>
                            <button class="btn btn-danger bg-theme-24 border-theme-24" :class="{'mt-[22px]':choice.specify_value.some(({label}) => label)}" @click="removeItem(key, key2, ans_idx)" v-else-if="!isView">
                              <MinusIcon class="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2 pl-6" v-else-if="choice.specify_value && (form.formObj[data.question_code]?.code == choice.option.code)">
                          <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf,spf_index) in choice.specify_value" :key="spf_index">
                            <label v-if="spf.label">{{spf.label}}</label>
                            <div class="flex items-center">
                              <div class="relative flex-grow">
                                <input
                                  :type="spf.type"
                                  class="form-control"
                                  :placeholder="spf.message"
                                  v-model="form.formObj[data.question_code][spf.key]"
                                  :disabled="isView || spf.disable"
                                  :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.[spf.key]?.$error || validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid, 'pr-[62px]' : spf.type == 'number' }"
                                  :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                  @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                  @input="handleInput($event, spf_index, data, null, key2, spf)"
                                >
                                <div
                                  v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                  class="absolute right-1 top-0 h-full mt-[6px]"
                                >
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="form.formObj[data.question_code][spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2)">
                                    <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                  </button>
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2)">
                                    <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                  </button>
                                </div>
                              </div>
                              <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                            </div>

                            <template v-if="validate?.formObj?.[data.question_code]?.[spf.key]?.$error || validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid">
                              <!-- when error always invalid true-->
                              <div
                                class="text-theme-24 mt-1 text-sm"
                                v-if="validate?.formObj?.[data.question_code]?.[spf.key]?.$invalid && !validate.formObj[data.question_code][spf.key]?.isLessThan?.$invalid"
                              >
                                {{ validate.formObj[data.question_code][spf.key].required.$message }}
                              </div>
                              <div
                              v-if="validate.formObj[data.question_code][spf.key]?.isLessThan?.$invalid"
                                class="text-theme-24 mt-1 text-sm"
                              >
                                {{ validate.formObj[data.question_code][spf.key].isLessThan.$message }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="form-checkbox-custom pl-6" v-if="data.question_type == 'checkbox'">
                        <div class="mr-3">
                          <label
                            class="form-check-label align-middle flex items-baseline"
                            :for="`form-input-problem-${choice.option.code}`"
                          >
                            <div class="order-wrapper" v-if="choice.option.order">
                              <div class="order-number text-white">{{choice.option.order}}</div>
                            </div>
                            <input
                              :id="`form-input-problem-${choice.option.code}`"
                              class="form-check-input align-middle"
                              type="checkbox"
                              :value="choice.option"
                              v-model="form.formObj[data.question_code]"
                              :disabled="isView || choice.disable || choice.required"
                              @change="data.answer_limit ? handleOrder($event, data.question_code, data.answer_limit) : null; handleMultiple(key, key2)"
                              :has-order="data.answer_limit ? true : false"
                            >{{ data.is_checkbox_group && !data.edit_only ? choice.option.code +' '+ choice.option.desc : choice.option.desc}}
                          </label>
                        </div>
                        <div v-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code) && choice.is_multiple_answer">
                          <div class="flex gap-2 items-start" :class="{'mt-2': ans_idx != 0}" v-for="(ans, ans_idx) in choice.option.answers" :key="ans_idx">
                            <div class="grid grid-cols-12 gap-2 flex-grow" v-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code)">
                              <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                                <label v-if="spf.label">{{spf.label}}</label>
                                <div class="flex items-center">
                                  <div class="relative flex-grow">
                                    <input
                                      :type="spf.type"
                                      class="form-control"
                                      :placeholder="spf.message"
                                      v-model="ans[spf.key]"
                                      :disabled="isView || spf.disable"
                                      :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.answers?.[0]?.$response?.$errors?.[ans_idx]?.[spf.key].length && !isView, 'pr-[62px]' : spf.type == 'number' }"
                                      @input="handleInput($event, spf_index, data, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), key2, spf, ans_idx)"
                                      :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                      @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                    >
                                    <div
                                      v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                      class="absolute right-1 top-0 h-full mt-[6px]"
                                    >
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : ans[spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="ans[spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), ans_idx)">
                                        <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                      </button>
                                      <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), ans_idx)">
                                        <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                      </button>
                                    </div>
                                  </div>
                                  <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                                </div>

                                <template v-if="validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.answers?.[0]?.$response?.$errors?.[ans_idx]?.[spf.key]?.length && !isView">
                                  <div
                                    v-for="(error, index) in validate.formObj[data.question_code].$each.$response.$errors[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)].answers[0].$response.$errors[ans_idx][spf.key]"
                                    :key="index"
                                    class="text-theme-24 mt-1 text-sm"
                                  >
                                    {{ error.$message }}
                                  </div>
                                </template>
                              </div>
                            </div>
                            <button class="btn btn-primary bg-primary-btn border-primary-btn" :class="{'mt-[22px]':choice.specify_value.some(({label}) => label)}" @click="addMoreItem(key, key2)" v-if="ans_idx == choice.option.answers.length-1 && !isView">
                              <PlusIcon class="h-4 w-4" />
                            </button>
                            <button class="btn btn-danger bg-theme-24 border-theme-24" :class="{'mt-[22px]':choice.specify_value.some(({label}) => label)}" @click="removeItem(key, key2, ans_idx)" v-else-if="!isView">
                              <MinusIcon class="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2" v-else-if="choice.specify_value && form.formObj[data.question_code].some(({code})=> code == choice.option.code)">
                          <div class="col-span-12" :class="{'sm:col-span-6': !data.is_column}" v-for="(spf, spf_index) in choice.specify_value" :key="spf_index">
                            <label v-if="spf.label">{{spf.label}}</label>
                            <div class="flex items-center">
                              <div class="relative flex-grow">
                                <input
                                  :type="spf.type"
                                  class="form-control"
                                  :placeholder="spf.message"
                                  v-model="form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key]"
                                  :disabled="isView || spf.disable"
                                  :class="{ 'border-theme-24': validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.[spf.key]?.length && !isView, 'pr-[62px]' : spf.type == 'number' }"
                                  @input="handleInput($event, spf_index, data, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code), key2, spf)"
                                  :min="spf.type == 'number' && !spf.is_negative ? '0' : null"
                                  @keypress="handleTypeNumber(spf.type, $event, null, spf.is_decimal, spf.is_negative)"
                                >
                                <div
                                  v-if="spf.type == 'number' && !isView && !spf.is_decimal"
                                  class="absolute right-1 top-0 h-full mt-[6px]"
                                >
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px]" :class="{'!opacity-30' : form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key] <= 0 && !spf.is_negative || spf.disable}" :disabled="form.formObj[data.question_code][form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key] <= 0 && !spf.is_negative || spf.disable" @click="handleButtonIncreaseDecrease('decrease', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code))">
                                    <MinusIcon class="h-4 w-4 text-theme-24 !stroke-2.5" />
                                  </button>
                                  <button class="btn border-0 z-20 !shadow-none rounded-[5px] border-[#d1d1d1] bg-[#f1f1f1] p-[5px] ml-[2px]" :class="{'!opacity-30' : spf.disable}" :disabled="spf.disable" @click="handleButtonIncreaseDecrease('increase', data, spf, spf_index, key2, form.formObj[data.question_code].findIndex(x => x.code == choice.option.code))">
                                    <PlusIcon class="h-4 w-4 !stroke-2.5" />
                                  </button>
                                </div>
                              </div>
                              <div class="ml-2" v-if="spf.unit">{{spf.unit}}</div>
                            </div>

                            <template v-if="validate?.formObj?.[data.question_code]?.$each?.$response?.$errors?.[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)]?.[spf.key]?.length && !isView">
                              <div
                                v-for="(error, index) in validate.formObj[data.question_code].$each.$response.$errors[form.formObj[data.question_code].findIndex(x => x.code == choice.option.code)][spf.key]"
                                :key="index"
                                class="text-theme-24 mt-1 text-sm"
                              >
                                {{ error.$message }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="(validate?.formObj?.[data.question_code]?.$error && data.question_type == 'radio' && validate?.formObj?.[data.question_code]?.$model?.code == undefined) || (validate?.formObj?.[data.question_code]?.$error && data.question_code != 'address_num' && (data.question_type == 'text' || data.question_type == 'select' || data.question_type == 'number' || data.question_type == 'education' || data.question_type == 'province' || data.question_type == 'district' || data.question_type == 'subdistrict' || data.question_type == 'village')) || (data.question_type == 'checkbox' && validate?.formObj?.[data.question_code]?.$error && validate?.formObj?.[data.question_code]?.$model?.length == 0)">
                  <div
                    v-for="(error, index) in validate.formObj[data.question_code].$errors"
                    :key="index"
                    class="text-theme-24 mt-1 text-sm"
                  >
                    {{ error.$message }}
                  </div>
                </template>
                <template v-else-if="validate?.formObj?.[data.question_code]?.$error && data.question_type == 'multiple-inputs-answer'">
                  <div class="text-theme-24 mt-1 text-sm">
                    {{ validate.formObj[data.question_code].required.$message}}
                  </div>
                </template>
                <template v-if="(data.is_check_house_code ? isNotDuplicateHouseNo11 == false : null)">
                  <div
                    class="text-theme-24 mt-1 text-sm"
                  >
                    เลขรหัสประจำบ้าน 11 หลักถูกใช้งานแล้ว
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-span-12 flex items-center mt-10 justify-between"
    >
      <div>
        <button class="btn btn-secondary" @click="exitForm">ออก</button>
        <button class="btn btn-secondary ml-2" @click="skipStep" v-if="isView || isAdminManagement">ข้าม</button>
      </div>
      <button class="btn btn-primary ml-2" @click="isView || isAdminManagement ? emit('nextStepView', 1) : nextstep()" :disabled="isDisabledSubmitBtn">
        {{isView || isAdminManagement ? 'ถัดไป' : 'บันทึกและถัดไป'}}
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, ref, toRefs, computed, onMounted } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf, requiredUnless, minLength, maxLength, maxValue } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import axios from "axios";
import localForage from "localforage"
import {checkDuplicateHouse} from "@/utils/checkExist"
import RadioCustomButton from "@/components/base/radio-custom-button/index.vue";
import SurveyAlert from "@/components/survey-alert"
const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-primary m-1",
        cancelButton: "btn btn-outline-secondary m-1",
    },
    buttonsStyling: false,
});

const emit = defineEmits(["exitForm", "nextStep", "prevStep", "skipStep", "nextStepView", "editQuestion", "deleteQuestion", "updateStatus", "addQuestion"]);
const props = defineProps({
  houseData: {type: Object},
  isView: {type: Boolean, default: false},
  isDefaultHide:{type: Boolean, default: false},
  isAddSurvey: {type: Boolean, default: false},
  questionList: {type: Array},
  isAdminManagement:{type: Boolean, default: false},
  isDisabledSubmitBtn: {type: Boolean, default: false},
  updateResult: {type: Object},
  checkValidate: {type: Object}
})

const question_group1 = reactive(
  props.questionList.find(x=> x.step.step_number == 1).questions.reduce((acc,v)=>{
  if(v.header_info){
    acc = [...acc, {
      question_type: "header",
      question_desc: v.header,
      question_list: v.question_lists.map(({question_code})=> question_code),
      choices: [],
      header_info: v.header_info
    }]
  }
  return [...acc, ...v.question_lists]
  },[])
)

let villageStore = localForage.createInstance({
    name        : 'mpi-logbook',
    storeName   : 'village'
});
const provinceStore = localForage.createInstance({
  name        : 'mpi-logbook',
  storeName   : 'province'
});

const isNotSaved = ref(false)
const provinceArr = ref([]);
const districtArr = ref([]);
const subDistrictArr = ref([]);
const villageArr = ref([]);
const form = reactive({
  formObj:{
    haveHouseAddressNum: null
  }
});
function checkQuestionType(question){
  if(question.question_type === "checkbox"){
    //set default to checkbox in case choice has required property
    if(question.choices.some(({required})=>required)){
      let temp =[]
      for(let item of question.choices){
        if(item.required){
          temp.push(item.option)
        }
      }
      return temp
    }
    return []
  }else if(question.question_type === "radio" || question.question_type === "multiple-inputs-answer"){
    return {}
  }else{
    return null
  }
}
const checkMemberCount = (value) =>{
  if(value != null && value !== "" && value >= 0){
    return value <= form.formObj.member_cnt
  }
  return false
}
const getRelateResult = ({question_code, question_type, relate_question, choices}) => {
  if(props.isAdminManagement)
    return true
  if(relate_question.length == 0)
    return true
  //check current question which appear or not with a relate question have answer
  let tempQuestionAppear = []
  for(const {code, answer, type} of relate_question){
    let has_answer
    if(type == "radio"){
      has_answer = answer.includes(form.formObj[code]?.code)
    }
    else if(type == "checkbox"){
      has_answer = form.formObj[code].findIndex(x => answer.includes(x.code)) != -1 ? true : false
    }
    else if(type == "number"){//for type number answer more than 0
      has_answer = form.formObj[code] > 0
    }
    else if(type == "select"){
      has_answer = answer.includes(form.formObj[code])
    }
    else if(type == "multiple-inputs-answer"){
      has_answer = !Object.values(form.formObj[code]).every(x=>x == "" || x == null)
    }
    else{
      has_answer = form.formObj[code] ? true : false
    }
    //check current question when relate question's answer not include then clear current question's answer to default
    //checkbox required in choice, not clear this question code to []
    if(!has_answer && (
      ((question_type == "radio" || question_type == "multiple-inputs-answer") && Object.keys(form.formObj[question_code]).length > 0) || (question_type == "checkbox" && form.formObj[question_code].length > 0 && !choices.some(({required})=>required)) || (question_type == "text" || question_type == "number" || question_type == "date" || question_type == "select" || question_type == "province" || question_type == "district") && form.formObj[question_code])
    ){
      const type = checkQuestionType({question_code, question_type, relate_question, choices})
      form.formObj[question_code] = type

    }
    tempQuestionAppear = [...tempQuestionAppear, has_answer]
  }
  return tempQuestionAppear.every(elm => elm)
}


let initDataForm = question_group1.filter(({question_type}) => question_type != "header")
.reduce((a,v)=>
  {
    //init form obj from question list and map new input in side question type = multiple-inputs-group
    let newObj = {}
    if(v.question_type == "multiple-inputs-group"){
      let temp = v.choices.reduce((acc,val)=>({...acc, [val.question_code]: checkQuestionType(val)}),{})
      a = {...a, ...temp}
    }
    else{
      newObj = {
        [v.question_code]: checkQuestionType(v)
      }
    }
    return {...a, ...newObj}
  }
,{})
form.formObj = Object.assign(form.formObj, initDataForm)

const rules = computed(()=>{
  let hasRequired = question_group1.filter(e=>e.required || e.choices.some(({specify_value, required}) => specify_value || required) || e.required_count_member || e.max_length_message)
  let requiredFieldObj = hasRequired.reduce((a,v)=>{
    let requiredField = {}
    if(v.question_type == "radio"){
      let set_required = {}
      let specify_value = {}
      let multiple_answers = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required),
        }
      }

      if(v.choices.some(({specify_value})=>specify_value)){
        let temp_is_multiple = []
        let temp_is_not_multiple = []
        let temp_specify_value = v.choices.reduce((acc, val)=>{
          if(val.specify_value){
            for(let i of val.specify_value){
              if(val.is_multiple_answer){
                let idx = temp_is_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_multiple = [...temp_is_multiple, i]
              }
              else{
                let idx = temp_is_not_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_not_multiple = [...temp_is_not_multiple, i]
              }
            }
          }
          return {...acc, is_multiple_answer: temp_is_multiple, is_not_multiple_answer: temp_is_not_multiple}
        },{})

        let validation = {}
        let array_validation = {}
        if(temp_specify_value?.is_multiple_answer?.length && form.formObj[v.question_code].hasOwnProperty("answers")){
          for(let i of temp_specify_value.is_multiple_answer){
            let max_value_temp ={}
            if(i.is_max_value && temp_specify_value.is_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }

            array_validation = {
              ...array_validation,
              [i.key]: {
                required: helpers.withMessage(i.message, requiredIf(()=>form.formObj[v.question_code]?.answers?.length)),
                ...max_value_temp
              }
            }
          }
          multiple_answers = {
            answers: {
              $each: helpers.forEach({
                ...array_validation
              })
            }
          }
        }
        if(temp_specify_value?.is_not_multiple_answer?.length){
          for(let i of temp_specify_value.is_not_multiple_answer){
            let max_value_temp = {}
            if(i.is_max_value && temp_specify_value.is_not_multiple_answer.length > 1 && form.formObj[v.question_code].hasOwnProperty(i.key)){
              const value_input = temp_specify_value.is_not_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            validation = {...validation,
              [i.key]: {
                required: helpers.withMessage(i.message, requiredIf(()=>form.formObj[v.question_code].hasOwnProperty(i.key))),
                ...max_value_temp
              }
            }
          }
        }
        specify_value = {
          ...validation
        }
      }

      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...specify_value,
          ...multiple_answers
        }
      }
    }
    else if(v.question_type == "checkbox"){
      let set_required = {}
      let specify_value = {}
      let multiple_answers = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required),
        }
      }
      if(v.choices.some(({specify_value})=>specify_value)){
        let temp_is_multiple = []
        let temp_is_not_multiple = []
        let temp_specify_value = v.choices.reduce((acc, val)=>{
          if(val.specify_value){
            for(let i of val.specify_value){
              if(val.is_multiple_answer){
                let idx = temp_is_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_multiple = [...temp_is_multiple, i]
              }
              else{
                let idx = temp_is_not_multiple.findIndex(({key})=>key == i.key)
                if(idx == -1)
                  temp_is_not_multiple = [...temp_is_not_multiple, i]
              }
            }
          }
          return {...acc, is_multiple_answer: temp_is_multiple, is_not_multiple_answer: temp_is_not_multiple}
        },{})

        let validation = {}
        let array_validation = {}
        if(temp_specify_value?.is_multiple_answer?.length){
          for(let i of temp_specify_value.is_multiple_answer){
            let max_value_temp ={}
            if(i.is_max_value && temp_specify_value.is_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }

            let choice_required_max_value = {}
            if(i.required_count_member){
              choice_required_max_value = {
                maxValue: helpers.withMessage(
                  "จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน",
                  checkMemberCount
                )
              }
            }
            array_validation = {
              ...array_validation,
              [i.key]: {
                required: helpers.withMessage(i.message, v.relate_question.length ? requiredUnless(()=> !getRelateResult(v)) : required),
                ...choice_required_max_value,
                ...max_value_temp
              }
            }
          }
          multiple_answers = {
            answers: {
              $each: helpers.forEach({
                ...array_validation
              })
            }
          }
        }
        if(temp_specify_value?.is_not_multiple_answer?.length){
          for(let i of temp_specify_value.is_not_multiple_answer){
            let max_value_temp = {}
            if(i.is_max_value && temp_specify_value.is_not_multiple_answer.length > 1){
              const value_input = temp_specify_value.is_not_multiple_answer.filter(x=>x.key != "other" && x.type != "text") //always at least 2 keys
              let label = "ก่อนหน้า"
              if(value_input[0].label)
                label = value_input[0].label
              max_value_temp = {
                isLessThan: helpers.withMessage(`จำนวนต้องไม่เกินจำนวน${label}`, (val,obj) => {
                  return val <= obj[value_input[0].key]
                })
              }
            }
            let choice_required_max_value = {}
            if(i.required_count_member){
              choice_required_max_value = {
                maxValue: helpers.withMessage(
                  "จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน",
                  checkMemberCount
                )
              }
            }

            validation = {...validation,
              [i.key] :{// this question need answer from relate question then required choice when function getRelateResult (has answer from relate question) return true
                required: helpers.withMessage(i.message, v.relate_question.length ? requiredUnless(()=> !getRelateResult(v)
                ) : required),
                ...choice_required_max_value,
                ...max_value_temp
              }
            }
          }
        }

        specify_value = {
          $each: helpers.forEach({
            ...validation,
            ...multiple_answers
          })
        }
      }
      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...specify_value
        }
      }
    }
    else if((v.question_type == "text" || v.question_type == "date" || v.question_type == "number" || v.question_type == "education" || v.question_type == "province" || v.question_type == "district" || v.question_type == "subdistrict" || v.question_type == "village" || v.question_type == "select") && v.question_code != "address_num"){
      let set_required = {}
      let required_max_length = {}
      let required_count_member = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(v.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required)
        }
      }
      if(v.max_length_message){
        required_max_length = {
          minLength: helpers.withMessage(
            v.max_length_message,
            minLength(v.max_length)
          ),
          maxLength: helpers.withMessage(
            v.max_length_message,
            maxLength(v.max_length)
          ),
        }
      }

      if(v.required_count_member){
        required_count_member = {
          maxValue: helpers.withMessage(
            "จำนวนต้องไม่เกินจำนวนสมาชิกในครัวเรือน",
            maxValue(form.formObj.member_cnt)
          )
        }
      }
      requiredField = {
        [v.question_code]: {
          ...set_required,
          ...required_max_length,
          ...required_count_member
        }
      }
    }
    else if(v.question_type == "multiple-inputs-group"){
      for(let i of v.choices){
        requiredField = {...requiredField,
          [i.question_code] :{
            required: helpers.withMessage(i.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required)
          }
        }
      }
    }
    else if(v.question_type == "multiple-inputs-answer"){
      let validation = {
        required: helpers.withMessage(v.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required),
      }
      for(let i of v.choices){
        validation = {...validation,
          [i.key] :{
            required: helpers.withMessage(v.required_message, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required)
          }
        }
      }
      requiredField = {
        [v.question_code]: {
          ...validation
        }
      }
    }
    else if(v.question_code == "address_num"){//for address_num condition
      let set_required = {}
      if(v.required){
        set_required = {
          required: helpers.withMessage(`กรุณาระบุ`, v.relate_question.length ? requiredIf(()=> getRelateResult(v)) : required)
        }
      }
      requiredField = {
        haveHouseAddressNum: {
          ...set_required
        },
        address_num:{
          required: helpers.withMessage(v.required_message, requiredIf(() => form.formObj.haveHouseAddressNum == 'มี')),
        }
      }
    }
    return (
      {
        ...a,
        ...requiredField
      }
    )
  }
  ,{})

  return {
    formObj:{
      ...requiredFieldObj
    }
  }
})

const validate = useVuelidate(rules, toRefs(form));

const store = useStore();
const token = store.getters["userAuth/getToken"];
const onlineComputed = computed(()=> store.getters["network/isOnline"])
const isCheckingDuplicateHouseNo11 = ref(null)
const isNotUniqueHouse = ref(null)
const isNotDuplicateHouseNo11 = ref(null)
const isCheckingUniqueHouse = ref(null)
const disabledAmphur = ref(false);
const disabledTumbol = ref(false);
const disableProvince = ref(false)
const headers = {
  Authorization: `Bearer ${store.getters["userAuth/getToken"]}`,
  "Content-Type": `application/json; charset=utf-8`,
};

const prepareData = () => {
  console.log(form);
  var data = form;
  return data;
};

const nextstep = (isCheckValidate = null) => {
  isNotSaved.value = false
  validate.value.$touch()
  if (validate.value.$invalid || isNotUniqueHouse.value == false || isNotDuplicateHouseNo11.value == false || isCheckingDuplicateHouseNo11.value || isCheckingUniqueHouse.value) {
    //check from validate object
    for (let key of Object.keys(validate.value.formObj)) {
      // Remove special properties
      if (key.includes("$")) continue
      if (validate.value.formObj[key].$error) {
        document.getElementById(key).focus()
        break;
      }
    }
    if(isNotUniqueHouse.value == false || isCheckingUniqueHouse.value)
      document.getElementById("address_num").focus()
    if(isNotDuplicateHouseNo11.value == false || isCheckingDuplicateHouseNo11.value)
      document.getElementById("house_number_11").focus()
    return;
  }
  if(!isCheckValidate){
    emit("nextStep", form.formObj, 1);
  }
  else{
    isNotSaved.value = true
  }
};

const setValidate = (field, isRequired) =>{
  if(isRequired){
    validate.value.formObj[field].$touch()
  }
  return false
}


const checkQuestionAnswer = (data) =>{
  if(props.isAdminManagement){
    return true
  }
  if(!data.question_desc){//no header text in case publish survey
    return false
  }

  //each question code in data check below if some true then show header
  return data.some((item)=>{
    //get idx of this question to get relate_question to check condition to show question
    //check condition with the same condition to show question in dom
    const idx = question_group1.findIndex(x => x.question_code == item)
    return getRelateResult(question_group1[idx])
  })
}

const handleAcceptInputType = (data) => {
  if(data.question_type == 'text' && data.is_only_number){
    form.formObj[data.question_code] = form.formObj[data.question_code].replace(/[^\d]/g, "");
  }
  else if(data.question_type == 'text' && data.is_only_alphabet){
    form.formObj[data.question_code] = form.formObj[data.question_code].replace(/[^A-Za-z\u0E00-\u0E4F\s]|\u0E3F/g, "");
  }
  return;
};


const exitForm = () => {
  emit("exitForm");
};
function prevStep() {
  emit("prevStep", 1);
}
const skipStep= () => {
  emit('skipStep', 1)
}

function handleOrder(event, question, limit){
  const q_idx = question_group1.findIndex(x=>x.question_code == question)

  if(event.target.checked == false){
    const ch_idx = question_group1[q_idx].choices.findIndex(x=>x.option.code == event.target._value.code)
    question_group1[q_idx].choices[ch_idx].option.order = null
  }
  if(form.formObj[question].length <= limit){
    for(let i = 0 ; i < form.formObj[question].length; i++){
      form.formObj[question][i].order = i+1
    }
  }
  if(form.formObj[question].length >= limit){
    question_group1[q_idx].choices.map((item)=>{
      if(!form.formObj[question].some(({code})=>item.option.code == code))
        item.disable = true
    })
  }
  else{
    question_group1[q_idx].choices.map((item)=>item.disable = false)
  }
}

watch(()=> props.houseData,(val)=>{
    const dataEditGeneral = JSON.parse(JSON.stringify(props.houseData))
    Object.assign(form.formObj, {
      haveHouseAddressNum: dataEditGeneral.address_num ? 'มี' : 'ไม่มี',
      address_num: dataEditGeneral.address_num ?? null,
      house_number_11 : dataEditGeneral.house_number_11 ?? null,
      soi: dataEditGeneral.soi ?? null,
      road: dataEditGeneral.road ?? null,
      postcode : dataEditGeneral.postcode ?? null,
      sector : dataEditGeneral.sector ?? null,
      township: dataEditGeneral.township ?? {},
    })
    if(dataEditGeneral.province_ID){
      form.formObj.provinceSelected = {
        province_name: dataEditGeneral.province_name,
        _id: {
          province_ID: dataEditGeneral.province_ID
        },
      };
    }
    if(dataEditGeneral.amphur_ID){
      form.formObj.districtSelected = {
        amphur_name: dataEditGeneral.amphur_name,
        province_name: dataEditGeneral.province_name,
        _id: {
          amphur_ID: dataEditGeneral.amphur_ID,
          province_ID: dataEditGeneral.province_ID
        },
      }
    }
    if(dataEditGeneral.tambol_ID){
      form.formObj.subDistrictSelected = {
        _id: {
          province_ID: dataEditGeneral.province_ID,
          amphur_ID: dataEditGeneral.amphur_ID,
          tumbol_ID: dataEditGeneral.tambol_ID
        },
        province_name: dataEditGeneral.province_name,
        amphur_name: dataEditGeneral.amphur_name,
        tumbol_name: dataEditGeneral.tambol_name,
      }
    }
    if(dataEditGeneral.village_ID){
      form.formObj.villageSelected = {
        _id: {
            province_ID: dataEditGeneral.province_ID,
            amphur_ID: dataEditGeneral.amphur_ID,
            tumbol_ID: dataEditGeneral.tambol_ID,
            village_ID: dataEditGeneral.village_ID
        },
        province_name: dataEditGeneral.province_name,
        amphur_name: dataEditGeneral.amphur_name,
        tumbol_name: dataEditGeneral.tambol_name,
        village_name: dataEditGeneral.village_name
      }
    }
    if(val.questionnaires){
      const dataEditSurvey = JSON.parse(JSON.stringify(props.houseData?.questionnaires))
      for(const item of Object.keys(form.formObj)){
        for (const [key, value] of Object.entries(dataEditSurvey)) {
          if((item == key) && (value || typeof value == "number")){
            form.formObj[key] = value
            const q_idx = question_group1.findIndex(x=>x.question_code == key)
            if(question_group1[q_idx]?.question_type == "checkbox"){
              form.formObj[key].forEach((elm, index, arr) => {
                const idx = question_group1[q_idx].choices.findIndex(x => x.option.code == elm.code);
                if(idx != -1){
                  for(let i = 0 ; i < question_group1[q_idx].choices[idx]?.specify_value?.length; i++){
                    if(question_group1[q_idx].choices[idx].is_multiple_answer){
                      question_group1[q_idx].choices[idx].option.answers = elm.answers
                    }
                    else{
                      question_group1[q_idx].choices[idx].option[question_group1[q_idx].choices[idx].specify_value[i].key] = elm[question_group1[q_idx].choices[idx].specify_value[i].key]
                    }
                    //check relate key to disabled next input if answer 0
                    if(question_group1[q_idx].choices[idx].specify_value[i].key != "other" && question_group1[q_idx].choices[idx]?.specify_value?.length > 1){
                      if(elm[question_group1[q_idx].choices[idx].specify_value[i].key] == 0 && question_group1[q_idx].choices[idx].specify_value[i+1]){
                        question_group1[q_idx].choices[idx].specify_value[i+1]["disable"] = true
                      }
                      else if(question_group1[q_idx].choices[idx].specify_value[i+1]){
                        question_group1[q_idx].choices[idx].specify_value[i+1]["disable"] = false
                      }
                    }
                  }
                  //check for question with limit answer
                  if(question_group1[q_idx].answer_limit){
                    question_group1[q_idx].choices[idx].option.order = elm.order
                    if(form.formObj[key].length >= question_group1[q_idx].answer_limit){
                      question_group1[q_idx].choices.map((item)=>{
                        if(!form.formObj[key].some(({code})=>item.option.code == code))
                          item.disable = true
                      })
                    }
                    else{
                      question_group1[q_idx].choices.map((item)=>item.disable = false)
                    }
                  }
                  arr[index] = question_group1[q_idx].choices[idx].option
                }
              });
            }
            else if(question_group1[q_idx]?.question_type == "radio"){
              if(form.formObj[key] ? Object.keys(form.formObj[key]).length > 0 : false){
                const idx = question_group1[q_idx].choices.findIndex(x => x.option.code == form.formObj[key].code);
                if(idx != -1){
                  for(let i = 0 ; i < question_group1[q_idx].choices[idx]?.specify_value?.length; i++){
                    if(question_group1[q_idx].choices[idx].is_multiple_answer){
                      question_group1[q_idx].choices[idx].option.answers = form.formObj[key].answers
                    }
                    else{
                      question_group1[q_idx].choices[idx].option[question_group1[q_idx].choices[idx].specify_value[i].key] = form.formObj[key][question_group1[q_idx].choices[idx].specify_value[i].key]
                    }
                  }
                  form.formObj[key] = question_group1[q_idx].choices[idx].option
                }
              }
            }
          }
        }
      }
    }
})

const handleInput = (evt, curr_input_index, question, ans_idx, choice_idx, current_input, mul_ans_idx) =>{
  const q_idx = question_group1.findIndex(x => x.question_code == question.question_code)
  if(question_group1[q_idx].choices[choice_idx].specify_value.length <= 1)
    return ;
  for(let i = 0 ; i < question_group1[q_idx].choices[choice_idx].specify_value.length; i++){
    if(i > 0 && question_group1[q_idx].choices[choice_idx].specify_value[i].type == "number" && current_input.type == "number" && current_input.key != "other" && i > curr_input_index){
      if(!question_group1[q_idx].choices[choice_idx].is_multiple_answer){
        if(evt.target.value && evt.target.value == 0){
          question_group1[q_idx].choices[choice_idx].specify_value[i].disable = true
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
          else{
            form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
        }else{
          question_group1[q_idx].choices[choice_idx].specify_value[i].disable = false
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
          else{
            form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
        }
      }
      else{
        if(evt.target.value && evt.target.value == 0){
          question_group1[q_idx].choices[choice_idx].specify_value[i].disable = true
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
          else{
            form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
          }
        }else{
          question_group1[q_idx].choices[choice_idx].specify_value[i].disable = false
          if(question.question_type == "checkbox"){
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
          else{
            form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
          }
        }
      }
    }
  }
}
const uncheckedChoice = (name,evt) =>{
  if(form.formObj[name]?.code == evt.target._value?.code){
    form.formObj[name] = {}
  }
}
onMounted(async () => {
  // console.log(store.getters["userAuth/getUserProvinceCode"]);
  if(onlineComputed.value){
    getProvince()
  }else{
    await villageStore.getItem("options").then((value)=>{
      form.formObj.provinceSelected = {
        province_name: value[0].province_name,
        _id: {
          province_ID: value[0]._id.province_ID,
        },
      };
       form.formObj.districtSelected = {
        amphur_name: value[0].amphur_name,
        _id: {
          amphur_ID: value[0]._id.amphur_ID,
        },
      };
      form.formObj.subDistrictSelected = {
        tumbol_name: value[0].tumbol_name,
        _id: {
          tumbol_ID: value[0]._id.amphur_ID
        },
      };
      villageArr.value = value
    })
    await provinceStore.getItem("sector").then((value)=>{
      if(value)
        form.formObj.sector = value
    })
    await provinceStore.getItem("zipcode").then((value)=>{
      if(value)
        form.formObj.postcode = value
    })
  }
});

const getProvince = () => {
  axios
    .get(`${process.env.VUE_APP_BASE_API}refadms/`, {
      headers
    })
    .then((response) => {
      if (response.status === 200) {
        provinceArr.value = response.data.sort((a, b) =>
          a.province_name.localeCompare(b.province_name,'th')
        );
        if(store.getters["userAuth/getUserProvinceCode"]) {
          let prov_data = response.data.find(x=> x._id.province_ID == store.getters["userAuth/getUserProvinceCode"])
          if(prov_data){
            form.formObj.provinceSelected = prov_data
            disableProvince.value = true
          }
        }
      } else {
        console.log(response.status.statusText);
      }
    });
}

const handleProvinceSelected = (province_data) => {
  form.formObj.districtSelected = null;
  form.formObj.subDistrictSelected = null;
  form.formObj.villageSelected = null;
  form.formObj.postcode = null;
}

const getDistrict = (province_data) => {
  var provinceid = parseInt(province_data._id.province_ID);
  try {
    axios
      .get(`${process.env.VUE_APP_BASE_API}refadms/`, {
        params: {
          prov: provinceid,
        },
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          districtArr.value = response.data.sort((a, b) =>
            a.amphur_name.localeCompare(b.amphur_name,'th')
          );
          if(store.getters["userAuth/getUserAmphurCode"].length == 4){
            let amp_data = response.data.find(x=> x._id.amphur_ID == store.getters["userAuth/getUserAmphurCode"])
            if(amp_data){
              form.formObj.districtSelected = amp_data
              disabledAmphur.value = true;
            }
          }
        } else {
          console.log(response.status.statusText);
        }
      });
  } catch (error) {
    console.log(error.message);
  }
};

const handleDistrictSelected = (district_data) => {
  form.formObj.subDistrictSelected = null;
  form.formObj.villageSelected = null;
  form.formObj.postcode = null;
}

const getSubDistrict = (district_data) => {
  var districtid = district_data._id.amphur_ID;
  try {
    axios
      .get(`${process.env.VUE_APP_BASE_API}refadms/`, {
        params: {
          prov: district_data._id.province_ID,
          amp: districtid,
        },
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          subDistrictArr.value = response.data.sort((a, b) =>
            a.tumbol_name.localeCompare(b.tumbol_name,'th')
          );
          if(store.getters["userAuth/getUserTumbolCode"].length == 6){
            let tam_data = response.data.find(x=> x._id.tumbol_ID == store.getters["userAuth/getUserTumbolCode"])
            if(tam_data){
              form.formObj.subDistrictSelected = tam_data
              disabledTumbol.value = true;
            }
          }
        } else {
          console.log(response.status.statusText);
        }
      });
  } catch (error) {
    console.log(error.message);
  }
};
const handleSubDistrictSelected = (tumbol_data) => {
  form.formObj.villageSelected = null;
  form.formObj.postcode = null;
}

const getVillage = (tumbol_data) => {
  var tumbolid = tumbol_data._id.tumbol_ID;
  try {
    axios
      .get(`${process.env.VUE_APP_BASE_API}refadms/`, {
        params: {
          prov: tumbol_data._id.province_ID,
          amp: tumbol_data._id.amphur_ID,
          tam: tumbolid,
        },
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          villageArr.value = response.data.sort((a, b) =>
            a.village_name.localeCompare(b.village_name, undefined, { numeric: true })
          );
        } else {
          console.log(response.status.statusText);
        }
      });
  } catch (error) {
    console.log(error.message);
  }
};

const getSector = (province_data) => {
  var provinceid = parseInt(province_data._id.province_ID);
  try {
    axios
      .get(`${process.env.VUE_APP_BASE_API}region/${provinceid}`, {
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          if(response.data.region_name)
            form.formObj.sector = response.data.region_name
        } else {
          console.log(response.status.statusText);
        }
      });
  } catch (error) {
    console.log(error.message);
  }
};
const getZipcode = (tumbol_data) => {
  var tumbolid = tumbol_data._id.tumbol_ID;
  try {
    axios
      .get(`${process.env.VUE_APP_BASE_API}zipcode/${tumbolid}`, {
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          if(response.data.ZIPCODE)
            form.formObj.postcode = response.data.ZIPCODE
        } else {
          console.log(response.status.statusText);
        }
      });
  } catch (error) {
    console.log(error.message);
  }
};

watch(()=> form.formObj.provinceSelected, () => {
  if(onlineComputed.value){
    if (form.formObj.provinceSelected) {
      getDistrict(form.formObj.provinceSelected);
      getSector(form.formObj.provinceSelected)
    } else {
      districtArr.value = [];
      subDistrictArr.value = [];
      villageArr.value = [];
    }
  }
})
watch(()=> form.formObj.districtSelected, () => {
  if(onlineComputed.value){
    if (form.formObj.districtSelected) {
      getSubDistrict(form.formObj.districtSelected);
    } else {
      subDistrictArr.value = [];
      villageArr.value = [];
    }
  }
})
watch(()=> form.formObj.subDistrictSelected, () => {
  if(onlineComputed.value){
    if (form.formObj.subDistrictSelected) {
      getVillage(form.formObj.subDistrictSelected);
      getZipcode(form.formObj.subDistrictSelected)
    } else {
      villageArr.value = [];
    }
  }
})
watch(()=> form.formObj.haveHouseAddressNum, (val) => {
  if(val == "ไม่มี"){
    isNotUniqueHouse.value = null
    form.formObj.address_num = null
  }
})


const checkHouseDuplicate = async () =>{
  if((form.formObj.address_num && form.formObj.address_num != "-" && form.formObj.villageSelected) && onlineComputed.value){
    isCheckingUniqueHouse.value = true
    const result = await checkDuplicateHouse(form.formObj.address_num, form.formObj.villageSelected._id.village_ID)
    if(result.data.length){
      isCheckingUniqueHouse.value = false
      //for edit mode check _id if found then not duplicate
      if(result.data.some(x => x._id === props.houseData?._id)){
        isNotUniqueHouse.value = true
      }
      else{
        isNotUniqueHouse.value = false
      }
    }
    else{
      isCheckingUniqueHouse.value = false
      isNotUniqueHouse.value = true
    }
  }
  else{
    isNotUniqueHouse.value = null
  }
}

const checkDuplicateHouseNo = async () => {
  if(form.formObj.house_number_11.length == 11 && !isNaN(form.formObj.house_number_11) && onlineComputed.value){
    isCheckingDuplicateHouseNo11.value = true
    const result = await checkDuplicateHouse(null, null, form.formObj.house_number_11)
    if(result.data.length){
      isCheckingDuplicateHouseNo11.value = false
      if(result.data.some(x => x._id === props.houseData?._id)){
        isNotDuplicateHouseNo11.value = true
      }
      else{
        isNotDuplicateHouseNo11.value = false
      }
    }
    else{
      isCheckingDuplicateHouseNo11.value = false
      isNotDuplicateHouseNo11.value = true
    }
  }
  else{
    isNotDuplicateHouseNo11.value = null
    isCheckingDuplicateHouseNo11.value = null
  }
}
const updateStatusChange = (evt, data) => {
  swalCustom
  .fire({
      text: `ยืนยัน ${evt.target.checked ? `เปิดการใช้งาน` : `ปิดการใช้งาน`}`,
      icon: "info",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,
  })
  .then((result) => {
    if (result.isConfirmed) {
      emit("updateStatus", data)
    }
    if(result.dismiss === Swal.DismissReason.cancel){
      evt.target.checked = !evt.target.checked
    }
  });
}

const handleTypeNumber = (type, event, have_max_length = null, is_decimal = null, is_negative = null) => {
  if(type != "number")
    return;
  let max_length_value = false,
  negative_value = true,
  decimal_value = true
  if(have_max_length){
    max_length_value = event.target.value.length == have_max_length
  }
  if(is_negative){
    negative_value = event.charCode != 45
  }
  if(is_decimal){
    event.target.setAttribute("step", "any")
    decimal_value = event.charCode != 46
  }
  return ((event.charCode != 8 && event.charCode == 0) || (negative_value && decimal_value && !(event.charCode >= 48 && event.charCode <= 57)) || max_length_value) && event.preventDefault()
}

const handleMultiple = (question_idx, choice_idx) => {
  if(Array.isArray(question_group1[question_idx].choices[choice_idx].option?.answers)){
    if(question_group1[question_idx].choices[choice_idx].option.answers.length == 0){
      let obj = {}
      for(let k of question_group1[question_idx].choices[choice_idx].specify_value){
        obj = {
          ...obj,
          [k.key]: null
        }
      }
      question_group1[question_idx].choices[choice_idx].option.answers.push(obj)
    }
  }
  return;
}

const addMoreItem = (question_idx, choice_idx) =>{
  let obj = {}
  for(let k of question_group1[question_idx].choices[choice_idx].specify_value){
    obj = {
      ...obj,
      [k.key]: null
    }
  }
  question_group1[question_idx].choices[choice_idx].option.answers.push(obj)
}

const removeItem = (question_idx, choice_idx, item_idx) =>{
  question_group1[question_idx].choices[choice_idx].option.answers.splice(item_idx, 1)
}

const handleButtonIncreaseDecrease = (action, question, current_input, curr_input_index, choice_idx, ans_idx, mul_ans_idx) =>{
  const q_idx = question_group1.findIndex(x => x.question_code == question.question_code)
  if(curr_input_index >= 0){
    if(question.question_type == "checkbox"){
      if(action == "increase"){
        if(mul_ans_idx >= 0){
          if(typeof form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key]++
        }else{
          if(typeof form.formObj[question.question_code][ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code][ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx][current_input.key]++
        }
      }else{
        if(mul_ans_idx >= 0){
          if(form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key]--
        }
        else{
          if(form.formObj[question.question_code][ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code][ans_idx][current_input.key]--
        }
      }
    }
    else{//radio
      if(action == "increase"){
        if(mul_ans_idx >= 0){
          if(typeof form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] != 'number')
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key]++
        }
        else{
          if(typeof form.formObj[question.question_code][current_input.key] != 'number')
            form.formObj[question.question_code][current_input.key] = 0
          else
            form.formObj[question.question_code][current_input.key]++
        }
      }else{
        if(mul_ans_idx >= 0){
          if(form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key] = 0
          else
            form.formObj[question.question_code]["answers"][mul_ans_idx][current_input.key]--
        }
        else{
          if(form.formObj[question.question_code][current_input.key] < 1 && !current_input.is_negative)
            form.formObj[question.question_code][current_input.key] = 0
          else
            form.formObj[question.question_code][current_input.key]--
        }
      }
    }

    if(question_group1[q_idx].choices[choice_idx].specify_value.length <= 1)
      return ;
    for(let i = 0 ; i < question_group1[q_idx].choices[choice_idx].specify_value.length; i++){
      if(i > 0 && question_group1[q_idx].choices[choice_idx].specify_value[i].type == "number" && current_input.type == "number" && current_input.key != "other" && i > curr_input_index){
        if(!question_group1[q_idx].choices[choice_idx].is_multiple_answer){
          if(form.formObj[question.question_code][ans_idx][current_input.key] == 0){
            question_group1[q_idx].choices[choice_idx].specify_value[i].disable = true
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
            else{
              form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
          }else{
            question_group1[q_idx].choices[choice_idx].specify_value[i].disable = false
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
            else{
              form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
          }
        }
        else{
          if(form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][current_input.key] == 0){
            question_group1[q_idx].choices[choice_idx].specify_value[i].disable = true
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
            else{
              form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = 0
            }
          }else{
            question_group1[q_idx].choices[choice_idx].specify_value[i].disable = false
            if(question.question_type == "checkbox"){
              form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code][ans_idx]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
            else{
              form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] = form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] ? form.formObj[question.question_code]["answers"][mul_ans_idx][question_group1[q_idx].choices[choice_idx].specify_value[i].key] : null
            }
          }
        }
      }
    }
  }
  else{
    if(current_input){//multiple-answer-inputs question
      if(action == "increase"){
        if(typeof form.formObj[question.question_code][current_input.key] != 'number')
          form.formObj[question.question_code][current_input.key] = 0
        else
          form.formObj[question.question_code][current_input.key]++
      }
      else{
        if(form.formObj[question.question_code][current_input.key] < 1 && !current_input.is_negative)
          form.formObj[question.question_code][current_input.key] = 0
        else
          form.formObj[question.question_code][current_input.key]--
      }
    }else{//input number question
      if(action == "increase"){
        if(typeof form.formObj[question.question_code] != 'number')
          form.formObj[question.question_code] = 0
        else
          form.formObj[question.question_code]++
      }else{
        if(form.formObj[question.question_code] < 1 && !question.is_negative)
          form.formObj[question.question_code] = 0
        else
          form.formObj[question.question_code]--
      }

    }
  }
}
const addEvent = (curr_header) => {
  emit("addQuestion", curr_header)
}

watch(()=> props.updateResult, (val)=>{
  if(val){
    let input = document.getElementById(`input-check-switch-${val.question_code}`)
    if(val.status == 'active'){
      input.checked = true
    }
    else{
      input.checked = false
    }
  }
})


watch(()=> props.checkValidate, (val) => {
  if(val?.step?.step_number == 1){
    setTimeout(()=> nextstep(true), 500)
  }
})

const createDebounce = () => {
    let timeout = null;
    return function (fnc) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fnc();
      }, 1000);
    };
}

const debounce = createDebounce()
</script>