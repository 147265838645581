<template>
  <!-- <div :id="chartId">
    <canvas ref="chartRef" class="lg:p-[3rem] mb-3 lg:mb-0" />
    <div class="customLegend">

    </div>
  </div> -->
  <div class="w-full" :id="chartId" v-if="!legendOnRight">
    <canvas ref="chartRef" class="mb-3 lg:mb-0 h-full" :height="height"/>
    <div class="customLegend">

    </div>
  </div>
  <div class="grid grid-cols-12 sm:gap-3 xl:gap-6" v-else>
    <div class="col-span-12 medium:col-span-6 large:col-span-12 extra:col-span-6 my-auto p-5 medium:p-3 extra:p-0">
      <canvas ref="chartRef" :height="height"/>
    </div>
    <div class="col-span-12 medium:col-span-6 large:col-span-12 extra:col-span-6 pt-0 my-auto" :id="chartId">
      <div class="customLegend px-5 medium:px-3 large:px-2 large:ml-2 mt-5 large:mt-0"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject, watch } from 'vue'
import { helper as $h } from '@/utils/helper'
import Chart from 'chart.js/auto'
import {millionShortNum,millionShortString,globalNumDecFixed} from "@/utils/format-number"

const props = defineProps(
  {
    type: {
      type: String,
      required: true,
      default: 'line',
      validator: value => {
        return ['line', 'pie', 'doughnut', 'bar', 'horizontalBar'].indexOf(value) !== -1
      }
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    refKey: {
      type: String,
      default: null
    },
    chartId: {
      type: String,
      required: true,
      default: "chartBox"
    },
    legendOnRight:{
      type: Boolean,
      default: false
    },
    sumTotal:{
      type: Number,
      default: 0
    }

  }
)

const chartRef = ref()
const init = () => {
  const canvas = chartRef.value?.getContext('2d')
  Chart.defaults.font.family = "Kanit"
  Chart.defaults.plugins.legend.labels.usePointStyle = true
  const chart = new Chart(canvas, {
    type: props.type,
    data: $h.toRaw(props.data),
    options: props.options
  })
  watch(props, () => {
    chart.data = $h.toRaw(props.data)
    chart.options = props.options
    chart.update()
    //add custom legend
    const chartBox = document.getElementById(props.chartId)
    const div = chartBox.querySelector(".customLegend")
    const ul = document.createElement('UL')
    chart.legend.legendItems.forEach((dataset, index)=>{
      const text = dataset.text
      const datasetIndex = dataset.index
      const bgColor = dataset.fillStyle
      const bColor = dataset.strokeStyle
      const fontColor = dataset.fontColor
      if(chart.data.datasets[0].data[index]){
        const li = document.createElement('LI')
        li.setAttribute('title', text)
        const spanBox = document.createElement('SPAN')
        spanBox.classList.add("legend-type")
        spanBox.style.borderColor = bColor
        spanBox.style.backgroundColor = bgColor

        const p = document.createElement('P')
        p.classList.add("truncate")
        p.classList.add("mr-3")
        const textNode = document.createTextNode(text)
        li.onclick = (click) => {
          chart.toggleDataVisibility(datasetIndex)
          const element = click.target.parentNode
          element.classList.toggle('fade')
          chart.update()
        }

        ul.appendChild(li)
        li.appendChild(spanBox)
        li.appendChild(p)
        p.appendChild(textNode)
        const valueSpan = document.createElement('span')
        valueSpan.classList.add("ml-auto")
        valueSpan.classList.add("legend-value")
        let percent = (chart.data.datasets[0].data[index] * 100) / props.sumTotal
        if(Math.round(percent) !== percent) {
          percent = percent.toFixed(2)
        }
        valueSpan.appendChild(document.createTextNode(`${percent}%`))
        li.appendChild(valueSpan)
      }
    })
    // chartBox.appendChild(div)
    div.replaceChildren(ul)
  })
  // Attach ChartJs instance
  chartRef.value.instance = chart
}

const setSize = () => {
  if (props.width) {
    cash(chartRef.value).attr({
      width: props.width
    })
  }

  if (props.height) {
    cash(chartRef.value).attr({
      height: props.height
    })
  }
}

const bindInstance = () => {
  if (props.refKey) {
    const bind = inject(`bind[${props.refKey}]`)
    if (bind) {
      bind(chartRef.value)
    }
  }
}

onMounted(() => {
  bindInstance()
  setSize()
  init()
})

</script>
