<template>
    <a href="#">
        <div class="px-5 py-3 flex items-center border-b-1">
            <div class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden">
                <img :src="newsImage">
            </div>
            <div class="ml-4 mr-auto overflow-hidden">
                <div class="font-medium truncate">{{newsTitle}}</div>
                <div class="text-gray-600 text-xs mt-0.5">{{newsDate}}</div>
            </div>
        </div>
    </a>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    props:{
        newsTitle: String,
        newsDate: String,
        newsImage: String
    }
})
</script>

<style>

</style>