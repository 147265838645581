import axios from "axios";

const INIT_AUTH_VALUE = {
    keycloak: {}
};

const state = () => {
    return {
        ...INIT_AUTH_VALUE
    };
};

// actions
const actions = {
    clearStore({
        commit
    }) {
        commit("clearStore");
    },
    setStore({
        commit
    }, store) {
        commit("setStore", store);
    },
    setToken({
        commit
    }, token) {
        commit("setToken", token);
    },
};

// mutations
const mutations = {
    clearStore(state) {
        state = {
            ...INIT_AUTH_VALUE
        };
    },
    setStore(state, store) {
        Object.assign(state, store);
    },
    setToken(state, token) {
        state.token = token;
    },
};

// getters
const getters = {
    getAllKeycloak: (state) => state,
    isAuth: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return true;
        } else {
            return false;
        }
    },
    getToken: (state) => state.token,
    getCreateLoginUrl: (state) => state.createLoginUrl,
    getAuthenticated: (state) => state.authenticated,
    getTokenParsed: (state) => state.tokenParsed,
    getUserID: (state) => state.tokenParsed.preferred_username,
    getUserAuth: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return true
        } else {
            return false
        }
    },
    getUserRoleCode: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return "cm" //state.tokenParsed.roleCode
        } else {
            return ""
        }
    },
    getUsername: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return state.tokenParsed.position
        } else {
            return "ผู้ใช้งานทั่วไป"
        }
    },

    // phat comment
    // getSubordinateVt: (state) => {
    //     if (typeof state.tokenParsed != "undefined") {
    //         return state.tokenParsed.subordinateVt
    //     } else {
    //         return "ผู้ใช้งานทั่วไป"
    //     }
    // },
    getUserPositionNameShort: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return state.tokenParsed.position
        } else {
            return ""
        }
    },
    getAreaCode: (state) => {
        if (typeof state.tokenParsed != "undefined") {
            return state.tokenParsed.accessible_area_code;
        } else {
            return ""
        }
    },
    getRole: (state) => {
        if (typeof state.tokenParsed != "undefined" && state.tokenParsed?.resource_access?.logbook?.roles) {
            return state.tokenParsed.resource_access.logbook.roles
        } else {
            return null
        }
    },
    getUserProvinceName: (state) => {},
    getUserProvinceCode: (state) => {
        let a;
        if (typeof state.tokenParsed != "undefined") {
            if (state.tokenParsed.accessible_area_code.length === 6) {
                a = state.tokenParsed.accessible_area_code.slice(0, 2)
            } else if (state.tokenParsed.accessible_area_code.length === 4) {
                a = state.tokenParsed.accessible_area_code.slice(0, 2)
            } else {
                a = state.tokenParsed.accessible_area_code;
            }
            return a;
        } else {
            return ""
        }
    },
    getUserAmphurCode: (state) => {
        let a;
        if (typeof state.tokenParsed != "undefined") {
            if (state.tokenParsed.accessible_area_code.length === 6) {
                a = state.tokenParsed.accessible_area_code.slice(0, 4)
            } else {
                a = state.tokenParsed.accessible_area_code;
            }
            return a;
        } else {
            return ""
        }
    },
    getUserTumbolCode: (state) => {
        let a;
        if (typeof state.tokenParsed != "undefined") {
            a = state.tokenParsed.accessible_area_code;
            return a;
        } else {
            return ""
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};