<template>
  <div>
    <!-- data-backdrop="static" -->
    <div
      id="static-backdrop-modal-preview-announcements"
      class="modal grid justify-center content-center"
      tabindex="-1"
      aria-hidden="true"
      :data-backdrop="modalAttributesComputed"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content bg-theme-25 px-2">
          <div class="modal-header">
            <h3 class="font-medium text-base mr-auto font-bold">
              {{ titleTextComputed }}
            </h3>
            <button
              v-if="closeAbleComputed"
              @click="closeModal"
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              data-modal-toggle="defaultModal"
            >
              <svg
                class="w-5 h-5"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="modal-body grid grid-cols-12 pb-0">
            <div class="md:col-span-12 xs:col-span-12">
              <div v-for="(item, index) in messageArrayComputed" :key="index">
                <p>{{ item }}</p>
                <br />
              </div>
            </div>
          </div>
          <div class="modal-footer text-right" v-if="closeAbleComputed">
            <button
              type="button"
              class="btn btn-primary w-15 font-medium"
              @click="closeModal"
            >
              {{ buttonTextComputed }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, computed } from "vue";
import axios from "axios";

const announcementsData = ref({});
const visitAble = ref(false);

const buttonTextComputed = computed(() =>
  announcementsData.value.BUTTON_TEXT
    ? announcementsData.value.BUTTON_TEXT
    : "รับทราบ"
);

const titleTextComputed = computed(() =>
  announcementsData.value.TITLE ? announcementsData.value.TITLE : "ประกาศ"
);

const messageArrayComputed = computed(() =>
  announcementsData.value.MESSAGE ? announcementsData.value.MESSAGE : []
);

const closeAbleComputed = computed(() =>
  announcementsData.value.USER_CAN_CLOSE
    ? announcementsData.value.USER_CAN_CLOSE
    : true
);

const modalAttributesComputed = computed(() => {
  if (announcementsData.value.USER_CAN_CLOSE) return undefined;
  return "static";
});

onMounted(() => {
  fetchAnnouncementData();
});

const closeModal = async () => {
  if (closeAbleComputed.value)
    cash("#static-backdrop-modal-preview-announcements").modal("hide");
};

const showModal = async () => {
  if (visitAble.value)
    await cash("#static-backdrop-modal-preview-announcements").modal("show");
};

const fetchAnnouncementData = async () => {
  const url = `https://storage.thaisme.one/mso/api/v1/modal/MSO_MODAL_MESSAGE.json`;
  const res = await axios.get(url).catch((err) => {
    console.log("error", err);
  });
  if (res.status === 200) {
    announcementsData.value = res.data.DATA ? res.data.DATA: {};
    visitAble.value = res.data.IS_VISIBLE ? res.data.IS_VISIBLE : false;
    showModal();
  }
};
</script>

<style></style>
